import {
  Email,
  FileRequest,
  ItemStatusType,
  Recipient,
  Section,
  SectionItem,
  SectionItemType,
  UserAccount,
  Workspace,
} from '@core/models';

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserAccountService } from '../UserAccountService';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceService } from './workspace.service';
import { HttpClient } from './http.client';
import { OrganizationService } from './organization.service';
import { WorkflowUpdateService } from '@app/feature/workspace/details/workspace-layout/workspace-workflow/workflow-update.service';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private baseUrl = `${environment.apiBaseUrl}/communication/email/v1`;

  constructor(
    private http: HttpClient,
    private userAccountService: UserAccountService,
    private translate: TranslateService,
    private workspaceService: WorkspaceService,
    private organizationService: OrganizationService,
    private workflowUpdateService: WorkflowUpdateService
  ) {}

  async communicationSettings(): Promise<{ [key: string]: boolean }> {
    if (!this.currentWorkspace.organization) {
      const organizationId = this.currentWorkspace.creator.organizationId;
      this.currentWorkspace.organization = await this.organizationService.get(organizationId);
    }
    const organization = this.currentWorkspace.organization;
    return {
      [SectionItemType.FileRequest]: organization?.requestContentItemUpdateNotification,
      [SectionItemType.Section]: organization?.sectionUpdateNotification,
    };
  }

  get user(): UserAccount {
    return this.userAccountService.user;
  }

  get currentWorkspace(): Workspace {
    return this.workspaceService.currentWorkspace;
  }

  async sendEmail(mail: Email): Promise<void> {
    const languageCode = mail.languageCode || this.translate.defaultLang;
    const email = { ...mail, languageCode };
    await this.http.post(`${this.baseUrl}`, email);
  }

  async notifyFileRequestChange(sectionItem: FileRequest): Promise<void> {
    const section = this.currentWorkspace.getSection(sectionItem.parentId);
    const rcis = this.getVisibleRcis(section);
    const sectionApproved = rcis.every((rci) => rci.status === ItemStatusType.Approved);
    const sectionReceived = rcis.every(
      (rci) => rci.status === ItemStatusType.Received || rci.status === ItemStatusType.Approved
    );
    const communicationSettings = await this.communicationSettings();
    if (communicationSettings[SectionItemType.Section] && (sectionApproved || sectionReceived)) {
      const status = sectionApproved ? ItemStatusType.Approved : ItemStatusType.Received;
      this.notifySectionItemChange(section, status);
    } else {
      this.notifySectionItemChange(sectionItem, sectionItem.status, section.name);
    }
  }

  private getVisibleRcis(section: Section): FileRequest[] {
    const getMatchedRcis = (rci: FileRequest): boolean => this.workflowUpdateService.isVisible(rci);
    return section.rcis.filter(getMatchedRcis);
  }

  private async notifySectionItemChange(
    sectionItem: SectionItem,
    statusKey: ItemStatusType,
    groupName?: string
  ): Promise<void> {
    const communicationSettings = await this.communicationSettings();
    if (!communicationSettings[sectionItem.type]) return;
    const workspace = this.workspaceService.currentWorkspace;
    const creator: Recipient = {
      recipientEmailAddress: workspace.creator.email,
      recipientName: workspace.creator.fullName,
    };
    const sharedUsers = workspace.shares
      .filter((share) => share.user?.organizationId === workspace.creator?.organizationId)
      .map((share) => ({
        recipientEmailAddress: share.email,
        recipientName: share.user?.fullName,
      }));
    const messageKey =
      sectionItem.type === SectionItemType.Section
        ? 'SectionUpdateNotification'
        : 'RciUpdateNotification';
    const status = this.translate.instant(statusKey).toLowerCase();
    const name = sectionItem.name;
    const sender = this.user.fullName;
    this.translate.use(workspace.creator.languageCode);
    const email: Email = {
      senderAddress: this.user.email,
      senderName: this.user.email,
      templateId: environment.sectionItemNotificationTemplateId,
      recipients: [creator, ...sharedUsers].filter(
        (r) => r.recipientEmailAddress !== this.user.email
      ),
      languageCode: workspace.creator.languageCode,
      templateData: {
        NameReceiver: workspace.creator.fullName,
        Sender: this.user.email,
        SectionItemType: this.translate.instant(sectionItem.type).toLowerCase(),
        SectionItemTitle: sectionItem.name,
        BucketName: workspace.name,
        ChangeDescription: this.translate.instant(messageKey, { status, groupName, name, sender }),
        BucketURL: `${document.baseURI}workspace/${workspace.id}`,
      },
    };
    this.translate.use(this.user.languageCode);
    await this.sendEmail(email);
  }
}
