import { Component, Input, OnInit } from '@angular/core';
import { TabsComponent } from '@app/shared/tabs/tabs.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tab',
  template: `
    <div *ngIf="active === true">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @Input() tabTitle: string;
  @Input() iconClass: string;
  @Input() icon: string;
  @Input() id: string;
  active = false;

  @Input() visible = true;

  @Input() registrationUrl: string;

  constructor(private tabs: TabsComponent, private route: ActivatedRoute) {
    tabs.addTab(this);
  }

  ngOnInit(): void {
    // Let's check if the fragment is provided in the routing.
    // If so, mark this tab active.
    this.route.fragment.subscribe((fragment: string) => {
      if (this.registrationUrl.indexOf(fragment) > 0) {
        this.tabs.selectTab(this);
      }
    });
  }
}
