import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Invoice } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private baseUrl = `${environment.apiBaseUrl}/payment`;

  constructor(private http: HttpClient) {}

  list(): Observable<HttpResponse<Invoice[]>> {
    return this.http.get<Invoice[]>(`${this.baseUrl}/invoice/v1`, {
      observe: 'response',
    });
  }

  all(): Observable<HttpResponse<Invoice[]>> {
    return this.http.get<Invoice[]>(`${this.baseUrl}/invoice/v1/all`, {
      observe: 'response',
    });
  }

  forOrganization(organizationId: string): Observable<HttpResponse<Invoice[]>> {
    return this.http.get<Invoice[]>(`${this.baseUrl}/invoice/v1/${organizationId}`, {
      observe: 'response',
    });
  }

  getDownloadUrl(invoiceId: string): Observable<HttpResponse<any>> {
    return this.http.get(`${this.baseUrl}/download/url/${invoiceId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  download(invoiceId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.baseUrl}/download/${invoiceId}`, {
      observe: 'response',
    });
  }
}
