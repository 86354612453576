import { Pipe, PipeTransform } from '@angular/core';
import { ItemStatusFilters } from '@app/core/models/item-status-filters';
import { ItemStatusType, SectionItem, SectionItemType } from '@core/models';

@Pipe({
  name: 'itemStatusFilter',
  pure: false,
})
/* eslint-disable no-bitwise */
export class ItemStatusFilterPipe implements PipeTransform {
  transform(items: Array<SectionItem>, ...selectedStates: ItemStatusFilters[]): Array<SectionItem> {
    // Convert to string array
    const keys: string[] = selectedStates.filter((s) => ItemStatusFilters[s]);

    return items.filter(
      (item) =>
        selectedStates.length === 0 ||
        !selectedStates[0] ||
        selectedStates.includes(ItemStatusFilters.None) ||
        (item.type === SectionItemType.ContentItem &&
          selectedStates.includes(ItemStatusFilters.AdditionalFiles)) ||
        (item.type === SectionItemType.Question &&
          selectedStates.includes(ItemStatusFilters.Questions)) ||
        item.type === SectionItemType.Section ||
        (item.type === SectionItemType.FileRequest &&
          keys.includes(ItemStatusType[item.status || ItemStatusType.Pending])) ||
        (item.type === SectionItemType.FileRequest &&
          item.status === ItemStatusType.Unknown &&
          keys.includes(ItemStatusType.Pending))
    );
  }
}
