<app-modal-popup
  #confirmSubscription
  (eventPrimaryButtonClick)="updateSubscription()"
  [popupTitle]="'UpdatePlanConfirmTitle' | translate"
  [primaryButtonText]="'Save' | translate"
  [requiresConsent]="true">
  <div class="popup-content rename-form">
    <label>
      {{ 'UpdatePlanConfirmMessagePrefix' | translate }}
      <a [routerLink]="['/support']" fragment="terms and conditions" target="_blank">
        {{ 'Terms and conditions' | translate }}
      </a>
      .
      <br />
      <br />
      {{ 'UpdatePlanConfirmMessagePostfix' | translate }}
    </label>
  </div>
</app-modal-popup>

<div *ngIf="!organization?.id">
  {{ 'FillOrganizationDetailsFirst' | translate }}
</div>
<div *ngIf="organization?.id">
  <app-subscription-card
    (selectSubscription)="selectSubscription($event)"
    *ngIf="freeSubscription"
    [buttonTitle]="'Switch to Basic' | translate"
    [name]="'Basic'"
    [pricingSubheader]="'Free' | translate"
    [subscription]="freeSubscription">
    <div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ freeSubscription.maxUsers }} {{ 'user' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ freeSubscription.maxFileSets }} {{ 'template' | translate }}</label>
      </div>
      <div class="feature-check">
        <!--TODO make configurable -->
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>5 {{ 'Workspaces' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Able to connect' | translate }}
          {{ freeSubscription.maxNumberOfRelations }}
          {{ 'relations' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Workspace' | translate }} {{ 'use of' | translate
          }}{{ freeSubscription.workspaceRetentionInDays }}
          {{ 'days for use' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ freeSubscription.maxWorkspaceSizeInGb }}GB
          {{ 'storage per workspace' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <!--TODO make configurable -->
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>5GB {{ 'total storage' | translate }}</label>
      </div>
      <div class="feature-check">
        <!--TODO make configurable -->
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Workspace' | translate }} {{ 'sharing with' | translate }} 5
          {{ 'people' | translate }}
        </label>
      </div>
    </div>
  </app-subscription-card>
  <app-subscription-card
    (selectSubscription)="selectSubscription($event)"
    *ngIf="agencySubscription"
    [buttonTitle]="'Switch to Starter' | translate"
    [name]="'Starter'"
    [subscription]="agencySubscription"
    [userPrice]="'€20'">
    <div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ freeSubscription.maxUsers }} - {{ agencySubscription.maxUsers }}
          {{ 'users' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ agencySubscription.maxFileSets }} {{ 'templates' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Unlimited' | translate }} {{ 'amount of' | translate }} {{ 'Workspaces' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Able to connect' | translate }}
          {{ agencySubscription.maxNumberOfRelations }}
          {{ 'relations' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Workspace' | translate }} {{ 'use of' | translate
          }}{{ agencySubscription.workspaceRetentionInDays }}
          {{ 'days for use' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ agencySubscription.maxWorkspaceSizeInGb }}GB
          {{ 'storage per workspace' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <!--TODO make configurable -->
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>1TB {{ 'total storage' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Sharing with unlimited people' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Analytics' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Custom branding' | translate }}</label>
      </div>
    </div>
  </app-subscription-card>
  <app-subscription-card
    (selectSubscription)="selectSubscription($event)"
    *ngIf="professionalSubscription"
    [buttonTitle]="'Switch to Professional' | translate"
    [mostPopular]="true"
    [name]="'Professional'"
    [subscription]="professionalSubscription"
    [userPrice]="'€40'">
    <div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ agencySubscription.maxUsers }} - {{ professionalSubscription.maxUsers }}
          {{ 'users' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Unlimited' | translate }} {{ 'amount of' | translate }}
          {{ 'templates' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Unlimited' | translate }} {{ 'amount of' | translate }} {{ 'Workspaces' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>
          {{ 'Able to connect' | translate }}
          {{ professionalSubscription.maxNumberOfRelations }}
          {{ 'relations' | translate }}
        </label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Workspace use of 1 year' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Unlimiteded' | translate }} {{ 'storage per workspace' | translate }}</label>
      </div>
      <div class="feature-check">
        <!--TODO make configurable -->
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>20TB {{ 'total storage' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Sharing with unlimited people' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Extended analytics' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Customer support' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'AuditLog' | translate }}</label>
      </div>
    </div>
  </app-subscription-card>
  <app-subscription-card
    (selectSubscription)="openMailForm()"
    *ngIf="enterPriceSubscription"
    [buttonTitle]="'Contact us' | translate"
    [name]="'Enterprise'"
    [pricingSubheader]="'Contact us for Enterprise solutions' | translate"
    [subscription]="enterPriceSubscription">
    <div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Unlimited users' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Unlimited relations' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'No expiration date' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Same day chat/email support' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Custom integrations' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Choose data location' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Usage training & consultancy' | translate }}</label>
      </div>
      <div class="feature-check">
        <span class="material-icons-round material-icon-approved">check_circle</span>
        <label>{{ 'Uptime SLA' | translate }}</label>
      </div>
    </div>
  </app-subscription-card>
</div>
<section class="always-included">
  <h3>{{ 'Always included' | translate }}</h3>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>{{ 'Secure & fast' | translate }}</label>
  </div>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>{{ 'Send and receive files' | translate }}</label>
  </div>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>{{ 'Create and delete workspaces' | translate }}</label>
  </div>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>
      {{ 'Workspaces workflows (approve, deny, comments)' | translate }}
    </label>
  </div>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>{{ 'Share workspaces with link and email' | translate }}</label>
  </div>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>{{ 'Protected files and workspaces' | translate }}</label>
  </div>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>{{ 'Multilingual interface' | translate }}</label>
  </div>
  <div class="feature-check">
    <span class="material-icons-round material-icon-approved">check_circle</span>
    <label>
      <b>{{ 'And much more' | translate }}</b>
    </label>
  </div>
</section>
