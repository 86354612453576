<div class="workspace-container-wrapper" [class.about-to-expire]="workspace.aboutToExpire">
  <a
    class="workspace-container"
    [routerLink]="['/workspace', workspace.id]"
    [class.about-to-expire]="workspace.aboutToExpire">
    <div class="workspace-info">
      <div class="workspace-item-header">
        {{ workspace.name }}

        <div *ngIf="showShares(workspace)" class="share-info">
          <div *ngFor="let share of shares; let i = index">
            <app-user-avatar
              [userName]="share.name"
              [avatar]="share.user?.avatar"
              [class]="'avatar' + i"
              [avatarColor]="share.avatarColor"></app-user-avatar>
          </div>
          <span *ngIf="workspace.shares.length > 5" class="others">
            {{ workspace.shares.length - 5 }} {{ 'others' | translate }}...
          </span>
          <span
            class="material-icons-outlined material-icon-rejected"
            *ngIf="workspace.aboutToExpire">
            report_problem
          </span>
        </div>
      </div>
    </div>
    <hr />
    <div class="workspace-stats">
      <div class="workspace-stats-container">
        <div class="workspace-stat">
          <span class="material-icons">update</span>
          {{
            workspace.lastModifiedTimestampUtc | date : 'mediumDate' : '' : translate.currentLang
          }}
        </div>

        <div class="statistics-bar" *ngIf="workspace.allRcis.length > 0">
          <app-workspace-statistics
            [workspace]="workspace"
            [showInvisible]="false"></app-workspace-statistics>
        </div>
      </div>
    </div>
  </a>
  <div *ngIf="workspace.isOwner">
    <div class="click menu-button" [class.about-to-expire]="workspace.aboutToExpire" tabindex="-1">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
    <div class="popup popout-menu">
      <ul class="menu">
        <li *ngIf="workspace.requestContentItemStatus !== statusTypes.Archived">
          <button (click)="requestWorkspaceArchive(workspace)">
            <span class="material-icons-outlined">archive</span>
            {{ 'Archive' | translate }}
          </button>
        </li>
        <li>
          <button
            (click)="requestWorkspaceDelete(workspace)"
            class="remove-button"
            *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Workspaces' }]">
            <span class="material-icons material-icon-rejected">delete_outline</span>
            {{ 'Remove' | translate }}
          </button>
        </li>
        <li>
          <button (click)="requestWorkspaceShare(workspace)">
            <span class="material-icons material-icon-share">share</span>
            {{ 'Share' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
