<div *ngIf="type === 'title'">
  <input
    #textField
    #titleInput="ngModel"
    id="{{ id }}"
    (blur)="parentBlur()"
    (keyup)="parentBlur(true)"
    (ngModelChange)="hasTitleError = titleInput.errors?.pattern"
    [(ngModel)]="item.title ? item.title : item.name"
    [pattern]="titlePattern"
    class="flex-input"
    placeholder="{{ placeholder }}"
    type="text"
    [disabled]="disabled"
    value="{{ item.title ? item.title : item.name }}" />
</div>
<div *ngIf="type === 'description'">
  <input
    #textField
    id="{{ id }}"
    (blur)="parentBlur()"
    (keyup)="parentBlur(true)"
    [(ngModel)]="item.description"
    class="flex-input description"
    placeholder="{{ placeholder }}"
    type="text"
    [disabled]="disabled"
    value="{{ item?.description }}" />
</div>
