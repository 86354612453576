import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Invoice, OrganizationListItem, PermissionType, UserAccount } from '@core/models';
import {
  InvoiceService,
  OrganizationService,
  SubscriptionService,
  TitleService,
} from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-supers-settings',
  templateUrl: './supers-settings.component.html',
  styleUrls: ['./supers-settings.component.scss'],
})
export class SupersSettingsComponent implements OnInit {
  public permissionTypes = PermissionType;
  public organizations: OrganizationListItem[];
  public subscriptions;
  public invoices: Invoice[];

  organizationSearchText: string;

  dashboardUrl = environment.dashboardBaseUrl;
  metricsUrl = `${environment.dashboardBaseUrl}/grafana/d/linkerd-namespace/linkerd-namespace?orgId=1&var-namespace=default&refresh=5s`;

  constructor(
    titleService: TitleService,
    private router: Router,
    public translate: TranslateService,
    private organizationService: OrganizationService,
    private subscriptionService: SubscriptionService,
    private invoiceService: InvoiceService
  ) {
    titleService.set(translate.instant('Super settings'));
  }

  async ngOnInit(): Promise<void> {
    const invoices = (await this.invoiceService.all().toPromise()).body;
    this.invoices = invoices.sort((a, b) =>
      a.lastModifiedTimestampUtc > b.lastModifiedTimestampUtc ? -1 : 1
    );
    this.subscriptions = await this.subscriptionService.list();
    this.organizations = (await this.organizationService.list()).map(
      (o) => new OrganizationListItem(o, this.subscriptions)
    );
  }

  subscriptionName(id: string): string {
    for (const subscription of this.subscriptions) {
      if (subscription.id === id) {
        return this.translate.instant(subscription.name);
      }
    }
    return this.translate.instant('Unknown');
  }

  isValidDate(date: Date): boolean {
    return new Date(date).getFullYear() > 1970;
  }

  amountOfActiveUsers(users: UserAccount[]): number {
    let amount = 0;
    users.forEach((u) => {
      if (u.isActive) {
        amount++;
      }
    });
    return amount;
  }

  goToDetailsPage(id: string): void {
    this.router.navigate([`/settings/supersettings/${id}`]);
  }
}
