<div class="invite-searchbar-full">
  <div class="input" (click)="openResults()">
    <span class="material-icons-outlined">reply</span>
    <input
      (input)="processSearchInput($event)"
      placeholder="{{ 'email-or-name' | translate }}"
      type="search"
      [(ngModel)]="searchText"
      [autofocus]="true" />
  </div>
  <div class="found-members" *ngIf="showResultBar && searchText !== ''">
    <div *ngFor="let share of membersMatchingSearchString">
      <div class="person-row" *ngIf="!shareExists(share)">
        <app-person-row
          (click)="onClickNewMember(share)"
          [share]="share"
          [showMenu]="false"
          [isSelectable]="true"
          [smallPadding]="true"
          [smallAvatar]="true"></app-person-row>
      </div>
    </div>
    <!--User enters a new Email-address which is unknown to us.-->
    <div
      *ngIf="
        membersMatchingSearchString.length === 0 &&
        isEmail(searchText) &&
        !isExistingUser(searchText)
      "
      (click)="onclickNewMemberByEmail(searchText)">
      <div class="add-new-user-content">
        <span class="material-icons add-user-icon">person_add</span>
        <span *ngIf="translate.currentLang === 'en'">
          {{ 'Invite' | translate }} {{ searchText }}
        </span>
        <span *ngIf="translate.currentLang === 'nl'">
          {{ searchText }} {{ ('Invite' | translate).toLowerCase() }}
        </span>
      </div>
    </div>
  </div>
</div>
