import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationRoutingModule } from './organization-routing.module';
import { OrganizationComponent } from './organization.component';
import { SharedModule } from '@app/shared/shared.module';
import { OrganizationDetailComponent } from './organization-detail/organization-detail.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PaymentsComponent } from './payments/payments.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionCardComponent } from './subscription/subscription-card/subscription-card.component';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  declarations: [
    OrganizationComponent,
    OrganizationDetailComponent,
    SubscriptionComponent,
    PaymentsComponent,
    SubscriptionCardComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    OrganizationRoutingModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    ColorPickerModule,
  ],
})
export class OrganizationModule {}
