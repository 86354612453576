import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageService } from '@core/services/api/message.service';
import { TranslateService } from '@ngx-translate/core';
import { ContextService } from '@core/services';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() message;
  @Input() ownEmail: string;
  @Input() canEdit = false;
  @Output() refreshList: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('messageElement') messageElement: ElementRef;
  @ViewChild('editArea') editArea: ElementRef;

  editingMessage = false;
  editMessageDisabled = false;
  originalMessage = '';
  menu = [
    {
      name: this.translate.instant('edit-message'),
      action: () => this.switchEdit(),
    },
    {
      name: this.translate.instant('remove-message'),
      action: () => this.removeMessage(),
    },
  ];

  constructor(
    public translate: TranslateService,
    private messageService: MessageService,
    public contextService: ContextService
  ) {}

  switchEdit(): void {
    this.contextService.hide();
    this.originalMessage = this.message.message;
    this.editingMessage = !this.editingMessage;

    if (this.editingMessage) {
      //wait for angular draw
      setTimeout(() => {
        this.inputTextHeight();
        this.editArea.nativeElement.focus();
      });
    }
  }

  isEmptyOrSpacesEditMessage(message: string): void {
    this.editMessageDisabled =
      message === undefined || message === '' || message.match(/^ *$/) !== null;
  }

  async saveEdit(): Promise<void> {
    if (this.originalMessage.length) {
      await this.messageService
        .update(this.message.id, { message: this.originalMessage })
        .toPromise();

      this.message.message = this.originalMessage;
    }
    this.editingMessage = false;
  }

  inputTextHeight(): void {
    if (this.editArea) {
      //back to zero for limit calculation
      this.editArea.nativeElement.style.height = 0;
      this.editArea.nativeElement.style.height =
        this.editArea.nativeElement.scrollHeight + 4 + 'px';
    }
  }

  async removeMessage(): Promise<void> {
    this.contextService.hide();
    await this.messageService.delete(this.message.id).toPromise();
    this.refreshList.emit();
  }
}
