<tabs>
  <tab
    [registrationUrl]="'User | General info'"
    icon="badge"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Information' | translate }}">
    <app-user-account
      *ngIf="userId"
      [titleShouldChange]="true"
      [userId]="userId"></app-user-account>
  </tab>
  <tab
    *ngIf="editRole < EditRole.NoAccess"
    [registrationUrl]="'User | Teams'"
    icon="groups"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Teams' | translate }}">
    <app-users-teams
      *ngIf="userId && editRole < EditRole.NoAccess"
      [userId]="userId"></app-users-teams>
  </tab>
  <tab
    *ngIf="editRole < EditRole.NoAccess"
    [registrationUrl]="'User | Roles'"
    icon="shield"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Roles' | translate }}">
    <app-users-roles *ngIf="userId" [userId]="userId"></app-users-roles>
  </tab>
</tabs>
