<div
  *ngIf="share"
  [ngClass]="[
    isSelectable ? 'isSelectable' : '',
    smallPadding ? 'small-padding-left-right' : 'default-padding-left-right'
  ]"
  class="persona-row-container">
  <div class="left-side">
    <app-user-avatar
      [avatarColor]="share.avatarColor"
      [avatar]="share.avatar"
      [isOwn]="false"
      [isUser]="isUser"
      [showTooltip]="false"
      [style.align-self]="smallAvatar ? 'center' : ''"
      [style.height]="smallAvatar ? '25px' : '35.5px'"
      [style.width]="smallAvatar ? '25px' : '35.5px'"
      [userName]="share.name"></app-user-avatar>

    <div class="name-email">
      <span class="boldText full-name">{{ share.name || '' }}</span>
      <span class="email-address">{{ (kind | translate) || share.email }}</span>
    </div>
  </div>

  <div *ngIf="showMenu" class="right-side">
    <div class="person-row-options">
      <div *ngIf="showDefaultShare" class="checkbox-item">
        <label class="checkboxcontainer--new">
          {{ 'auto-share' | translate }}
          <input
            (change)="toggleIsDefaultShare.emit()"
            [(ngModel)]="share.isDefaultShare"
            [checked]="share.isDefaultShare"
            type="checkbox" />
          <span class="checkmark--new"></span>
        </label>
        <span
          [largeTooltip]="true"
          [tooltip]="'auto-share-description' | translate"
          class="material-icons info-tooltip">
          help
        </span>
      </div>
      <!--Dropdown desktop rights-->
      <app-dropdown *ngIf="!onMobile" [label]="share.permissionType | translate">
        <app-dropdown-item
          (response)="changePermissionType.emit($event)"
          *ngFor="let permissionType of permissionTypes"
          [key]="permissionType"
          [title]="permissionType | translate"></app-dropdown-item>
      </app-dropdown>
      <!--Dropdown desktop menu-->
      <icon-button
        (click)="deleteMember.emit()"
        [icon]="'delete'"
        [title]="'Remove' | translate"></icon-button>
      <!--Mobile menu-->
      <div *ngIf="onMobile" class="mobile-menu-button">
        <span class="label">
          {{ share.permissionType | translate }}
        </span>
        <span class="material-icons-outlined">more_horiz</span>
      </div>
    </div>
  </div>
</div>
