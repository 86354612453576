<tabs>
  <tab
    [registrationUrl]="'Role | General info'"
    icon="badge"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Information' | translate }}">
    <div *ngIf="role" class="settings-row">
      <div class="workspace-settings-group">
        <h3>{{ 'General information' | translate }}</h3>
        <div class="workspace-settings-object">
          <div class="input-group">
            <label class="settings-title" for="name">
              {{ 'Name' | translate }}
            </label>
            <input
              #roleNameInput
              (ngModelChange)="role.name = $event; updateRole()"
              *ngIf="!role.isSystemRole"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
              [ngModel]="role.name"
              autocomplete="__away"
              id="name"
              type="text" />
            <input
              *ngIf="role.isSystemRole"
              [disabled]="role.isSystemRole"
              autocomplete="__away"
              type="text"
              value="{{ role.name | translate }}" />
          </div>

          <div class="input-group">
            <label class="settings-title" for="description">
              {{ 'Description' | translate }}
            </label>
            <textarea
              (ngModelChange)="role.description = $event; updateRole()"
              [(ngModel)]="role.description"
              [disabled]="role.isSystemRole"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
              autosize
              id="description"
              name="description"
              placeholder="{{ 'Description' | translate }}"
              rows="1"></textarea>
          </div>
        </div>
        <h3>{{ 'Other actions' | translate }}</h3>
        <button
          (click)="confirmDeleteRole()"
          *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Roles' }]"
          [disabled]="role.isSystemRole"
          class="btn-secondary-large"
          click-stop-propagation>
          <span class="material-icons material-icon-rejected">delete_outline</span>
          {{ 'DeleteRoleActionButton' | translate }}
        </button>
      </div>
    </div>
  </tab>
  <tab
    [registrationUrl]="'Role | Permissions'"
    icon="security"
    iconClass="material-icons"
    tabTitle="{{ 'Permissions' | translate }}">
    <app-roles-permissions [role]="role"></app-roles-permissions>
  </tab>
  <tab
    [registrationUrl]="'Role | Team'"
    icon="groups"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Teams' | translate }}">
    <app-roles-teams [role]="role"></app-roles-teams>
  </tab>
  <tab
    [registrationUrl]="'Role | Users'"
    icon="people"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Users' | translate }}">
    <app-roles-users [role]="role"></app-roles-users>
  </tab>
</tabs>

<app-modal-popup
  #warnAboutChangePagePopup
  (eventPrimaryButtonClick)="navigateDespiteWarning()"
  [popupTitle]="'WarningDoubleRelation' | translate">
  <div>
    {{ 'WarningDoubleRelationTextOne' | translate }}
    <span *ngIf="role">{{ role.name }}.</span>
    <br />
    {{ 'WarningDoubleRelationTextTwo' | translate }}{{ duplicateName }}
  </div>
</app-modal-popup>

<app-modal-popup
  #deleteTeamPopup
  (eventPrimaryButtonClick)="deleteRole()"
  [popupTitle]="'DeleteTeamTitle' | translate"
  [primaryButtonText]="'DeleteCaption' | translate">
  <div>{{ 'ConfirmTeamDelete' | translate }}</div>
</app-modal-popup>
