<div *ngIf="role && memberships">
  <div class="section-header">
    <input
      [(ngModel)]="membershipSearchText"
      class="searchBox"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>
  <section *ngIf="memberships.length > 0">
    <table>
      <tr>
        <th>{{ 'Assigned' | translate }}</th>
        <th [appSort]="memberships" class="sortable" data-name="name" scope="col">
          {{ 'Name' }}
          <app-sort-icon></app-sort-icon>
        </th>
      </tr>
      <tr *ngFor="let membership of memberships | membershipFilter: membershipSearchText">
        <td>
          <label class="checkboxcontainer">
            <input
              (change)="toggleMembershipActiveForRole(membership)"
              [checked]="checkMembershipActiveForRole(membership)"
              type="checkbox" />
            <span class="checkmark"></span>
          </label>
        </td>
        <td>
          {{ membership.name }}
        </td>
      </tr>
    </table>
  </section>
</div>
