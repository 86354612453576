<div class="workspace-menu" [contextMenu]="menu">
  <div class="workspace-menu__wrapper" [class.active]="active">
    <app-breadcrumb
      [workspace]="workspace"
      [title]="workspace.isTemplate ? 'Template' : 'Workspace'"></app-breadcrumb>

    <div class="right-part">
      <div
        [tooltip]="'create-at-least-one-section' | translate"
        class="bulk-edit-menu"
        *ngIf="workspace.containsOwnedSections && !workspace.isTemplate"
        isActivated="{{ workspace.isEmpty }}">
        <div class="lightswitch" (mouseup)="changeLightswitch()" [class.checked]="isEdit | async">
          {{ 'Edit' | translate }}
          <i></i>
        </div>
      </div>
      <app-three-dot-menu [menu]="menu"></app-three-dot-menu>
    </div>
  </div>

  <div class="workspace-menu__wrapper return" [class.active]="!active">
    <div class="return-button" (click)="returnToWorkspace()">
      <span class="material-icons-outlined">chevron_left</span>
      {{ 'return-to-workspace' | translate }}
    </div>
  </div>
</div>

<input
  #uploadButton
  (change)="uploadFolder($event)"
  (click)="$event.stopPropagation()"
  webkitDirectory
  multiple
  type="file"
  class="upload-button" />

<copy-workspace-popup #copyWorkspacePopup [workspace]="workspace"></copy-workspace-popup>

<select-template-popup [append]="true" #selectTemplateForWorkspacePopup></select-template-popup>
