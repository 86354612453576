import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatisticsUpdateService } from '@app/feature/workspace/details/workspace-layout/workspace-statistics/statistics-update.service';
import {
  FileRequest,
  IFileRequestDto,
  ItemStatusType,
  SectionItem,
  UpdateSectionItemRequest,
} from '@core/models';
import { environment } from '@env/environment';
import { IContentItemService } from './content-item.service';
import { HttpClient } from './http.client';
import { CommunicationService } from './communication.service';

const baseUrl = `${environment.apiBaseUrl}/share`;

const getBaseUrl = (workspaceId: string, sectionId: string, fileRequestId?: string): string => {
  return `${baseUrl}/v1/workspaces/${workspaceId}/sections/${sectionId}/filerequests${
    fileRequestId ? `/${fileRequestId}` : ''
  }`;
};

@Injectable({
  providedIn: 'root',
})
export class FileRequestService implements IContentItemService {
  constructor(
    private http: HttpClient,
    private statisticsUpdateService: StatisticsUpdateService,
    private communicationService: CommunicationService
  ) {}

  update(fileRequest: SectionItem, updateRequest: UpdateSectionItemRequest): Promise<boolean> {
    const { id, workspaceId, parentId: parentSectionId } = fileRequest;
    return this.http.put<UpdateSectionItemRequest>(getBaseUrl(workspaceId, parentSectionId, id), {
      ...updateRequest,
      lastModifiedTimestampUtc: new Date(),
    });
  }

  async updateStatus(fileRequest: FileRequest, status: ItemStatusType): Promise<boolean> {
    await this.statisticsUpdateService.updateStatistics(fileRequest.parentId);
    this.communicationService.notifyFileRequestChange(fileRequest);
    return this.update(fileRequest, { status });
  }

  post(workspaceId: string, sectionId: string, fileRequest: IFileRequestDto): Promise<FileRequest> {
    return this.http
      .post(getBaseUrl(workspaceId, sectionId), fileRequest)
      .then((r) => new FileRequest(r.body));
  }

  async delete(fileRequest: FileRequest): Promise<any> {
    const { id, workspaceId, parentId: parentSectionId } = fileRequest;
    this.statisticsUpdateService.updateStatistics(parentSectionId);
    return this.http.delete(getBaseUrl(workspaceId, parentSectionId, id));
  }

  async addContentItem(sectionItem: FileRequest, ciId: string): Promise<HttpResponse<any>> {
    const { id, workspaceId, sectionId, status } = sectionItem;
    const baseUrl = getBaseUrl(workspaceId, sectionId, id);
    this.statisticsUpdateService.updateStatistics(sectionId);
    await this.updateStatus(sectionItem, status);
    return this.http.post(`${baseUrl}/contentItems/${ciId}`);
  }

  async deleteContentItem(sectionItem: FileRequest, ciId: string): Promise<HttpResponse<any>> {
    const { id, workspaceId, parentId: parentSectionId, sectionId, status } = sectionItem;
    const baseUrl = getBaseUrl(workspaceId, parentSectionId, id);
    this.statisticsUpdateService.updateStatistics(sectionId);
    await this.updateStatus(sectionItem, status);
    return this.http.delete(`${baseUrl}/contentItems/${ciId}`);
  }
}
