import { Pipe, PipeTransform } from '@angular/core';
import { Contact, Relation } from '@core/models';

@Pipe({
  name: 'relationsFilter',
})
export class RelationsFilterPipe implements PipeTransform {
  transform(items: Relation[], searchText: string): Relation[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.name.toLowerCase().includes(searchText);
    });
  }
}

@Pipe({
  name: 'contactFilter',
})
export class ContactFilterPipe implements PipeTransform {
  transform(items: Contact[], searchText: string): Contact[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return (
        it.emailAddress.toLowerCase().includes(searchText) ||
        it.lastname.toLowerCase().includes(searchText) ||
        it.firstname.toLowerCase().includes(searchText)
      );
    });
  }
}
