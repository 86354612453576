import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatBytesFilterPipe } from './filters/format.bytes.filter.pipe';
import { WorkspaceFilterPipe, WorkspaceStatusFilterPipe } from './filters/workspace.filter.pipe';
import {
  TruncatePipe,
  TruncateStringInMiddleFilterPipe,
} from './filters/truncate.string.middle.filter.pipe';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { DropzoneComponent } from '@app/shared/dropzone/dropzone.component';
import { NotFoundComponent } from '@app/shared/notfound/notfound.component';
import { ContentFilterPipe } from '@app/shared/filters/contentitem.filter.pipe';
import { RouterModule } from '@angular/router';
import { ClickStopPropagationDirective } from '@app/shared/directives/click-stop-propagation';
import { ModalPopupComponent } from './directives/modal-popup/modal-popup.component';
import { UserAvatarComponent } from './directives/user-avatar/user-avatar.component';
import { TabsComponent } from '@app/shared/tabs/tabs.component';
import { TabComponent } from '@app/shared/tabs/tab.component';
import { DndDirective } from '@app/shared/dropzone/dnd.directive';
import { HeaderComponent } from '@app/shared/layout/header/header.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserFilterPipe } from '@app/shared/filters/user.filter.pipe';
import { RoleFilterPipe } from '@app/shared/filters/role.filter.pipe';
import { MembershipFilterPipe } from '@app/shared/filters/membership.filter.pipe';
import { HasPermissionDirective } from '@app/shared/directives/has-permission';
import { SortDirective } from '@app/shared/directives/sort.directive';
import { SortIndicatorComponent } from '@app/shared/directives/sort.indicator';
import { ContextMenuDirective } from '@app/shared/context-menu/context-menu.directive';
import { ContextMenuComponent } from '@app/shared/context-menu/component/context-menu.component';
import { CreateWorkspaceButtonComponent } from './create-workspace-button/create-workspace-button.component';
import { DragPreviewComponent } from '@app/shared/drag-preview/drag-preview.component';
import { ThreeDotMenuComponent } from '@app/shared/three-dot-menu/three-dot-menu.component';
import { StatusMenuComponent } from '@app/shared/status-menu/status-menu.component';
import { UpgradeButtonComponent } from './upgrade-button/upgrade-button.component';
import {
  ItemShareFilterPipe,
  WorkspaceSharePermissionsFilterPipe,
} from '@app/shared/filters/workspace-shares.filter.pipe';
import { AddressFormComponent } from './address-form/address-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContactFilterPipe, RelationsFilterPipe } from '@app/shared/filters/relations.filter.pipe';
import { TemplateSearchPanelComponent } from './template-search-panel/template-search-panel.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { EmptyAnimationComponent } from './empty-animation/empty-animation.component';
import { LottieModule } from 'ngx-lottie';
import { OrganizationFilterPipe } from '@app/shared/filters/organization.filter.pipe';
import { InvoicesComponent } from './organization/invoices/invoices.component';
import { OrganizationInformationComponent } from './organization/organization-information/organization-information.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { InvoicePaymentsComponent } from './organization/invoices/invoice-payments/invoice-payments.component';
import { NoAccessAnimationComponent } from './no-access-animation/no-access-animation.component';
import { UnauthorizedComponent } from '@app/shared/unauthorized/unauthorized.component';
import { ItemStatusFilterPipe } from '@app/shared/filters/item-status-filter.pipe';
import { VisibilityFilterPipe } from '@app/shared/filters/visibility-filter.pipe';
import { ArchiveWorkspacePopupComponent } from './pop-up/archive-workspace-popup/archive-workspace-popup.component';
import { ApproveWorkspacePopupComponent } from '@app/shared/pop-up/approve-workspace-popup/approve-workspace-popup.component';
import { WorkspaceGroupContentFilterPipe } from './filters/groupcontent.filter.pipe';
import { ContentGroupFilterPipe } from './filters/content-group.filter.pipe';
import { MaterialInputComponent } from '@app/shared/material-input/material-input.component';
import { TitleInputComponent } from '@app/shared/title-input/title-input.component';
import { PersonRowComponent } from '@app/shared/row/person-row/person-row.component';
import { IconDropdownComponent } from '@app/shared/dropdown/dropdown/icon-dropdown.component';
import { DropdownItemComponent } from './dropdown/dropdown-item/dropdown-item.component';
import { DropdownComponent } from './dropdown/dropdown/dropdown.component';
import { PersonSearchbarComponent } from './searchbar/person-searchbar/person-searchbar.component';
import { SharingPopupComponent } from '@app/shared/sharing-popup/sharing-popup.component';
import { BottomSheetShareContentComponent } from './sharing-popup/small-screens/bottom-sheet-share-content/bottom-sheet-share-content.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { OptionButtonComponent } from './option-button/option-button.component';
import { MobileOptionCheckboxComponent } from './mobile-option-checkbox/mobile-option-checkbox.component';
import { SimpleDialogComponent } from '@app/shared/sharing-popup/simple-dialog/simple-dialog.component';
import { ScrollStateDirective } from './directives/scroll-state.directive';
import { ScrollPositionFixedDirective } from './directives/scroll-position-fixed.directive';
import { FilePreviewDirective } from '@app/shared/file-preview/file-preview.directive';
import { FilePreviewComponent } from '@app/shared/file-preview/component/file-preview.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { LoadingSpinnerComponent } from '@app/shared/loading-spinner/loading-spinner.component';

import { BreadcrumbComponent } from '@app/shared/directives/breadcrumb/breadcrumb.component';
import { TagComponent } from '@app/shared/directives/tag/tag.component';
import { SmallPopupComponent } from '@app/shared/small-popup/small-popup.component';
import { ContainerComponent } from './container/container.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { ChatOverlayComponent } from '@app/shared/chat-overlay/chat-overlay.component';
import { MessageComponent } from '@app/shared/chat-overlay/message/message.component';
import { DateAgoPipe } from '@app/shared/filters/date-ago.pipe';
import { ProgressChartComponent } from '@app/shared/progress-chart/progress-chart.component';
import { MultiSelectCheckboxComponent } from '@app/shared/multi-select/multi-select-checkbox/multi-select-checkbox.component';
import { MultiSelectDirective } from '@app/shared/multi-select/multi-select.directive';
import { MultiSelectBarComponent } from '@app/shared/multi-select/multi-select-bar/multi-select-bar.component';
import { MultiSelectAllComponent } from '@app/shared/multi-select/multi-select-all/multi-select-all.component';

@NgModule({
  declarations: [
    ProgressChartComponent,
    PersonRowComponent,
    IconDropdownComponent,
    ItemStatusFilterPipe,
    VisibilityFilterPipe,
    SortIndicatorComponent,
    SortDirective,
    FilePreviewDirective,
    FilePreviewComponent,
    ContextMenuDirective,
    ContextMenuComponent,
    CreateWorkspaceButtonComponent,
    DragPreviewComponent,
    ThreeDotMenuComponent,
    StatusMenuComponent,
    HeaderComponent,
    IconButtonComponent,
    DndDirective,
    TabsComponent,
    TabComponent,
    MaterialInputComponent,
    TitleInputComponent,
    ClickStopPropagationDirective,
    ContentFilterPipe,
    WorkspaceStatusFilterPipe,
    WorkspaceFilterPipe,
    UserFilterPipe,
    MembershipFilterPipe,
    RoleFilterPipe,
    OrganizationFilterPipe,
    FormatBytesFilterPipe,
    LoadingSpinnerComponent,
    TruncateStringInMiddleFilterPipe,
    ProgressbarComponent,
    DropzoneComponent,
    NotFoundComponent,
    TagComponent,
    BreadcrumbComponent,
    ModalPopupComponent,
    UserAvatarComponent,
    HasPermissionDirective,
    UpgradeButtonComponent,
    AddressFormComponent,
    RelationsFilterPipe,
    TruncatePipe,
    TemplateSearchPanelComponent,
    WorkspaceSharePermissionsFilterPipe,
    ContactFilterPipe,
    EmptyAnimationComponent,
    UserAccountComponent,
    ItemShareFilterPipe,
    InvoicesComponent,
    OrganizationInformationComponent,
    InvoicePaymentsComponent,
    NoAccessAnimationComponent,
    UnauthorizedComponent,
    ArchiveWorkspacePopupComponent,
    WorkspaceGroupContentFilterPipe,
    ContentGroupFilterPipe,
    ApproveWorkspacePopupComponent,
    DropdownItemComponent,
    DropdownComponent,
    PersonSearchbarComponent,
    SharingPopupComponent,
    BottomSheetShareContentComponent,
    BottomSheetComponent,
    OptionButtonComponent,
    MobileOptionCheckboxComponent,
    SimpleDialogComponent,
    ScrollStateDirective,
    ScrollPositionFixedDirective,
    SmallPopupComponent,
    ContainerComponent,
    TooltipDirective,
    ChatOverlayComponent,
    MessageComponent,
    DateAgoPipe,
    MultiSelectCheckboxComponent,
    MultiSelectBarComponent,
    MultiSelectAllComponent,
    MultiSelectDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    NgSelectModule,
    LottieModule,
    ReactiveFormsModule,
    ColorPickerModule,
    NgxDocViewerModule,
  ],
  providers: [TabsComponent, HeaderComponent],
  exports: [
    ProgressChartComponent,
    AddressFormComponent,
    ApproveWorkspacePopupComponent,
    ArchiveWorkspacePopupComponent,
    WorkspaceFilterPipe,
    WorkspaceGroupContentFilterPipe,
    WorkspaceSharePermissionsFilterPipe,
    ItemStatusFilterPipe,
    VisibilityFilterPipe,
    WorkspaceStatusFilterPipe,
    ClickStopPropagationDirective,
    BreadcrumbComponent,
    ContactFilterPipe,
    ContentFilterPipe,
    ContentGroupFilterPipe,
    DndDirective,
    TagComponent,
    DropzoneComponent,
    EmptyAnimationComponent,
    FormatBytesFilterPipe,
    HasPermissionDirective,
    HeaderComponent,
    IconButtonComponent,
    InvoicePaymentsComponent,
    InvoicesComponent,
    ItemShareFilterPipe,
    MaterialInputComponent,
    MembershipFilterPipe,
    ModalPopupComponent,
    NoAccessAnimationComponent,
    NotFoundComponent,
    OrganizationFilterPipe,
    OrganizationInformationComponent,
    ProgressbarComponent,
    RelationsFilterPipe,
    RoleFilterPipe,
    SortDirective,
    SortIndicatorComponent,
    FilePreviewDirective,
    FilePreviewComponent,
    ContextMenuDirective,
    ContextMenuComponent,
    CreateWorkspaceButtonComponent,
    DragPreviewComponent,
    ThreeDotMenuComponent,
    StatusMenuComponent,
    TabComponent,
    TabsComponent,
    TemplateSearchPanelComponent,
    TitleInputComponent,
    TruncatePipe,
    TruncateStringInMiddleFilterPipe,
    UnauthorizedComponent,
    UpgradeButtonComponent,
    UserAccountComponent,
    UserAvatarComponent,
    UserFilterPipe,
    PersonRowComponent,
    IconDropdownComponent,
    DropdownItemComponent,
    DropdownComponent,
    PersonSearchbarComponent,
    SharingPopupComponent,
    SmallPopupComponent,
    ContainerComponent,
    TooltipDirective,
    ChatOverlayComponent,
    MessageComponent,
    DateAgoPipe,
    MultiSelectCheckboxComponent,
    MultiSelectBarComponent,
    MultiSelectAllComponent,
    MultiSelectDirective,
  ],
})
export class SharedModule {}
