<div
  *ngIf="loaderService.progress | async as progress"
  [ngClass]="{
    active: progress.percentage > 0,
    reset: progress.percentage === 0
  }"
  [style.width.%]="progress.percentage"
  class="progress-bar"></div>
<div [hidden]="hidePage" class="body-container">
  <div class="main-container">
    <app-header></app-header>
    <div class="fillBody">
      <router-outlet></router-outlet>
    </div>
    <context-menu></context-menu>
    <drag-preview></drag-preview>
    <app-file-preview></app-file-preview>
  </div>
</div>
