<div (click)="close()" class="relate-popup__background"></div>
<div class="relate-popup" *ngIf="question">
  <div class="relate-popup__header">
    <h2 class="title">{{ 'If answer is' | translate }}...</h2>

    <div (click)="close()" class="close">
      <span class="material-icons-outlined">close</span>
    </div>
  </div>

  <div class="relate-popup__answer">
    <label [ngClass]="labelClass" *ngIf="question.value[answerSelect]">
      {{ question.value[answerSelect].name }}
      <input checked type="checkbox" />
      <span [ngClass]="checkmarkClass"></span>
    </label>

    <label [ngClass]="labelClass" *ngIf="answerSelect === -1">
      {{ 'other-namely' | translate }}
      <input checked type="checkbox" />
      <span [ngClass]="checkmarkClass"></span>
    </label>
  </div>

  <div class="relate-popup__options" #relateMenu [class.option-selected]="selectedId">
    <h4 class="options-title">{{ 'Show' | translate }}:</h4>
    <div
      *ngFor="let section of (workspace | async).itemsSorted; let i = index"
      class="section-option option"
      [class.active]="expandedSections.includes(section.id)"
      [class.selected-option]="selectedId === section.id">
      <div class="option-line" [class.has-children]="section.items?.length > 0">
        <label class="radiocontainer" [class.disabled]="section.id === question.parentId">
          <input
            type="checkbox"
            [disabled]="section.id === question.parentId"
            [checked]="selectedId === section.id"
            (change)="changeSelected($event, section.id)" />
          <span class="radiomark"></span>
        </label>
        <div class="option__icon" (click)="collapseSection(section)">
          <span class="material-icons-outlined">{{ section.isFolder ? 'folder' : 'inbox' }}</span>
        </div>
        <span class="option__title" (click)="collapseSection(section)">
          {{ section.name }}
        </span>

        <span
          class="option__collapse"
          (click)="collapseSection(section)"
          *ngIf="section.items?.length > 0">
          <span class="material-icons-outlined">expand_more</span>
        </span>
      </div>

      <div *ngIf="section.items" class="questions-list">
        <div
          *ngFor="let questionItem of section.items"
          class="option"
          [class.selected-option]="selectedId === questionItem.id">
          <label class="radiocontainer" [class.disabled]="question.id === questionItem.id">
            <input
              type="checkbox"
              [disabled]="question.id === questionItem.id"
              [checked]="selectedId === questionItem.id"
              (change)="changeSelected($event, questionItem.id)" />
            <span class="radiomark"></span>
          </label>

          <div class="option__icon">
            <span class="material-icons-outlined" *ngIf="questionItem.sectionType">
              {{ section.isFolder ? 'folder' : 'inbox' }}
            </span>
            <span class="material-icons-outlined" *ngIf="questionItem.type === 'FileRequest'">
              file_upload
            </span>
            <span
              class="material-icons-outlined"
              *ngIf="questionItem.questionType === QuestionType.OpenQuestion">
              article
            </span>
            <span
              class="material-icons-outlined"
              *ngIf="questionItem.questionType === QuestionType.Text">
              format_size
            </span>
            <span
              class="material-icons-outlined"
              *ngIf="
                questionItem.questionType === QuestionType.MultipleChoice &&
                questionItem.properties.type === 'radio'
              ">
              radio_button_checked
            </span>
            <span
              class="material-icons-outlined"
              *ngIf="
                questionItem.questionType === QuestionType.MultipleChoice &&
                questionItem.properties.type !== 'radio'
              ">
              check_box
            </span>
            <span class="material-icons-outlined" *ngIf="questionItem.type === 'ContentItem'">
              insert_drive_file
            </span>
          </div>

          <span class="option__title">
            {{ questionItem.name }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="relate-popup__confirm">
    <button class="btn-primary btn-primary--large" (click)="confirm()">Confirm</button>
  </div>
</div>
