/**
 * Same as Promise.all(items.map(item => task(item))), but it waits for
 * the first {batchSize} promises to finish before starting the next batch.
 *
 * @template A
 * @template B
 * @param {function(A): B} task The task to run for each item.
 * @param {A[]} items Arguments to pass to the task for each call.
 * @param {int} batchSize
 * @returns {Promise<B[]>}
 */
export async function parallel<T>(
  batchSize: number,
  items: T[],
  task: (item: T, i?: number) => Promise<any>
) {
  let batch = 0;
  var copy = [...items];
  let result = [];
  while (copy.length) {
    result.push(
      ...(await Promise.all(copy.splice(0, batchSize).map((item, i) => task(item, i + batch))))
    );
    batch += batchSize;
  }
  return result;
}
