import { AfterContentInit, Component, Input } from '@angular/core';
import { IconDropdownComponent } from '@app/shared/dropdown/dropdown/icon-dropdown.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':leave', [animate('50ms', style({ opacity: 0, transform: 'translateY(10px)' }))]),
    ]),
  ],
})
export class DropdownComponent extends IconDropdownComponent implements AfterContentInit {
  @Input() label = '';
  @Input() labelTop = '';
  @Input() icon: string;
  @Input() iconStyle: string;
  @Input() dropdownStyle: string;

  constructor() {
    super();
  }

  /*
   * We Subscribe to the child component's event to be able to close the dropdown
   * */
  override ngAfterContentInit(): void {
    this.templates.forEach((template) => {
      template.response.subscribe(() => {
        this.handleEmit();
      });
    });
  }

  handleEmit(): void {
    this.close();
  }
}
