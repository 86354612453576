import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-workspace-search',
  templateUrl: './workspace-search.component.html',
  styleUrls: ['./workspace-search.component.scss'],
})
export class WorkspaceSearchComponent {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() search: string;
  @Output() searchChange = new EventEmitter();

  collapsed: boolean = false;

  update(newValue) {
    this.search = newValue;
    this.searchChange.emit(newValue);
  }

  searchButton() {
    if (this.collapsed) {
      this.update('');
    } else {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 100);
    }

    this.collapsed = !this.collapsed;
  }
}
