import { Component, OnInit, ViewChild } from '@angular/core';
import { QuotaService, RelationsService, SubscriptionService, TitleService } from '@core/services';
import { PermissionType, Relation, StatisticsGroup } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.scss'],
})
export class RelationsComponent implements OnInit {
  public permissionTypes = PermissionType;
  public relations: Relation[];
  public searchString = '';
  colorScheme = {
    domain: ['#4FCCFF', '#b7b7b7', '#CE1313'],
  };

  view = [200, 35];
  relationStatistics: StatisticsGroup[];
  @ViewChild('deleteRelationPopup') deleteRelationPopup: ModalPopupComponent;
  private focussedRelation: Relation;

  constructor(
    private relationService: RelationsService,
    public router: Router,
    public translate: TranslateService,
    subscriptionService: SubscriptionService,
    private titleService: TitleService,
    public quotaService: QuotaService
  ) {
    titleService.set(translate.instant('Settings'));
    subscriptionService.currentPlan.subscribe(() => {
      this.loadRelationStatistics();
    });
  }

  async ngOnInit(): Promise<any> {
    this.titleService.set(this.translate.instant('Settings'));
    this.relations = await this.relationService.list();
    this.loadRelationStatistics();
  }

  goToDetails(id: string): void {
    this.router.navigate([`/settings/relations/${id}`]);
  }

  confirmDeleteRelation(relation: Relation): void {
    this.deleteRelationPopup.popupActive = true;
    this.focussedRelation = relation;
  }

  async addNewRelation(): Promise<any> {
    const relationCreationResult = (
      await this.relationService.post({
        name: this.translate.instant('New relation'),
      })
    ).body;
    this.router.navigate([`/settings/relations`, relationCreationResult]);
  }

  async deleteRelation(): Promise<any> {
    await this.relationService.delete(this.focussedRelation.id);
    this.relations.splice(this.relations.indexOf(this.focussedRelation), 1);
    this.deleteRelationPopup.popupActive = false;
    this.loadRelationStatistics();
    this.relations = await this.relationService.list();
  }

  loadRelationStatistics(): void {
    if (this.relations === undefined || this.quotaService.maxNumberOfRelations === undefined) {
      return;
    }

    this.relationStatistics = [
      {
        name: '',
        series: [
          {
            name: this.translate.instant('Relationships'),
            value: Math.min(this.quotaService.maxNumberOfRelations, this.relations.length),
          },
          {
            name: this.translate.instant('More available'),
            value: Math.max(0, this.quotaService.maxNumberOfRelations - this.relations.length),
          },
          {
            name: this.translate.instant('Overspend'),
            value: Math.abs(
              Math.min(0, this.quotaService.maxNumberOfRelations - this.relations.length)
            ),
          },
        ],
      },
    ];
  }
}
