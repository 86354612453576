import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltip = '';
  @Input() largeTooltip = false;
  @Input() delay? = 190; // in MS
  @Input() isActivated?: 'true' | 'false' = 'true';

  private toolTipPopup;
  private timer;

  constructor(private el: ElementRef) {}

  ngOnDestroy(): void {
    if (this.toolTipPopup) {
      this.toolTipPopup.remove();
    }
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.isActivated === 'false') {
      return;
    }

    this.timer = setTimeout(() => {
      const x = this.getMiddleOfElement();
      const y = this.getPositionTop(5);
      this.createTooltipPopup(x, y);
    }, this.delay);
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.toolTipPopup) {
      this.toolTipPopup.remove();
    }
  }

  // Moves the tooltip within the screen if it overflows
  fixElementIfOverflows(element: HTMLDivElement): HTMLDivElement {
    const rect = element.getBoundingClientRect();

    const overflowsLeft = rect.left < 0;
    if (overflowsLeft) {
      element.style.left = String(rect.width / 2 + 10) + 'px';
      return element;
    }

    return element;
  }

  private createTooltipPopup(x: number, y: number): void {
    let popup = document.createElement('div');
    popup.innerText = this.tooltip;
    if (this.largeTooltip) {
      popup.setAttribute('class', 'tooltip-container large-version');
    } else {
      popup.setAttribute('class', 'tooltip-container');
    }
    popup.style.top = y.toString() + 'px';
    const absoluteX = x - popup.getBoundingClientRect().width / 2;
    popup.style.left = absoluteX - 10 + 'px';
    document.body.appendChild(popup);
    popup = this.fixElementIfOverflows(popup);
    this.toolTipPopup = popup;
  }

  // Places the tooltip above the element
  private getPositionTop(marginBottom: number): number {
    return this.el.nativeElement.getBoundingClientRect().top - marginBottom;
  }

  private getMiddleOfElement(): number {
    const rect = this.el.nativeElement.getBoundingClientRect();
    return rect.left + rect.width;
  }
}
