<div *ngIf="organization" class="settings-form">
  <div class="settings-row">
    <div class="workspace-settings-group">
      <h3>{{ 'Contact information' | translate }}</h3>
      <div class="workspace-settings-object">
        <div #generalSettings class="input-group">
          <mt-input placeholder="{{ 'Company name' | translate }}">
            <input
              #mt="ngModel"
              (ngModelChange)="updateOrganization({ name: $event })"
              [(ngModel)]="organization.name"
              [disabled]="readOnly"
              [ngModelOptions]="{ updateOn: 'blur' }"
              id="name"
              type="text" />
          </mt-input>
        </div>
        <div class="input-group">
          <mt-input placeholder="{{ 'Phone number' | translate }}">
            <input
              #mt="ngModel"
              (ngModelChange)="updateOrganization({ phoneNumber: $event })"
              [(ngModel)]="organization.phoneNumber"
              [disabled]="readOnly"
              [ngModelOptions]="{ updateOn: 'blur' }"
              id="phone"
              type="text" />
          </mt-input>
        </div>
        <div class="input-group">
          <mt-input placeholder="{{ 'General email address' | translate }}">
            <input
              #mt="ngModel"
              (ngModelChange)="updateOrganization({ emailAddress: $event })"
              [(ngModel)]="organization.emailAddress"
              [disabled]="readOnly"
              [ngModelOptions]="{ updateOn: 'blur' }"
              id="organization-email"
              type="text" />
          </mt-input>
        </div>
        <div class="input-group">
          <mt-input placeholder="{{ 'General website address' | translate }}">
            <input
              #mt="ngModel"
              (ngModelChange)="updateOrganization({ websiteUrl: $event })"
              [(ngModel)]="organization.websiteUrl"
              [disabled]="readOnly"
              [ngModelOptions]="{ updateOn: 'blur' }"
              id="organization-website"
              type="text" />
          </mt-input>
        </div>
      </div>
    </div>

    <app-address-form
      (addressUpdated)="updateOrganization({ address: $event })"
      [address]="address"
      [readOnly]="readOnly"
      [title]="'Address' | translate"
      class="workspace-settings-group"></app-address-form>

    <div class="workspace-settings-group">
      <h3>{{ 'Binsend properties' | translate }}</h3>
      <div class="workspace-settings-object">
        <div class="input-group">
          <div class="inline-input-items">
            <mt-input placeholder="{{ 'Binsend url' | translate }}">
              <input
                #mt="ngModel"
                (keypress)="keypress($event)"
                (ngModelChange)="setBinsendUrl($event)"
                [(ngModel)]="customDomain"
                [disabled]="readOnly"
                [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
                autocomplete="__away"
                id="binsendUrl"
                type="text" />
            </mt-input>
            <span>.{{ currentDomain }}</span>
          </div>
        </div>
        <div class="input-group">
          <mt-input placeholder="{{ 'Standard email sender' | translate }}">
            <input
              #mt="ngModel"
              (ngModelChange)="updateOrganization({ customEmailSender: $event })"
              [(ngModel)]="organization.customEmailSender"
              [disabled]="readOnly"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
              autocomplete="__away"
              id="emailSender"
              type="text" />
          </mt-input>
        </div>
        <div class="input-group">
          <label class="settings-title expire-title" for="workspaceRetentionInDays">
            {{ 'Default days until workspace expires' | translate }}
          </label>
          <input
            (blur)="updateDays()"
            [(ngModel)]="newAmountOfDays"
            [disabled]="readOnly || newAmountOfDays > 999999"
            [ngClass]="[
              isInvalidAmountOfDays() ? 'small_bottom' : '',
              newAmountOfDays < 0 ? 'hidden-input' : ''
            ]"
            class="expire-input"
            autocomplete="__away"
            id="workspaceRetentionInDays"
            type="number" />
          <small *ngIf="isInvalidAmountOfDays() && maxAllowedDays" class="material-icon-rejected">
            {{ 'Between 1 and ' | translate }} {{ maxAllowedDays }}
          </small>
          <small *ngIf="isInvalidAmountOfDays() && !maxAllowedDays" class="material-icon-rejected">
            {{ 'Must be more then 1' | translate }}
          </small>

          <label class="lightswitch" *ngIf="!maxAllowedDays" [class.disabled]="readOnly">
            <input type="checkbox" [checked]="newAmountOfDays < 0" (change)="changeDate()" />
            <i></i>
            {{ 'Do not allow workspaces to expire' | translate }}
          </label>
        </div>

        <div
          *ngIf="(currentPlan === undefined || !currentPlan.customBranding) && !readOnly"
          class="upgrade-subscription-overlay">
          <app-upgrade-button targetSubscriptionName="Starter"></app-upgrade-button>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-branding-section">
    <div class="settings-row">
      <div class="workspace-settings-group">
        <h3>{{ 'Styling' | translate }}</h3>
        <div class="workspace-settings-object">
          <div class="input-group">
            <label class="settings-title">{{ 'Login image' | translate }} (jpeg, jpg, png)</label>
            <span>{{ 'Login info' | translate }}</span>
            <div class="inline-input-items stretch">
              <div (fileDropped)="uploadLoginPicture($event)" appDnd>
                <label class="profile-picture settings-title" for="login-picture">
                  <input
                    (change)="uploadLoginPicture($event.target.files[0])"
                    [disabled]="readOnly"
                    [value]="loginPicture"
                    id="login-picture"
                    accept=".jpg, .jpeg, .png"
                    type="file" />
                  <img
                    *ngIf="organization.loginImageUrl"
                    [src]="organization.loginImageUrl"
                    alt="logo"
                    decoding="async"
                    loading="lazy" />
                  <span>{{ 'Choose image' | translate }}</span>
                </label>
              </div>
              <button (click)="clearLoginImage()" *ngIf="!readOnly">
                <span class="material-icons material-icon-rejected">delete_outline</span>
              </button>
            </div>
          </div>

          <div class="input-group">
            <label class="settings-title">{{ 'Logo' | translate }} (jpeg, jpg, png)</label>
            <span>{{ 'Logo info' | translate }}</span>
            <div class="inline-input-items stretch">
              <div (fileDropped)="uploadLogo($event)" appDnd>
                <label
                  [ngClass]="darkColor(organization.primaryColor) ? 'dark' : ''"
                  class="profile-picture settings-title live-profile-picture"
                  for="logo-picture">
                  <input
                    (change)="uploadLogo($event.target.files[0])"
                    [disabled]="readOnly"
                    [value]="logoPicture"
                    id="logo-picture"
                    accept=".jpg, .jpeg, .png"
                    type="file" />
                  <img
                    *ngIf="organization.logoUrl && organization.logoUrl !== ''"
                    [src]="organization.logoUrl"
                    alt="logo"
                    decoding="async"
                    loading="lazy" />
                  <img
                    *ngIf="organization.logoUrl === undefined || organization.logoUrl === ''"
                    alt="logo"
                    decoding="async"
                    loading="lazy"
                    src="/assets/binsend-logo-horizontal-white@3x.png" />
                  <span>{{ 'Choose image' | translate }}</span>
                </label>
              </div>
              <button (click)="clearLogo()" *ngIf="!readOnly">
                <span class="material-icons material-icon-rejected">delete_outline</span>
              </button>
            </div>
          </div>

          <div class="input-group">
            <label class="settings-title" for="primaryColor">
              {{ 'Primary color' | translate }}
            </label>
            <span>{{ 'Primary info' | translate }}</span>
            <div class="inline-input-items stretch">
              <input
                (ngModelChange)="updateOrganization({ primaryColor: $event })"
                [(ngModel)]="organization.primaryColor"
                [disabled]="readOnly"
                [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
                autocomplete="__away"
                id="primaryColor"
                type="text" />
              <!-- TODO: test the following -->
              <input
                (colorPickerClose)="updateOrganization({ primaryColor: $event })"
                [(colorPicker)]="organization.primaryColor"
                [disabled]="readOnly"
                [style.background-color]="organization.primaryColor"
                class="colorPreview" />
              <button (click)="resetPrimaryColor()" *ngIf="!readOnly">
                <span class="material-icons material-icon-rejected">delete_outline</span>
              </button>
            </div>
          </div>
          <div class="input-group">
            <label class="settings-title" for="accentColor">
              {{ 'Accent color' | translate }}
            </label>
            <span>{{ 'Accent info' | translate }}</span>
            <div class="inline-input-items stretch">
              <input
                (ngModelChange)="updateOrganization({ accentColor: $event })"
                [(ngModel)]="organization.accentColor"
                [disabled]="readOnly"
                [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
                autocomplete="__away"
                id="accentColor"
                type="text" />
              <!-- TODO: test the following -->
              <input
                (colorPickerClose)="updateOrganization({ accentColor: $event })"
                [(colorPicker)]="organization.accentColor"
                [disabled]="readOnly"
                [style.background-color]="organization.accentColor"
                class="colorPreview" />
              <button (click)="resetAccentColor()" *ngIf="!readOnly">
                <span class="material-icons material-icon-rejected">delete_outline</span>
              </button>
            </div>
          </div>
          <div
            *ngIf="(currentPlan === undefined || !currentPlan.customBranding) && !readOnly"
            class="upgrade-subscription-overlay">
            <app-upgrade-button targetSubscriptionName="Starter"></app-upgrade-button>
          </div>
        </div>
      </div>
      <div *ngIf="readOnly" class="workspace-settings-group">
        <h3>{{ 'Subscription' | translate }}</h3>

        <div class="workspace-settings-object">
          <div class="input-group">
            <label class="settings-title" for="name">
              {{ 'Subscription' | translate }}
            </label>
            <select
              (change)="confirmUpdateOrganization(organization.subscriptionId)"
              [(ngModel)]="organization.subscriptionId"
              class="form-control">
              <option
                *ngFor="let subscription of subscriptions"
                [defaultSelected]="organization.subscriptionId === subscription.id"
                [ngValue]="subscription.id"
                [selected]="organization.subscriptionId === subscription.id">
                {{ subscription.name | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-popup
  #changeSubscriptionPopup
  (eventPrimaryButtonClick)="updateSubscription()"
  (eventSecondaryButtonClick)="resetSubscription()"
  [popupTitle]="'Edit subscription' | translate"
  [primaryButtonText]="'Edit' | translate">
  <div>{{ 'ConfirmEditOrganization' | translate }}</div>
</app-modal-popup>
