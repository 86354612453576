import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { CreateOrganizationRequest, Organization, UpdateOrganizationRequest } from '@core/models';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private baseUrl = `${environment.apiBaseUrl}/users/organization/v1`;

  constructor(private http: HttpClient) {}

  get(id: string): Promise<Organization> {
    return id ? this.getByIds([id]).then((o) => o[0]) : null;
  }

  getByIds(ids: string[]): Promise<Organization[]> {
    return this.http.post<Organization[]>(`${this.baseUrl}/ids`, ids).then((r) => r.body);
  }

  list(): Promise<Organization[]> {
    return this.http.get<Organization[]>(`${this.baseUrl}`);
  }

  update(id: string, request: UpdateOrganizationRequest): Promise<any> {
    return this.http.put(`${this.baseUrl}/${id}`, request);
  }

  post(request: CreateOrganizationRequest): Promise<HttpResponse<Organization>> {
    return this.http.post<Organization>(`${this.baseUrl}`, request);
  }

  delete(id: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
