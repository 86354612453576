import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  elRef: ElementRef;
  @Output() onBlur = new EventEmitter<string>();
  @Input() tag: string;
  new = false;
  remove = false;

  constructor(elRef: ElementRef) {
    this.elRef = elRef;
  }

  tagUpdate(): void {
    console.log('update:', this.elRef.nativeElement.innerHTML);
  }

  tagClick(): void {
    if (!this.tag) {
      this.new = true;
    }
  }

  tagRemove(): void {
    this.remove = true;
  }
}
