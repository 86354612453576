<app-container *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Relations' }]">
  <div *ngIf="relations">
    <div *ngIf="relations.length > 0" class="relations-container-header">
      <div *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Relations' }]">
        <button
          (click)="addNewRelation()"
          *ngIf="relations.length > 0"
          [disabled]="
            quotaService.maxNumberOfRelations &&
            quotaService.maxNumberOfRelations <= relations.length
          "
          class="btn-primary">
          <img
            alt="new workspace icon"
            decoding="async"
            loading="lazy"
            src="assets/contacts-light.svg" />
          {{ 'Create new relation' | translate }}
        </button>
        <app-upgrade-button
          *ngIf="
            relations &&
            quotaService.maxNumberOfRelations &&
            quotaService.maxNumberOfRelations <= relations.length
          "></app-upgrade-button>
      </div>
      <input
        [(ngModel)]="searchString"
        class="searchBox"
        placeholder="{{ 'search' | translate }}"
        type="text" />
    </div>
    <div *ngIf="relations">
      <div class="statistics-bar" *ngIf="quotaService.maxNumberOfRelations && relations.length > 0">
        <app-progress-chart
          [statistics]="relationStatistics"
          [total]="quotaService.maxNumberOfRelations"></app-progress-chart>
      </div>
      <section *ngIf="relations.length > 0">
        <table>
          <tr>
            <th [appSort]="relations" class="sortable" data-name="name" scope="col">
              {{ 'Name' | translate }}
              <app-sort-icon></app-sort-icon>
            </th>
            <th
              [appSort]="relations"
              class="sortable"
              data-name="lastModifiedTimestampUtc"
              scope="col">
              {{ 'Last modified' | translate }}
              <app-sort-icon></app-sort-icon>
            </th>
            <th>{{ 'Actions' | translate }}</th>
          </tr>
          <tr
            (click)="goToDetails(fileSet.id)"
            *ngFor="let fileSet of relations | relationsFilter : searchString"
            class="table-row">
            <td>{{ fileSet.name }}</td>
            <td>
              {{
                fileSet.lastModifiedTimestampUtc | date : 'mediumDate' : '' : translate.currentLang
              }}
            </td>
            <td>
              <button
                (click)="confirmDeleteRelation(fileSet)"
                *hasPermission="[
                  {
                    permissionType: permissionTypes.Delete,
                    section: 'Relations'
                  }
                ]"
                click-stop-propagation>
                <span class="material-icons">delete_outline</span>
              </button>
            </td>
          </tr>
        </table>
      </section>
      <div *ngIf="relations.length === 0" class="empty-item-container">
        <h2 class="no-items-title">{{ 'No relations' | translate }}</h2>
        <button
          (click)="addNewRelation()"
          *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Relations' }]"
          class="btn-primary no-items-subtitle">
          <span>
            <img
              alt="new workspace icon"
              decoding="async"
              loading="lazy"
              src="assets/contacts-light.svg" />
            {{ 'CreateItem' | translate }}
          </span>
        </button>
        <app-empty-animation></app-empty-animation>
      </div>
    </div>
  </div>

  <app-modal-popup
    #deleteRelationPopup
    (eventPrimaryButtonClick)="deleteRelation()"
    [popupTitle]="'DeleteRelationTitle' | translate"
    [primaryButtonText]="'DeleteRelationActionButton' | translate">
    <div>{{ 'ConfirmRelationDelete' | translate }}</div>
  </app-modal-popup>
</app-container>
<router-outlet></router-outlet>
