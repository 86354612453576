import { Component, OnInit } from '@angular/core';
import { BrandingService, UserAccountService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoaderService } from '@core/services/loader.service';
import * as i18nIsoCountries from 'i18n-iso-countries';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // Page is hidden while house branding is applied.
  hidePage = true;

  constructor(
    private userAccountService: UserAccountService,
    private translate: TranslateService,
    private brandingService: BrandingService,
    private router: Router,
    public loaderService: LoaderService
  ) {
    // Add localized country names....expand with new country names.
    i18nIsoCountries.registerLocale(
      // eslint-disable-next-line
      require('i18n-iso-countries/langs/en.json')
    );
    i18nIsoCountries.registerLocale(
      // eslint-disable-next-line
      require('i18n-iso-countries/langs/nl.json')
    );

    // Support dutch & english languages.
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('nl');
  }

  ngOnInit(): void {
    this.brandingService.eventNotifier.subscribe(() => {
      this.hidePage = false;
    });

    this.userAccountService.accountUpdated.subscribe(() => {
      // check if user has organization, if not, default branding is used.
      const user = this.userAccountService.user;
      if (!user?.organizationId) {
        this.hidePage = false;
      }

      if (user?.languageCode) {
        this.translate.use(user.languageCode);
      } else {
        console.log('user_language empty falling back to system default');
        this.translate.use('nl');
      }
    });

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        (window as any).gtag('config', 'G-N5J1MZD6WF', {
          page_path: event.urlAfterRedirects,
          page_title: event.urlAfterRedirects,
        });
      }
    });
  }
}
