import { environment } from '@env/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateDiscountModel, Discount, EditDiscountModel } from '@core/models/discount.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DiscountService {
  private baseUrl = `${environment.apiBaseUrl}/payment/discount/v1`;

  constructor(private http: HttpClient) {}

  get(discountId: string): Observable<HttpResponse<Discount>> {
    return this.http.get<Discount>(`${this.baseUrl}/details/${discountId}`, {
      headers: new HttpHeaders()
        .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        .set('Pragma', 'no-cache')
        .set('Expires', '0'),
      observe: 'response',
    });
  }

  list(organizationId: string): Observable<HttpResponse<Discount[]>> {
    return this.http.get<Discount[]>(`${this.baseUrl}/${organizationId}`, {
      headers: new HttpHeaders()
        .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        .set('Pragma', 'no-cache')
        .set('Expires', '0'),
      observe: 'response',
    });
  }

  post(request: CreateDiscountModel): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}`, request, {
      observe: 'response',
    });
  }

  delete(discountId: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.baseUrl}/${discountId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  update(discountId: string, request: EditDiscountModel): Observable<HttpResponse<any>> {
    return this.http.put(`${this.baseUrl}/${discountId}`, request, {
      observe: 'response',
    });
  }
}
