import { Component, Input } from '@angular/core';
import { QuotaService } from '@core/services';

@Component({
  selector: 'app-upgrade-button',
  templateUrl: './upgrade-button.component.html',
  styleUrls: ['./upgrade-button.component.scss'],
})
export class UpgradeButtonComponent {
  @Input() targetSubscriptionName: string;

  constructor(public quotaService: QuotaService) {}
}
