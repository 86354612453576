import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DoCheck,
  Input,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mt-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss'],
})
export class MaterialInputComponent implements AfterContentInit, DoCheck {
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @ContentChild('mt') child: NgModel | undefined;
  @ViewChild('contentWrapper') contentWrapper;
  @ViewChild('placeholderElement') placeholderElement;
  filled: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.cdr.detectChanges();
    const content = this.contentWrapper.nativeElement.children[0];
    if (this.autofocus && content) {
      content.focus();
    }
    this.inputTextHeight();

    //wait for redraw with placeholder text to fix multiline placeholder
    setTimeout(() => {
      if (this.placeholderElement) {
        const offset = this.placeholderElement.nativeElement.offsetHeight - 18;
        if (offset > 0) {
          this.contentWrapper.nativeElement.style.paddingTop = offset + 'px';
        }
      }

      this.inputTextHeight();
    }, 250);
  }

  ngDoCheck() {
    if (this.child?.model?.length > 0) {
      this.filled = true;
    }
  }

  inputTextHeight(): void {
    const textarea = this.contentWrapper.nativeElement.querySelector('textarea');
    if (textarea) {
      const limit = 356; //height limit
      //back to zero for limit calculation
      textarea.style.height = 0;
      textarea.style.height = Math.min(textarea.scrollHeight, limit) + 'px';
    }
  }

  inputChange(event) {
    this.filled = event.target.value.length > 0;
    this.inputTextHeight();
  }
}
