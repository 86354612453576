import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextMenuItem, ContextService } from '@core/services';
import { FileRequestService } from '@app/core/services/api/fileRequest.service';
import { FileRequest, ItemStatusType } from '@app/core/models';
import { StatisticsUpdateService } from '@feature/workspace/details/workspace-layout/workspace-statistics/statistics-update.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const stateMachine = {
  [ItemStatusType.Unknown]: [ItemStatusType.NotApplicable],
  [ItemStatusType.Pending]: [ItemStatusType.NotApplicable],
  [ItemStatusType.Received]: [ItemStatusType.Rejected, ItemStatusType.Approved],
  [ItemStatusType.Rejected]: [ItemStatusType.Approved, ItemStatusType.Received],
  [ItemStatusType.Approved]: [ItemStatusType.Rejected, ItemStatusType.Received],
  [ItemStatusType.Archived]: [],
  [ItemStatusType.NotApplicable]: [ItemStatusType.Pending],
};

class MenuItem implements ContextMenuItem {
  name: string;
  icon: string;
  style: string;
  status: ItemStatusType;
}

@UntilDestroy()
@Component({
  selector: 'status-menu',
  templateUrl: './status-menu.component.html',
  styleUrls: ['./status-menu.component.scss'],
})
export class StatusMenuComponent implements OnInit, OnDestroy {
  @Input() dark: boolean;
  @Input() item: FileRequest;
  @Input() isOwner: boolean;
  @ViewChildren('contextMenu') contextMenu: ElementRef;
  public sub: Subscription;
  allStatuses: MenuItem[] = [];
  popup: boolean;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public contextService: ContextService,
    private requestContentItemService: FileRequestService,
    private statisticsUpdateService: StatisticsUpdateService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  get statuses(): ContextMenuItem[] {
    let valid = stateMachine[this.selectedStatus.status];
    if ((!this.isOwner && !this.item.isApprovable) || (this.isOwner && this.item.isApprovable)) {
      valid = valid.filter(
        (s) =>
          s !== ItemStatusType.Rejected &&
          s !== ItemStatusType.Approved &&
          s !== ItemStatusType.Received
      );
    }
    const isValid = (s) => s !== this.selectedStatus && valid.includes(s.status);
    return [this.selectedStatus, ...this.allStatuses.filter(isValid)];
  }

  get selectedStatus(): any {
    return this.allStatuses.find((s) => s.status === this.item.status);
  }

  public ngOnInit(): void {
    this.allStatuses = [
      {
        name: 'Pending',
        icon: 'pending',
        style: 'material-icons pending',
        status: ItemStatusType.Unknown,
      },
      {
        name: this.isOwner ? 'Approve' : 'Approved',
        icon: 'check_circle',
        style: 'material-icons approved',
        status: ItemStatusType.Approved,
      },
      {
        name: 'Pending',
        icon: 'pending',
        style: 'material-icons pending',
        status: ItemStatusType.Pending,
      },
      {
        name: 'Received',
        icon: 'swap_horizontal_circle',
        style: 'material-icons received',
        status: ItemStatusType.Received,
      },
      {
        name: this.isOwner ? 'Reject' : 'Rejected',
        icon: 'cancel',
        style: 'material-icons rejected',
        status: ItemStatusType.Rejected,
      },
      {
        name: 'Archived',
        icon: 'archive',
        style: 'material-icons non-applicable',
        status: ItemStatusType.Archived,
      },
      {
        name: 'Does not apply',
        icon: 'do_disturb_on',
        style: 'material-icons non-applicable',
        status: ItemStatusType.NotApplicable,
      },
    ];

    this.elementRef.nativeElement.addEventListener('click', this.showContextMenu);
    this.elementRef.nativeElement.addEventListener('contextmenu', this.showContextMenu);

    this.statisticsUpdateService.onStatisticUpdate$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('click', this.showContextMenu);
    this.elementRef.nativeElement.removeEventListener('contextmenu', this.showContextMenu);
  }

  async updateStatus(status: ItemStatusType): Promise<void> {
    if (status !== this.item.status && this.item.canEdit) {
      this.item.status = status;
      this.cdr.markForCheck();
      await this.requestContentItemService.updateStatus(this.item, status);
    }
  }

  public open(): void {
    const width = document.getElementsByClassName('context-menu')[0].clientWidth - 40;
    let x = this.elementRef.nativeElement.getBoundingClientRect().left;
    const y = this.elementRef.nativeElement.getBoundingClientRect().top;
    if (x + width > document.body.offsetWidth) {
      x = this.elementRef.nativeElement.getBoundingClientRect().left - width;
    }

    const action = (item) => this.updateStatus(item.status);
    this.contextService.set(x - 12, y - 12, this.statuses, action);

    setTimeout(() => {
      this.contextService.animate(true);
    }, 300);
  }

  private showContextMenu = (event$) => {
    event$.preventDefault();
    event$.stopPropagation();
    this.open();
  };
}
