<header (click)="closeContext()" [ngClass]="mobileOpen ? 'menu-open' : ''" class="header">
  <div [routerLink]="['/dashboard']" class="logo"></div>

  <nav *ngIf="user" class="main-menu">
    <div [routerLinkActive]="['active-link']" class="sub-menu">
      <a (click)="closeMenu()" [routerLinkActive]="['active-link']" [routerLink]="['/dashboard']">
        <span class="material-icons-outlined">dashboard</span>
        {{ 'Dashboard' | translate }}
      </a>
    </div>
    <div [routerLinkActive]="['active-link']" class="sub-menu has-arrow">
      <a (click)="closeMenu()" [routerLink]="['/workspace']">
        <span class="material-icons-outlined">all_inbox</span>
        {{ 'Workspaces' | translate }}
      </a>
      <input [checked]="isActive('/workspace')" id="workspaceExpander" type="checkbox" />
      <label for="workspaceExpander">
        <span class="material-icons-outlined">arrow_drop_down</span>
      </label>
      <ul [ngClass]="closeHover ? 'close-hover' : ''">
        <li *ngFor="let workspace of top4Workspaces()">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/workspace', workspace.id]">
            <span class="material-icons-outlined">inbox</span>
            {{ workspace.name | truncate : [25] }}
          </a>
        </li>

        <li class="menu-divider"></li>
        <li class="small-link">
          <a (click)="closeMenu()" [routerLink]="['/workspace']">
            {{ 'All workspaces' | translate }}
            <span class="material-icons-outlined">arrow_forward</span>
          </a>
        </li>
        <li
          *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Filesets' }]"
          class="small-link">
          <a (click)="closeMenu()" [routerLink]="['/templates']">
            {{ 'All templates' | translate }}

            <span class="material-icons-outlined">arrow_forward</span>
          </a>
        </li>
      </ul>
    </div>
    <div
      *hasPermission="[
        { permissionType: permissionTypes.Create, section: 'Organization' },
        { permissionType: permissionTypes.Any, section: 'Users' },
        { permissionType: permissionTypes.Any, section: 'Teams' },
        { permissionType: permissionTypes.Any, section: 'Roles' },
        { permissionType: permissionTypes.Any, section: 'SuperSettings' },
        { permissionType: permissionTypes.Any, section: 'AuditLog' },
        { permissionType: permissionTypes.Any, section: 'Relations' }
      ]"
      [routerLinkActive]="['active-link']"
      class="sub-menu has-arrow">
      <a (click)="closeMenu()" [routerLink]="['/settings/relations']">
        <span class="material-icons-outlined">admin_panel_settings</span>
        {{ 'Settings' | translate }}
      </a>
      <input [checked]="isActive('/admin')" id="settings-expander" type="checkbox" />
      <label for="settings-expander">
        <span class="material-icons-outlined">arrow_drop_down</span>
      </label>
      <ul [ngClass]="closeHover ? 'close-hover' : ''">
        <li *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Relations' }]">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/settings/relations']">
            <span class="material-icons-outlined">switch_account</span>
            {{ 'Relationships' | translate }}
          </a>
        </li>
        <li
          *hasPermission="[
            { permissionType: permissionTypes.Any, section: 'Organization' },
            { permissionType: permissionTypes.Create, section: 'Organization' }
          ]">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/settings/organization']">
            <span class="material-icons-outlined">apartment</span>
            {{ 'Organization' | translate }}
          </a>
        </li>
        <li *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Teams' }]">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/settings/teams']">
            <span class="material-icons-outlined">groups</span>
            {{ 'Teams' | translate }}
          </a>
        </li>
        <li *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Users' }]">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/settings/users']">
            <span class="material-icons-outlined">people</span>
            {{ 'Users' | translate }}
          </a>
        </li>
        <li *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Roles' }]">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/settings/roles']">
            <span class="material-icons-outlined">shield</span>
            {{ 'Roles' | translate }}
          </a>
        </li>
        <li *hasPermission="[{ permissionType: permissionTypes.Any, section: 'AuditLog' }]">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/settings/auditlogs']">
            <span class="material-icons-outlined">text_snippet</span>
            {{ 'Audit log' | translate }}
          </a>
        </li>
        <li *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]">
          <a
            (click)="closeMenu()"
            [routerLinkActive]="['active-link']"
            [routerLink]="['/settings/supersettings']">
            <span class="material-icons-outlined">settings_suggest</span>
            {{ 'Super settings' | translate }}
          </a>
        </li>
      </ul>
    </div>

    <div
      *ngIf="userAccountService.user"
      [routerLinkActive]="['active-link']"
      class="sub-menu mobile-only sub-divider">
      <a (click)="closeMenu()" [routerLink]="['/settings']">
        <span class="material-icons-outlined">manage_accounts</span>
        {{ 'Profile' | translate }}
      </a>
    </div>

    <div
      *ngIf="userAccountService.isIdentityManager === true"
      [routerLinkActive]="['active-link']"
      class="sub-menu mobile-only">
      <a (click)="closeMenu()" [routerLink]="[inviteUrl]">
        <span class="material-icons-outlined">supervisor_account</span>
        {{ 'Invite' | translate }}
      </a>
    </div>

    <div [routerLinkActive]="['active-link']" class="sub-menu mobile-only">
      <a (click)="closeMenu()" [routerLink]="['/support']">
        <span class="material-icons-outlined">support_agent</span>
        {{ 'Support' | translate }}
      </a>
    </div>

    <div class="sub-menu mobile-only">
      <a (click)="logout()">
        <span class="material-icons-outlined">logout</span>
        {{ 'Logout' | translate }}
      </a>
    </div>

    <div class="sub-menu">
      <button
        *ngIf="!exceedsQuota"
        [queryParams]="{ newWorkspace: true }"
        [routerLink]="['/dashboard']"
        class="create-workspace-button">
        <div
          (click)="closeMenu()"
          [queryParams]="{ newWorkspace: true }"
          [routerLink]="['/dashboard', { newWorkspace: true }]"
          class="button-content">
          <span class="material-icons-outlined icon-plus">add</span>
          <span class="button-text-create">{{ 'CreateCaption' | translate }}</span>
          <input id="create-workspace-expander" type="checkbox" />
          <label for="create-workspace-expander">
            <span class="material-icons-outlined icon-dropdown">arrow_drop_down</span>
          </label>
        </div>

        <ul [ngClass]="closeHover ? 'close-hover' : ''">
          <li>
            <a
              (click)="stopPropagation($event)"
              [queryParams]="{ newWorkspace: true }"
              [routerLink]="['/dashboard', { newWorkspace: true }]">
              <span class="material-icons-outlined">inbox</span>
              {{ 'New empty' | translate : { workspaceType: 'Workspace' } }}
            </a>
          </li>
          <li>
            <a
              (click)="stopPropagation($event)"
              [queryParams]="{ workspaceFromTemplate: true }"
              [routerLink]="['/dashboard']">
              <span class="material-icons-outlined">inbox</span>
              {{ 'Workspace from template' | translate }}
            </a>
          </li>
        </ul>
      </button>
      <button *ngIf="exceedsQuota" class="create-workspace-button" disabled>
        <div class="button-content">
          <span class="material-icons-outlined icon-plus">add</span>
          <span class="button-text-create">{{ 'CreateCaption' | translate }}</span>
          <label for="create-workspace-expander">
            <span class="material-icons-outlined icon-dropdown">arrow_drop_down</span>
          </label>
        </div>
      </button>
    </div>
  </nav>

  <div class="user-part sub-menu">
    <input id="user-menu-expander" type="checkbox" />
    <label for="user-menu-expander"></label>
    <div class="user-menu">
      <ul
        (click)="closeMenu()"
        *ngIf="userAccountService.user"
        [ngClass]="closeHover ? 'close-hover' : ''">
        <li>
          <a [routerLinkActive]="['active-link']" [routerLink]="['/profile']">
            <span class="material-icons-outlined">manage_accounts</span>
            {{ 'Profile' | translate }}
          </a>
        </li>
        <li>
          <a [routerLinkActive]="['active-link']" [routerLink]="['/support/']">
            <span class="material-icons-outlined">support_agent</span>
            {{ 'support' | translate }}
          </a>
        </li>
        <li class="menu-divider"></li>
        <li>
          <a (click)="logout()">
            <span class="material-icons-outlined">logout</span>
            {{ 'Logout' | translate }}
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="userAccountService.user" class="user-text">
      <span class="user-text__name">
        {{ userAccountService.user.fullName }}
      </span>
      <span *ngIf="userAccountService.user.organization" class="user-text__organization">
        {{ userAccountService.user.organization }}
      </span>
    </div>

    <div *ngIf="user; then logoutBlock; else loginBlock"></div>
    <ng-template #loginBlock>
      <div class="click sign-in" tabindex="-1">
        <span (click)="login()" class="material-icons notificationIcon">login</span>
      </div>
    </ng-template>
    <ng-template #logoutBlock>
      <div *ngIf="userAccountService.user" class="userInfo">
        <div #menuPopup class="menu-button user-button" tabindex="-1">
          <app-user-avatar
            [avatarColor]="userAccountService.user.avatarColor"
            [avatar]="userAccountService.user.avatar"
            [className]=""
            [isOwn]="true"
            [showTooltip]="false"
            [userName]="userAccountService.user.fullName"></app-user-avatar>
        </div>
      </div>
    </ng-template>
  </div>

  <div (click)="menuMobile()" [ngClass]="mobileOpen ? 'active' : ''" class="hamburger">
    <div></div>
    <div></div>
    <div></div>
  </div>
</header>
