<tabs #tabItems>
  <tab
    icon="business"
    iconClass="material-icons"
    tabTitle="{{ 'Organization' | translate }}"
    [registrationUrl]="'Settings | organization'"
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Organization' }]">
    <app-settings-organization></app-settings-organization>
  </tab>
  <tab
    icon="card_membership"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Subscription' | translate }}"
    id="subscriptions"
    [registrationUrl]="'Settings | subscription'"
    *hasPermission="[
      { permissionType: permissionTypes.Edit, section: 'Subscriptions' },
      { permissionType: permissionTypes.Create, section: 'Subscriptions' }
    ]">
    <app-settings-subscription></app-settings-subscription>
  </tab>
  <tab
    icon="account_balance"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Payments' | translate }}"
    id="payments"
    [registrationUrl]="'Settings | payments'"
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Payments' }]">
    <app-settings-payments></app-settings-payments>
  </tab>
</tabs>
