import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { SharedModule } from '@app/shared/shared.module';
import { AccountComponent } from './account/account.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  declarations: [ProfileComponent, AccountComponent],
  exports: [],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    ColorPickerModule,
  ],
})
export class ProfileModule {}
