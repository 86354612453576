<div
  [class.empty]="empty"
  [class.edit]="isEdit | async"
  [class.isActive]="isActive"
  [class.mobile-active]="mobileToggle"
  class="workspace-add-containers"
  (click)="mobileClick()"
  [contextMenu]="menu">
  <div class="workspace-add-containers__buttons">
    <span (click)="buttonFolder()" class="button">
      <span class="material-icons-outlined">folder</span>
      {{ 'Add folder' | translate }}
    </span>
    <span (click)="buttonGroup()" class="button">
      <span class="material-icons-outlined">inbox</span>
      {{ 'Add group' | translate }}
    </span>
    <span (click)="placeTemplate.emit()" class="button button--alt">
      <span class="material-icons-outlined">description</span>
      {{ 'Add template between' | translate }}
    </span>
  </div>

  <div class="plus-sign">+</div>
</div>
