import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ContentItem, FileRequest } from '@app/core/models';
import { Message } from '@app/core/models/message.model';
import { FileDownloaderService } from '@app/core/services/api/file-downloader.service';
import { ContentService, Preview, PreviewService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  contentItems: ContentItem[] = [];
  fileRequest?: FileRequest;

  fileIndex = 0;

  element: HTMLElement;

  fileUrl: string;
  supportedVideos: string[] = ['mpg', 'mpeg', 'mp4', 'webm', 'ogg'];
  supportedImages: string[] = ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'webp'];

  supportedDocs: string[] = [
    'txt',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'pdf',
    'ai',
    'psd',
    'svg',
    'eps',
    'ps',
    'tif',
    'xps',
  ];

  @Output() uploadClick = new EventEmitter();

  constructor(
    public previewService: PreviewService,
    public downloadService: FileDownloaderService,
    private contentService: ContentService
  ) {
    previewService.contextChange.pipe(untilDestroyed(this)).subscribe((preview: Preview) => {
      const { contentItems, fileRequest, fileIndex } = preview || {};
      this.contentItems = contentItems;
      this.fileRequest = fileRequest;
      this.fileIndex = fileIndex || 0;
      this.updateCurrentFile();
    });
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    } else if (event.key === 'ArrowLeft' && this.contentItems?.length > 1) {
      this.previous();
    } else if (event.key === 'ArrowRight' && this.contentItems?.length > 1) {
      this.next();
    }
  }

  public close(): void {
    this.previewService.close();
  }

  previous(): void {
    this.fileIndex = (this.fileIndex + this.contentItems.length - 1) % this.contentItems.length;
    this.updateCurrentFile();
  }

  next(): void {
    this.fileIndex = (this.fileIndex + 1) % this.contentItems.length;
    this.updateCurrentFile();
  }

  get activeContentItem(): ContentItem {
    return (this.contentItems || [])[this.fileIndex];
  }

  get isSupportedFile(): boolean {
    return this.fileType && (this.isImage || this.isVideo || this.isDoc);
  }

  get isImage(): boolean {
    return this.supportedImages.includes(this.fileType);
  }

  get isVideo(): boolean {
    return this.supportedVideos.includes(this.fileType);
  }

  get isDoc(): boolean {
    return this.supportedDocs.includes(this.fileType);
  }

  get tooBig(): boolean {
    return this.activeContentItem?.size > 20000000;
  }

  get isProcessing(): boolean {
    return this.activeContentItem?.size === 0;
  }

  get hasNoPreview(): boolean {
    return !this.isSupportedFile || this.tooBig || this.isProcessing || !this.activeContentItem;
  }

  get fileType(): string {
    const fileBreak = this.activeContentItem?.name.split('.') || [];
    return fileBreak[fileBreak.length - 1]?.toLocaleLowerCase();
  }

  get icon(): string {
    if (this.tooBig) return 'too-big';
    else if (this.isProcessing) return 'processing';
    else if (!this.activeContentItem) return 'no-file';
    else if (!this.isSupportedFile) return 'unsupported';
    else return 'no-file';
  }

  get messages(): Message[] {
    return this.fileRequest?.messages;
  }

  get chatActive(): boolean {
    return !!this.messages;
  }

  get active(): boolean {
    return !!this.contentItems;
  }

  imageButton(): void {
    if (!this.activeContentItem) {
      this.upload();
    } else if (this.fileUrl) {
      this.download();
    }
  }

  upload(): void {
    console.log('upload', this.fileRequest);
    if (!this.fileRequest) return;
    this.element = document.getElementById(this.fileRequest.id) as HTMLElement;
    this.previewService.close();
    this.element.click();
  }

  download(): Promise<void> {
    const contentItem = this.activeContentItem;
    const workspaceId = contentItem?.workspaceId;
    return this.downloadService.downloadContentItem(workspaceId, contentItem, contentItem.name);
  }

  async updateCurrentFile(): Promise<void> {
    const targetItem: ContentItem = this.activeContentItem;
    if (targetItem?.size) {
      const workspaceId = this.activeContentItem?.workspaceId;
      const r = await this.contentService.getDownloadUrl(workspaceId, targetItem.id);
      this.fileUrl = r.body;
    }
  }
}
