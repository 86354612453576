import { Pipe, PipeTransform } from '@angular/core';
import { UserAccount } from '@core/models';

@Pipe({
  name: 'userFilter',
})
export class UserFilterPipe implements PipeTransform {
  transform(items: UserAccount[], searchText: string): UserAccount[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return (
        it.firstname.toLowerCase().includes(searchText) ||
        it.lastname.toLowerCase().includes(searchText) ||
        it.email.toLowerCase().includes(searchText)
      );
    });
  }
}
