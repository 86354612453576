import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import {
  ContentItem,
  FileRequest,
  IQuestionDto,
  ItemStatusType,
  QuestionType,
  Section,
  SectionItem,
  SectionItemType,
  SectionType,
  Workspace,
} from '@app/core/models';
import { ItemStatusFilters } from '@app/core/models/item-status-filters';
import { FileRequestService } from '@app/core/services/api/fileRequest.service';
import { QuestionService } from '@app/core/services/api/question.service';
import { SectionService } from '@app/core/services/api/section.service';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { SharingPopupComponent } from '@app/shared/sharing-popup/sharing-popup.component';
import { FileUploaderService } from '@core/services/api/file-uploader.service';
import { StatisticsUpdateService } from '../workspace-statistics/statistics-update.service';
import { SelectTemplatePopupComponent } from '@feature/workspace/details/workspace-layout/select-template-popup/select-template-popup';
import { TranslateService } from '@ngx-translate/core';
import { RouterService } from '@app/core/services/api/router.service';
import { NotificationService, WorkspaceUiStateService } from '@app/core/services';
import { MultiSelectUpdateService } from '@app/shared/multi-select/multi-select-update.service';
import { stateMachine } from '@app/shared/status-menu/status-menu.component';
import { WorkflowUpdateService } from '@feature/workspace/details/workspace-layout/workspace-workflow/workflow-update.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'section-container',
  templateUrl: 'section-container.component.html',
  styleUrls: ['section-container.component.scss'],
})
export class SectionContainerComponent implements OnChanges, AfterViewInit {
  currentSection?: Section;
  stopRequestPropagnation = false;
  activeSectionItem = -1;
  templateInsertIndex: number;
  public deletableItemRequest: SectionItem;
  private selectedSection: Section;

  @Input() workspace: Workspace;

  get groupCount(): number {
    return this.groups.length;
  }

  get groups(): Section[] {
    return this.workspace.sections.filter((s) => !s.isFolder);
  }

  @Input() searchText: string;
  @Input() selectedStatus: ItemStatusFilters = ItemStatusFilters.None;

  @ViewChild('sectionContainer') sectionContainer: ElementRef;

  @ViewChild('deleteSectionPopup')
  deleteSectionPopup: ModalPopupComponent;

  @ViewChild('deleteFileRequestPopup')
  deleteFileRequestPopup: ModalPopupComponent;

  @ViewChild('deleteMultiplePopup')
  deleteMultiplePopup: ModalPopupComponent;

  deletingFile: boolean;

  @ViewChild('deleteSectionItemPopup')
  deleteSectionItemPopup: ModalPopupComponent;

  @ViewChild('selectTemplateForWorkspacePopup')
  selectTemplateForWorkspacePopup: SelectTemplatePopupComponent;

  @ViewChild('sharePopup') sharePopup: SharingPopupComponent;

  get isEdit(): Observable<boolean> {
    return this.stateService.isEdit;
  }

  constructor(
    private stateService: WorkspaceUiStateService,
    private sectionService: SectionService,
    private questionService: QuestionService,
    private fileRequestService: FileRequestService,
    private fileUploadService: FileUploaderService,
    public routerService: RouterService,
    private statisticsUpdateService: StatisticsUpdateService,
    private requestContentItemService: FileRequestService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private multiSelectUpdateService: MultiSelectUpdateService,
    private workflowUpdateService: WorkflowUpdateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    setTimeout(() => {
      this.resetSidebar();
    }, 150);
  }

  ngAfterViewInit(): void {
    this.resetSidebar();
  }

  canDrop(item: CdkDrag, list: CdkDropList): boolean {
    return !(
      item.data.type !== SectionItemType.ContentItem &&
      list.connectedTo[0] === SectionType.ContainsOnlySections
    );
  }

  async dropItem($event: CdkDragDrop<SectionItem[]>, section: Section): Promise<void> {
    const item = $event.item.data;
    const parentSection = section.id;
    const insertAt = $event.currentIndex;

    if ($event.previousContainer === $event.container) {
      this.workspace.moveSectionItem(item, $event.previousIndex, $event.currentIndex);
    } else {
      // Determine previous index, because if it comes from content item container, index is incorrect
      const previousSection = this.workspace.getSection($event.previousContainer.id);
      const previousIndex = previousSection.items.indexOf(item);
      this.workspace.transferSectionItem(
        $event.previousContainer.id,
        $event.container.id,
        previousIndex,
        insertAt
      );

      //decouple illigal workflow
      if (item.value?.length) {
        item.value.forEach((value, index) => {
          if (value.workflowId === parentSection) {
            this.workflowUpdateService.updateWorkflowById(value.workflowId, true);
            item.value[index].workflowId = undefined;
            this.questionService.update(item, { value: item.value });
          }
        });
      }

      //update parentId
      item.parentId = parentSection;
    }

    if (!item.selected) {
      const parent = this.workspace.getSection(parentSection);
      parent.selected = false;
    }

    await this.sectionService.moveSectionItem(this.workspace.id, parentSection, {
      parentSection,
      insertAt,
      sectionItemId: item.id,
      type: item.type,
    });

    this.resetSidebar();
  }

  async dropContentItem($event: CdkDragDrop<ContentItem[]>): Promise<void> {
    const droppedItem: ContentItem = $event.item.data;
    const currentlyAtIndex = this.workspace.contentItems[$event.currentIndex];
    const insertAt = this.workspace.items.indexOf(currentlyAtIndex);
    const parentSection = this.workspace.currentSectionId;

    this.workspace.transferSectionItem(
      $event.previousContainer.id,
      $event.container.id,
      $event.previousIndex,
      insertAt
    );

    await this.sectionService.moveSectionItem(this.workspace.id, parentSection, {
      parentSection,
      insertAt,
      sectionItemId: droppedItem.id,
      type: droppedItem.type,
    });
  }

  async dropItemOnFolder($event: CdkDragDrop<SectionItem[]>, folder: Section): Promise<void> {
    const droppedItem: SectionItem = $event.item.data;
    const previousSection = this.workspace.getSection($event.previousContainer.id);
    const previousIndex = previousSection.items.indexOf(droppedItem);
    previousSection.items.splice(previousIndex, 1);

    await this.sectionService.moveSectionItem(this.workspace.id, folder.id, {
      parentSection: folder.id,
      insertAt: -1,
      sectionItemId: droppedItem.id,
      type: droppedItem.type,
    });

    this.routerService.navigateToSection(this.workspace, folder.id);
  }

  async dropItemGroup($event: CdkDragDrop<Section[]>): Promise<void> {
    const insertAt = $event.currentIndex;
    const item = $event.item.data;
    const parentSection = this.workspace.currentSectionId;

    this.workspace.moveSectionItem(item, $event.previousIndex, $event.currentIndex);
    await this.sectionService.moveSectionItem(this.workspace.id, item.id, {
      parentSection,
      insertAt,
      sectionItemId: item.id,
      type: item.type,
    });

    this.resetSidebar();
  }

  get selectedItemsStatus(): boolean {
    return (
      this.workspace.selectedItems.filter((item) => item.type === SectionItemType.FileRequest)
        .length > 0
    );
  }

  multipleDeletePopup(): void {
    this.deleteMultiplePopup.popupActive = true;
  }

  async multipleDelete(): Promise<void> {
    // first all sections then items iff they are still there
    this.workspace.selectedItems
      .filter((si) => si.type === SectionItemType.Section)
      .forEach((item) => {
        this.workspace.removeSections((d) => d.id !== item.id);
        this.sectionService.delete(this.workspace.id, item.id);
        this.resetSidebar();
      });

    this.workspace.selectedItems.forEach((item) => {
      this.removeSectionItemConfirm(item, false);
    });

    this.deleteMultiplePopup.closePopup();
  }

  async multipleStatusChange(status: ItemStatusType): Promise<void> {
    let statusNotChanged = false;
    this.workspace.selectedItems
      .filter((si) => si.type === SectionItemType.FileRequest)
      .forEach((item) => {
        if (stateMachine[item.status]?.includes(status)) {
          item.status = status;
          this.changeStatus(item as FileRequest, status);
        } else {
          statusNotChanged = true;
        }
      });

    if (statusNotChanged) {
      this.notificationService.showWarning(
        'status-not-changed-description',
        'status-not-changed-title'
      );
    }
  }

  async changeStatus(item: FileRequest, status: ItemStatusType): Promise<void> {
    this.cdr.markForCheck();
    await this.requestContentItemService.updateStatus(item, status);
  }

  upload(section: Section, $event): void {
    this.fileUploadService.upload($event, this.workspace, section);
    section.selected = false;
  }

  resetSidebar(): void {
    const currentSectionId = this.currentSection?.id || this.groups[0]?.id;
    this.groupActivate(currentSectionId);
  }

  groupActivate(id?: string): void {
    if (!id || !this.sectionContainer) return;
    this.currentSection = this.workspace.getSection(id);
    this.cdr.detectChanges();
  }

  hideSidebar(): void {
    this.groupActivate();
  }

  getParentSection(): Section {
    const sectionId = this.workspace.currentSectionId;
    return sectionId ? this.workspace.getSection(sectionId) : this.workspace.rootSection;
  }

  setFocusedSectionItem(event, item?: SectionItem): void {
    if (!this.stopRequestPropagnation) {
      this.stopRequestPropagnation = true;
      if (item) {
        const section = this.workspace.getParent(item?.id);
        const index = section.items.indexOf(item);
        this.activeSectionItem = index + 1;
        if (!section) return;

        this.groupActivate(section.id);
      } else {
        this.activeSectionItem = -1;
      }
      //prevent double index clicks
      setTimeout(() => {
        this.stopRequestPropagnation = false;
      }, 10);
    }
  }

  async addQuestionRequest(type: string, properties?: string): Promise<void> {
    const requestItem: IQuestionDto = this.createNewItem<IQuestionDto>({
      label: '',
      questionType: type,
      value: '',
      properties: properties ? { type: properties } : '',
      insertAt: this.activeSectionItem !== -1 ? this.activeSectionItem : null,
    });

    const question = await this.questionService.post(
      this.workspace.id,
      this.currentSection?.id,
      requestItem
    );

    this.insertSectionItem(question);
    this.currentSection.selected = false;
  }

  async addFileRequest(): Promise<void> {
    const requestItem: FileRequest = this.createNewItem<FileRequest>({
      allowedFileTypes: [],
      description: '',
      name: '',
      insertAt: this.activeSectionItem !== -1 ? this.activeSectionItem : null,
    });

    const rci = await this.fileRequestService.post(
      this.workspace.id,
      this.currentSection?.id,
      requestItem
    );
    this.insertSectionItem(rci);
    this.workspace.focusItem(rci.id);
    this.statisticsUpdateService.updateStatistics(this.currentSection?.id);
    this.currentSection.selected = false;
  }

  insertSectionItem(item: SectionItem): void {
    this.workspace.insertSectionItem(item, this.currentSection, this.activeSectionItem);
    if (this.activeSectionItem >= 0) this.activeSectionItem++;
  }

  async makeSection(type: SectionType, index: number): Promise<void> {
    const parentSection = this.workspace.currentSection;
    const insertAt = index;
    const section = await this.sectionService.post(this.workspace.id, parentSection.id, {
      name: '',
      description: '',
      SectionType: type,
      items: [],
      insertAt,
    });

    this.workspace.insertSectionItem(section, parentSection, insertAt);

    if (type === SectionType.Default) {
      this.groupActivate(section.id);
    } else {
      this.groupActivate(this.currentSection?.id);
    }

    this.activeSectionItem = -1;
    this.resetSidebar();
  }

  placeTemplate(i: number): void {
    if (this.stateService.isEdit.value) {
      this.templateInsertIndex = i;
      this.selectTemplateForWorkspacePopup.open();
    }
  }

  createNewItem<TItemType>(item): TItemType {
    return {
      isRequired: true,
      status: ItemStatusType.Pending,
      ...item,
    } as TItemType;
  }

  getAllParents(origin): Section[] {
    return this.addParent([], origin);
  }

  addParent(items, origin): Section[] {
    const appending = this.workspace.getSection(origin.parentId);
    if (appending?.type === SectionItemType.Section) {
      items.push(appending);
      this.addParent(items, appending);
    }

    return items;
  }

  checkboxChange(item: SectionItem): void {
    const checked = item.selected;
    if (item.type === SectionItemType.Section) {
      const section = item as Section;
      section.getChildren<SectionItem>().forEach((item) => {
        item.selected = checked;
      });

      //when section is unchecked uncheck the parents also so it wont get deleted
      if (!checked) {
        this.getAllParents(item).forEach((item) => {
          item.selected = false;
        });
      }
    } else if (item.parentId && !checked) {
      this.workspace.getSection(item.parentId).selected = checked;
    }
    this.cdr.markForCheck();

    this.multiSelectUpdateService.updateMultiSelect();
  }

  async removeSectionItem(item: SectionItem): Promise<void> {
    this.deletingFile = item.type === SectionItemType.ContentItem;
    this.deletableItemRequest = item;
    this.deleteSectionItemPopup.popupActive = true;
  }

  async removeSectionItemConfirm(
    item: FileRequest | SectionItem,
    closePopup = true
  ): Promise<void> {
    this.workspace.removeSectionItem(item.id);
    this.workspace.removeSections((d) => d.selected !== true);
    this.selectedSection = undefined;
    this.activeSectionItem = -1;

    if (item.type === SectionItemType.FileRequest) {
      await this.fileRequestService.delete(item as FileRequest);
    }

    if (closePopup) {
      this.deleteSectionItemPopup.closePopup();
    }
  }

  shareSectionItem(sectionItem: SectionItem, link = false): void {
    this.sharePopup.shareSectionItem(sectionItem, link);
  }

  removeSectionConfirm(): void {
    this.deleteSectionPopup.popupActive = true;
  }

  async removeSection(): Promise<void> {
    const group = this.selectedSection;
    this.workspace.removeSections((d) => d.id !== group.id);
    await this.sectionService.delete(this.workspace.id, group.id);
    this.deleteSectionPopup.closePopup();
    this.resetSidebar();
  }

  public groupRemove(section: Section): void {
    this.selectedSection = section;
    this.deleteSectionPopup.popupActive = true;
  }

  get removeGroupParams(): any {
    let sectionType = this.selectedSection?.isFolder == true ? 'folder' : 'group';
    sectionType = this.translateService.instant(sectionType).toLowerCase();
    return { sectionType };
  }

  get selectedItemsLength(): any {
    const selectedItemsLength = this.workspace.selectedItems.length;
    return { selectedItemsLength };
  }

  isContentItem(item: CdkDrag<SectionItem>): boolean {
    return item.data.type === SectionItemType.ContentItem;
  }

  sidebarMenu = [
    {
      name: 'add-text',
      icon: 'format_size',
      action: (): Promise<void> => this.addQuestionRequest(QuestionType.Text),
    },
    {
      name: 'add-upload-button',
      icon: 'file_upload',
      action: (): Promise<void> => this.addFileRequest(),
    },
    {
      name: 'add-checkbox',
      icon: 'check_box',
      action: (): Promise<void> => this.addQuestionRequest(QuestionType.MultipleChoice),
    },
    {
      name: 'add-radiobutton',
      icon: 'radio_button_checked',
      action: (): Promise<void> => this.addQuestionRequest(QuestionType.MultipleChoice, 'radio'),
    },
    {
      name: 'add-open-question',
      icon: 'article',
      action: (): Promise<void> => this.addQuestionRequest(QuestionType.OpenQuestion),
    },
  ];
}
