import { Component, Input } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotFoundComponent {
  @Input() title: string;
  animation: AnimationItem;
  options: AnimationOptions = {
    path: '/assets/animations/404notfound.json',
    loop: false,
  };

  animationCreated(animationItem: AnimationItem): void {
    this.animation = animationItem;
  }

  animationEnd(): void {
    this.animation.goToAndPlay(62, true);
  }
}
