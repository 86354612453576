import { Injectable } from '@angular/core';
import { ItemShare, Workspace } from '@core/models';
import { OrganizationService } from './api/organization.service';
import { UserInfoService } from './api/userinfo.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceShareInfoService {
  constructor(
    private userInfoService: UserInfoService,
    private organizationService: OrganizationService
  ) {}

  public async GetShareInformation(...workspaces: Workspace[]): Promise<void> {
    const shares = workspaces.flatMap((workspace) => workspace.shares);
    const orgShares = shares.filter((share) => share.organizationId);
    const userShares = shares.filter((share) => share.userId);
    this.getOrganizations(orgShares);
    this.getUsers(userShares);
  }

  private async getOrganizations(shares: ItemShare[]): Promise<void> {
    const organizations = await this.organizationService.getByIds(
      shares.map((s) => s.organizationId)
    );
    shares.forEach(
      (share) => (share.organization = organizations.find((o) => o.id === share.organizationId))
    );
  }

  private async getUsers(shares: ItemShare[]): Promise<void> {
    const users = await this.userInfoService.getByIds(shares.map((s) => s.userId));
    shares.forEach((share) => (share.user = users.find((u) => u.id === share.userId)));
  }
}
