import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApplySubscriptionRequest, Subscription } from '@core/models';
import { HttpClient } from './http.client';

type Subscriptions = { subscription: Subscription; nextSubscription?: Subscription };

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private baseUrl = `${environment.apiBaseUrl}/users/subscription/v1`;

  public currentPlan: Subject<Subscriptions>;
  public currentPlanValue: Subscription;

  constructor(private http: HttpClient) {
    this.currentPlan = new Subject<Subscriptions>();
    this.currentPlan.subscribe(({ subscription }) => {
      this.currentPlanValue = subscription;
    });
  }

  get(id: string): Promise<Subscription> {
    return this.http.get<Subscription>(`${this.baseUrl}/${id}`);
  }

  list(): Promise<Subscription[]> {
    return this.http.get<Subscription[]>(`${this.baseUrl}`);
  }

  post(request: ApplySubscriptionRequest): Promise<HttpResponse<string>> {
    return this.http.post<string>(`${this.baseUrl}`, request);
  }
}
