<div class="abc">
  <span class="material-icons-outlined dropdown-icon" (click)="toggle()">
    {{ materialIcon }}
  </span>
  <div
    class="dropdown-options"
    *ngIf="active"
    (window:wheel)="close()"
    appScrollPositionFixed
    @fadeSlideInOut>
    <ng-content></ng-content>
  </div>
</div>
