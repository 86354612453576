<div class="workspace-search" [class.active]="collapsed">
  <div class="search-button" (click)="searchButton()">
    <span class="material-icons-outlined">search</span>
  </div>
  <div class="search-wrap">
    <input
      #searchInput
      [(ngModel)]="search"
      (ngModelChange)="update($event)"
      autocomplete="off"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>
</div>
