<app-container *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Users' }]">
  <div *ngIf="users" class="section-header">
    <div *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Users' }]">
      <button
        (click)="openCheckEmailPopup()"
        [disabled]="quotaService.maxNumberOfUsers && quotaService.maxNumberOfUsers <= users.length"
        class="btn-primary">
        <span class="material-icons-outlined">person_add</span>
        {{ 'Add user' | translate }}
      </button>
      <app-upgrade-button
        *ngIf="isUserQuotaReached && isSubscriptionUpgradable"></app-upgrade-button>
    </div>
    <input
      [(ngModel)]="userSearchText"
      class="searchBox"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>
  <div *ngIf="users">
    <section>
      <div class="statistics-bar" *ngIf="quotaService.maxNumberOfUsers">
        <app-progress-chart
          [statistics]="userStatistics"
          [total]="quotaService.maxNumberOfUsers"></app-progress-chart>
      </div>
      <table *ngIf="users">
        <tr>
          <th [appSort]="users" class="sortable" data-name="firstname" scope="col">
            {{ 'Name' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="users" class="sortable" data-name="email" scope="col">
            {{ 'Email address' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="users" class="sortable" data-name="memberships" scope="col">
            {{ 'Team' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="users" class="sortable" data-name="roles" scope="col">
            {{ 'Role' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="users" class="sortable" data-name="lastLoginUtc" scope="col">
            {{ 'Last login' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="users" class="sortable icon-column" data-name="isActive" scope="col">
            {{ 'Active' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th>{{ 'Actions' | translate }}</th>
        </tr>
        <tr
          (click)="goToDetails(user)"
          *ngFor="let user of users | userFilter : userSearchText"
          class="table-row">
          <td>{{ user.firstname }} {{ user.lastname }}</td>
          <td>{{ user.email }}</td>
          <td>
            {{ getMembershipNames(user.memberships) }}
          </td>
          <td>
            {{ getRoleNames(user.roles) }}
          </td>
          <td>
            <span *ngIf="isValidLoginDate(user.lastLoginUtc)">
              {{ user.lastLoginUtc | date : 'medium' : '' : translate.currentLang }}
            </span>
          </td>
          <td class="icon-column">
            <span *ngIf="user.isActive" class="material-icons">check_circle_outline</span>
          </td>
          <td>
            <div *ngIf="ownAccount !== user.email">
              <button
                (click)="confirmDeleteUser(user)"
                *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Users' }]"
                click-stop-propagation>
                <span class="material-icons">delete_outline</span>
              </button>
            </div>
          </td>
        </tr>
      </table>
    </section>
  </div>

  <app-modal-popup
    #checkUserBeforeAdd
    (eventPrimaryButtonClick)="checkEmail()"
    (eventSecondaryButtonClick)="closeAddUserPopup()"
    [fixed]="false"
    [popupTitle]="'Add user' | translate"
    [primaryButtonText]="'Next' | translate"
    [showButtons]="userNotChecked">
    <div *ngIf="userNotChecked">
      <div class="input-group">
        <mt-input placeholder="{{ 'Email' | translate }}">
          <input
            #mt="ngModel"
            (keyup.enter)="checkEmail()"
            [(ngModel)]="email"
            id="emailCheck"
            type="text" />
        </mt-input>
      </div>
    </div>
    <div *ngIf="!userNotChecked">
      <p>{{ 'Already in org explanation' | translate }}</p>
    </div>
  </app-modal-popup>

  <app-modal-popup
    #createUserPopup
    (eventPrimaryButtonClick)="addUser()"
    (eventSecondaryButtonClick)="openCheckEmailPopup()"
    [fixed]="false"
    [popupTitle]="'Create user' | translate"
    [primaryButtonText]="'Save' | translate"
    [secondaryButtonText]="'Back' | translate"
    [showButtons]="true">
    <div *ngIf="selectedUser">
      <div
        class="input-group"
        *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]">
        <mt-input placeholder="{{ 'Id' | translate }}">
          <input
            #createIdInput
            id="id"
            type="text"
            [(ngModel)]="selectedUser.id"
            #mt="ngModel"
            name="id"
            ngDefaultControl />
        </mt-input>
      </div>
      <div class="input-group">
        <mt-input placeholder="{{ 'Email' | translate }}">
          <input
            #mt="ngModel"
            [(ngModel)]="selectedUser.email"
            [disabled]="true"
            id="email"
            type="text" />
        </mt-input>
      </div>
      <div class="input-group">
        <mt-input placeholder="{{ 'First name' | translate }}">
          <input
            #createUserFirstnameInput
            #mt="ngModel"
            [(ngModel)]="selectedUser.firstname"
            id="firstname"
            type="text" />
        </mt-input>
      </div>
      <div class="input-group">
        <mt-input placeholder="{{ 'Last name' | translate }}">
          <input #mt="ngModel" [(ngModel)]="selectedUser.lastname" id="lastname" type="text" />
        </mt-input>
      </div>
      <div class="input-group">
        <ng-select
          [(ngModel)]="selectedUser.languageCode"
          [addTag]="true"
          [items]="translate.getLangs()"
          [ngModelOptions]="{
            updateOn: 'blur',
            standalone: true
          }"
          class="form-control"
          placeholder="{{ 'Language' | translate }}"></ng-select>
      </div>
    </div>
  </app-modal-popup>

  <app-modal-popup
    #deleteUserPopup
    (eventPrimaryButtonClick)="deleteUserFromOrganization()"
    [popupTitle]="'DeleteUserTitle' | translate"
    [primaryButtonText]="'DeleteCaption' | translate">
    <div>{{ 'ConfirmUserDelete' | translate }}</div>
  </app-modal-popup>
</app-container>
<router-outlet></router-outlet>
