<div *ngIf="organization">
  <div *ngIf="organization.users.length > 0" class="section-header">
    <input
      [(ngModel)]="userSearchText"
      class="searchBox"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>
  <section *ngIf="organization.users.length > 0">
    <table>
      <tr>
        <th [appSort]="organization.users" class="sortable" data-name="firstname" scope="col">
          {{ 'Name' }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th [appSort]="organization.users" class="sortable" data-name="email" scope="col">
          {{ 'Email address' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th
          [appSort]="organization.users"
          class="sortable"
          data-name="creationTimestampUtc"
          scope="col">
          {{ 'Created on' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th scope="col">{{ 'Roles' | translate }}</th>
        <th [appSort]="organization.users" class="sortable" data-name="isActive" scope="col">
          {{ 'Active' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
      </tr>
      <tr
        (click)="goToDetail(user.id)"
        *ngFor="let user of organization.users | userFilter: userSearchText"
        class="table-row">
        <td>{{ user.firstname }} {{ user.lastname }}</td>
        <td>
          {{ user.email }}
        </td>
        <td>
          <span *ngIf="isValidDate(user.creationTimestampUtc)">
            {{ user.creationTimestampUtc | date: 'medium':'':translate.currentLang }}
          </span>
        </td>
        <td>
          {{ getRoleNames(user.roles) }}
        </td>
        <td>
          <span *ngIf="user.isActive" class="material-icons">check_circle_outline</span>
        </td>
      </tr>
    </table>
  </section>
  <div *ngIf="organization.users.length === 0" class="empty-item-container">
    <h2 class="no-items-title">{{ 'No users' | translate }}</h2>
    <app-empty-animation></app-empty-animation>
  </div>
</div>
