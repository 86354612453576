import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkspaceComponent } from './workspace.component';
import { DetailsComponent } from './details/details.component';
import { AuthGuard } from '@core/guards';

const routes: Routes = [
  {
    path: '',
    component: WorkspaceComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: ':id',
    component: DetailsComponent,
    runGuardsAndResolvers: 'always',
    data: {
      breadcrumb: '',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
