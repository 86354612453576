import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { RelationsComponent } from './relations/relations.component';
import { RelationsDetailsComponent } from './relations/details/relations-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SupersSettingsComponent } from '@feature/settings/supers-settings/supers-settings.component';
import { AuditlogsComponent } from '@feature/settings/auditlogs/auditlogs.component';
import { TeamsComponent } from '@feature/settings/teams/teams.component';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { UsersDetailsComponent } from './users/details/users-details.component';
import { RelationsDetailInformationComponent } from './relations/details/information/relations-detail-information.component';
import { UsersTeamsComponent } from './users/details/teams/users-teams.component';
import { UsersRolesComponent } from './users/details/roles/users-roles.component';
import { TeamsDetailComponent } from '@feature/settings/teams/detail/teams-detail.component';
import { TeamsRolesComponent } from '@feature/settings/teams/detail/roles/teams-roles.component';
import { TeamsUsersComponent } from './teams/detail/users/teams-users.component';
import { RolesDetailComponent } from './roles/detail/roles-detail.component';
import { RolesPermissionsComponent } from './roles/detail/permissions/roles-permissions.component';
import { RolesTeamsComponent } from './roles/detail/teams/roles-teams.component';
import { RolesUsersComponent } from './roles/detail/users/roles-users.component';
import { OrganizationDetailsComponent } from './supers-settings/details/organization-details.component';
import { OrganizationUsersComponent } from './supers-settings/details/organization-users/organization-users.component';
import { AutosizeModule } from 'ngx-autosize';
import { OrganizationDiscountsComponent } from './supers-settings/details/organization-discounts/organization-discounts.component';
import { CreateAccountComponent } from './supers-settings/create-account/create-account.component';
import { DiscountDetailsComponent } from './supers-settings/details/organization-discounts/discount-details/discount-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { StyleguideComponent } from '@feature/settings/supers-settings/styleguide/styleguide.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    RelationsComponent,
    RelationsDetailsComponent,
    TeamsComponent,
    AuditlogsComponent,
    SupersSettingsComponent,
    UsersComponent,
    RolesComponent,
    UsersDetailsComponent,
    RelationsDetailInformationComponent,
    UsersTeamsComponent,
    UsersRolesComponent,
    TeamsDetailComponent,
    TeamsRolesComponent,
    TeamsUsersComponent,
    RolesDetailComponent,
    RolesPermissionsComponent,
    RolesTeamsComponent,
    RolesUsersComponent,
    OrganizationDetailsComponent,
    OrganizationUsersComponent,
    CreateAccountComponent,
    OrganizationDiscountsComponent,
    DiscountDetailsComponent,
    StyleguideComponent,
  ],
  imports: [
    InfiniteScrollModule,
    CommonModule,
    SettingsRoutingModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    AutosizeModule,
    NgxPaginationModule,
    NgSelectModule,
  ],
})
export class SettingsModule {}
