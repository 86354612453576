import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { HttpClient } from './http.client';
import { AuditEntry, CreateAuditEntry } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private baseUrl = `${environment.apiBaseUrl}/audit/v1`;

  constructor(private http: HttpClient) {}

  get(options?: string): Promise<AuditEntry[]> {
    return this.http.get<AuditEntry[]>(`${this.baseUrl}?${options}`);
  }

  post(entry: CreateAuditEntry): Promise<HttpResponse<AuditEntry>> {
    const { targetId, targetType } = entry;
    entry.eventTimestamp = new Date().toISOString();
    entry.comment = entry.comment || 'client action';
    return this.http.post(this.baseUrl, {
      ...entry,
      targetIds: targetId ? [targetId] : [],
      targetTypes: targetType ? [targetType] : [],
    });
  }
}
