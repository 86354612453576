import { Component, Input, OnInit } from '@angular/core';
import { Permission, Role } from '@core/models';
import { PermissionService } from '@core/services';

@Component({
  selector: 'app-roles-permissions',
  templateUrl: './roles-permissions.component.html',
  styleUrls: ['./roles-permissions.component.scss'],
})
export class RolesPermissionsComponent implements OnInit {
  @Input() role: Role;
  permissions;

  constructor(private permissionService: PermissionService) {}

  ngOnInit(): void {
    this.permissionService.list().then((u) => {
      const permissions = u.filter((p) => p.section !== 'SuperSettings');
      this.permissions = permissions.reduce((r, { section }) => {
        if (!r.some((o) => o.section === section)) {
          r.push({
            section,
            groupItem: permissions.filter((v) => v.section === section),
          });
        }
        return r;
      }, []);
    });
  }

  sortBy(array: Permission[]): Permission[] {
    return array.sort((a, b) =>
      a.permissionType > b.permissionType ? 1 : a.permissionType === b.permissionType ? 0 : -1
    );
  }

  getPermission(permission: Permission): Permission {
    return this.role.permissions.find((p) => p.id === permission.id);
  }

  async togglePermissionActive(permission: Permission): Promise<any> {
    const existingPermission = this.getPermission(permission);
    if (existingPermission) {
      this.role.permissions.splice(this.role.permissions.indexOf(existingPermission), 1);
      await this.permissionService.removeFromRole({
        permissionId: permission.id,
        roleId: this.role.id,
      });
    } else {
      this.role.permissions.push(permission);
      await this.permissionService.assignToRole({
        permissionId: permission.id,
        roleId: this.role.id,
      });
    }
  }
}
