import { Pipe, PipeTransform } from '@angular/core';
import { Membership } from '@core/models';

@Pipe({
  name: 'membershipFilter',
})
export class MembershipFilterPipe implements PipeTransform {
  transform(items: Membership[], searchText: string): Membership[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.name.toLowerCase().includes(searchText);
    });
  }
}
