import { Pipe, PipeTransform } from '@angular/core';
import { SectionItem } from '@core/models';

@Pipe({
  name: 'visibilityFilter',
  pure: false,
})
/* eslint-disable no-bitwise */
export class VisibilityFilterPipe implements PipeTransform {
  transform(items: Array<SectionItem>, edit: boolean): Array<SectionItem> {
    if (edit) return items.filter((item) => item.isOwner);
    else return items.filter((item) => item.isVisible);
  }
}
