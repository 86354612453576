<div
  (click)="gotoSection()"
  [class.deactive]="!section.expanded"
  [class.edit]="isEdit | async"
  [class.is-folder]="isFolder"
  [class]="isFolder ? '' : 'line-group'"
  class="workspace-section"
  multiSelectHover>
  <ng-content select=".drag-handle"></ng-content>
  <div (click)="sectionClick($event)" [contextMenu]="menu" class="workspace-section__top">
    <app-multi-select-checkbox
      type="section"
      (checkboxChange)="checkboxChange.emit()"
      [item]="section"></app-multi-select-checkbox>

    <div class="workspace-section__icon" [class.checked]="section.selected">
      <span class="material-icons-outlined">{{ isFolder ? 'folder' : 'inbox' }}</span>
    </div>
    <div
      *ngIf="isEdit | async"
      [ngClass]="!section.description ? 'no-description' : ''"
      class="workspace-section__text">
      <title-input
        (outputBlur)="updateSection()"
        [autofocus]="section.focused"
        [item]="section"
        class="title"
        click-stop-propagation
        placeholder="{{ isFolder ? 'New Folder' : ('New group' | translate) }}"
        type="title"></title-input>
      <title-input
        (outputBlur)="updateSection()"
        [item]="section"
        class="description"
        click-stop-propagation
        placeholder="{{ 'Description' | translate }}"
        type="description"></title-input>
    </div>
    <div
      *ngIf="(isEdit | async) === false"
      [class]="isFolder ? 'text-link' : ''"
      class="workspace-section__text">
      <span class="title">{{ section.name }}</span>
      <span *ngIf="section.description" class="description">
        {{ section.description }}
      </span>
    </div>

    <div class="workspace-section__link">
      <div class="workspace-section__chart">
        <app-workspace-statistics
          [section]="section"
          [showInvisible]="isEdit | async"
          [round]="true"></app-workspace-statistics>
      </div>

      <app-three-dot-menu [menu]="menu" click-stop-propagation></app-three-dot-menu>
      <icon-button
        [icon]="isFolder ? 'chevron_right' : 'expand_more'"
        click-stop-propagation
        (click)="navigationButtonClick()"></icon-button>
    </div>
  </div>

  <div *ngIf="!isFolder" class="workspace-section__content">
    <ng-content></ng-content>
  </div>
</div>
