<footer>
  <div class="container">
    <div class="left column">
      <a href="#">Terms & Conditions</a>
      <a href="https://www.binsend.com/contact">Contact</a>
    </div>
    <div class="center column">
      <img
        src="/assets/baseflow-logo-dark.png"
        srcset="/assets/baseflow-logo-dark@2x.png 2x, /assets/baseflow-logo-dark@3x.png 3x"
        class="baseflow-logo-dark"
        loading="lazy"
        decoding="async" />
    </div>
    <div class="right column">
      <!--            <a href="#">Developer API</a>-->
    </div>
  </div>
</footer>
