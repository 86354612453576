<app-dropdown
  [dropdownStyle]="'bigger-version'"
  [iconStyle]="selectedItem.style"
  [icon]="selectedItem.icon"
  [labelTop]="selectedItem.label | translate"
  [label]="selectedItem.name | translate">
  <app-dropdown-item
    (response)="selectItem(item)"
    *ngFor="let item of menu"
    [dropdownStyle]="'bigger-version'"
    [icon]="item.icon"
    [key]="item.status"
    [style]="item.style"
    [title]="item.name | translate"></app-dropdown-item>
</app-dropdown>
