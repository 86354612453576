import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthorizedSubject = new ReplaySubject<boolean>(1);
  public isAuthorized = this.isAuthorizedSubject.asObservable();
  private accessToken: string;

  constructor(private oidcSecurityService: OidcSecurityService) {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, accessToken }) => {
      this.accessToken = accessToken;
      this.isAuthorizedSubject.next(isAuthenticated);
    });
  }

  login(): void {
    localStorage.setItem('targetRedirectUrl', window.location.href);
    this.oidcSecurityService.authorize();
  }

  logout(): Promise<unknown> {
    return lastValueFrom(this.oidcSecurityService.logoff());
  }

  getIsAuthorized(): Observable<any> {
    return this.isAuthorized;
  }

  getToken(): string {
    return this.accessToken;
  }

  public getUserData(): Promise<any> {
    return this.oidcSecurityService.userData$.pipe(take(1)).toPromise();
  }
}
