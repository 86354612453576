import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { WorkspaceService } from '@core/services';
import { ItemStatusType, Workspace } from '@core/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-template-search-panel',
  templateUrl: './template-search-panel.component.html',
  styleUrls: ['./template-search-panel.component.scss'],
})
export class TemplateSearchPanelComponent implements AfterViewInit {
  public statusTypes = ItemStatusType;
  searchText: string;

  get templates(): Workspace[] {
    return this.workspaceService.templates.filter(
      (b) => b.requestContentItemStatus !== ItemStatusType.Archived
    );
  }

  @ViewChild('searchTextBox') searchTextBox: ElementRef;

  @Output() templateSelected: EventEmitter<Workspace> = new EventEmitter<Workspace>();

  constructor(private workspaceService: WorkspaceService, public translate: TranslateService) {}

  async useTemplate(template: Workspace): Promise<void> {
    this.templateSelected.emit(template);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchTextBox.nativeElement.select();
    }, 300);
  }
}
