<dialog class="popup" *ngIf="isPopupActive">
  <div class="header">
    <div class="popup-title">
      <span class="header-title">{{ popupTitle }}</span>
    </div>
    <div class="close-button">
      <span class="material-icons" (click)="closePopup()">close</span>
    </div>
  </div>

  <div class="content" (appScrollState)="setIsScrollShadow($event)">
    <ng-content></ng-content>
  </div>

  <div class="scroll-shadow" [ngClass]="getIfScrollShadow() ? 'active' : ''"></div>
</dialog>

<div *ngIf="popupActive === true" class="popup-background" (click)="closePopup()"></div>
