import { Component, OnInit } from '@angular/core';
import {
  BrandingService,
  NotificationService,
  OrganizationService,
  SubscriptionService,
  UserAccountService,
} from '@core/services';
import {
  Address,
  Organization,
  Subscription,
  UpdateOrganizationDto,
  UserAccount,
} from '@core/models';
import { Constants } from '@core/common/constants';

@Component({
  selector: 'app-settings-organization',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss'],
})
export class OrganizationDetailComponent implements OnInit {
  organization: Organization;

  logoPicture = '';
  loginPicture = '';

  constructor(
    private organizationService: OrganizationService,
    public subscriptionService: SubscriptionService,
    private notifyService: NotificationService,
    private userAccountService: UserAccountService,
    private brandingService: BrandingService
  ) {}

  darkColor(color): boolean {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    const r = color >> 16;
    const g = (color >> 8) & 255;
    const b = color & 255; // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html

    // Variables for red, green, blue values
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)); // Using the HSP value, determine whether the color is light or dark
    return hsp <= 127.5;
  }

  async ngOnInit(): Promise<void> {
    this.userAccountService.accountUpdated.subscribe(async (user) => {
      if (user.hasOrganization) {
        const organizationId = user.organizationId;
        this.organization = await this.organizationService.get(organizationId);

        if (!this.organization.primaryColor) {
          this.resetPrimaryColor();
        }
        if (!this.organization.accentColor) {
          this.resetAccentColor();
        }
      } else if (!this.organization) {
        const subscriptions = await this.subscriptionService.list();

        this.organization = new Organization();
        this.organization.subscriptionId = subscriptions.find((d) => d.isStarterTier).id;
      }
    });
  }

  get user(): UserAccount {
    return this.userAccountService.user;
  }

  get currentPlan(): Subscription {
    return this.subscriptionService.currentPlanValue;
  }

  async updateOrganization(updateOrganization: UpdateOrganizationDto): Promise<any> {
    const organization = { ...this.organization, ...updateOrganization };
    if (!organization.name) {
      this.notifyService.showError(
        'Please provide an organization name first',
        'Cannot save organization'
      );
      return;
    }
    if (organization.id === Constants.EmptyGuid || !organization.id) {
      await this.createOrganization(updateOrganization.name);
    } else {
      await this.organizationService.update(organization.id, {
        OrganizationDto: updateOrganization,
      });
      this.userAccountService.user.organization = organization.name;
      this.brandingService.checkAndApplyBranding(
        organization,
        this.subscriptionService.currentPlanValue
      );
      this.organization = organization;
    }
  }

  async createOrganization(name: string): Promise<void> {
    const result = await this.organizationService.post({
      organizationName: name,
    });
    if (result.ok) {
      // get the full new organization object.
      this.organization = await this.organizationService.get(result.body.id);
      this.organization.address = new Address();
      this.organization.invoiceAddress = new Address();
      await this.userAccountService.refreshSession();
    } else {
      this.notifyService.showError('GenericErrorMessage', 'UnableToStoreChangesTitle');
    }
  }

  resetPrimaryColor(): void {
    const primaryColor = Constants.BinsendColorHex;
    this.updateOrganization({ primaryColor });
  }

  resetAccentColor(): void {
    const accentColor = Constants.BinsendColorHex;
    this.updateOrganization({ accentColor });
  }
}
