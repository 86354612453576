import { Injectable } from '@angular/core';
import {
  IMoveToParentRequest,
  Section,
  SectionItem,
  SectionItemType,
  SectionRequestItem,
} from '@core/models';
import { environment } from '@env/environment';
import { IContentItemService } from './content-item.service';
import { HttpClient } from './http.client';

const baseUrl = `${environment.apiBaseUrl}/share`;

const getSectionUrl = (workspaceId: string, sectionId: string): string =>
  `${baseUrl}/v1/workspaces/${workspaceId}/sections/${sectionId}`;

const getSectionItemUrl = (
  workspaceId: string,
  sectionId: string,
  path: string,
  sectionItemId: string
): string => {
  return `${baseUrl}/v1/workspaces/${workspaceId}/sections/${sectionId}/${path}/${sectionItemId}`;
};

const getPostSectionUrl = (workspaceId: string, sectionId: string): string =>
  `${getSectionUrl(workspaceId, sectionId)}/sections`;

@Injectable({
  providedIn: 'root',
})
export class SectionService implements IContentItemService {
  subPaths: Record<SectionItemType, string>;

  constructor(private http: HttpClient) {
    this.subPaths = {
      [SectionItemType.Section]: 'sections',
      [SectionItemType.FileRequest]: 'fileRequests',
      [SectionItemType.Question]: 'questions',
      [SectionItemType.ContentItem]: 'contentItems',
    };
  }

  moveSectionItem(
    workspaceId: string,
    sectionId: string,
    moveRequest: IMoveToParentRequest
  ): Promise<boolean> {
    const getUrl = moveRequest.type === SectionItemType.Section ? getSectionUrl : getSectionItemUrl;
    const path = this.subPaths[moveRequest.type];
    return this.http.put<boolean>(getUrl(workspaceId, sectionId, path, moveRequest.sectionItemId), {
      ...moveRequest,
      parentSection: moveRequest.parentSection,
    });
  }

  update(workspaceId: string, sectionId: string, section: Section): Promise<boolean> {
    const { name, description } = section;
    const success = this.http.put<boolean>(getSectionUrl(workspaceId, sectionId), {
      name,
      description,
    });
    return success;
  }

  post(workspaceId: string, sectionId: string, fileRequest: SectionRequestItem): Promise<Section> {
    return this.http
      .post(getPostSectionUrl(workspaceId, sectionId), fileRequest)
      .then((r) => new Section(r.body));
  }

  delete(workspaceId: string, sectionId: string): Promise<any> {
    return this.http.delete(getSectionUrl(workspaceId, sectionId));
  }

  addContentItem(
    sectionItem: SectionItem,
    contentItemId: string,
    insertAt: number
  ): Promise<boolean> {
    const { workspaceId, sectionId } = sectionItem;
    return this.moveSectionItem(workspaceId, sectionId, {
      insertAt,
      parentSection: sectionId,
      sectionItemId: contentItemId,
      type: SectionItemType.ContentItem,
    });
  }

  deleteContentItem(sectionItem: SectionItem, contentItemId: string): Promise<any> {
    const { workspaceId, sectionId } = sectionItem;
    return this.deleteContentItemById(workspaceId, sectionId, contentItemId);
  }

  deleteContentItemById(workspaceId: string, sectionId: string, ciId: string): Promise<any> {
    const url = getSectionItemUrl(workspaceId, sectionId, 'contentItems', ciId);
    return this.http.delete(url);
  }
}
