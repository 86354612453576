import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ItemStatusType, PermissionType, Subscription, Workspace } from '@core/models';
import { WorkspaceUiStateService, SubscriptionService, WorkspaceService } from '@core/services';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { Router } from '@angular/router';
import { ArchiveWorkspacePopupComponent } from '@app/shared/pop-up/archive-workspace-popup/archive-workspace-popup.component';
import { ApproveWorkspacePopupComponent } from '@app/shared/pop-up/approve-workspace-popup/approve-workspace-popup.component';

@Component({
  selector: 'app-workspace-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public permissionTypes = PermissionType;
  public statusTypes = ItemStatusType;
  maxRetention = 5;

  @Input() workspace: Workspace;

  @ViewChild('deleteWorkspacePopup') deleteWorkspacePopup: ModalPopupComponent;
  @ViewChild('archiveWorkspacePopup')
  archiveWorkspacePopup: ArchiveWorkspacePopupComponent;

  @ViewChild('confirmApproveWorkspacePopup')
  confirmApproveWorkspacePopup: ApproveWorkspacePopupComponent;

  minDate = new Date();
  maxDateValue = new Date();
  maxDate: string;
  currentPlan;

  constructor(
    private workspaceService: WorkspaceService,
    private stateService: WorkspaceUiStateService,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const workspaceCreation = new Date(this.workspace.creationTimestampUtc);
    this.maxDate = this.maxDateValue.setDate(workspaceCreation.getDate() + 5).toString();
    this.updateSubscription(this.subscriptionService.currentPlanValue);
    this.subscriptionService.currentPlan.subscribe(({ subscription }) =>
      this.updateSubscription(subscription)
    );
  }

  get interpolationParams(): any {
    return { workspaceType: this.workspace?.isTemplate ? 'template' : 'workspace' };
  }

  get hideDate(): any {
    return (
      this.currentPlan?.workspaceRetentionInDays === null &&
      this.workspace.workspaceSettings.workspaceRetentionInDays === -1
    );
  }

  updateNoExpire(): void {
    if (this.workspace.workspaceSettings.workspaceRetentionInDays < 0) {
      //check if has enterprise (null)
      this.workspace.workspaceSettings.workspaceRetentionInDays =
        this.currentPlan.workspaceRetentionInDays > 0
          ? this.currentPlan.workspaceRetentionInDays
          : 365;
    } else {
      this.workspace.workspaceSettings.workspaceRetentionInDays = -1;
    }

    this.updateWorkspace();
  }

  updateSubscription(subscription: Subscription): void {
    if (!subscription) return;
    this.currentPlan = subscription;
    const workspaceCreation = new Date(this.workspace.creationTimestampUtc);
    if (subscription.name === 'Enterprise') {
      this.maxRetention = 3650;
      this.maxDate = this.maxDateValue.setDate(workspaceCreation.getDate() + 3650).toString();
    } else {
      this.maxRetention = subscription.workspaceRetentionInDays;
      this.maxDate = this.maxDateValue
        .setDate(workspaceCreation.getDate() + subscription.workspaceRetentionInDays)
        .toString();
    }

    const wsSettings = this.workspace.workspaceSettings;
    if (wsSettings) {
      wsSettings.workspaceRetentionInDays =
        wsSettings.workspaceRetentionInDays || this.maxRetention;
      wsSettings.deliveryDeadlineInDays = wsSettings.deliveryDeadlineInDays || this.maxRetention;
    }
  }

  async updateWorkspace(): Promise<void> {
    await this.workspaceService.update(this.workspace.id, {
      newName: this.workspace.name,
      workspaceSettings: this.workspace.workspaceSettings,
      newDescription: this.workspace.description,
      workspaceStatus: this.workspace.requestContentItemStatus,
    });
  }

  openConfirmPopup(): void {
    this.confirmApproveWorkspacePopup.activatePopup();
  }

  openArchivePopup(): void {
    this.archiveWorkspacePopup.activatePopup();
  }

  async removeWorkspace(): Promise<void> {
    await this.workspaceService.delete(this.workspace.id);
    await this.workspaceService.refresh();
    await this.router.navigate([`/`]);
  }

  async setWorkspaceStatus(itemStatusType: ItemStatusType): Promise<void> {
    if (itemStatusType === ItemStatusType.Archived || itemStatusType === ItemStatusType.Approved) {
      this.stateService.setIsEdit(false);
    }
    this.workspace.requestContentItemStatus = itemStatusType;
    await this.workspaceService.update(this.workspace.id, {
      newDescription: this.workspace.description,
      newName: this.workspace.name,
      workspaceSettings: this.workspace.workspaceSettings,
      workspaceStatus: itemStatusType,
    });
    await this.workspaceService.refresh();
  }

  async approveWorkspace(): Promise<void> {
    if (this.workspace.containsUnapprovedRcis) {
      this.confirmApproveWorkspacePopup.activatePopup();
    } else {
      await this.setWorkspaceStatus(ItemStatusType.Approved);
    }
  }

  checkRetentionAndUpdate(): void {
    const wsSettings = this.workspace.workspaceSettings;
    if (this.maxRetention && wsSettings.workspaceRetentionInDays > this.maxRetention) {
      wsSettings.workspaceRetentionInDays = this.maxRetention;
    }
    if (this.maxRetention && wsSettings.deliveryDeadlineInDays > this.maxRetention) {
      wsSettings.deliveryDeadlineInDays = this.maxRetention;
    }
    if (wsSettings.deliveryDeadlineInDays && wsSettings.deliveryDeadlineInDays < 1) {
      wsSettings.deliveryDeadlineInDays = 1;
    }
    if (wsSettings.workspaceRetentionInDays && wsSettings.workspaceRetentionInDays < 1) {
      wsSettings.workspaceRetentionInDays = 1;
    }
    this.updateWorkspace();
  }
}
