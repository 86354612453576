<app-breadcrumb [contextMenu]="bread"></app-breadcrumb>

<div class="tag-bar">
  <app-tag tag="Van Dike B.V"></app-tag>
  <app-tag tag="VD202243295"></app-tag>
  <app-tag></app-tag>
</div>

<label class="checkboxcontainer--new">
  Checkbox
  <input type="checkbox" />
  <span class="checkmark--new"></span>
</label>

<label class="checkboxcontainer--new disabled">
  Checkbox disabled
  <input disabled type="checkbox" />
  <span class="checkmark--new"></span>
</label>

<label class="checkboxcontainer--new">
  Checkbox checked
  <input checked type="checkbox" />
  <span class="checkmark--new"></span>
</label>

<label class="checkboxcontainer--new disabled">
  Checkbox checked disabled
  <input checked disabled type="checkbox" />
  <span class="checkmark--new"></span>
</label>

<div class="bar">
  <label class="checkboxcontainer--new checkboxcontainer--big">
    <input type="checkbox" />
    <span class="checkmark--new"></span>
  </label>
</div>

<div [contextMenu]="menu" class="test-block">
  <span>Test dit dat</span>
  <icon-button icon="inbox" (click)="alert('You pressed an icon button!')"></icon-button>
  <app-three-dot-menu [menu]="menu"></app-three-dot-menu>
  <app-three-dot-menu [menu]="bread" dark="true"></app-three-dot-menu>
</div>
