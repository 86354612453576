import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemPermissionType } from '@core/models';

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
})
export class DropdownItemComponent {
  @Output() response: EventEmitter<ItemPermissionType> = new EventEmitter();
  @Input() title = '';
  @Input() icon = '';
  @Input() key: ItemPermissionType;
  @Input() bold = false;
  @Input() style;
  @Input() dropdownStyle: string;

  emitOnResponse(): void {
    this.response.emit(this.key);
  }
}
