import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appScrollPositionFixed]',
})

/*
 * Places a fixed element under its parent
 * Makes a 'position fixed' element not ignore scrolling.
 * The advantage is that the element moves with the scrolling. (unlike it's normal behaviour)
 *
 * */
export class ScrollPositionFixedDirective implements OnInit {
  constructor(readonly elRef: ElementRef) {}

  ngOnInit(): void {
    const thisElement = this.elRef.nativeElement;
    const parent = this.elRef.nativeElement.parentElement;
    const parentCoords = parent.getBoundingClientRect();
    // We place the element under the parent element
    thisElement.style.top = parentCoords.top + parentCoords.height + 'px';
  }
}
