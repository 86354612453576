<ng-container *ngIf="workspace">
  <div class="settings-row workspace-settings">
    <div class="workspace-settings-group">
      <h3>{{ 'General settings' | translate }}</h3>

      <div class="workspace-settings-object" *ngIf="workspace.isTemplate">
        <div class="input-group">
          <div class="settings-row">
            <input
              id="expiryDateInDays"
              type="number"
              [(ngModel)]="workspace.workspaceSettings.workspaceRetentionInDays"
              [max]="maxRetention"
              min="1"
              (blur)="checkRetentionAndUpdate()" />
            <div class="input-group">
              <label for="expiryDate">
                {{ 'Workspace retention in days' | translate }}
              </label>
              <label class="checkbox-subtitle-number">
                {{ 'Retention date expl' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="workspace-settings-object" *ngIf="workspace.isTemplate">
        <div class="input-group">
          <div class="settings-row">
            <input
              id="deadlineInDays"
              type="number"
              [(ngModel)]="workspace.workspaceSettings.deliveryDeadlineInDays"
              [max]="maxRetention"
              min="1"
              (blur)="checkRetentionAndUpdate()" />
            <div class="input-group">
              <label for="deadline">
                {{ 'Delivery deadline in days' | translate }}
              </label>
              <label class="checkbox-subtitle-number">
                {{ 'Deadline period expl' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="workspace-settings-object" *ngIf="!workspace.isTemplate">
        <div class="input-group">
          <label class="settings-title" for="expiryDate">
            {{ 'Workspace expiry date' | translate }}
          </label>
          <label for="expiryDate" class="date-label" [class.hidden-label]="hideDate">
            {{ 'Expiry date expl' | translate }}
          </label>
          <div class="date-input" [class.hidden-date]="hideDate">
            <input
              (ngModelChange)="
                workspace.workspaceSettings.workspaceExpiryDate = $event; updateWorkspace()
              "
              [disabled]="workspace.requestContentItemStatus === statusTypes.Archived"
              [ngModel]="workspace.workspaceSettings.workspaceExpiryDate | date : 'yyyy-MM-dd'"
              id="expiryDate"
              max="{{ maxDate | date : 'yyyy-MM-dd' }}"
              min="{{ minDate | date : 'yyyy-MM-dd' }}"
              type="date" />
          </div>

          <label
            class="lightswitch"
            *ngIf="currentPlan?.workspaceRetentionInDays === null"
            [class.disabled]="workspace.requestContentItemStatus === statusTypes.Archived">
            <input
              type="checkbox"
              (change)="updateNoExpire()"
              [checked]="workspace.workspaceSettings.workspaceRetentionInDays === -1"
              [disabled]="workspace.requestContentItemStatus === statusTypes.Archived" />
            <i></i>
            {{ 'Workspace does not expire' | translate }}
          </label>
        </div>
      </div>

      <div class="workspace-settings-object" *ngIf="!workspace.isTemplate">
        <div class="input-group">
          <label class="settings-title" for="deadline">
            {{ 'Item request deadline' | translate }}
          </label>
          <label for="deadline">{{ 'Deadline date expl' | translate }}</label>
          <div class="date-input">
            <input
              (ngModelChange)="
                workspace.workspaceSettings.deliveryDeadline = $event; updateWorkspace()
              "
              [disabled]="workspace.requestContentItemStatus === statusTypes.Archived"
              [ngModel]="workspace.workspaceSettings.deliveryDeadline | date : 'yyyy-MM-dd'"
              id="deadline"
              max="{{ maxDate | date : 'yyyy-MM-dd' }}"
              min="{{ minDate | date : 'yyyy-MM-dd' }}"
              type="date" />
          </div>
        </div>
      </div>

      <div class="workspace-settings-object">
        <div class="input-group">
          <label class="checkboxcontainer">
            <input
              (change)="updateWorkspace()"
              [(ngModel)]="workspace.workspaceSettings.allowNonTemplateFiles"
              [disabled]="workspace.requestContentItemStatus === statusTypes.Archived"
              [ngModelOptions]="{ standalone: true }"
              type="checkbox" />
            <span class="checkmark"></span>
            <label class="checkbox-title settings-title">
              {{ 'Allow non-template files' | translate }}
            </label>
          </label>
          <label class="checkbox-subtitle">
            {{ 'Non template expl' | translate }}
          </label>
        </div>
      </div>
    </div>

    <div class="workspace-settings-group">
      <h3 class="with-explanation">{{ 'Email settings' | translate }}</h3>
      <p class="workspace-settings-explanation">
        {{ 'Receive email when' | translate }}
      </p>
      <div class="workspace-settings-object">
        <div class="input-group">
          <label class="checkboxcontainer">
            <input
              (change)="updateWorkspace()"
              [(ngModel)]="workspace.workspaceSettings.emailOnPendingExpiration"
              [disabled]="workspace.requestContentItemStatus === statusTypes.Archived"
              [ngModelOptions]="{ standalone: true }"
              type="checkbox" />
            <span class="checkmark"></span>
            <label class="checkbox-title settings-title">
              {{ 'Workspace about to expire' | translate }}
            </label>
          </label>
          <label class="checkbox-subtitle">
            {{ 'Expiry email expl' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="settings-row workspace-settings">
    <div class="workspace-settings-group">
      <h3>{{ 'Actions' | translate }}</h3>
      <div class="workspace-actions">
        <div *hasPermission="[{ permissionType: permissionTypes.Edit, section: 'Workspaces' }]">
          <button
            (click)="openArchivePopup()"
            [disabled]="workspace.requestContentItemStatus === statusTypes.Archived"
            class="btn-secondary-large first-button">
            <span class="material-icons-outlined">archive</span>
            {{ 'Archive' | translate }}
          </button>

          <button
            *ngIf="!workspace.isTemplate"
            (click)="approveWorkspace()"
            [disabled]="
              workspace.requestContentItemStatus === statusTypes.Approved ||
              workspace.requestContentItemStatus === statusTypes.Archived
            "
            class="btn-secondary-large">
            <span class="material-icons-round material-icon-approved">check_circle</span>
            {{ 'Approve' | translate }}
          </button>

          <button
            (click)="setWorkspaceStatus(statusTypes.Unknown)"
            [disabled]="workspace.requestContentItemStatus === statusTypes.Unknown"
            class="btn-secondary-large">
            <span class="material-icons">power_settings_new</span>
            {{ 'Activate' | translate }}
          </button>

          <button
            (click)="deleteWorkspacePopup.popupActive = true"
            *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Workspaces' }]"
            class="btn-secondary-large">
            <span class="material-icons material-icon-rejected">delete_outline</span>
            {{ 'DeleteCaption' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-modal-popup
  #deleteWorkspacePopup
  (eventPrimaryButtonClick)="removeWorkspace()"
  [popupTitle]="'DeleteWorkspaceTitle' | translate"
  [primaryButtonText]="'DeleteWorkspaceActionButton' | translate : interpolationParams">
  <div>{{ 'ConfirmWorkspaceDelete' | translate }}</div>
</app-modal-popup>

<app-approve-workspace-popup
  #confirmApproveWorkspacePopup
  (changeEventEmitter)="setWorkspaceStatus(statusTypes.Approved)"></app-approve-workspace-popup>

<app-archive-workspace-popup
  #archiveWorkspacePopup
  (changeEventEmitter)="setWorkspaceStatus(statusTypes.Archived)"
  [workspace]="workspace"></app-archive-workspace-popup>
