import { Component, Input, OnInit } from '@angular/core';
import { Membership, Role } from '@core/models';
import { MembershipService, RoleService } from '@core/services';

@Component({
  selector: 'app-teams-roles',
  templateUrl: './teams-roles.component.html',
  styleUrls: ['./teams-roles.component.scss'],
})
export class TeamsRolesComponent implements OnInit {
  @Input() membership: Membership;
  roles: Role[];
  roleSearchText: string;

  constructor(private membershipService: MembershipService, private roleService: RoleService) {}

  async ngOnInit(): Promise<void> {
    this.roles = await this.roleService.list();
  }

  checkRoleActiveForMembership(role: Role): Role {
    return this.membership.roles.find((p) => p.id === role.id);
  }

  async toggleRoleActiveForMembership(role: Role): Promise<any> {
    const existingRole = this.checkRoleActiveForMembership(role);
    if (existingRole) {
      this.membership.roles.splice(this.membership.roles.indexOf(existingRole), 1);
      await this.membershipService
        .removeRoleFromMembership({
          roleId: role.id,
          membershipId: this.membership.id,
        })
        .toPromise();
    } else {
      this.membership.roles.push(role);
      await this.membershipService
        .assignRoleToMembership({
          roleId: role.id,
          membershipId: this.membership.id,
        })
        .toPromise();
    }
  }
}
