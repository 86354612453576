import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportComponent } from '@feature/support/support.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PrivacyStatementComponent } from '@feature/support/privacy-statement/privacy-statement.component';
import { TermsAndConditionsComponent } from '@feature/support/terms-and-conditions/terms-and-conditions.component';
import { SupportRoutingModule } from '@feature/support/support-routing.module';
import { SupportFormComponent } from './support-form/support-form.component';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';

@NgModule({
  declarations: [
    SupportComponent,
    PrivacyStatementComponent,
    TermsAndConditionsComponent,
    SupportFormComponent,
  ],
  imports: [
    SupportRoutingModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    AutosizeModule,
  ],
})
export class SupportModule {}
