<app-container *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Roles' }]">
  <div *ngIf="roles" class="section-header">
    <div *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Roles' }]">
      <button
        (click)="addRole()"
        *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Roles' }]"
        class="btn-primary">
        <span class="material-icons-outlined">add_moderator</span>
        {{ 'Add role' | translate }}
      </button>
    </div>
    <input
      [(ngModel)]="roleSearchText"
      class="searchBox"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>

  <section>
    <table>
      <tr>
        <th></th>
        <th [appSort]="roles" class="sortable" data-name="name" scope="col">
          {{ 'Name' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th [appSort]="roles" class="strech sortable" data-name="permissions" scope="col">
          {{ 'Permissions' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th>{{ 'Actions' | translate }}</th>
      </tr>
      <tr
        (click)="goToDetails(role.id)"
        *ngFor="let role of roles | roleFilter : roleSearchText"
        class="table-row">
        <td>
          <span *ngIf="role.isSystemRole" class="material-icons-outlined">lock</span>
        </td>
        <td>
          <span *ngIf="!role.isSystemRole">{{ role.name }}</span>
          <span *ngIf="role.isSystemRole">{{ role.name | translate }}</span>
        </td>
        <td class="strech">
          <span *ngFor="let permission of role.permissions | slice : 0 : 4; let i = index">
            {{ permission.section | translate }}
            {{ 'rolePermissionType_' + permission.permissionType | translate
            }}{{ i < role.permissions.length - 1 ? ', ' : '' }}
          </span>
          <span *ngIf="role.permissions.length > 4" class="others">
            {{ role.permissions.length - 1 }} {{ 'more' | translate }}
          </span>
        </td>
        <td>
          <span *ngIf="!role.isSystemRole">
            <button
              (click)="confirmDeleteRole(role)"
              *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Roles' }]"
              click-stop-propagation>
              <span class="material-icons">delete_outline</span>
            </button>
          </span>
        </td>
      </tr>
    </table>
  </section>

  <app-modal-popup
    #deleteRolePopup
    (eventPrimaryButtonClick)="deleteRole()"
    [popupTitle]="'DeleteRoleTitle' | translate"
    [primaryButtonText]="'DeleteCaption' | translate">
    <div>{{ 'ConfirmRoleDelete' | translate }}</div>
  </app-modal-popup>
</app-container>
