<div class="workspace-title-edit-form">
  <div
    *ngIf="
      workspace &&
      (workspace.requestContentItemStatus === itemStatusTypes.Approved ||
        workspace.requestContentItemStatus === itemStatusTypes.Archived)
    "
    class="workspace-status-message">
    {{ 'This workspace has been' | translate }}
    {{ itemStatusTypes[workspace.requestContentItemStatus] | translate | lowercase }}{{ '.' }}
    <span *ngIf="!workspace.isOwner">
      {{ 'You can no longer edit the contents of this workspace.' | translate }}
    </span>
  </div>
</div>

<div
  (fileDropped)="upload($event)"
  [appDndEnabled]="workspace.workspaceSettings.allowNonTemplateFiles && workspace.canEdit"
  appDnd
  class="workspace-details"
  dragIcon="all_inbox"
  dragTitle="{{ 'place-file-in-workspace' | translate }}">
  <div class="content-container workspace-details-container">
    <div *ngIf="workspace" class="workspace-form">
      <div class="content-items-container">
        <div *ngIf="workspace.aboutToExpire" class="about-to-expire">
          <span class="material-icons-outlined material-icon-rejected">report_problem</span>
          {{ 'Warning:' | translate }} {{ 'about to expire' | translate }}
        </div>

        <workspace-header [workspace]="workspace" [saved]="saved" (hideSidebar)="hideSidebar()">
          <div *ngIf="workspace.items?.length" class="workspace-actions">
            <div class="left">
              <app-multi-select-all
                [active]="true"
                (checkAll)="checkAll()"
                [checked]="workspace.allVisibleSelected"></app-multi-select-all>

              <workspace-filter
                (selectedStatusChange)="selectStatus($event)"
                [selectedStatus]="selectedStatus"></workspace-filter>

              <app-workspace-search
                *ngIf="!workspace.isTemplate"
                [(search)]="searchText"></app-workspace-search>

              <icon-button
                (click)="downloadWorkspace()"
                [tooltip]="(selectionActive ? 'Download selection' : 'Download all') | translate"
                icon="file_download"
                *ngIf="
                  (downloadStatus | async)?.progressKind === ProgressKind.None &&
                  workspace.hasUploads
                "
                class="download-all"
                id="download-all"></icon-button>
              <ng-container *ngIf="downloadStatus | async as status">
                <icon-button
                  (click)="onClickCancelAllDownloads()"
                  *ngIf="status.progressKind !== ProgressKind.None"
                  [tooltip]="(selectionActive ? 'Cancel selection' : 'Cancel all') | translate"
                  class="download-all"
                  icon="cancel">
                  {{ progressCaption(status.progressKind) }} ({{ status.percentage }}%)
                </icon-button>
              </ng-container>
            </div>
          </div>
        </workspace-header>

        <section-container
          *ngIf="!workspace.isEmpty || (isEdit | async)"
          #sectionContainer
          [workspace]="workspace"
          [searchText]="searchText"
          [selectedStatus]="selectedStatus"></section-container>

        <div *ngIf="workspace.isEmpty && (isEdit | async) === false" class="empty-item-container">
          <h2 class="no-items-title">
            {{
              workspace.isTemplate ? ('NoFilesYetTemplate' | translate) : ('NoFilesYet' | translate)
            }}
          </h2>
          <div
            #hoverImage
            (dragleave)="unsetDragOver($event)"
            (dragover)="setDragOver($event)"
            class="container">
            <app-empty-animation></app-empty-animation>

            <input
              #fileDropRef
              (change)="uploadFromBrowser($event.target.files)"
              id="fileDropRef"
              multiple
              type="file" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-popup
  #warnAboutChangePagePopup
  (eventPrimaryButtonClick)="navigateDespiteWarning()"
  [popupTitle]="'WarningNavigateAwayUploadTitle' | translate">
  <div>{{ 'WarningNavigateAwayUpload' | translate }}</div>
</app-modal-popup>
