import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Workspace } from '@core/models';
import {
  ContextMenuItem,
  WorkspaceUiStateService,
  UserAccountService,
  WorkspaceService,
} from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { FileDownloaderService } from '@app/core/services/api/file-downloader.service';
import { FileUploaderService } from '@app/core/services/api/file-uploader.service';
import { SelectTemplatePopupComponent } from '../select-template-popup/select-template-popup';
import { CopyWorkspacePopup } from '../copy-workspace-popup/copy-workspace-popup';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'workspace-menu',
  templateUrl: './workspace-menu.component.html',
  styleUrls: ['./workspace-menu.component.scss'],
})
export class WorkspaceMenuComponent implements OnInit {
  @ViewChild('uploadButton') uploadButton: ElementRef;
  @ViewChild('selectTemplateForWorkspacePopup')
  selectTemplateForWorkspacePopup: SelectTemplatePopupComponent;

  @ViewChild('copyWorkspacePopup') copyWorkspacePopup: CopyWorkspacePopup;

  active = true;
  element: HTMLElement;
  menu: ContextMenuItem[] = [];

  @Input() workspace: Workspace;

  constructor(
    private userAccountService: UserAccountService,
    public workspaceService: WorkspaceService,
    private downloadService: FileDownloaderService,
    private uploaderService: FileUploaderService,
    private stateService: WorkspaceUiStateService,
    private readonly cdr: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.menu = [
      {
        name: 'Download all',
        icon: 'file_download',
        visible: () => this.workspace?.hasUploads && !this.downloadService.isDownloading,
        action: () => document.getElementById('download-all').click(),
      },
      {
        name: 'share-workspace',
        interpolationParams: {
          toLowerCase: true,
          workspaceType: this.workspace?.isTemplate ? 'template' : 'workspace',
        },
        icon: 'share',
        style: 'material-icons-outlined',
        visible: () => this.workspace?.isOwner,
        action: () => document.getElementById('share-info').click(),
      },
      {
        name: 'add-template',
        icon: 'description',
        style: 'material-icons-outlined',
        interpolationParams: {
          toLowerCase: true,
          folderOrWorkspace:
            this.parentSectionId === this.workspace.rootSection.id
              ? this.workspace?.isTemplate
                ? 'template'
                : 'workspace'
              : 'folder',
        },
        visible: () => this.isEdit.value && this.workspace?.currentSection?.canEdit,
        action: () => this.selectTemplateForWorkspacePopup.open(),
      },
      {
        name: 'Save as new template',
        icon: 'copy_all',
        style: 'material-icons-outlined',
        visible: () =>
          this.workspace?.isOwnedByOrganization(this.organizationId) && !this.workspace?.isTemplate,
        action: () => this.copyWorkspacePopup.open(true),
      },
      {
        name: 'CopyWorkspace',
        icon: 'copy',
        style: 'material-icons-outlined',
        interpolationParams: this.params,
        visible: () => this.workspace?.isOwnedByOrganization(this.organizationId),
        action: () => this.copyWorkspacePopup.open(false),
      },
      {
        name: 'upload-folder',
        icon: 'file_upload',
        style: 'material-icons-outlined',
        visible: () =>
          this.workspace?.workspaceSettings.allowNonTemplateFiles &&
          this.workspace?.currentSection?.canEdit,
        action: () => this.uploadButton.nativeElement.click(),
      },
      {
        name: 'Settings',
        icon: 'settings',
        style: 'material-icons-outlined',
        visible: () => this.workspace?.isOwner,
        action: () => this.goToSettings(),
      },
    ];

    this.cdr.detectChanges();
  }

  get params(): { [key: string]: string } {
    return {
      workspaceType: this.workspace.isTemplate ? 'template' : 'workspace',
    };
  }

  get parentSectionId(): string {
    const sections = this.route.snapshot.queryParamMap.getAll('sections');
    return sections[sections.length - 1] ?? this.workspace.rootSection?.id;
  }

  get organizationId(): string {
    return this.userAccountService.user.organizationId;
  }

  get isEdit(): BehaviorSubject<boolean> {
    return this.stateService.isEdit;
  }

  changeLightswitch(): void {
    this.stateService.toggleIsEdit();
    this.cdr.detectChanges();
  }

  goToSettings(): void {
    document.getElementById('settings-tab').click();
    this.active = false;
    window.scrollTo(0, 0);
  }

  returnToWorkspace(): void {
    document.getElementById('workspace-tab').click();
    this.active = true;
    window.scrollTo(0, 0);
  }

  async uploadFolder(event): Promise<void> {
    const selectedParentSectionId = this.workspace.currentSectionId;
    const parentSection = this.workspace.getSection(selectedParentSectionId);
    const files = (Array.from(event.target.files) as File[]).filter((file) => file.type);
    const parentFolder = await this.uploaderService.uploadFolder(
      files,
      this.workspace,
      parentSection
    );
    this.cdr.detectChanges();
    const folderElement = document.getElementById(parentFolder?.id);
    if (folderElement && parentFolder) {
      folderElement.scrollIntoView();
    }
  }
}
