import { Component } from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})

// Provides the same margins as the 'tabs' component provides.
// May be used to keep the paddings of the 'tabs' component without having tabs.
export class ContainerComponent {}
