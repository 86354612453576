import { Pipe, PipeTransform } from '@angular/core';
import { INamed } from '@core/models';

@Pipe({ name: 'contentFilter' })
export class ContentFilterPipe implements PipeTransform {
  transform(items: INamed[], searchText: string): INamed[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    return items.filter((item) => {
      return this.itemMeetsSearchCriteria(searchText, item);
    });
  }

  itemMeetsSearchCriteria(searchText: string, item: INamed): boolean {
    return item.name.toLowerCase().includes(searchText);
  }
}
