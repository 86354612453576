import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ItemPermissionType,
  ItemShare,
  ItemStatusType,
  PermissionType,
  SectionType,
  UserInfo,
  Workspace,
} from '@core/models';
import { WorkspaceUiStateService, WorkspaceService, UserAccountService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { SharingPopupComponent } from '@app/shared/sharing-popup/sharing-popup.component';
import { titlePattern } from '@app/core/common/constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'workspace-header',
  templateUrl: './workspace-header.component.html',
  styleUrls: ['./workspace-header.component.scss'],
})
export class WorkspaceHeaderComponent implements OnChanges, AfterViewInit {
  @Input() shareShow = true;
  @Input() template = false;
  @Input() saved: boolean;
  @Output() hideSidebar = new EventEmitter();
  @ViewChild('shareWorkspacePopup') shareWorkspacePopup: SharingPopupComponent;
  @ViewChild('workspaceTextarea') textarea: ElementRef;

  // regex for unsupported characters in title. Showing message with following chars "- _ ^ < > : " / \ | ? * "
  titlePattern = titlePattern;

  public permissionTypes = PermissionType;
  public PermissionTypes = ItemPermissionType;
  public itemStatusTypes = ItemStatusType;
  private templateId: string;

  @Input() workspace: Workspace;

  get creator(): UserInfo {
    return this.workspace.creator;
  }

  get isEdit(): BehaviorSubject<boolean> {
    return this.stateService.isEdit;
  }

  get isOwner(): boolean {
    return this.workspace.isOwner;
  }

  get canShare(): boolean {
    return this.workspace.canShare(this.userAccountService.user);
  }

  constructor(
    private readonly workspaceService: WorkspaceService,
    private readonly userAccountService: UserAccountService,
    private readonly stateService: WorkspaceUiStateService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.templateId = route.snapshot.paramMap.get('id');
  }

  get shares(): ItemShare[] {
    return this.workspace.shares;
  }

  get isGroupsExpanded(): boolean {
    const defaultSections = this.workspace.sections.filter(
      (section) => section.sectionType === SectionType.Default
    );
    return defaultSections.some((section) => section.expanded);
  }

  get parentSectionId(): string {
    const sections = this.route.snapshot.queryParamMap.getAll('sections');
    return sections[sections.length - 1] ?? this.workspace.rootSection?.id;
  }

  async ngOnChanges(): Promise<void> {
    this.inputTextHeight();

    this.cdr.detectChanges();
  }

  async ngAfterViewInit(): Promise<void> {
    this.inputTextHeight();

    //nasty ng redraw...
    setTimeout(() => {
      this.inputTextHeight();
    }, 300);

    this.cdr.detectChanges();
  }

  onShareWorkspaceClick(): void {
    if (this.isEdit.value || this.canShare) {
      this.shareWorkspacePopup.open();
    }
  }

  async updateWorkspace(template?: boolean, error?: boolean): Promise<void> {
    if (error) {
      return;
    }

    this.showSaved();
    if (template) {
      await this.workspaceService.update(this.templateId, this.workspace);
    } else {
      await this.workspaceService.update(this.workspace.id, {
        newName: this.workspace.name,
        workspaceSettings: this.workspace.workspaceSettings,
        newDescription: this.workspace.description,
        workspaceStatus: this.workspace.requestContentItemStatus,
      });
    }
  }

  showSaved(): void {
    this.saved = true;

    setTimeout(() => {
      this.saved = false;
    }, 2000);
  }

  inputTextHeight(): void {
    const limit = 362; //height limit
    if (this.textarea) {
      //back to zero for limit calculation
      this.textarea.nativeElement.style.height = 0;
      this.textarea.nativeElement.style.height =
        Math.min(this.textarea.nativeElement.scrollHeight, limit) + 'px';
    }
  }

  /**
   * Collapses all sections in the workspace.
   * */
  expandCollapseAll(): void {
    this.stateService.collapseAll();
    this.workspace.sections.map((g) => (g.expanded = false));
    this.hideSidebar.emit();
  }

  /**
   * Expands all sections in the workspace.
   * */
  expandAll(): void {
    this.stateService.expandAll();
    this.workspace.sections.map((section) => (section.expanded = true));
  }
}
