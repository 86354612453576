import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import {
  CreateMembershipRequest,
  Membership,
  RoleMembershipRequest,
  UpdateMembershipRequest,
} from '@core/models';
@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  private baseUrl = `${environment.apiBaseUrl}/users/membership/v1`;

  @Output() membershipsUpdated = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  get(userId: string): Observable<HttpResponse<Membership>> {
    return this.http.get<Membership>(`${this.baseUrl}/${userId}`, {
      headers: new HttpHeaders()
        .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        .set('Pragma', 'no-cache')
        .set('Expires', '0'),
      observe: 'response',
    });
  }

  list(): Promise<Membership[]> {
    return this.http
      .get<Membership[]>(`${this.baseUrl}`, {
        observe: 'response',
      })
      .toPromise()
      .then((r) => r.body);
  }

  update(userId: string, request: UpdateMembershipRequest): Observable<HttpResponse<any>> {
    return this.http.put(`${this.baseUrl}/${userId}`, request, {
      observe: 'response',
    });
  }

  post(request: CreateMembershipRequest): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}`, request, {
      observe: 'response',
    });
  }

  delete(roleId: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.baseUrl}/${roleId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  assignRoleToMembership(request: RoleMembershipRequest): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiBaseUrl}/users/membership/role/v1`, request, {
      observe: 'response',
    });
  }

  removeRoleFromMembership(payload: RoleMembershipRequest): Observable<HttpResponse<any>> {
    return this.http.request('delete', `${environment.apiBaseUrl}/users/membership/role/v1`, {
      body: payload,
      observe: 'response',
    });
  }
}
