import { Component, Input, OnInit } from '@angular/core';
import { Membership, Role } from '@core/models';
import { MembershipService } from '@core/services';

@Component({
  selector: 'app-roles-teams',
  templateUrl: './roles-teams.component.html',
  styleUrls: ['./roles-teams.component.scss'],
})
export class RolesTeamsComponent implements OnInit {
  @Input() role: Role;
  memberships: Membership[];
  membershipSearchText: string;

  constructor(private membershipService: MembershipService) {}

  async ngOnInit(): Promise<void> {
    this.memberships = await this.membershipService.list();
  }

  checkMembershipActiveForRole(membership: Membership): Role {
    return membership.roles.find((r) => r.id === this.role.id);
  }

  async toggleMembershipActiveForRole(membership: Membership): Promise<any> {
    const existingMembership = this.checkMembershipActiveForRole(membership);
    if (existingMembership) {
      membership.roles.splice(membership.roles.indexOf(existingMembership), 1);
      await this.membershipService
        .removeRoleFromMembership({
          roleId: this.role.id,
          membershipId: membership.id,
        })
        .toPromise();
    } else {
      membership.roles.push(this.role);
      await this.membershipService
        .assignRoleToMembership({
          roleId: this.role.id,
          membershipId: membership.id,
        })
        .toPromise();
    }
    this.membershipService.membershipsUpdated.emit(membership);
  }
}
