import { Injectable } from '@angular/core';
import {
  MultipleChoiceItem,
  Question,
  QuestionType,
  SectionItemType,
  Workspace,
  filter,
} from '@app/core/models';
import { QuestionService } from '../question.service';
import { parallel } from '@app/core/common/parallel.functions';

@Injectable({ providedIn: 'root' })
export class WorkspaceCopier {
  public constructor(private questionService?: QuestionService) {}

  fixReferences(source: Workspace, destination: Workspace): Question[] {
    const allDestinationItems = destination.allItems;

    const sourceQuestions = filter<Question>(source.allItems, SectionItemType.Question).filter(
      (q) => q.questionType === QuestionType.MultipleChoice
    );
    const destinationQuestions = filter<Question>(
      destination.allItems,
      SectionItemType.Question
    ).filter((q) => q.questionType === QuestionType.MultipleChoice);
    sourceQuestions.forEach((sourceQuestion) => {
      const destinationQuestion = destinationQuestions.find(
        (q) => q.copiedFrom === sourceQuestion.id
      );
      const options = sourceQuestion.value as MultipleChoiceItem[];

      // Unfound destination question can happen in case we insert a template in a workspace
      if (!destinationQuestion) return;
      // sourceQuestion.value could be other than array despite the as MultipleChoiceItem[] cast
      if (!Array.isArray(options)) return;

      const destinationOptions = destinationQuestion.value as MultipleChoiceItem[];
      options.forEach((option, j) => {
        const destinationOption = destinationOptions[j];
        const workflowId = option.workflowId;
        if (!workflowId) return;
        const destinationItem = allDestinationItems.find((i) => i.copiedFrom === workflowId);
        if (!destinationItem) {
          console.warn('no destination item found for', workflowId);
          return;
        }
        destinationOption.workflowId = destinationItem.id;
      });
    });

    return destinationQuestions;
  }

  async updateReferences(source: Workspace, destination: Workspace): Promise<Workspace> {
    const destinationQuestions = this.fixReferences(source, destination);
    await parallel(1, destinationQuestions, (q) =>
      this.questionService.update(q, { value: q.value })
    );
    return destination;
  }
}
