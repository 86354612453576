import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-empty-animation',
  templateUrl: './empty-animation.component.html',
  styleUrls: ['./empty-animation.component.scss'],
})
export class EmptyAnimationComponent {
  animation: AnimationItem;
  options: AnimationOptions = {
    path: '/assets/animations/Nofiles.json',
    loop: false,
  };

  animationCreated(animationItem: AnimationItem): void {
    this.animation = animationItem;
  }

  animationEnd(): void {
    this.animation.goToAndPlay(135, true);
  }
}
