import { Component, OnInit, ViewChild } from '@angular/core';
import { QuotaService, TitleService, UserInfoService, WorkspaceService } from '@core/services';
import { ItemStatusType, IWorkspaceBaseDto, PermissionType, Workspace } from '@core/models';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { parallel } from '@core/common/parallel.functions';
import { ArchiveWorkspacePopupComponent } from '@app/shared/pop-up/archive-workspace-popup/archive-workspace-popup.component';
import { SharingPopupComponent } from '@app/shared/sharing-popup/sharing-popup.component';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit {
  @ViewChild('selectTemplateForWorkspacePopup')
  selectTemplateForWorkspacePopup: ModalPopupComponent;

  @ViewChild('confirmTemplateForWorkspacePopup')
  confirmTemplateForWorkspacePopup: ModalPopupComponent;

  @ViewChild('existingWorkspacePopup') existingWorkspacePopup: ModalPopupComponent;
  @ViewChild('deleteWorkspacePopup') deleteWorkspacePopup: ModalPopupComponent;
  @ViewChild('archiveWorkspacePopup')
  archiveWorkspacePopup: ArchiveWorkspacePopupComponent;

  @ViewChild('shareWorkspacePopup') shareWorkspacePopup: SharingPopupComponent;

  selectedTemplate: Workspace;

  existingEmptyWorkspace: Workspace;

  public statusTypes = ItemStatusType;
  public permissionTypes = PermissionType;
  workspaces: IWorkspaceBaseDto[];
  searchText: string;
  isListView = false;
  focusedWorkspace: Workspace;
  showTemplates: boolean;

  constructor(
    private workspaceService: WorkspaceService,
    private titleService: TitleService,
    private userInfoService: UserInfoService,
    private router: Router,
    public quotaService: QuotaService,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.showTemplates = this.route.snapshot.data['breadcrumb'] === 'Templates';
    this.titleService.set(this.translate.instant('Workspaces'));
  }

  get interpolationParams(): any {
    return { workspaceType: this.showTemplates ? 'template' : 'workspace' };
  }

  openConfirmPopup(workspace: Workspace): void {
    this.deleteWorkspacePopup.popupActive = true;
    this.focusedWorkspace = workspace;
  }

  openArchivePopup(workspace: Workspace): void {
    this.archiveWorkspacePopup.activatePopup();
    this.focusedWorkspace = workspace;
  }

  async addNewWorkspace(): Promise<any> {
    const workspaces = await this.workspaceService.list(this.showTemplates);
    await this.createNewWorkspace(workspaces);
  }

  async createSecondaryEmptyWorkspace(): Promise<any> {
    const workspaces = await this.workspaceService.list(this.showTemplates);
    await this.createNewWorkspace(workspaces);
  }

  async createNewWorkspace(workspaces: IWorkspaceBaseDto[]): Promise<any> {
    const newWorkspaceWorkspaces = workspaces.filter(
      (d) => d.name.indexOf('New workspace') > -1
    ).length;
    const name = this.showTemplates ? 'New template' : 'New workspace';
    const workspaceCreationResult = await this.workspaceService.post({
      name:
        newWorkspaceWorkspaces > 0
          ? `${this.translate.instant(name)} (${newWorkspaceWorkspaces + 1})`
          : this.translate.instant(name),
      description: '',
      isTemplate: this.showTemplates,
    });
    await this.workspaceService.refresh();

    this.navigateToWorkspace(workspaceCreationResult.id, true);
  }

  async removeWorkspace(workspace: Workspace): Promise<any> {
    const index = this.workspaces.indexOf(workspace);
    this.workspaces.splice(index, 1);
    await this.workspaceService.delete(workspace.id);
    this.deleteWorkspacePopup.popupActive = false;
    this.listWorkspaces();
  }

  openExistingWorkspace(): void {
    this.navigateToWorkspace(this.existingEmptyWorkspace.id);
  }

  ngOnInit(): void {
    this.listWorkspaces();
  }

  async listWorkspaces(): Promise<any> {
    const response = await this.workspaceService.list(this.showTemplates);
    this.workspaces = response.sort((a, b) =>
      a.lastModifiedTimestampUtc > b.lastModifiedTimestampUtc ? -1 : 1
    );
    const isListViewString = localStorage.getItem('listView');
    if (isListViewString !== null) {
      this.isListView = isListViewString === 'true';
    }
  }

  approveWorkspace(workspace: Workspace): void {
    this.focusedWorkspace = workspace;
    this.approveWorkspaceConfirm(this.focusedWorkspace);
  }

  async approveWorkspaceConfirm(workspace: Workspace): Promise<any> {
    await this.workspaceService.update(workspace.id, {
      newDescription: workspace.description,
      newName: workspace.name,
      workspaceSettings: workspace.workspaceSettings,
      workspaceStatus: ItemStatusType.Approved,
    });
    this.listWorkspaces();
    this.focusedWorkspace = undefined;
  }

  async popupEmptyWorkspaceByTemplate(template: Workspace): Promise<void> {
    this.selectedTemplate = template;
    this.selectTemplateForWorkspacePopup.popupActive = false;
    this.confirmTemplateForWorkspacePopup.popupActive = true;
    await parallel(5, template.shares, async (share) => {
      if (!share.email) {
        try {
          share.email = (await this.userInfoService.get(share.userId)).email;
        } catch {
          console.log(`User ${share.userId} no longer exists`);
        }
      }
    });
  }

  async createEmptyWorkspaceByTemplate(): Promise<any> {
    const workspaceCreationResult = await this.workspaceService.post({
      name: this.selectedTemplate.name,
      description: this.selectedTemplate.description,
      sourceTemplateId: this.selectedTemplate.id,
      isTemplate: false,
    });
    await this.workspaceService.refresh();

    this.navigateToWorkspace(workspaceCreationResult.id, true);
  }

  async shareWorkspace(workspace: Workspace): Promise<any> {
    this.focusedWorkspace = workspace;
    await this.shareWorkspacePopup.open();
  }

  private navigateToWorkspace(workspaceId, isNew = false): void {
    if (isNew) {
      const storage = JSON.parse(localStorage.getItem('edits') || '[]');
      storage.push(workspaceId);

      localStorage.setItem('isNewWorkspace', 'true');
      localStorage.setItem('edits', JSON.stringify(storage));
    }
    this.router.navigate(['workspace', workspaceId]);
  }

  get quotaWorkspaces(): boolean {
    if (this.showTemplates) {
      return (
        this.quotaService.maxNumberOfTemplates &&
        this.workspaces.length >= this.quotaService.maxNumberOfTemplates
      );
    } else {
      return (
        this.quotaService.maxActiveWorkspaces &&
        this.workspaces.length >= this.quotaService.maxActiveWorkspaces
      );
    }
  }

  selectView(isListView: boolean): void {
    this.isListView = isListView;
    const isListViewString = this.isListView ? 'true' : 'false';
    localStorage.setItem('listView', isListViewString);
  }
}
