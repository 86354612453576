<div
  #fileRequestPreview
  *ngIf="item.name || (isEdit | async)"
  [class.edit]="isEdit | async"
  [filePreview]="item"
  class="file-request"
  (contextmenu)="onRightClick($event, false)"
  multiSelectHover>
  <app-multi-select-checkbox
    [item]="item"
    [type]="(isEdit | async) ? 'request' : 'filerequest'"
    (checkboxChange)="checkboxChange.emit()"></app-multi-select-checkbox>

  <div *ngIf="isEdit | async" class="file-request__inputs">
    <div
      *ngIf="item"
      [ngClass]="title.errors?.pattern || !item.name.length ? 'invalid-regex' : ''"
      class="form-input">
      <div
        [ngClass]="!item.description.length && !item.focused ? 'no-description' : ''"
        class="title-description">
        <div class="title-wrapper">
          <mt-input [autofocus]="item.focused" placeholder="{{ 'file-request' | translate }}">
            <input
              #mt="ngModel"
              #title="ngModel"
              (blur)="updateFileRequest({ name: item.name })"
              (focus)="onFocus()"
              (ngModelChange)="hasTitleError = title.errors?.pattern"
              [(ngModel)]="item.name"
              [ngClass]="
                title.errors?.pattern || (!item.name.length && !item.focused)
                  ? 'no-bottom-margin'
                  : ''
              "
              [pattern]="titlePattern"
              autocomplete="off"
              click-stop-propagation
              id="workspaceItemTitle"
              type="text" />
          </mt-input>
        </div>
        <div class="description-wrapper">
          <textarea
            #descriptionInput
            (focus)="onFocus()"
            (blur)="updateFileRequest({ description: item.description })"
            (keydown)="inputTextHeight()"
            (keyup)="inputTextHeight()"
            [(ngModel)]="item.description"
            class="description-input"
            click-stop-propagation
            placeholder="{{ 'Description' | translate }}"
            type="description"></textarea>
        </div>
      </div>
      <small *ngIf="title.errors?.pattern" class="text-danger">
        {{ 'Invalid regex' | translate }}
      </small>

      <small *ngIf="item.name.length === 0 && !item.focused" class="text-danger">
        {{ 'empty-field' | translate }}
      </small>

      <span *ngFor="let allowedFileType of allowedFileTypes">
        <span *ngIf="allowedFileType.checked" class="allowed-types">
          .{{ allowedFileType.key }}
        </span>
      </span>
    </div>

    <div [class.active]="popup" class="file-request__control">
      <div *ngIf="isEdit | async" class="three-dot">
        <app-three-dot-menu [menu]="fileRequestMenu" click-stop-propagation></app-three-dot-menu>

        <app-small-popup
          (submitButton)="changeFileSpecs()"
          [active]="popup"
          click-stop-propagation
          title="Limit filetypes">
          <label *ngFor="let allowedFileType of allowedFileTypes" class="checkboxcontainer--new">
            {{ allowedFileType.key }}
            <input
              [(ngModel)]="allowedFileType.checked"
              [ngModelOptions]="{ standalone: true }"
              type="checkbox" />
            <span class="checkmark--new"></span>
          </label>
        </app-small-popup>
      </div>

      <icon-button
        (click)="removeRequest()"
        class="remove-control"
        click-stop-propagation
        icon="delete"></icon-button>
    </div>

    <div [class.active]="item.messages?.length > 0" class="chat-icon" [filePreview]="item">
      <span class="material-icons">chat</span>
      <div *ngIf="item.messages" class="amount_messages">
        {{ item.messages.length }}
      </div>
    </div>

    <status-menu [isOwner]="isOwner" [item]="item" click-stop-propagation></status-menu>
  </div>

  <div
    (dragleave)="unsetDragOver()"
    (dragover)="setDragOver()"
    (fileDropped)="fileBrowseHandler($event.files)"
    [appDndEnabled]="item.canEdit"
    *ngIf="downloadHelper.downloadPercentageSubject | async as downloadStatus"
    appDnd
    class="file-request__request">
    <div [class.active]="showDrop" class="show-drop">
      <span class="material-icons-outlined">file_upload</span>
      <h3>{{ 'drag-and-drop-files-here' | translate }}</h3>
    </div>

    <div *ngIf="(isEdit | async) === false" class="first-line">
      <div class="description-wrapper">
        <label>
          <h3>{{ item.name }}</h3>
          <p *ngIf="item.description" class="description">{{ item.description }}</p>
          <span *ngFor="let allowedFileType of allowedFileTypes">
            <span *ngIf="allowedFileType.checked" class="allowed-types">
              .{{ allowedFileType.key }}
            </span>
          </span>
        </label>
      </div>
      <div class="file-request__control">
        <icon-button
          class="upload-button"
          [tooltip]="'Upload Files' | translate"
          (click)="uploadButton.click()"
          *ngIf="item.canEdit"
          click-stop-propagation
          icon="file_upload"></icon-button>
        <icon-button
          [tooltip]="'DownloadCaption' | translate"
          [disabled]="downloadStatus.percentage > 0"
          (onClick)="downloadFileRequest()"
          *ngIf="item.hasContentItems"
          click-stop-propagation
          icon="file_download"></icon-button>
      </div>

      <div
        [class.active]="item.messages.length > 0"
        class="chat-icon"
        [filePreview]="item"
        click-stop-propagation>
        <span class="material-icons">chat</span>
        <div *ngIf="item.messages" class="amount_messages">
          {{ item.messages.length }}
        </div>
      </div>

      <status-menu [isOwner]="isOwner" [item]="item"></status-menu>
    </div>

    <input
      #uploadButton
      (change)="fileBrowseHandler($event.target.files)"
      click-stop-propagation
      id="{{ item.id }}"
      multiple
      type="file" />
    <ul class="current__item__list" *ngIf="item?.contentItems?.length">
      <li
        *ngFor="let contentItem of item?.contentItems || []; let i = index"
        [fileIndex]="i"
        [filePreview]="item"
        class="current"
        (contextmenu)="onRightClick($event, true, contentItem)">
        <div class="current__item" *ngIf="contentItem.uploadProgress | async as uploadProgress">
          <span class="material-icons-outlined">
            {{ getIcon(contentItem.name) }}
          </span>
          {{ contentItem.name }}
          <div
            [style.max-width.%]="uploadProgress?.percentage"
            *ngIf="uploadProgress?.percentage"
            class="upload-bar"></div>
        </div>
        <icon-button
          (onClick)="download(contentItem)"
          class="remove-current"
          click-stop-propagation
          [disabled]="downloadStatus.percentage > 0"
          icon="file_download"></icon-button>
        <icon-button
          (click)="removeContentItem(contentItem.id)"
          *ngIf="item.canEdit && this.canDelete"
          class="remove-current"
          click-stop-propagation
          icon="delete"></icon-button>
      </li>
    </ul>
  </div>
</div>

<app-modal-popup
  #deleteFilePopup
  (eventPrimaryButtonClick)="removeContentItemConfirm()"
  [popupTitle]="'DeleteFileTitle' | translate"
  [primaryButtonText]="'DeleteCaption' | translate">
  <div>
    {{ 'ConfirmFileDelete' | translate }}
  </div>
</app-modal-popup>
