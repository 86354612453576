import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Workspace,
  Contact,
  CreateShareRequest,
  IItemShare,
  ItemPermissionType,
  ItemShare,
  IUserAccount,
  Membership,
  Organization,
  Relation,
  Section,
  SectionItem,
  SectionItemType,
  SectionType,
  UserAccount,
} from '@core/models';
import { SimpleDialogComponent } from '@app/shared/sharing-popup/simple-dialog/simple-dialog.component';
import {
  BottomSheetShareContentComponent,
  IChangePermissionRequest,
  IDeleteShareRequest,
} from '@app/shared/sharing-popup/small-screens/bottom-sheet-share-content/bottom-sheet-share-content.component';
import { SearchResultGroupType } from '@app/shared/searchbar/person-searchbar/share.searchresults.model';
import {
  AccountService,
  MembershipService,
  NotificationService,
  OrganizationService,
  RelationsService,
  ShareService,
  UserAccountService,
  UserInfoService,
} from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { PersonSearchbarComponent } from '../searchbar/person-searchbar/person-searchbar.component';
import { uniqBy } from 'ramda';

@Component({
  selector: 'app-sharing-popup',
  templateUrl: './sharing-popup.component.html',
  styleUrls: ['./sharing-popup.component.scss'],
})
export class SharingPopupComponent implements OnInit {
  @ViewChild('bottomSheet') bottomSheet: BottomSheetShareContentComponent;
  @ViewChild('searchbar') searchBar: PersonSearchbarComponent;
  @ViewChild('sharingPopup') sharingPopup: SimpleDialogComponent;

  searchResultGroupType: SearchResultGroupType;
  /*Event emitters*/
  @Output() membersAddedEvent = new EventEmitter<ItemShare[]>();
  /*Input*/
  @Input() workspace: Workspace;
  sectionItem?: SectionItem;

  relations: Relation[];
  _organization: Organization;
  _users: UserAccount[];
  _contacts: Contact[];
  _memberships: Membership[];

  newShares: IItemShare[] = [];
  shareMessage = '';
  onMobile = window.innerWidth < 830;
  isMessageFieldActive = false;
  link: boolean;

  constructor(
    private userAccountService: UserAccountService,
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private membershipService: MembershipService,
    public relationsService: RelationsService,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private userInfoService: UserInfoService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.onMobile = event.target.innerWidth < 830;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    if (this.sharingPopup) this.sharingPopup.popupActive = false;
  }

  openBottomSheet(share: IItemShare, isNewUser = true): void {
    if (this.onMobile) {
      this.bottomSheet.openMobileMenu(share, isNewUser);
    }
  }

  get sectionItemId(): string {
    return this.sectionItem?.id;
  }

  get isWorkspaceShare(): boolean {
    return !this.sectionItem;
  }

  get existingShares(): IItemShare[] {
    const shares = this.isWorkspaceShare
      ? this.workspace.shares
      : this.workspace.siShares.filter(
          (s) => s.sectionItemId === this.sectionItemId || s.sectionItemId === this.sectionItem.id
        );
    return shares.filter((share) => (this.link && share.isLink) || (!this.link && !share.isLink));
  }

  get memberships(): Membership[] {
    return this.link ? [] : this._memberships;
  }

  get organization(): Organization {
    return this.link ? undefined : this._organization;
  }

  get users(): UserAccount[] {
    return this.link ? [] : this._users;
  }

  get contacts(): Contact[] {
    return this.link ? [] : this._contacts;
  }

  async init(user: UserAccount): Promise<IUserAccount[]> {
    let users: UserAccount[] = [];

    const organizationId = user?.organizationId;
    // List own organization users
    if (user?.hasOrganization) {
      this._organization = await this.organizationService.get(organizationId);
      users = (await this.accountService.list()).filter((u) => u.id !== user.id);
    }

    // List organization teams
    this._memberships = await this.membershipService.list();
    const membershipUsers = this._memberships.map((m) => m.users).flat();

    // List relation teams
    this.relations = await this.relationsService.listFull();
    this._contacts = this.relations.map((m) => m.contacts).flat();

    this._users = uniqBy((u) => u.id, [...users, ...membershipUsers]);

    return this._users;
  }

  get shareTypeKey(): string {
    if (this.sectionItem?.type === SectionItemType.Section)
      return (this.sectionItem as Section).sectionType === SectionType.ContainsOnlySections
        ? 'folder'
        : 'group';
    else if (this.sectionItem?.type === SectionItemType.FileRequest) return 'file-request';
    else if (this.sectionItem?.type === SectionItemType.Question) return 'question';
    else if (this.sectionItem?.type === SectionItemType.ContentItem) return 'file';
    else return this.workspace?.isTemplate ? 'template' : 'workspace';
  }

  get shareType(): any {
    return { shareType: this.translateService.instant(this.shareTypeKey).toLowerCase() };
  }

  get popupTitle(): string {
    const key = this.link ? 'Link relation to item' : 'Give access to this item';
    return this.translateService.instant(key, this.shareType);
  }

  ngOnInit(): void {
    this.userAccountService.accountUpdated.subscribe((user) => this.init(user));
  }

  private createShare(share: IItemShare): Promise<IItemShare> {
    const request = {
      workspaceId: this.workspace.id,
      personalMessage: this.shareMessage,
      ...share,
      name: share.name, // Set explicitly because this is a property
      isLink: this.link,
    } as CreateShareRequest;
    return this.shareService.post(request);
  }

  async createNewUsers(newShares: IItemShare[]): Promise<void> {
    if (this.isWorkspaceShare) return;
    let sharesWithoutUser = newShares.filter((share) => !share.userId && share.email);
    const emails = sharesWithoutUser.map((share) => share.email);
    const existingUsers = await this.userInfoService.getUsersByEmail(emails);

    sharesWithoutUser.forEach((share, i) => (share.userId = existingUsers[i]?.id));
    sharesWithoutUser = newShares.filter((share) => !share.userId && share.email);
    const newUserRequests = sharesWithoutUser.map((share) =>
      this.accountService.post({
        id: null,
        email: share.email,
        firstname: this.searchBar.getNameFromEmail(share.email),
        lastname: '',
        languageCode: this.userAccountService.user.languageCode,
      })
    );
    const results = await Promise.all(newUserRequests);
    results.forEach((result, index) => {
      const userId = result.body;
      const newShare = sharesWithoutUser[index];
      if (newShare) newShare.userId = userId;
    });
  }

  async sendInvitation(): Promise<void> {
    // Close dialog here to prevent multiple clicks
    this.sharingPopup.popupActive = false;

    await this.createNewUsers(this.newShares);

    const requests = this.newShares.map((s) => this.createShare(s));

    const newShareDtos = await Promise.all(requests);

    // Add new shares to workspace: id's need to be added to share
    newShareDtos.forEach((share) => {
      if (this.isWorkspaceShare) this.workspace.shares.push(share as ItemShare);
      else this.workspace.siShares.push(share as ItemShare);
    });

    this.cleanUp();
  }

  cleanUp(): void {
    this.newShares = [];
    this.shareMessage = '';
    this.searchBar.searchText = '';
    this.sectionItem = undefined;
  }

  open(): void {
    this.sharingPopup.openPopup();
    this.getShareInfo();
  }

  async getShareInfo(): Promise<void> {
    if (!this.isWorkspaceShare) {
      const shares = this.workspace.siShares;
      const users = await this.userInfoService.getByIds(shares.map((s) => s.userId));
      shares.forEach((share) => (share.user = users.find((u) => u.id === share.userId)));
    }
  }

  shareSectionItem(sectionItem: SectionItem, link = false): void {
    this.link = link;
    this.sectionItem = sectionItem;
    this.open();
  }

  openMobileMessageField(): void {
    this.isMessageFieldActive = true;
  }

  closeMobileMessageField(): void {
    this.isMessageFieldActive = false;
  }

  /*
   * The button is hidden if user is on mobile and appends a message
   * */
  get isAppendMessageMobile(): boolean {
    if (!this.onMobile) return true;
    else return !this.isMessageFieldActive;
  }

  addNewMember(share: ItemShare): void {
    this.newShares.push(
      new ItemShare({
        ...share,
        name: share.name, // Set explicitly because this is a property
        sectionItemId: this.sectionItem?.id,
      })
    );
  }

  changePermission(request: IChangePermissionRequest): void {
    const { member, permissionType } = request;
    if (!request.isNewUser) {
      this.changePermissionOfExistingMember(member, permissionType);
    }
  }

  deleteMember(request: IDeleteShareRequest): void {
    if (request.isNewUser) {
      this.deleteNewMember(request.member);
    } else {
      this.deleteExistingMember(request.member);
    }
  }

  deleteNewMember(share: IItemShare): void {
    const index = this.newShares.findIndex((m) => m.id === share.id);
    if (index > -1) {
      this.newShares.splice(index, 1);
    }
  }

  async deleteExistingMember(share: IItemShare): Promise<void> {
    const shares = this.isWorkspaceShare ? this.workspace.shares : this.workspace.siShares;
    await this.deleteShare(shares, share);
  }

  async deleteShare(shares: ItemShare[], share: IItemShare): Promise<void> {
    const index = shares.findIndex((s) => s.id == share.id);
    if (index > -1) {
      shares.splice(index, 1);
      await this.shareService.delete(share.id);
      this.notificationService.showSuccess(
        this.translate('Successfully removed share'),
        this.translate('Removal successful')
      );
    }
  }

  changePermissionOfNewMember(member: IItemShare, permissionType: ItemPermissionType): void {
    member.permissionType = permissionType;
  }

  async changePermissionOfExistingMember(
    share: IItemShare,
    newPermissionType: ItemPermissionType
  ): Promise<void> {
    share.permissionType = newPermissionType;
    await this.updateShare(share);
    this.notificationService.showSuccess(
      'The permissions have successfully been updated',
      'Update successful'
    );
  }

  async updateShare(share: IItemShare): Promise<void> {
    const { id, isDefaultShare, permissionType } = share;
    await this.shareService.put(id, { isDefaultShare, permissionType });
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
