import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@core/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // We only want api requests.
    if (request.url.indexOf('//api.') < 0) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap(
        () => {
          return;
        },
        (response: any) => {
          if (response instanceof HttpErrorResponse) {
            const errorMessage =
              typeof response.error !== 'string'
                ? JSON.stringify(response.error || {})
                : response.error;
            if (response.status >= 500 && response.status <= 599) {
              this.notificationService.showError(
                this.translate.instant('GenericErrorMessage') + ' : ' + errorMessage,
                this.translate.instant('GenericErrorTitle'),
                { translate: false }
              );
            }
            if (
              response.status >= 400 &&
              response.status <= 499 &&
              response.status !== 401 &&
              request.method !== 'GET'
            ) {
              this.notificationService.showWarning(
                errorMessage,
                this.translate.instant('GenericBadRequestTitle'),
                { translate: false }
              );
            }
          }
        }
      )
    );
  }
}
