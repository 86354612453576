import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { CreateRelationRequest, Relation, UpdateRelationRequest } from '@core/models';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class RelationsService {
  private baseUrl = `${environment.apiBaseUrl}/relations/relations/v1`;

  constructor(private http: HttpClient) {}

  get(relationId: string): Promise<Relation> {
    return this.http.get<Relation>(`${this.baseUrl}/${relationId}`).then((r) => new Relation(r));
  }

  list(): Promise<Relation[]> {
    return this.http
      .get<Relation[]>(`${this.baseUrl}`)
      .then((relations) => relations.map((relation) => new Relation(relation)));
  }

  listFull(): Promise<Relation[]> {
    return this.http
      .get<Relation[]>(`${this.baseUrl}/full`)
      .then((relations) => relations.map((relation) => new Relation(relation)));
  }

  update(relationId: string, request: UpdateRelationRequest): Promise<Relation> {
    return this.http.put(`${this.baseUrl}/${relationId}`, request).then((r) => new Relation(r));
  }

  post(request: CreateRelationRequest): Promise<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}`, request);
  }

  delete(relationId: string): Promise<string> {
    return this.http.delete(`${this.baseUrl}/${relationId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }
}
