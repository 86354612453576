import { Component } from '@angular/core';
import { DragService } from '@core/services';

@Component({
  selector: 'drag-preview',
  templateUrl: './drag-preview.component.html',
  styleUrls: ['./drag-preview.component.scss'],
})
export class DragPreviewComponent {
  drag;
  subscription;

  constructor(public dragService: DragService) {
    this.drag = dragService.drag;
    this.subscription = dragService.dragChange.subscribe((value) => {
      this.drag = value;
    });
  }
}
