<app-modal-popup
  #copyWorkspacePopup
  (eventPrimaryButtonClick)="storeAsTemplate()"
  (onSecondaryButtonClick)="closeSaveTemplateForm()"
  [popupTitle]="'CopyWorkspaceTitle' | translate: params"
  [primaryButtonText]="'Save' | translate">
  <div class="file-set-form">
    {{ 'CopyWorkspaceBody' | translate: params }}
    <input
      [(ngModel)]="newName"
      autocomplete="off"
      autofocus
      placeholder="{{ 'Title' | translate }}"
      type="text" />

    <textarea
      [(ngModel)]="newDescription"
      autosize
      placeholder="{{ 'Description' | translate }}"
      rows="1"></textarea>
  </div>
</app-modal-popup>
