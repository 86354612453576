import { HttpClient as HttpClientAngular, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpClient {
  constructor(protected http: HttpClientAngular) {
    this.http = http;
  }

  get<T>(url: string): Promise<T | null> {
    return lastValueFrom(this.http.get<T>(url, { observe: 'response' })).then((r) => r.body);
  }

  getText(url: string): Promise<any> {
    return lastValueFrom(this.http.get(url, { observe: 'response', responseType: 'text' }));
  }

  put<T>(url: string, body: any | null): Promise<boolean | null> {
    return lastValueFrom(
      this.http.put<boolean>(url, body, {
        observe: 'response',
      })
    ).then((r) => r.body);
  }

  post<T>(url: string, body: any | null = null): Promise<HttpResponse<any>> {
    return lastValueFrom(
      this.http.post<T>(url, body, {
        observe: 'response',
      })
    );
  }

  delete(url: string, options: any = { observe: 'body', responseType: 'text' }): Promise<any> {
    return lastValueFrom(
      this.http.delete(url, {
        observe: 'body',
        ...options,
      })
    );
  }

  request(
    method: string,
    url: string,
    options: any = { observe: 'body', responseType: 'text' }
  ): Promise<any> {
    return lastValueFrom(
      this.http.request(method, url, {
        observe: 'response',
        ...options,
      })
    );
  }
}
