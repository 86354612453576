<div
  *ngIf="(isEdit | async) || item.label.length"
  [class.edit]="isEdit | async"
  class="open-question"
  multiSelectHover>
  <app-multi-select-checkbox
    [item]="item"
    [type]="(isEdit | async) ? 'request' : 'openquestion'"
    (checkboxChange)="checkboxChange.emit()"></app-multi-select-checkbox>

  <div *ngIf="isEdit | async" class="open-question__inputs">
    <div *ngIf="item" class="form-input">
      <mt-input [autofocus]="item.focused" placeholder="{{ 'open-question' | translate }}">
        <input
          #mt="ngModel"
          #title="ngModel"
          (blur)="update({ label: item.label })"
          (focus)="onFocus()"
          [(ngModel)]="item.label"
          autocomplete="off"
          type="text" />
      </mt-input>
    </div>
    <ng-select
      (change)="updateType($event)"
      [(ngModel)]="selectedOption"
      [bindValue]="item.properties?.type"
      [clearable]="false"
      [placeholder]="'open-question-type' | translate"
      [searchable]="false">
      <ng-option *ngFor="let option of options" [value]="option.type">
        {{ option.name }}
      </ng-option>
    </ng-select>

    <div *ngIf="isEdit | async" class="open-question__control">
      <app-three-dot-menu [menu]="menu" click-stop-propagation></app-three-dot-menu>

      <icon-button (click)="removeRequest()" icon="delete" class="remove-control"></icon-button>
      <label class="checkbox-switch" [tooltip]="'Is required' | translate">
        <input
          (change)="update({ isRequired: item.isRequired })"
          (focus)="onFocus()"
          [(ngModel)]="item.isRequired"
          [ngModelOptions]="{ standalone: true }"
          type="checkbox" />
        <span class="switch"></span>
      </label>
    </div>
  </div>

  <div *ngIf="(isEdit | async) === false" [class.check]="checked" class="open-question__inputs">
    <div *ngIf="item.properties?.type === 'multi'" class="form-input">
      <mt-input *ngIf="item.properties?.type" placeholder="{{ item.label }}">
        <textarea
          #mt="ngModel"
          #title="ngModel"
          (blur)="update({ value: item.value }); checkItem()"
          [(ngModel)]="item.value"
          autocomplete="off"
          rows="3"></textarea>
      </mt-input>
    </div>

    <div *ngIf="item.properties?.type === 'single' || !item.properties?.type" class="form-input">
      <mt-input placeholder="{{ item.label }}">
        <input
          #mt="ngModel"
          #title="ngModel"
          (blur)="update({ value: item.value }); checkItem()"
          [(ngModel)]="item.value"
          autocomplete="off"
          type="text" />
      </mt-input>
      <small *ngIf="title.errors?.pattern" class="text-danger">
        {{ 'Invalid regex' | translate }}
      </small>
    </div>

    <div *ngIf="item.properties?.type === 'number'" class="form-input">
      <mt-input placeholder="{{ item.label }}">
        <input
          #mt="ngModel"
          #title="ngModel"
          (keydown)="numberCheck($event)"
          (keyup)="numberCheck($event)"
          (blur)="update({ value: item.value }); checkItem()"
          [(ngModel)]="item.value"
          autocomplete="off"
          type="text" />
      </mt-input>
    </div>

    <div
      *ngIf="item.properties?.type === 'percentage' || item.properties?.type === 'currency'"
      class="form-input">
      <div class="number-input">
        <label for="ngModel">{{ item.label }}</label>
        <span class="number-prefix" *ngIf="item.properties?.type === 'percentage'">&#37;</span>
        <span class="number-prefix" *ngIf="item.properties?.type === 'currency'">&euro;</span>
        <input
          class="currency"
          #mt="ngModel"
          #title="ngModel"
          (keydown)="numberCheck($event)"
          (keyup)="numberCheck($event)"
          (blur)="numberUpdate($event)"
          [(ngModel)]="item.value"
          autocomplete="off"
          type="text" />
      </div>
    </div>

    <div [class.active]="checked" [class.offset]="item.properties?.type" class="check-mark">
      <span *ngIf="checkLength" class="material-icons-outlined">check</span>
      <span *ngIf="!checkLength" class="material-icons-outlined">close</span>
    </div>
  </div>
</div>
