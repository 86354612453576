import { Component, OnInit } from '@angular/core';
import { OrganizationService, UserAccountService } from '@core/services';
import { Address, Currencies, Organization } from '@core/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-settings-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  organization: Organization;
  currencies = Currencies;

  constructor(
    private userAccountService: UserAccountService,
    private organizationService: OrganizationService,
    public translate: TranslateService
  ) {}

  async ngOnInit(): Promise<any> {
    const user = this.userAccountService.user;
    if (!this.organization && user?.hasOrganization) {
      const organizationId = user.organizationId;
      this.organization = await this.organizationService.get(organizationId);
      if (this.organization.invoiceAddress === null) {
        this.organization.invoiceAddress = new Address();
      }
    }
  }

  async updateOrganization(): Promise<any> {
    await this.organizationService.update(this.organization.id, {
      OrganizationDto: this.organization,
    });
  }
}
