<div class="header">
  <h1
    *ngIf="workspace && isOwner"
    [class.invalid-regex]="titleInput.errors?.pattern"
    class="workspace-title">
    <input
      #titleInput="ngModel"
      (blur)="
        workspace.name = $event.target.value; updateWorkspace(template, titleInput.errors?.pattern)
      "
      [(ngModel)]="workspace.name"
      [maxlength]="40"
      [pattern]="titlePattern"
      [placeholder]="'Workspace' | translate"
      autocomplete="off"
      class="workspace-name"
      type="text" />

    <small [class.active]="this.saved" class="text-bubble">
      {{ 'saved bubble' | translate }}
    </small>
    <small [class.active]="titleInput.errors?.pattern" class="text-danger text-bubble">
      {{ 'Invalid regex' | translate }}
    </small>
  </h1>
  <h1 *ngIf="!isOwner" class="workspace-title">
    {{ workspace.name }}

    <small [class.active]="this.saved" class="text-bubble">
      {{ 'saved bubble' | translate }}
    </small>
  </h1>

  <div (click)="onShareWorkspaceClick()" *ngIf="canShare" class="share-info" id="share-info">
    <icon-button *ngIf="workspace.isOwner" [icon]="'share'"></icon-button>

    <div class="organization-avatar">
      <app-user-avatar
        *ngIf="creator"
        [avatarColor]="creator?.avatarColor"
        [avatar]="creator?.avatar"
        [isUser]="false"
        [square]="true"
        [userName]="creator?.fullName"></app-user-avatar>
    </div>
    <div *ngIf="workspace.shares.length > 0" class="share-users">
      <div *ngFor="let share of shares; let i = index" class="avatar-wrap">
        <app-user-avatar
          [avatarColor]="share.avatarColor"
          [avatar]="share.avatar"
          [userName]="share.name"></app-user-avatar>
      </div>
    </div>
    <span (click)="onShareWorkspaceClick()" *ngIf="shares.length > 3" class="others">
      + {{ shares.length - 3 }}
    </span>
  </div>
</div>

<textarea
  #workspaceTextarea
  (blur)="updateWorkspace(template)"
  (keydown)="inputTextHeight()"
  (keyup)="inputTextHeight()"
  *ngIf="isOwner"
  [(ngModel)]="workspace.description"
  [disabled]="
    workspace.requestContentItemStatus === itemStatusTypes.Archived ||
    workspace.requestContentItemStatus === itemStatusTypes.Approved
  "
  [placeholder]="'Description' | translate"
  class="workspace-intro workspace-textarea"></textarea>

<p *ngIf="!isOwner && workspace.description" class="workspace-intro">
  {{ workspace.description }}
</p>

<ng-content></ng-content>
<div class="bottom-container" [class.edit]="isEdit | async" *ngIf="!workspace.isEmpty">
  <ng-content selector="[header-right]"></ng-content>

  <div class="statistics-bar">
    <app-workspace-statistics
      [showInvisible]="isEdit | async"
      [workspace]="workspace"></app-workspace-statistics>
  </div>
  <icon-button
    [class.isExpanded]="isGroupsExpanded"
    (click)="isGroupsExpanded ? expandCollapseAll($event) : expandAll()"
    class="expand-button"
    icon="expand_more"></icon-button>
</div>

<app-sharing-popup #shareWorkspacePopup [workspace]="workspace"></app-sharing-popup>
