import { Component, Input, OnInit } from '@angular/core';
import { Membership, UserAccount } from '@core/models';
import { AccountService } from '@core/services';

@Component({
  selector: 'app-teams-users',
  templateUrl: './teams-users.component.html',
  styleUrls: ['./teams-users.component.scss'],
})
export class TeamsUsersComponent implements OnInit {
  @Input() membership: Membership;
  users: UserAccount[];
  userSearchText: string;

  constructor(private accountService: AccountService) {}

  async ngOnInit(): Promise<void> {
    this.users = await this.accountService.list();
  }

  checkTeamActiveForUser(userId: string): UserAccount {
    return this.membership.users.find((p) => p.id === userId);
  }

  async toggleUserActiveForTeam(userId: string): Promise<any> {
    const existingUser = this.checkTeamActiveForUser(userId);
    if (existingUser) {
      this.membership.users.splice(this.membership.users.indexOf(existingUser), 1);
      await this.accountService.removeMembershipFromUser({
        userId,
        membershipId: this.membership.id,
      });
    } else {
      this.membership.users.push(this.users.find((p) => p.id === userId));
      await this.accountService.assignMembershipToUser({
        userId,
        membershipId: this.membership.id,
      });
    }
  }
}
