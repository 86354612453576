import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ContentService,
  ContextMenuItem,
  WorkspaceUiStateService,
  WorkspaceService,
} from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Question, UpdateQuestionRequest } from '@core/models';
import { QuestionService } from '@app/core/services/api/question.service';
import { ISectionItemComponent } from '../workspace-request.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-open-question',
  templateUrl: './open-question.component.html',
  styleUrls: ['./open-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenQuestionComponent implements ISectionItemComponent, OnInit {
  @Input() item: Question;

  @Output() removeSectionItem = new EventEmitter<Question>();
  @Output() sendFocusInput = new EventEmitter<any>();
  @Output() checkboxChange = new EventEmitter<any>();

  checkLength: boolean;
  checked: boolean;

  menu: ContextMenuItem[] = [];

  selectedOption: string;
  options = [
    { type: 'single', name: this.translateService.instant('single-line') },
    { type: 'multi', name: this.translateService.instant('multi-line') },
    { type: 'number', name: this.translateService.instant('number') },
    { type: 'currency', name: this.translateService.instant('currency') },
    { type: 'percentage', name: this.translateService.instant('percentage') },
  ];

  get isEdit(): BehaviorSubject<boolean> {
    return this.stateService.isEdit;
  }

  constructor(
    private requestQuestionItemService: QuestionService,
    protected workspaceService: WorkspaceService,
    private stateService: WorkspaceUiStateService,
    protected contentService: ContentService,
    private translateService: TranslateService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.item.properties.multiline) {
      this.item.properties = { type: 'multi' };
    }

    if (!this.item.properties.type) {
      this.item.properties = { type: 'single' };
    }

    this.selectedOption = this.item.properties.type;
    if (!this.isEdit.value) {
      this.checkItem(true);
    }
  }

  public checkItem(init = false): void {
    if (init && this.item.value.length < 1) {
      return;
    }
    if (this.item.isRequired) {
      this.checkLength = this.item.value.length > 0;
      this.checked = true;
    }
  }

  numberUpdate(event): void {
    if (this.item.properties.type === 'currency') {
      this.formatCurrency(event);
    }

    this.update({ value: event.target.value });
    this.checkItem();
  }

  currencyCommas(n): any {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  formatCurrency(event): void {
    event.target.value.replace(',-', '');
    if (event.target.value.length === 0) {
      return;
    }
    let number = event.target.value;
    if (number.indexOf(',') >= 0) {
      const decimal_pos = number.indexOf(',');
      let leftSide = number.substring(0, decimal_pos);
      let rightSide = number.substring(decimal_pos);
      leftSide = this.currencyCommas(leftSide);
      rightSide = rightSide.replace(/[.,\s]/g, '');
      rightSide += '00';
      rightSide = rightSide.substring(0, 2);
      if (rightSide == '00') {
        rightSide = '-';
      }
      if (leftSide.length === 0) {
        leftSide = '0';
      }
      number = leftSide + ',' + rightSide;
    } else {
      number = this.currencyCommas(number);
      number += ',-';
    }

    event.target.value = number;
  }

  numberCheck(event): void {
    if (event.target.value === '' || event.key === ('Backspace' || 'Delete')) {
      return;
    }

    if (!/^[0-9.,]*$/.test(event.target.value))
      event.target.value = event.target.value.replaceAll(/[^0-9.,]/g, '').trim();
  }

  async updateType(event): Promise<void> {
    this.item.properties = { type: event };
    await this.update({ properties: this.item.properties });
  }

  async removeRequest(): Promise<void> {
    this.removeSectionItem.emit(this.item);
    await this.requestQuestionItemService.delete(this.item);
    this.item = null;
  }

  onFocus(): void {
    this.sendFocusInput.emit();
  }

  async update(request: UpdateQuestionRequest): Promise<any> {
    if (this.item.label.length) {
      await this.requestQuestionItemService.update(this.item, request);
    }
  }
}
