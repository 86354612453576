// `uploadFile` accepts the current filename and the pre-signed URL. It then uses `Fetch API`
// to upload this file to S3 at `play.min.io:9000` using the URL:
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { download, Download } from '@core/services/download.service';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { IDownloadable } from '@core/models/IDownloadable';

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  constructor(private http: HttpClient) {}

  // `uploadFile` accepts the current filename and the pre-signed URL. It then uses `Fetch API`
  // to upload this file to S3 at `play.min.io:9000` using the URL:
  uploadFile(url: string, file: File): Observable<HttpEvent<any>> {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
      headers: new HttpHeaders().set('Accept', '*/*'),
    });

    return this.http.request(req);
  }

  download(url: string, contentItem: IDownloadable, name?: string): Observable<Download> {
    return this.http
      .get(url, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(
        download((blob) => {
          contentItem.download = undefined;
          saveAs(blob, name ? name : contentItem.name);
        })
      );
  }

  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }
}
