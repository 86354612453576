<tabs>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'SuperSettings | information'"
    icon="badge"
    iconClass="material-icons-outlined"
    id="information"
    tabTitle="{{ 'Information' | translate }}">
    <div *ngIf="discount" class="settings-form">
      <div class="settings-row">
        <div class="workspace-settings-group">
          <h3>{{ 'General information' | translate }}</h3>

          <div class="workspace-settings-object">
            <div class="input-group">
              <label class="settings-title" for="title">
                {{ 'Title' | translate }}
              </label>
              <input
                #titleInput
                (blur)="updateDiscount()"
                (ngModelChange)="discount.title = $event"
                [ngModel]="discount.title"
                id="title"
                type="text" />
            </div>

            <div class="input-group">
              <label class="settings-title" for="description">
                {{ 'Description' | translate }}
              </label>
              <input
                (blur)="updateDiscount()"
                (ngModelChange)="discount.description = $event"
                [ngModel]="discount.description"
                id="description"
                type="text" />
            </div>

            <div class="input-group">
              <label class="settings-title" for="start">
                {{ 'Start date' | translate }}
              </label>
              <div class="date-input">
                <input
                  (ngModelChange)="discount.discountStartDate = $event; updateDiscount()"
                  [ngModel]="discount.discountStartDate | date : 'yyyy-MM-dd'"
                  id="start"
                  type="date" />
              </div>
            </div>

            <div class="input-group">
              <label class="settings-title" for="end">
                {{ 'End date' | translate }}
              </label>
              <div class="date-input">
                <input
                  (ngModelChange)="discount.discountEndDate = $event; updateDiscount()"
                  [ngModel]="discount.discountEndDate | date : 'yyyy-MM-dd'"
                  id="end"
                  type="date" />
              </div>
            </div>

            <div class="input-group">
              <label class="settings-title" for="licenseDiscountRate">
                {{ 'License discount' | translate }}
              </label>
              <div class="column">
                <div class="row">
                  <input
                    (blur)="updateDiscount()"
                    (ngModelChange)="
                      discount.licenseDiscountRate = $event; checkLicenseDiscount($event)
                    "
                    [ngClass]="
                      !licensePercentageIsValid && discount.licenseDiscountRate
                        ? 'error-message'
                        : ''
                    "
                    [ngModel]="discount.licenseDiscountRate"
                    id="licenseDiscountRate"
                    max="100"
                    min="0"
                    type="number" />
                  %
                </div>
                <small
                  *ngIf="!licensePercentageIsValid && discount.licenseDiscountRate"
                  class="text-danger">
                  {{ 'Invalid percentage' | translate }}
                </small>
              </div>
            </div>

            <div class="input-group">
              <label class="settings-title" for="userDiscountRate">
                {{ 'User discount' | translate }}
              </label>
              <div class="column">
                <div class="row">
                  <input
                    (blur)="updateDiscount()"
                    (ngModelChange)="discount.userDiscountRate = $event; checkUserDiscount($event)"
                    [ngClass]="
                      !userPercentageIsValid && discount.userDiscountRate ? 'error-message' : ''
                    "
                    [ngModel]="discount.userDiscountRate"
                    id="userDiscountRate"
                    max="100"
                    min="0"
                    type="number" />
                  %
                </div>
                <small
                  *ngIf="!userPercentageIsValid && discount.userDiscountRate"
                  class="text-danger">
                  {{ 'Invalid percentage' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="workspace-settings-group">
          <h3>{{ 'Other actions' | translate }}</h3>

          <button
            (click)="removeDiscount()"
            *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Roles' }]"
            class="btn-secondary-large"
            click-stop-propagation>
            <span class="material-icons material-icon-rejected">delete_outline</span>
            {{ 'DeleteDiscountActionButton' | translate }}
          </button>
        </div>
      </div>
    </div>
  </tab>
</tabs>
