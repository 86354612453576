<dialog
  *ngIf="popupActive === true"
  [@dialog]
  [ngClass]="[fixed ? 'fixed' : '', isItemDetails ? 'item-details' : '']"
  [ngStyle]="customContainerStyles"
  class="popup">
  <div [ngClass]="isItemDetails ? 'item-details-header' : ''" class="header">
    <h2>{{ popupTitle }}</h2>
    <div class="closeDiv">
      <button (click)="closePopup()" class="btn-tertiary close-button">
        <span class="material-icons">close</span>
      </button>
    </div>
  </div>

  <div
    [ngClass]="[
      requiresConsent ? 'requiresConsent' : '',
      isItemDetails ? 'item-details-content' : '',
      scrollableContent ? 'scrollable' : ''
    ]"
    [ngStyle]="customContentStyles"
    class="content">
    <ng-content select="div"></ng-content>
    <label *ngIf="requiresConsent" class="checkboxcontainer">
      <span>{{ 'ConsentLabel' | translate }}</span>
      <input (change)="toggleConsent($event)" type="checkbox" />
      <span class="checkmark"></span>
    </label>
  </div>
  <div *ngIf="showButtons" class="actions">
    <button
      (click)="secondaryButtonClick()"
      [hidden]="eventSecondaryButtonClick.observers.length === 0"
      class="btn-secondary">
      {{ secondaryButtonText }}
    </button>
    <div>&nbsp;</div>
    <button
      (click)="primaryButtonClick()"
      [disabled]="requiresConsent && consentGiven === false"
      class="btn-primary">
      {{ primaryButtonText }}
    </button>
  </div>
</dialog>

<div
  (click)="closePopup()"
  *ngIf="popupActive === true"
  [@background]
  class="popup-background"></div>
