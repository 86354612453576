/* eslint-disable guard-for-in */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from '@core/models';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit {
  @Input() address: Address;
  @Input() title: string;
  @Input() readOnly = false;

  @Output() addressUpdated = new EventEmitter<any>();

  countries: string[] = [];

  constructor(private translateService: TranslateService, private config: NgSelectConfig) {}

  ngOnInit(): void {
    this.getCountryNames();
    this.translateService.onLangChange.subscribe(() => {
      this.getCountryNames();
    });
  }

  getCountryNames(): void {
    this.config.notFoundText = this.translateService.instant('Nothing found');
    this.countries = [];
    if (this.translateService.currentLang) {
      const countries = i18nIsoCountries.getNames(this.translateService.currentLang, {
        select: 'official',
      });
      for (const key in countries) {
        this.countries.push(countries[key]);
      }
    }
  }

  updateAddress(address: any): void {
    this.addressUpdated.emit({ ...this.address, ...address });
  }
}
