import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ItemStatusType } from '@core/models';
import { ContextMenuItem, ContextService } from '@core/services';

class MenuItem implements ContextMenuItem {
  name: string;
  icon: string;
  style: string;
  status: ItemStatusType;
}

@Component({
  selector: 'app-workspace-multi-status',
  templateUrl: './workspace-multi-status.component.html',
  styleUrls: ['./workspace-multi-status.component.scss'],
})
export class WorkspaceMultiStatus implements AfterViewInit {
  @Input() showStatusChange: boolean;

  @Output() statusChange = new EventEmitter<any>();

  @ViewChild('statusMenu') statusMenu: ElementRef;

  selectOpen: boolean = false;
  list: MenuItem[] = [];

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public contextService: ContextService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.list = [
      {
        name: 'Approve',
        icon: 'check_circle',
        style: 'material-icons approved',
        status: ItemStatusType.Approved,
      },
      {
        name: 'Pending',
        icon: 'pending',
        style: 'material-icons pending',
        status: ItemStatusType.Pending,
      },
      {
        name: 'Received',
        icon: 'swap_horizontal_circle',
        style: 'material-icons received',
        status: ItemStatusType.Received,
      },
      {
        name: 'Reject',
        icon: 'cancel',
        style: 'material-icons rejected',
        status: ItemStatusType.Rejected,
      },
      {
        name: 'Does not apply',
        icon: 'do_disturb_on',
        style: 'material-icons non-applicable',
        status: ItemStatusType.NotApplicable,
      },
    ];
  }

  public toggleSelect(event): void {
    event.stopPropagation();
    this.selectOpen = !this.selectOpen;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    this.selectOpen = false;
  }
}
