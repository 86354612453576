<input
  type="text"
  #searchTextBox
  placeholder="{{ 'search' | translate }}"
  class="searchBox"
  [(ngModel)]="searchText" />

<div class="overview">
  <table>
    <tr>
      <th scope="col" class="sortable" [appSort]="templates" data-name="name">
        {{ 'Name' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
    </tr>
    <tr
      *ngFor="let template of templates | workspaceFilter : searchText"
      (click)="useTemplate(template)"
      class="table-row">
      <td class="settings-row">
        <div>
          <div>{{ template.name }}</div>
          <small class="small_info">
            {{ template.description }}
          </small>
        </div>
      </td>
    </tr>
  </table>
</div>
