import { Injectable } from '@angular/core';
import { UserInfo } from '@core/models';
import { environment } from '@env/environment';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  private baseUrl = `${environment.apiBaseUrl}/users/userinfo/v1`;

  constructor(private http: HttpClient) {}

  get(userId: string): Promise<UserInfo> {
    return this.getByIds([userId]).then((users) => users[0]);
  }

  getByIds(userIds: string[]): Promise<UserInfo[]> {
    return this.http
      .post<UserInfo[]>(`${this.baseUrl}/ids`, userIds)
      .then((response) => response.body.map((u) => new UserInfo(u)));
  }

  getByEmail(email: string): Promise<UserInfo> {
    return this.http.get<UserInfo>(`${this.baseUrl}/GetByEmail/${email}`);
  }

  getUsersByEmail(users: string[]): Promise<UserInfo[]> {
    return Promise.all(users.map((u) => this.getByEmail(u)));
  }
}
