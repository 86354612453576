<div
  #messageElement
  [ngClass]="[message.userEmail === ownEmail ? 'own-message' : '', editingMessage ? 'editing' : '']"
  class="message">
  <div class="info-row">
    <app-user-avatar
      [avatarColor]="message.userAvatarColor"
      [avatar]="message.userAvatar"
      [isOwn]="message.userEmail === ownEmail"
      [showTooltip]="false"
      [userName]="message.userName"></app-user-avatar>
    <div class="info">
      <span class="username">
        {{ message.userName }}
        <span *ngIf="message.userName === '' || message.userName === null">
          {{ message.userEmail }}
        </span>
      </span>
      <small class="time">
        {{ message.creationTimestampUtc | dateAgo }}
      </small>
    </div>

    <app-three-dot-menu
      *ngIf="message.userEmail === ownEmail && canEdit"
      [menu]="menu"></app-three-dot-menu>
  </div>
  <div class="text">{{ message.message }}</div>

  <textarea
    #editArea
    (keydown)="inputTextHeight()"
    (keyup)="inputTextHeight()"
    (ngModelChange)="originalMessage = $event; isEmptyOrSpacesEditMessage(originalMessage)"
    [ngModel]="originalMessage"
    autocomplete="__away"
    placeholder="{{ 'message' | translate }}"></textarea>

  <div class="button-bar">
    <button (click)="switchEdit()">{{ 'Cancel' | translate }}</button>
    <button (click)="saveEdit()" [disabled]="editMessageDisabled" class="save">
      {{ 'Save' | translate }}
    </button>
  </div>
</div>
