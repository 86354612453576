import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mobile-option-checkbox',
  templateUrl: './mobile-option-checkbox.component.html',
  styleUrls: ['./mobile-option-checkbox.component.scss'],
})
export class MobileOptionCheckboxComponent {
  @Input() label: string;
  @Input() checked = false;
}
