import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Membership, PermissionType, Role, UserAccount } from '@core/models';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { PermissionService, QuotaService, RoleService, TitleService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Constants } from '@core/common/constants';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  users: UserAccount[];
  roles: Role[];
  permissions;

  roleSearchText: string;

  public permissionTypes = PermissionType;

  selectedRole: Role;
  @ViewChild('createRolePopup') createRolePopup: ModalPopupComponent;
  @ViewChild('editRolePopup') editRolePopup: ModalPopupComponent;
  @ViewChild('deleteRolePopup') deleteRolePopup: ModalPopupComponent;

  @ViewChild('roleNameInput') roleNameInput: ElementRef;

  selectedMembership: Membership;
  selectedUser: UserAccount;

  constructor(
    private roleService: RoleService,
    private permissionService: PermissionService,
    titleService: TitleService,
    public router: Router,
    public translate: TranslateService,
    public quotaService: QuotaService
  ) {
    titleService.set(translate.instant('Settings'));
    this.permissionService.list().then((permissions) => {
      this.permissions = permissions.reduce((r, { section }) => {
        if (!r.some((o) => o.section === section)) {
          r.push({
            section,
            groupItem: permissions.filter((v) => v.section === section),
          });
        }
        return r;
      }, []);
    });
  }

  ngOnInit(): void {
    this.loadRoles();
  }

  async loadRoles(): Promise<any> {
    const roles = await this.roleService.list();
    const systemRoles = roles.filter((role) => role.isSystemRole);
    const ownRoles = roles.filter((role) => !role.isSystemRole);
    this.roles = systemRoles.concat(ownRoles);
    if (this.selectedRole !== undefined) {
      this.selectedRole = this.roles.find((r) => r.id === this.selectedRole.id);
    }
  }

  async updateRole(): Promise<any> {
    await this.roleService.update(this.selectedRole.id, {
      roleName: this.selectedRole.name,
      description: this.selectedRole.description,
    });
    this.roleService.rolesUpdated.emit(this.selectedRole);
  }

  async addRole(): Promise<any> {
    const amountOfNewRoles = this.roles.filter((r) =>
      r.name.startsWith(this.translate.instant('New role'))
    ).length;
    const newRoleId = await this.roleService.post({
      roleName:
        amountOfNewRoles > 0
          ? `${this.translate.instant('New role')} (${amountOfNewRoles + 1})`
          : this.translate.instant('New role'),
      description: Constants.EmptyString,
    });
    await this.loadRoles();
    localStorage.setItem('newRole', 'true');
    this.router.navigate([`/settings/roles/${newRoleId}`]);
  }

  async deleteRole(): Promise<any> {
    if (this.selectedRole.isSystemRole) {
      return;
    }
    await this.roleService.delete(this.selectedRole.id);
    await this.loadRoles();
    this.deleteRolePopup.popupActive = false;
    this.roleService.rolesUpdated.emit(this.selectedRole);
  }

  confirmDeleteRole(role: Role): void {
    if (role.isSystemRole) {
      return;
    }
    this.selectedRole = role;
    this.deleteRolePopup.popupActive = true;
    this.roleService.rolesUpdated.emit(this.selectedRole);
  }

  goToDetails(roleId: string): void {
    this.router.navigate([`/settings/roles/${roleId}`]);
  }
}
