<tabs>
  <tab
    icon="support_agent"
    iconClass="material-icons"
    tabTitle="{{ 'Support' | translate }}"
    [registrationUrl]="'Support | support'">
    <app-support-form></app-support-form>
  </tab>
  <tab
    icon="info"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'About' | translate }}"
    [registrationUrl]="'Support | about'">
    <div class="about-us">{{ 'About us text' | translate }}</div>
  </tab>
  <tab
    icon="gavel"
    iconClass="material-icons"
    tabTitle="{{ 'Terms and conditions' | translate }}"
    id="termsandconditions"
    [registrationUrl]="'Support | terms and conditions'">
    <app-terms-and-conditions></app-terms-and-conditions>
  </tab>
  <tab
    icon="policy"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Privacy statement' | translate }}"
    id="privacystatement"
    [registrationUrl]="'Support | privacy statment'">
    <app-privacy-statement></app-privacy-statement>
  </tab>
</tabs>
