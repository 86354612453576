import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private title;

  get(): string {
    return this.title;
  }

  set(newTitle: string): void {
    this.title = newTitle;
  }
}
