import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
})
export class ProgressbarComponent {
  @Input() value = 0;

  public circumference: number = 2 * Math.PI * 47;
  public strokeDashoffset = 0;
  public color = '#0000ff';

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.onPercentageChanged(changes['value'].currentValue);
    }
  }

  onPercentageChanged(val: number): void {
    this.strokeDashoffset = this.circumference - (val / 100) * this.circumference;
  }
}
