<div class="settings-row" *ngIf="user">
  <div class="workspace-settings-group">
    <h3>{{ 'Personal information' | translate }}</h3>
    <div class="workspace-settings-object">
      <div class="input-group">
        <mt-input placeholder="{{ 'Email address' | translate }}">
          <input
            #mt="ngModel"
            [(ngModel)]="user.email"
            [value]="user.email"
            disabled="disabled"
            id="email"
            type="text" />
        </mt-input>
      </div>

      <div class="input-group">
        <label class="settings-title">{{ 'Honorific' | translate }}</label>
        <select
          (change)="updateUserAccount()"
          [(ngModel)]="user.honorific"
          [disabled]="editRole > EditAccountRole.PartialAccess"
          class="form-control">
          <option
            [defaultSelected]="user.honorific === HonorificTypes.Unknown"
            [ngValue]="HonorificTypes.Unknown">
            {{ '' | translate }}
          </option>
          <option
            [defaultSelected]="user.honorific === HonorificTypes.Mr"
            [ngValue]="HonorificTypes.Mr">
            {{ 'Mr' | translate }}
          </option>
          <option
            [defaultSelected]="user.honorific === HonorificTypes.Mrs"
            [ngValue]="HonorificTypes.Mrs">
            {{ 'Mrs' | translate }}
          </option>
        </select>
      </div>

      <div class="input-group">
        <mt-input placeholder="{{ 'First name' | translate }}">
          <input
            #mt="ngModel"
            (ngModelChange)="user.firstname = $event; updateUserAccount()"
            [(ngModel)]="user.firstname"
            [disabled]="editRole > EditAccountRole.PartialAccess"
            [ngModelOptions]="{ updateOn: 'blur' }"
            id="firstname"
            type="text" />
        </mt-input>
      </div>

      <div class="input-group">
        <mt-input placeholder="{{ 'Last name' | translate }}">
          <input
            #mt="ngModel"
            (ngModelChange)="user.lastname = $event; updateUserAccount()"
            [(ngModel)]="user.lastname"
            [disabled]="editRole > EditAccountRole.PartialAccess"
            [ngModelOptions]="{ updateOn: 'blur' }"
            id="lastname"
            type="text" />
        </mt-input>
      </div>

      <div class="input-group">
        <mt-input placeholder="{{ 'Phone number' | translate }}">
          <input
            #mt="ngModel"
            #number="ngModel"
            (ngModelChange)="user.phoneNumber = $event; updatePhoneNumber(number.errors?.pattern)"
            [(ngModel)]="user.phoneNumber"
            [disabled]="editRole > EditAccountRole.PartialAccess"
            [ngClass]="number.errors?.pattern ? 'no-bottom-margin' : ''"
            [ngModelOptions]="{ updateOn: 'blur' }"
            [pattern]="phonePattern"
            id="phone"
            type="text" />
        </mt-input>
        <small *ngIf="number.errors?.pattern" class="text-danger">
          {{ 'Invalid number' | translate }}
        </small>
      </div>

      <div class="input-group">
        <mt-input placeholder="{{ 'Job title' | translate }}">
          <input
            #mt="ngModel"
            (ngModelChange)="user.jobTitle = $event; updateUserAccount()"
            [(ngModel)]="user.jobTitle"
            [disabled]="editRole > EditAccountRole.PartialAccess"
            [ngModelOptions]="{ updateOn: 'blur' }"
            id="jobTitle"
            type="text" />
        </mt-input>
      </div>

      <div class="input-group profile-picture-group">
        <label class="settings-title">
          {{ 'Profile picture' | translate }}
        </label>
        <div class="settings-row avatar-row">
          <div
            (fileDropped)="uploadLoginPicture($event)"
            appDnd
            class="container"
            id="avatar-container">
            <label class="profile-picture" for="profile-picture">
              <input
                (change)="uploadLoginPicture($event.target.files[0])"
                [disabled]="editRole > EditAccountRole.PartialAccess"
                id="profile-picture"
                type="file" />
              <app-user-avatar
                [avatarColor]="user.avatarColor"
                [avatar]="user.avatar"
                [isOwn]="true"
                [userName]="user.fullName"></app-user-avatar>
              <span class="fill-available">
                {{ 'Choose image' | translate }}
              </span>
            </label>
          </div>
          <button
            (click)="clearAvatar(); updateUserAccount()"
            *ngIf="editRole === EditAccountRole.FullAccess">
            <span class="material-icons material-icon-rejected">delete_outline</span>
          </button>
        </div>
      </div>

      <div class="input-group">
        <label class="settings-title">{{ 'Language' | translate }}</label>
        <select
          #selectedLang
          (change)="switchLang(selectedLang.value)"
          [disabled]="editRole > EditAccountRole.FullAccess"
          class="form-control">
          <option
            *ngFor="let language of translate.getLangs()"
            [selected]="language === user.languageCode"
            [value]="language">
            {{ language | translate }}
          </option>
        </select>
      </div>

      <div class="input-group">
        <label class="settings-title" for="lastname">
          {{ 'Password' | translate }}
        </label>
        <button
          (click)="openChangePasswordPopup()"
          [disabled]="editRole > EditAccountRole.PartialAccess"
          class="btn-secondary">
          {{ 'Change password' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="workspace-settings-group">
    <app-address-form
      *ngIf="user?.address"
      (addressUpdated)="user.address = $event; updateUserAccount()"
      [address]="user.address"
      [readOnly]="editRole > EditAccountRole.PartialAccess"
      [title]="'Address' | translate"></app-address-form>
  </div>
  <div class="workspace-settings-group">
    <h3>{{ 'Communication preferences' | translate }}</h3>
    <div *ngIf="user" class="workspace-settings-object">
      <div class="input-group">
        <label class="checkboxcontainer">
          <span>{{ 'Receive notifications by Email' | translate }}</span>
          <input
            (change)="togglePreferredCommunicationType(PreferredCommunicationType.Email)"
            [checked]="checkCommunicationTypeActive(PreferredCommunicationType.Email)"
            [disabled]="editRole > EditAccountRole.FullAccess"
            type="checkbox" />
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="input-group">
        <label class="checkboxcontainer">
          <span>{{ 'Receive new messages notification' | translate }}</span>
          <input
            (change)="toggleMessageReceivedNotification()"
            [defaultChecked]="
              user.communicationSettings.receiveMessageReceivedNotification ===
              PreferredCommunicationType.Email
            "
            [disabled]="editRole > EditAccountRole.FullAccess"
            type="checkbox" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <h3>{{ 'Workspace settings' | translate }}</h3>

    <div class="workspace-settings-object">
      <div class="input-group">
        <label class="checkboxcontainer">
          {{ 'Download files with requested name' | translate }}
          <input
            (change)="updateUserAccount()"
            [(ngModel)]="user.downloadFilesAsRequestedFileName"
            [checked]="user.downloadFilesAsRequestedFileName"
            [disabled]="editRole !== EditAccountRole.FullAccess"
            type="checkbox" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>

  <div *ngIf="editRole > EditAccountRole.FullAccess" class="workspace-settings-group">
    <h3>{{ 'Other settings' | translate }}</h3>

    <div class="workspace-settings-object">
      <div class="input-group extra-padding">
        <label class="checkboxcontainer">
          <span>{{ 'Is active' | translate }}</span>
          <input
            (change)="updateUserAccount()"
            [(ngModel)]="user.isActive"
            [checked]="user.isActive"
            [disabled]="editRole === EditAccountRole.NoAccess"
            type="checkbox" />
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="input-group">
        <button
          (click)="openConfirmPopup()"
          *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Users' }]"
          [disabled]="editRole === EditAccountRole.NoAccess"
          class="btn-secondary-large">
          <span class="material-icons material-icon-rejected">delete_outline</span>
          {{ 'DeleteUserActionButton' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal-popup
  #changePasswordPopup
  (eventPrimaryButtonClick)="updateUserAccount(true)"
  [popupTitle]="'Change password' | translate"
  [primaryButtonText]="'Save' | translate">
  <div class="popup-content rename-form">
    <div class="input-group">
      <label for="new-password">{{ 'New password' | translate }}</label>

      <input [(ngModel)]="newPassword" id="new-password" type="password" />
    </div>

    <div class="input-group">
      <label for="confirm-password">
        {{ 'Confirm new password' | translate }}
      </label>
      <input [(ngModel)]="confirmNewPassword" id="confirm-password" type="password" />
    </div>
  </div>
</app-modal-popup>

<app-modal-popup
  #deleteUserPopup
  (eventPrimaryButtonClick)="removeUser()"
  [popupTitle]="'DeleteUserTitle' | translate"
  [primaryButtonText]="'DeleteUserActionButton' | translate">
  <div>{{ 'ConfirmUserDelete' | translate }}</div>
</app-modal-popup>
