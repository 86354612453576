<div (click)="toggle()" class="dropdown-all">
  <div class="dropdown-select  {{ dropdownStyle }}">
    <span [class]="iconStyle" class="material-icons">{{ icon }}</span>
    <span class="label">{{ labelTop?.length ? labelTop : label }}</span>
    <div class="icon">
      <span class="material-icons-outlined">expand_more</span>
    </div>
  </div>

  <div
    (window:wheel)="close()"
    *ngIf="active"
    @fadeSlideInOut
    class="dropdown-options  {{ dropdownStyle }}">
    <ng-content></ng-content>
  </div>
</div>
