import { environment } from '@env/environment';
import { CreateShareRequest, ItemShare, ShareUpdateRequest } from '@core/models/share.model';
import { Injectable } from '@angular/core';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  private baseUrl = `${environment.apiBaseUrl}/share/v1`;

  constructor(private http: HttpClient) {}

  get(shareId: string): Promise<ItemShare> {
    return this.http
      .get<ItemShare>(`${this.baseUrl}/${shareId}`)
      .then((share) => new ItemShare(share));
  }

  post(request: CreateShareRequest): Promise<ItemShare> {
    return this.http
      .post<string>(`${this.baseUrl}`, request)
      .then((response) => new ItemShare(response.body));
  }

  put(shareId: string, request: ShareUpdateRequest): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/${shareId}`, request);
  }

  delete(id: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
