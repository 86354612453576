import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Contact, CreateContactRequest, UpdateContactRequest } from '@core/models';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private baseUrl = `${environment.apiBaseUrl}/relations/contact/v1`;

  constructor(private http: HttpClient) {}

  get(userId: string): Promise<Contact> {
    return this.http.get<Contact>(`${this.baseUrl}/${userId}`);
  }

  list(): Promise<Contact[]> {
    return this.http.get<Contact[]>(`${this.baseUrl}`);
  }

  update(contactId: string, request: UpdateContactRequest): Promise<any> {
    return this.http.put(`${this.baseUrl}/${contactId}`, request);
  }

  post(request: CreateContactRequest): Promise<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}`, request);
  }

  delete(contactId: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${contactId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }
}
