/* eslint-disable no-bitwise */
import { Role } from '@core/models/role.model';
import { Membership } from '@core/models/membership.model';
import { Address } from '@core/models/organization.model';
import { Constants } from '@core/common/constants';

export interface IUserAccount {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  avatar?: string;
  avatarColor?: string;
  name?: string;
  fullName: string;
}

export class UserAccount implements IUserAccount {
  id: string = Constants.EmptyGuid;
  firstname: string = Constants.EmptyString;
  lastname: string = Constants.EmptyString;
  email: string = Constants.EmptyString;
  organization: string = Constants.EmptyString;
  organizationId: string = Constants.EmptyString;
  roles: Role[] = [];
  memberships: Membership[] = [];
  avatarColor: string;
  avatar: string = Constants.EmptyString;
  lastLoginUtc: Date;
  creationTimestampUtc?: Date;
  isActive = false;
  address: Address = new Address();
  languageCode: string = Constants.EmptyString;
  phoneNumber: string = Constants.EmptyString;
  jobTitle: string = Constants.EmptyString;
  middleName: string = Constants.EmptyString;
  honorific: HonorificType = HonorificType.Unknown;
  downloadFilesAsRequestedFileName: boolean;
  communicationSettings: CommunicationSettings = new CommunicationSettings();

  constructor(user: any = {}) {
    const { communicationSettings, address, languageCode = 'en', ...rest } = user;
    Object.assign(this, rest);

    this.languageCode = languageCode.toLowerCase().split('-')[0];
    // Prevent overriding with null
    this.communicationSettings = communicationSettings ?? new CommunicationSettings();
    this.address = address ?? new Address();
  }

  get fullName(): string {
    return this.firstname ? `${this.firstname} ${this.lastname}` : this.email;
  }

  get hasOrganization(): boolean {
    return this.organizationId && this.organizationId !== Constants.EmptyGuid;
  }
}

export class CommunicationSettings {
  preferredCommunicationType: PreferredCommunicationType;
  receiveReleaseNotes: boolean;
  receiveMessageReceivedNotification: PreferredCommunicationType;
}

export enum PreferredCommunicationType {
  None = 0, // 0000
  Email = 1 << 0, // 0001
  Sms = 1 << 1, // 0010
  PortalAlerts = 1 << 2, // 0100
}

export enum HonorificType {
  Unknown,
  Mr,
  Mrs,
}

export interface CreateUserAccountRequest {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  organization?: string;
  languageCode: string;
}

export interface UpdateUserAccountRequest {
  firstname?: string;
  middleName?: string;
  lastname?: string;
  updatedPassword?: string;
  roles?: Role[];
  languageCode?: string;
  confirmUpdatedPassword?: string;
  phoneNumber?: string;
  downloadFilesAsRequestedFileName?: boolean;
  jobTitle?: string;
  honorific?: HonorificType;
  avatar?: string;
  isActive?: boolean;
  address?: Address;
  communicationSettings?: CommunicationSettings;
  organizationId?: string;
}

export interface UserMembershipRequest {
  membershipId: string;
  userId: string;
}

export interface UserRoleRequest {
  roleId: string;
  userId: string;
}

export interface UserOrganizationRequest {
  organizationId: string;
}

export class UserInfo implements IUserAccount {
  constructor(user: any) {
    const { languageCode = 'en', ...rest } = user;
    this.languageCode = languageCode.toLowerCase().split('-')[0];
    Object.assign(this, rest);
  }

  organizationId: string;
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  organization: string;
  avatar: string;
  avatarColor: string;
  languageCode: string;

  get fullName(): string {
    return this.firstname ? `${this.firstname} ${this.lastname}` : this.email;
  }
}

export enum EditAccountRole {
  FullAccess,
  PartialAccess,
  NoAccess,
}

export class AvatarColors {
  public static COLORS = [
    '#1b91be',
    '#d50045',
    '#1cb276',
    '#ace320',
    '#f4e013',
    '#f99a43',
    '#29c5c5',
    '#3143e0',
    '#994cc0',
    '#ff44a0',
  ];
}
