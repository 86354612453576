<div class="settings-row">
  <div class="workspace-settings-group">
    <h3>{{ 'General information' | translate }}</h3>

    <div *ngIf="relation" class="workspace-settings-object">
      <div class="input-group">
        <label class="settings-title" for="name">
          {{ 'Name' | translate }}
        </label>
        <input
          #nameInput
          (ngModelChange)="relation.name = $event; updateRelation()"
          [ngModelOptions]="{ updateOn: 'change', standalone: true }"
          [ngModel]="relation.name"
          autocomplete="__away"
          id="name"
          type="text" />
      </div>
      <div class="input-group">
        <label class="settings-title" for="emailAddress">
          {{ 'Email' | translate }}
        </label>
        <input
          (ngModelChange)="relation.emailAddress = $event; updateRelation()"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [ngModel]="relation.emailAddress"
          autocomplete="__away"
          id="emailAddress"
          type="text" />
      </div>
      <div class="input-group">
        <label class="settings-title" for="phone">
          {{ 'Phone number' | translate }}
        </label>
        <input
          (ngModelChange)="relation.phoneNumber = $event; updateRelation()"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [ngModel]="relation.phoneNumber"
          autocomplete="__away"
          id="phone"
          type="text" />
      </div>
      <div class="input-group">
        <label class="settings-title" for="reference">
          {{ 'Reference' | translate }}
        </label>
        <input
          (ngModelChange)="relation.reference = $event; updateRelation()"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [ngModel]="relation.reference"
          autocomplete="__away"
          id="reference"
          type="text" />
      </div>
      <div class="input-group">
        <label class="settings-title" for="website">
          {{ 'Website' | translate }}
        </label>
        <input
          (ngModelChange)="relation.website = $event; updateRelation()"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [ngModel]="relation.website"
          autocomplete="__away"
          id="website"
          type="text" />
      </div>
      <div class="input-group">
        <label class="settings-title" for="chamberOfCommerce">
          {{ 'Chamber of commerce' | translate }}
        </label>
        <input
          (ngModelChange)="relation.chamberOfCommerce = $event; updateRelation()"
          [(ngModel)]="relation.chamberOfCommerce"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          autocomplete="__away"
          id="chamberOfCommerce"
          type="text" />
      </div>
    </div>
  </div>

  <div *ngIf="relation" class="workspace-settings-group">
    <app-address-form
      (addressUpdated)="relation.address = $event; updateRelation()"
      [address]="relation.address"
      [title]="'Address' | translate"></app-address-form>
  </div>
</div>
<div class="settings-row">
  <div class="workspace-settings-group">
    <h3>{{ 'Other settings' | translate }}</h3>
    <div class="workspace-actions">
      <div *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Relations' }]">
        <button
          (click)="deleteRelationPopup.popupActive = true"
          *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Relations' }]"
          class="btn-secondary-large">
          <span class="material-icons material-icon-rejected">delete_outline</span>
          {{ 'DeleteRelationActionButton' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal-popup
  #warnAboutChangePagePopup
  (eventPrimaryButtonClick)="navigateDespiteWarning()"
  [popupTitle]="'WarningDoubleRelation' | translate">
  <div>
    {{ 'WarningDoubleRelationTextOne' | translate }}
    <span *ngIf="relation">{{ relation.name }}.</span>
    <br />
    {{ 'WarningDoubleRelationTextTwo' | translate }}{{ duplicateName }}
  </div>
</app-modal-popup>

<app-modal-popup
  #deleteRelationPopup
  (eventPrimaryButtonClick)="deleteRelation()"
  [popupTitle]="'DeleteRelationTitle' | translate"
  [primaryButtonText]="'DeleteRelationActionButton' | translate">
  <div>{{ 'ConfirmRelationDelete' | translate }}</div>
</app-modal-popup>
