import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  CreateUserAccountRequest,
  UpdateUserAccountRequest,
  UserAccount,
  UserMembershipRequest,
  UserOrganizationRequest,
  UserRoleRequest,
} from '@core/models';
import { HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private baseUrl = `${environment.apiBaseUrl}/users/account/v1`;
  @Output() accountsUpdated = new EventEmitter<any>();
  constructor(private http: HttpClient) {}

  get(userId: string): Promise<UserAccount> {
    return this.http.get<UserAccount>(`${this.baseUrl}/${userId}`).then((r) => new UserAccount(r));
  }

  list(): Promise<UserAccount[]> {
    return this.http
      .get<UserAccount[]>(`${this.baseUrl}`)
      .then((r) => (r || []).map((u) => new UserAccount(u)));
  }

  update(userId: string, updateAccountRequest: UpdateUserAccountRequest): Promise<any> {
    return this.http.put(`${this.baseUrl}/${userId}`, updateAccountRequest);
  }

  post(userAccountRequest: CreateUserAccountRequest): Promise<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}`, userAccountRequest);
  }

  delete(userId: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${userId}`);
  }

  assignRoleToUser(request: UserRoleRequest): Promise<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiBaseUrl}/users/role/user/v1`, request);
  }

  removeRoleFromUser(payload: UserRoleRequest): Promise<any> {
    return this.http.request('delete', `${environment.apiBaseUrl}/users/role/user/v1`, {
      body: payload,
    });
  }

  assignOrganizationToUser(userId: string, request: UserOrganizationRequest): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/org/${userId}`, request);
  }

  assignMembershipToUser(request: UserMembershipRequest): Promise<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiBaseUrl}/users/membership/user/v1`, request);
  }

  removeMembershipFromUser(payload: UserMembershipRequest): Promise<any> {
    return this.http.request('delete', `${environment.apiBaseUrl}/users/membership/user/v1`, {
      body: payload,
      observe: 'response',
    });
  }
}
