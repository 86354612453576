import { Pipe, PipeTransform } from '@angular/core';
import { ItemStatusType, IWorkspaceBaseDto } from '@core/models';

@Pipe({
  name: 'workspaceFilter',
})
export class WorkspaceFilterPipe implements PipeTransform {
  transform(items: IWorkspaceBaseDto[], searchText: string): IWorkspaceBaseDto[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.name.toLowerCase().includes(searchText);
    });
  }
}

@Pipe({
  name: 'workspaceStatusFilter',
})
export class WorkspaceStatusFilterPipe implements PipeTransform {
  transform(items: IWorkspaceBaseDto[], statusTypes: ItemStatusType[]): IWorkspaceBaseDto[] {
    if (!items) {
      return [];
    }
    return items.filter(
      (it) => statusTypes.filter((st) => it.requestContentItemStatus === st).length > 0
    );
  }
}
