<div
  [class.active]="drag.active"
  [style.transform]="'translate(' + drag.x + 'px, ' + drag.y + 'px)'"
  class="drag-preview">
  <div class="file-drop-to">
    <span class="material-icons-outlined from">insert_drive_file</span>
    <span *ngIf="drag.icon" class="material-icons-outlined arrow">arrow_right_alt</span>
    <span *ngIf="drag.icon" class="material-icons-outlined to">{{ drag.icon }}</span>
  </div>
  {{ drag.title }}
</div>
