import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-all',
  templateUrl: './multi-select-all.component.html',
  styleUrls: ['./multi-select-all.component.scss'],
})
export class MultiSelectAllComponent {
  @Input() active: boolean;
  @Input() checked: boolean = false;

  @Output() checkAll = new EventEmitter<any>();
}
