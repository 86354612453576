import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextMenuComponent } from '@app/shared/context-menu/component/context-menu.component';
import { ContextMenuItem, ContextService } from '@core/services';

export interface IContextMenuParent {
  menu: ContextMenuItem[];
}

@Directive({
  selector: '[contextMenu], [contextMenuParent]',
})
export class ContextMenuDirective implements OnInit, OnDestroy {
  @Input() contextMenu: ContextMenuItem[];
  @Input() contextMenuParent: IContextMenuParent;

  @ViewChild(ContextMenuComponent) contextMenuComponent: ElementRef;
  public sub: Subscription;

  constructor(public elementRef: ElementRef<HTMLElement>, public contextService: ContextService) {}

  public ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener('contextmenu', this.showContextMenu);
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('contextmenu', this.showContextMenu);
  }

  get visibleItems(): ContextMenuItem[] {
    return this.menu?.filter((item) =>
      typeof item.visible !== 'function' ? item.visible !== false : item.visible()
    );
  }

  get menu(): ContextMenuItem[] {
    return this.contextMenuParent?.menu || this.contextMenu;
  }

  public open({ x, y }: MouseEvent): void {
    if (Object.keys(this.visibleItems).length) {
      const itemHeight = 48 * this.visibleItems.filter((item) => !item.divider).length;
      const dividerHeight = 17 * this.visibleItems.filter((item) => item.divider).length;
      const height = itemHeight + 10 + dividerHeight;

      const width = document.getElementsByClassName('context-menu')[0].clientWidth + 12;

      this.close();

      if (x + width > document.body.offsetWidth) {
        x = document.body.offsetWidth - width;
      }
      if (y + height > window.innerHeight) {
        y = window.innerHeight - height;
      }

      this.contextService.set(x, y, this.visibleItems);

      setTimeout(() => {
        if (x + width > document.body.offsetWidth) {
          x = document.body.offsetWidth - width;
        }

        this.contextService.set(x, y, this.visibleItems);
      }, 300);

      setTimeout(() => {
        this.contextService.animate(true);
      }, 300);
    } else {
      this.close();
    }
  }

  public close(): void {
    // Make sure the context menu is closed if there are no items
    this.contextService.set(0, 0, this.visibleItems);
    if (this.sub) this.sub.unsubscribe();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    const contextMenuEnabled = targetElement.getAttribute('context-menu-enabled');
    if (contextMenuEnabled === null) {
      this.contextService.hide();
    }
  }

  private showContextMenu = (event$) => {
    event$.preventDefault();
    this.open(event$);
  };
}
