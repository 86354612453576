// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.binsend.com',
  authBaseUrl: 'https://auth.binsend.com',
  dashboardBaseUrl: 'https://dashboard.test.binsend.com',
  emptyMailTemplateId: 'd-2efcad7eed62444a9666f368857fca66',
  sectionItemNotificationTemplateId: 'd-4832f9db3485487a8544fb55b140bfe9',
  supportEmailsRecipientAddress: 'hello@binsend.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
