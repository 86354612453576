import { Component, Input, OnInit } from '@angular/core';
import { Organization } from '@core/models';
import { Discount } from '@core/models/discount.model';
import { DiscountService } from '@core/services/api/discount.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organization-discounts',
  templateUrl: './organization-discounts.component.html',
  styleUrls: ['./organization-discounts.component.scss'],
})
export class OrganizationDiscountsComponent implements OnInit {
  @Input() organization: Organization;
  discounts: Discount[];

  constructor(
    private discountService: DiscountService,
    public translate: TranslateService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.discounts = (await this.discountService.list(this.organization.id).toPromise()).body;
  }

  async addDiscount(): Promise<void> {
    let date = new Date();
    const newDate = new Date(date.setMonth(date.getMonth() + 1));
    date = new Date();

    const result = await this.discountService
      .post({
        organizationId: this.organization.id,
        discountStartDate: date,
        discountEndDate: newDate,
        title: this.translate.instant('New discount'),
      })
      .toPromise();
    localStorage.setItem('newDiscount', 'true');
    this.router.navigate([`/settings/supersettings/${this.organization.id}/${result.body}`]);
  }

  async removeDiscount(id: string): Promise<void> {
    await this.discountService.delete(id).toPromise();
    await this.ngOnInit();
  }

  goToDetails(id: string): void {
    this.router.navigate([`/settings/supersettings/${this.organization.id}/${id}`]);
  }
}
