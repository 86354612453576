<div *ngIf="permissions && role" class="settings-row">
  <div *ngFor="let permissionGroup of permissions" class="workspace-settings-group">
    <h3>{{ permissionGroup.section | translate }}</h3>
    <div class="workspace-settings-object">
      <p>{{ permissionGroup.section + ' info' | translate }}</p>
      <div *ngFor="let groupItem of sortBy(permissionGroup.groupItem)" class="labels">
        <label
          *ngIf="!(groupItem.permissionType === 1 && permissionGroup.section === 'Workspaces')"
          [ngClass]="role.isSystemRole ? 'checkboxcontainer disabled' : 'checkboxcontainer'">
          {{ 'rolePermissionType_' + groupItem.permissionType | translate }}
          <input
            (change)="togglePermissionActive(groupItem)"
            [checked]="!!getPermission(groupItem)"
            [disabled]="role.isSystemRole"
            type="checkbox" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</div>
