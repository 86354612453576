import {
  Component,
  HostListener,
  Input,
  ContentChildren,
  QueryList,
  AfterContentInit,
} from '@angular/core';
import { DropdownItemComponent } from '@app/shared/dropdown/dropdown-item/dropdown-item.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-icon-dropdown',
  templateUrl: './icon-dropdown.component.html',
  styleUrls: ['./icon-dropdown.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':leave', [animate('50ms', style({ opacity: 0, transform: 'translateY(10px)' }))]),
    ]),
  ],
})
export class IconDropdownComponent implements AfterContentInit {
  active = false;
  private clickWasInside = false;

  @Input() materialIcon: string;
  @ContentChildren(DropdownItemComponent)
  templates: QueryList<DropdownItemComponent>;

  @HostListener('click')
  clickInside(): void {
    this.clickWasInside = true;
  }

  @HostListener('document:click')
  clickOut(): void {
    if (!this.clickWasInside) {
      this.close();
    }
    this.clickWasInside = false;
  }

  ngAfterContentInit(): void {
    this.templates.forEach((template) => {
      template.response.subscribe(() => {
        this.close();
      });
    });
  }

  toggle(): void {
    this.active = !this.active;
  }

  close(): void {
    // Weird angular bug requires a time-out to close the dropdown
    setTimeout(() => {
      this.active = false;
    }, 0);
  }
}
