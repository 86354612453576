<div class="header">
  <div class="title">
    <h3>{{ name }}</h3>
    <h4 *ngIf="mostPopular">{{ 'Most popular' | translate }}</h4>
  </div>

  <div *ngIf="pricingSubheader" class="centered-content">
    <span class="material-icons-outlined">business</span>
    {{ pricingSubheader }}
  </div>
  <div *ngIf="!pricingSubheader">
    <div class="centered-content">
      <span class="material-icons-outlined">supervised_user_circle</span>
      {{ userPrice }} {{ 'per user per month' | translate }}
    </div>
  </div>
</div>
<div class="content">
  <ng-content select="div"></ng-content>
</div>
<button (click)="raiseSelectSubscription()" *ngIf="isActive === false" class="btn btn-primary">
  {{ buttonTitle }}
</button>
<button *ngIf="isActive === true" class="btn btn-primary active">
  {{ 'Current plan' | translate }}
</button>
