<div class="multi-select-bar" [class.active]="totalSelected && active">
  <div class="multi-select-bar__wrapper">
    <span class="select-count">{{ totalSelected }} {{ 'selected' | translate }}</span>

    <ng-content></ng-content>

    <div class="big-button" *ngIf="deleteFunction && isOwner" (click)="deleteFunction.emit()">
      {{ 'Remove' | translate }}
    </div>
  </div>
</div>
