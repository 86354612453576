import { Component, Input, OnInit } from '@angular/core';
import { Role, UserAccount } from '@core/models';
import { AccountService } from '@core/services';

@Component({
  selector: 'app-roles-users',
  templateUrl: './roles-users.component.html',
  styleUrls: ['./roles-users.component.scss'],
})
export class RolesUsersComponent implements OnInit {
  @Input() role: Role;
  users: UserAccount[];
  userSearchText: string;

  constructor(private accountService: AccountService) {}

  async ngOnInit(): Promise<void> {
    this.users = await this.accountService.list();
  }

  checkRoleActiveForUser(user: UserAccount): Role {
    return user.roles?.find((u) => u.id === this.role?.id);
  }

  async toggleUserActiveForRole(user: UserAccount): Promise<any> {
    const existingRole = this.checkRoleActiveForUser(user);
    if (existingRole) {
      user.roles.splice(user.roles.indexOf(existingRole), 1);
      await this.accountService.removeRoleFromUser({
        userId: user.id,
        roleId: this.role.id,
      });
    } else {
      user.roles.push(this.role);
      await this.accountService.assignRoleToUser({
        userId: user.id,
        roleId: this.role.id,
      });
    }
  }
}
