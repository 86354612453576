<div class="chart-container" [class.round]="round" #chartWrapper (mouseleave)="hideAll()">
  <div class="canvas-wrapper" *ngIf="this.statistics && total > 0">
    <div *ngFor="let item of this.statistics[0]?.series">
      <div class="message-container {{ item.name }}" *ngIf="item.value > 0">
        <div class="message-icon">
          <span *ngIf="item.name === 'Pending'" class="material-icons pending">pending</span>
          <span *ngIf="item.name === 'Received'" class="material-icons received">
            swap_horizontal_circle
          </span>
          <span *ngIf="item.name === 'Approved'" class="material-icons approved">check_circle</span>
          <span *ngIf="item.name === 'Rejected'" class="material-icons rejected">cancel</span>
          <span *ngIf="item.name === 'NotApplicable'" class="material-icons non-applicable">
            do_disturb_on
          </span>
        </div>

        <div class="message-text">
          <h4 class="message-title">
            {{ item.name | translate }}
          </h4>
          <span class="message-count">
            {{ item.value }} {{ 'from-the' | translate }} {{ total }}
          </span>
        </div>
      </div>
    </div>

    <div #barChart class="bar-chart" *ngIf="!round && total > 0">
      <span
        *ngFor="let item of this.statistics[0]?.series"
        class="{{ item.name }}"
        (mouseover)="activateBar(item.name)"
        (mouseleave)="hideAll()"
        [ngStyle]="{ maxWidth: (item.value / total) * 100.2 + '%' }"></span>
      <!-- * 100.2 is for fixing half pixels in chrome... If it's to much it will get cut off by overflow:hidden-->
    </div>
    <canvas #roundChart class="round-chart" *ngIf="round" (mouseleave)="hideAll()">
      {{ chart }}
    </canvas>
  </div>
</div>
