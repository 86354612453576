import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ContentItem, FileRequest, ItemStatusType, UpdateSectionItemRequest } from '@core/models';
import {
  ContentService,
  ContextMenuItem,
  NotificationService,
  WorkspaceService,
  WorkspaceUiStateService,
} from '@core/services';
import { FileRequestService } from '@app/core/services/api/fileRequest.service';
import { TranslateService } from '@ngx-translate/core';
import { ISectionItemComponent } from '../workspace-request.component';
import { FileDownloaderService } from '@app/core/services/api/file-downloader.service';
import { FileUploaderService } from '@app/core/services/api/file-uploader.service';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { titlePattern } from '@app/core/common/constants';
import { ContextMenuDirective } from '@app/shared/context-menu/context-menu.directive';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-file-request',
  templateUrl: './file-request.component.html',
  styleUrls: ['./file-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileRequestComponent implements OnInit, AfterViewInit, ISectionItemComponent {
  originalTitle: string;
  popup: boolean;
  @Input() isOwner: boolean;
  @Input() item: FileRequest;

  @Output() removeSectionItem: EventEmitter<FileRequest> = new EventEmitter<FileRequest>();
  @Output() sendFocusInput = new EventEmitter<any>();
  @Output() checkboxChange = new EventEmitter<any>();

  @ViewChild('descriptionInput') textarea: ElementRef;
  @ViewChild('uploadButton') uploadButton: ElementRef;
  @ViewChild('fileRequestPreview') fileRequestPreview: ElementRef;
  @ViewChild('deleteFilePopup')
  deleteFilePopup: ModalPopupComponent;

  fileDeleteId: string;
  public itemStatusTypes = ItemStatusType;
  titlePattern = titlePattern;
  hasTitleError: boolean;
  isEmpty: boolean;
  showDrop: boolean;
  fileRequestMenu: ContextMenuItem[] = [];
  contentItemMenu: ContextMenuItem[] = [];

  private activeFile: ContentItem;
  private showContentItemMenu = false;

  allowedFileTypes = [
    { key: 'jpeg/jpg', value: '.jpeg', checked: false },
    { key: 'pdf', value: '.pdf', checked: false },
    { key: 'gif', value: '.gif', checked: false },
    { key: 'png', value: '.png', checked: false },
    { key: 'zip', value: '.zip', checked: false },
  ];

  constructor(
    protected workspaceService: WorkspaceService,
    private stateService: WorkspaceUiStateService,
    protected contentService: ContentService,
    private fileRequestService: FileRequestService,
    private notifyService: NotificationService,
    public translate: TranslateService,
    public downloadHelper: FileDownloaderService,
    private fileUploader: FileUploaderService,
    private cdr: ChangeDetectorRef,
    private contextMenuDirective: ContextMenuDirective
  ) {}

  get canDelete(): boolean {
    return this.item.status !== ItemStatusType.Approved || this.isOwner;
  }

  get menu(): ContextMenuItem[] {
    return this.showContentItemMenu ? this.contentItemMenu : this.fileRequestMenu;
  }

  get isEdit(): BehaviorSubject<boolean> {
    return this.stateService.isEdit;
  }

  buildFileRequestMenu(): void {
    this.fileRequestMenu = [
      {
        name: 'limit-filetypes',
        icon: 'insert_drive_file',
        visible: () => this.isEdit.value,
        action: (): void => {
          this.popup = true;
          this.cdr.markForCheck();
        },
      },
      {
        name: 'open-preview',
        icon: 'preview',
        action: () => this.fileRequestPreview.nativeElement.click(),
      },
      {
        name: 'Download',
        icon: 'file_download',
        interpolationParams: { targetType: 'RequestContentItem' },
        visible: () => !this.downloadHelper.isDownloading,
        action: () => this.downloadFileRequest(),
      },
      {
        name: 'Upload Files',
        icon: 'file_upload',
        action: () => this.uploadButton.nativeElement.click(),
      },
      {
        divider: true,
      },
      {
        name: 'Is required',
        checked: () => this.item.isRequired,
        action: () => this.toggleRequired(),
      },
      {
        name: 'Approvable',
        checked: () => this.item.isApprovable,
        action: () => this.toggleApprovable(),
      },
    ];
  }

  ngOnInit(): void {
    this.originalTitle = this.item.name;
    this.allowedFileTypes.forEach((fileType) => {
      fileType.checked = this.item.allowedFileTypes?.filter((d) => d === fileType.value).length > 0;
    });

    this.inputTextHeight();
    this.buildFileRequestMenu();

    this.contentItemMenu = [
      {
        name: 'DownloadCaption',
        icon: 'file_download',
        visible: () => !this.downloadHelper.isDownloading,
        action: () => this.download(this.activeFile),
      },
      {
        name: 'DeleteCaption',
        icon: 'delete',
        visible: () => this.item.canEdit && this.canDelete,
        action: () => this.removeContentItem(this.activeFile?.id),
      },
    ];

    //nasty ng redraw...
    setTimeout(() => this.inputTextHeight(), 150);
  }

  onRightClick(event, showContentMenu, file?: ContentItem): void {
    event.stopPropagation();
    event.preventDefault();

    this.activeFile = file;
    this.showContentItemMenu = showContentMenu;

    this.contextMenuDirective.open(event);
  }

  async ngAfterViewInit(): Promise<void> {
    this.inputTextHeight();

    //nasty ng redraw...
    setTimeout(() => {
      this.inputTextHeight();
    }, 300);
  }

  async changeFileSpecs(): Promise<void> {
    this.popup = false;
    this.item.allowedFileTypes = this.allowedFileTypes.filter((d) => d.checked).map((d) => d.value);
    await this.updateFileRequest({ allowedFileTypes: this.item.allowedFileTypes });
  }

  inputTextHeight(): void {
    if (this.textarea) {
      //back to zero for limit calculation
      this.textarea.nativeElement.style.height = 0;
      this.textarea.nativeElement.style.height = this.textarea.nativeElement.scrollHeight + 'px';
    }
  }

  removeRequest(): void {
    if (this.canDelete) {
      this.removeSectionItem.emit(this.item);
    }
  }

  onFocus(): void {
    this.sendFocusInput.emit();
  }

  async updateFileRequest(request: UpdateSectionItemRequest): Promise<void> {
    this.item.focused = false;
    if (!this.hasTitleError) {
      if (this.item.name) {
        await this.fileRequestService.update(this.item, request);
      } else {
        this.item.name = this.originalTitle;
      }
    }
  }

  async toggleRequired(): Promise<void> {
    this.item.isRequired = !this.item.isRequired;
    await this.fileRequestService.update(this.item, { isRequired: this.item.isRequired });
  }

  async toggleApprovable(): Promise<void> {
    this.item.metadata = this.item.metadata || {};
    this.item.metadata.isApprovable = !this.item.metadata.isApprovable;
    await this.fileRequestService.update(this.item, { metadata: this.item.metadata });
  }

  getIcon(fileName): 'panorama' | 'article' | 'theaters' | 'insert_drive_file' {
    const videoIcon = ['mpg', 'mpeg', 'mp4', 'webm', 'ogg'];
    const imageIcon = ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'webp'];
    const textIcon = [
      'txt',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'pdf',
      'ai',
      'psd',
      'svg',
      'eps',
      'ps',
      'tif',
      'xps',
    ];

    const fileArray = fileName.split('.');
    const fileType = fileArray[fileArray.length - 1];

    if (imageIcon.indexOf(fileType) > -1) {
      return 'panorama';
    } else if (textIcon.indexOf(fileType) > -1) {
      return 'article';
    } else if (videoIcon.indexOf(fileType) > -1) {
      return 'theaters';
    } else {
      return 'insert_drive_file';
    }
  }

  async fileBrowseHandler(fileList: FileList): Promise<void> {
    this.showDrop = false;
    if (!this.canDelete) return;

    const files = Array.from(fileList);
    if (files.some((file) => !this.item.isFileAllowed(file))) {
      this.notifyService.showError('not-allowed-extension-body', 'not-allowed-extension-title');
      return;
    }

    const workspace = this.workspaceService.currentWorkspace;
    const event = { files: fileList, position: -1 };
    await this.fileUploader.upload(event, workspace, this.item, this.cdr);

    this.cdr.markForCheck();
  }

  async downloadFileRequest(): Promise<void> {
    if (this.item.contentItems.length === 1) {
      const firstItem = this.item.contentItems[0];
      await this.download(firstItem);
    } else {
      await this.downloadHelper.downloadFileRequest(this.item);
    }
  }

  async download(ci: ContentItem): Promise<void> {
    await this.downloadHelper.downloadContentItem(ci.workspaceId, ci, ci.name);
  }

  async removeContentItem(contentItemId: string): Promise<void> {
    this.fileDeleteId = contentItemId;
    this.deleteFilePopup.popupActive = true;
    this.cdr.markForCheck();
  }

  async removeContentItemConfirm(): Promise<any> {
    this.deleteFilePopup.closePopup();
    if (!this.canDelete) return;

    this.item.removeContentItem(this.fileDeleteId);
    await this.fileRequestService.updateStatus(this.item, this.item.status);
    await this.fileRequestService.deleteContentItem(this.item, this.fileDeleteId);
    this.cdr.markForCheck();
  }

  unsetDragOver(): void {
    this.showDrop = false;
  }

  setDragOver(): void {
    if (this.item.canEdit) this.showDrop = true;
  }
}
