<div *ngIf="membership && users">
  <div class="section-header">
    <input
      [(ngModel)]="userSearchText"
      class="searchBox"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>
  <section *ngIf="users">
    <table *ngIf="users.length > 0">
      <tr>
        <th>{{ 'Assigned' | translate }}</th>
        <th [appSort]="users" class="sortable" data-name="firstname" scope="col">
          {{ 'Name' }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th [appSort]="users" class="sortable" data-name="email" scope="col">
          {{ 'Email address' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
      </tr>
      <tr *ngFor="let user of users | userFilter: userSearchText">
        <td>
          <label class="checkboxcontainer">
            <input
              (change)="toggleUserActiveForTeam(user.id)"
              [checked]="checkTeamActiveForUser(user.id)"
              type="checkbox" />
            <span class="checkmark"></span>
          </label>
        </td>
        <td>{{ user.firstname }} {{ user.lastname }}</td>
        <td>
          {{ user.email }}
        </td>
      </tr>
    </table>
  </section>
</div>
