import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[multiSelectHover]',
})
export class MultiSelectDirective {
  constructor(readonly elRef: ElementRef, private renderer2: Renderer2) {}

  @HostListener('mouseover') onMouseEnter() {
    this.renderer2.addClass(this.elRef.nativeElement, 'multi-select-hover');
    const checkbox = this.elRef.nativeElement.querySelector('.multi-select-checker');
    if (checkbox) {
      checkbox.classList.add('hover');
    }
  }

  @HostListener('mouseout') onMouseLeave() {
    this.renderer2.removeClass(this.elRef.nativeElement, 'multi-select-hover');
    const checkbox = this.elRef.nativeElement.querySelector('.multi-select-checker');
    if (checkbox) {
      checkbox.classList.remove('hover');
    }
  }
}
