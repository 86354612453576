import { Pipe, PipeTransform } from '@angular/core';
import { Section, SectionItem, SectionItemType } from '@core/models';

@Pipe({
  name: 'workspaceGroupContentFilter',
})
export class WorkspaceGroupContentFilterPipe implements PipeTransform {
  transform(items: SectionItem[], searchText: string): SectionItem[] {
    if (items?.length === 0) {
      return items;
    }

    if (!searchText || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    return items.filter((item) => {
      if (item.type !== SectionItemType.Section) {
        return this.itemMeetsSearchCriteria(searchText, item);
      } else {
        const section = item as Section;
        const children = section.items.filter((item) => {
          return this.itemMeetsSearchCriteria(searchText, item);
        });
        return children.length > 0 || this.itemMeetsSearchCriteria(searchText, item);
      }
    });
  }

  itemMeetsSearchCriteria(searchText: string, item: SectionItem): boolean {
    return item.name.toLowerCase().includes(searchText);
  }
}
