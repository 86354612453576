import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchResultGroupType } from '@app/shared/searchbar/person-searchbar/share.searchresults.model';
import {
  Contact,
  IItemShare,
  ItemPermissionType,
  ItemShare,
  IUserAccount,
  Membership,
  Organization,
  Relation,
} from '@app/core/models';

const includes = (str, search) => str?.toLowerCase().includes(search.toLowerCase());

const match = (search) => (entity) =>
  includes(entity?.name, search) ||
  includes(entity?.email, search) ||
  includes(entity?.emailAddress, search) ||
  includes(entity?.firstname, search) ||
  includes(entity?.lastname, search);

@Component({
  selector: 'app-person-searchbar',
  templateUrl: './person-searchbar.component.html',
  styleUrls: ['./person-searchbar.component.scss'],
})
export class PersonSearchbarComponent {
  public searchText = '';
  @Input() existingShares: ItemShare[];
  @Input() newShares: ItemShare[] = [];
  @Output() newMemberEvent = new EventEmitter<ItemShare>();
  searchResultGroupType = SearchResultGroupType;
  PermissionTypes = ItemPermissionType;
  // In a map, to make searching faster
  membersMatchingSearchString: ItemShare[] = [];
  clickWasInside = false;
  showResultBar = false;
  private searchTimeout = null;

  @Input() relations: Relation[];
  @Input() organization: Organization;
  @Input() users: IUserAccount[];
  @Input() contacts: Contact[];
  @Input() memberships: Membership[];

  constructor(public translate: TranslateService) {}

  @HostListener('click')
  @HostListener('focus')
  clickInside(): void {
    this.clickWasInside = true;
  }

  @HostListener('document:click')
  @HostListener('blur')
  clickOut(): void {
    if (!this.clickWasInside) {
      this.closeResults();
    }
    this.clickWasInside = false;
  }

  openResults(): void {
    this.showResultBar = true;
  }

  closeResults(): void {
    this.showResultBar = false;
  }

  isOpenSuggestions(): boolean {
    return this.showResultBar;
  }

  shareExists(share: IItemShare): boolean {
    const shareExists = (s) =>
      (share.email && s.email === share.email) ||
      (share.userId && s.userId === share.userId) ||
      (share.relationId && s.relationId === share.relationId) ||
      (share.membershipId && s.membershipId === share.membershipId) ||
      (share.organizationId && s.organizationId === share.organizationId);
    return this.newShares.some(shareExists) || this.existingShares.some(shareExists);
  }

  isExistingUser(email: string): boolean {
    return email && this.users.some((member) => member.email === email);
  }

  processSearchInput(searchText: any): void {
    this.showResultBar = true;
    this.searchText = searchText.target.value;

    if (this.searchTimeout !== null) {
      clearTimeout(this.searchTimeout);
    }

    if (this.searchText === '') {
      this.membersMatchingSearchString.splice(0, this.membersMatchingSearchString.length);
      return;
    }

    this.searchTimeout = setTimeout(() => {
      // First we get the users matching the search criteria. We than place the organizations, team and relations before the users.
      this.membersMatchingSearchString = [
        ...this.getOrganization(),
        ...this.getMatchingRelations(),
        ...this.getMatchingTeams(),
        ...this.getMatchingUsers(),
        ...this.getMatchingContacts(),
      ].map((share) => new ItemShare(share));
    }, 500);
  }

  getMatchingUsers(): IItemShare[] {
    return this.users.filter(match(this.searchText)).map((user) => ({
      userId: user.id,
      email: user.email,
      user,
    }));
  }

  getMatchingContacts(): IItemShare[] {
    return this.contacts.filter(match(this.searchText)).map((contact) => ({
      email: contact.emailAddress,
      user: contact,
    }));
  }

  getMatchingRelations(): IItemShare[] {
    return this.relations.filter(match(this.searchText)).map((r) => ({
      relationId: r.id,
      name: r.name,
    }));
  }

  getMatchingTeams(): IItemShare[] {
    return this.memberships.filter(match(this.searchText)).map((team) => ({
      membershipId: team.id,
      name: team.name,
    }));
  }

  getOrganization(): IItemShare[] {
    if (match(this.searchText)(this.organization)) {
      return [
        {
          organizationId: this.organization.id,
          name: this.organization.name,
          organization: this.organization,
        },
      ];
    }
    return [];
  }

  onClickNewMember(share: ItemShare): void {
    clearTimeout(this.searchTimeout);
    this.newMemberEvent.emit(share);
    this.closeResults();
    this.searchText = '';
  }

  onclickNewMemberByEmail(email: string): void {
    const share = new ItemShare({
      name: this.getNameFromEmail(email),
      email: email,
    });
    this.onClickNewMember(share);
  }

  getNameFromEmail(emailAddress: string): string {
    return emailAddress.split('@')[0];
  }

  isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
}
