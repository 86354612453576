import { Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-no-access-animation',
  templateUrl: './no-access-animation.component.html',
  styleUrls: ['./no-access-animation.component.scss'],
})
export class NoAccessAnimationComponent {
  animation: AnimationItem;
  options: AnimationOptions = {
    path: '/assets/animations/no_access.json',
    loop: false,
  };

  animationCreated(animationItem: AnimationItem): void {
    this.animation = animationItem;
  }

  animationEnd(): void {
    this.animation.goToAndPlay(62, true);
  }
}
