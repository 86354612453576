import { Organization, Subscription, UserAccount } from '@core/models';

import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { OrganizationService } from '@core/services/api/organization.service';
import { SubscriptionService } from '@core/services/api/subscription.service';
import { BrandingService } from '@core/services/branding.service';
import { AccountService } from './api/account.service';
import { ReplaySubject } from 'rxjs';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  public user: UserAccount;
  public userData: any;
  public role: string;
  public organization: Organization;
  public subscription: Subscription;
  public isIdentityManager: boolean;
  public accountUpdated = new ReplaySubject<UserAccount>(1);

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private subscriptionService: SubscriptionService,
    private brandingService: BrandingService,
    private oidcSecurityService: OidcSecurityService
  ) {
    this.authService.isAuthorized.subscribe(async () => {
      const userData: UserDataResult = await this.authService.getUserData();
      this.updateUser(userData);
    });
    this.oidcSecurityService.userData$.subscribe((userData) => this.updateUser(userData));
  }

  public async updateUser({ userData }: UserDataResult): Promise<void> {
    this.userData = userData;
    if (!userData?.sub) return;
    this.role = Array.isArray(userData.role)
      ? userData.role.find((role) => role.length > 1)
      : userData.role;
    this.isIdentityManager =
      Array.isArray(userData.role) &&
      userData.role.filter((r) => r === 'Identity Manager').length > 0;
    this.user = await this.accountService.get(userData.sub);
    if (this.user.isActive === false) {
      this.authService.logout();
      return;
    }

    this.accountUpdated.next(this.user);

    let nextSubscription: Subscription;

    const subscriptions = (await this.subscriptionService.list()).sort(
      // HMM...sorting by retention in days works...but it isn't really an actual index indicator...
      // Perhaps the index indicator should be added to the subscriptions model (server-side)
      (a, b) => {
        const retentionA = a.workspaceRetentionInDays
          ? a.workspaceRetentionInDays
          : Number.MAX_VALUE;
        const retentionB = b.workspaceRetentionInDays
          ? b.workspaceRetentionInDays
          : Number.MAX_VALUE;
        return retentionA - retentionB;
      }
    );

    let organization: Organization;
    let subscription: Subscription;
    if (this.user.hasOrganization) {
      const organizationId = this.user.organizationId;
      organization = await this.organizationService.get(organizationId);

      subscription = await this.subscriptionService.get(organization.subscriptionId);
      const subscriptionIndex = subscriptions.indexOf(
        subscriptions.find((d) => d.id === organization.subscriptionId)
      );
      nextSubscription =
        subscriptionIndex < subscriptions.length
          ? subscriptions[subscriptionIndex + 1]
          : subscriptions[subscriptions.length];
    }

    if (!nextSubscription && !subscription) {
      nextSubscription = subscriptions[1];
    }

    this.organization = organization;
    this.subscription = subscription;
    this.subscriptionService.currentPlan.next({ subscription, nextSubscription });
    await this.brandingService.checkAndApplyBranding(organization, subscription);
  }

  async resetOrganization(): Promise<void> {
    if (this.organization && this.subscription)
      await this.brandingService.checkAndApplyBranding(this.organization, this.subscription);
  }

  refreshSession(): Promise<void> {
    return this.oidcSecurityService
      .forceRefreshSession()
      .toPromise()
      .then((response) => {
        const token = response.accessToken;
        return this.updateUser(jwtDecode(token));
      });
  }
}
