import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  GetUploadUrlResponse,
  Section,
  SectionItem,
  SectionRequestItem,
  SectionType,
  Workspace,
} from '@app/core/models';
import { UploadGuardService } from '@app/core/services/upload-guard.service';
import { retry } from 'rxjs/operators';
import { BlobService } from './blob.service';
import { ContentService } from './content.service';
import { SectionService } from './section.service';
import * as R from 'ramda';
import { parallel } from '@app/core/common/parallel.functions';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ProgressEvent } from '../loader.service';
import { NotificationService } from '../notification.service';
import { FileRequestService } from './fileRequest.service';
import { IContentItemService } from './content-item.service';

export interface FileDroppedEvent {
  files: FileList;
  position: number;
}

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  public progress = new BehaviorSubject<ProgressEvent>(new ProgressEvent());

  constructor(
    private uploadGuardService: UploadGuardService,
    private sectionService: SectionService,
    private blobService: BlobService,
    private contentService: ContentService,
    private notificationService: NotificationService,
    private fileRequestService: FileRequestService
  ) {}

  async upload(
    event: FileDroppedEvent,
    workspace: Workspace,
    parent: SectionItem,
    cdr?: ChangeDetectorRef
  ): Promise<void> {
    // Invoke `reverse` to ensure that the files are uploaded in the same order as they were selected.
    let files: File[] = Array.from(event.files).reverse() as File[];

    const areFiles = await Promise.all(files.map((file) => this.isFile(file)));
    files = files.filter((_, index) => areFiles[index]);
    const parents = Array(files.length).fill(parent);

    const service = (
      parent.isSection ? this.sectionService : this.fileRequestService
    ) as IContentItemService;

    await this.uploadFiles(service, workspace, parents, files, cdr, event.position);
  }

  async uploadFolder(files: File[], workspace: Workspace, section: Section): Promise<Section> {
    const swp = await this.createSections(files, workspace, section);
    const sections = files.map((file) => this.findSection(swp, file.webkitRelativePath));
    const topFolder = swp[0]?.section;
    topFolder.isVisible = false;

    await this.uploadFiles(this.sectionService, workspace, sections, files);

    topFolder.isVisible = true;
    return topFolder;
  }

  private async uploadFiles(
    service: IContentItemService,
    workspace: Workspace,
    parents: SectionItem[],
    files: File[],
    cdr?: ChangeDetectorRef,
    position = -1
  ): Promise<void> {
    this.uploadGuardService.setStatusToInProgress();
    this.progress.next(new ProgressEvent(0));

    const percentages = Array(files.length).fill(0);
    const responses = await this.createContentItems(
      service,
      workspace,
      parents,
      files,
      cdr,
      position
    );

    for (const [fileIndex, response] of responses.entries()) {
      const { contentItem } = response;
      await this.uploadFileAsync(workspace.id, response, percentages, fileIndex).catch(
        async (err) => {
          workspace.removeSectionItem(contentItem?.id);
          await service.deleteContentItem(parents[fileIndex], contentItem?.id);
          return err;
        }
      );
    }

    this.progress.next(new ProgressEvent());
    this.uploadGuardService.setStatusToDone();
  }

  uploadToFileRequest(wsId: string, request: UploadStatus): Promise<HttpEvent<any>> {
    this.uploadGuardService.setStatusToInProgress();

    return this.uploadFileAsync(wsId, request, undefined, undefined).finally(() => {
      this.uploadGuardService.setStatusToDone();
    });
  }

  findSection(swp: SectionWithPath[], path: string): Section {
    const [parentPath] = getPathAndName(path);
    return swp.find((s) => s.path === parentPath)?.section;
  }

  async createSections(
    files: File[],
    workspace: Workspace,
    uploadSection: Section
  ): Promise<SectionWithPath[]> {
    const parentPaths = files.flatMap((f) => getParentPaths(f));
    const uniquePaths = Array.from(new Set(parentPaths));
    uniquePaths.sort((a, b) => a.split('/').length - b.split('/').length);

    const newSections: SectionWithPath[] = [];
    for (const path of uniquePaths) {
      const swp = await this.createSection(newSections, workspace, uploadSection, path);
      newSections.push(swp);
    }
    return newSections;
  }

  async createSection(
    newSections: SectionWithPath[],
    workspace: Workspace,
    uploadSection: Section,
    path: string
  ): Promise<SectionWithPath> {
    const [parentPath, name] = getPathAndName(path);
    const parentSection = newSections.find((s) => s.path === parentPath)?.section || uploadSection;

    const section = await this.sectionService.post(workspace.id, parentSection.id, {
      name,
      description: '',
      SectionType: SectionType.ContainsOnlySections,
      items: [],
      parentSectionId: parentSection.id,
    } as SectionRequestItem);

    if (parentSection === uploadSection) section.isVisible = false;

    workspace.insertSectionItem(section, parentSection);

    return { section, path };
  }

  async isFile(file: File): Promise<boolean> {
    try {
      await file.slice(0, 1).arrayBuffer();
      return true;
    } catch (err) {
      return false;
    }
  }

  async uploadFileAsync(
    workspaceId: string,
    response: UploadStatus,
    percentages?: number[],
    fileIndex?: number
  ): Promise<HttpEvent<any>> {
    const { file, contentItem, uploadUrl } = response;

    const uploadTask = this.blobService
      .uploadFile(uploadUrl, file)
      .pipe(retry({ count: 10, delay: 500 }));

    uploadTask.subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // This is an upload progress event. Compute and show the % done:
          const newPercentage = Math.round((100 * event.loaded) / event.total);

          contentItem.size = event.total;
          contentItem.uploadProgress.next(new ProgressEvent(newPercentage));

          if (percentages) {
            percentages[fileIndex] = newPercentage;
            const percentage = R.sum(percentages) / percentages.length;
            this.progress.next(new ProgressEvent(percentage));
          }
        }
      },
      complete: () => {
        contentItem.uploadProgress.next(new ProgressEvent());
        return this.contentService.confirmUploadSuccessful(workspaceId, contentItem.id);
      },
      error: () => {
        contentItem.uploadProgress.next(new ProgressEvent());
        this.notifyUploadFailed(file);
        throw new Error(`Uploading content item ${contentItem.id} failed`);
      },
    });

    return lastValueFrom(uploadTask);
  }

  notifyUploadFailed(file: File): void {
    const args = { file: file.name };
    this.notificationService.showError('upload-failed', 'upload-failed-description', args);
  }

  async createContentItems(
    service: IContentItemService,
    workspace: Workspace,
    parents: SectionItem[],
    files: File[],
    cdr?: ChangeDetectorRef,
    insertAt = -1
  ): Promise<UploadStatus[]> {
    const createContentItem = async (file, index) => {
      const parent = parents[index];
      const response = await this.contentService.getUploadUrl(
        workspace.id,
        file.name,
        false,
        parent
      );

      workspace.insertSectionItem(response.contentItem, parent, insertAt);
      await service.addContentItem(parent, response.contentItem.id, insertAt);
      cdr?.detectChanges();

      return { ...response, index, file };
    };
    return parallel(1, files, createContentItem);
  }
}

interface UploadStatus extends GetUploadUrlResponse {
  file: File;
}

export interface SectionWithPath {
  path: string;
  section: Section;
}

const getPathAndName = (path: string): string[] => {
  const pathSegments = path.split('/');
  const name = pathSegments.pop();
  const parentPath = pathSegments.join('/');
  return [parentPath, name];
};

export const getParentPaths = (file: File): any[] => {
  const paths = file.webkitRelativePath.split('/');
  return paths.reduce(
    (acc, _, index) =>
      index === paths.length - 1 ? acc : [...acc, paths.slice(0, index + 1).join('/')],
    []
  );
};
