import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemStatusFilters } from '@app/core/models/item-status-filters';
import { ContextMenuItem } from '@app/core/services';

class MenuItem implements ContextMenuItem {
  name: string;
  label?: string;
  icon: string;
  style?: string;
  status: ItemStatusFilters;
}

@Component({
  selector: 'workspace-filter',
  templateUrl: 'workspace-filter.component.html',
})
export class WorkspaceFilterComponent implements OnInit {
  @Input() selectedStatus: ItemStatusFilters;
  @Output() selectedStatusChange = new EventEmitter<ItemStatusFilters>();

  menu: MenuItem[] = [
    {
      name: 'None',
      label: 'Filter',
      icon: 'sort',
      style: 'material-icons text',
      status: ItemStatusFilters.None,
    },
    {
      name: 'Pending',
      icon: 'pending',
      style: 'material-icons pending',
      status: ItemStatusFilters.Pending,
    },
    {
      name: 'Received',
      icon: 'swap_horizontal_circle',
      style: 'material-icons received',
      status: ItemStatusFilters.Received,
    },
    {
      name: 'Approved',
      icon: 'check_circle',
      style: 'material-icons approved',
      status: ItemStatusFilters.Approved,
    },
    {
      name: 'Rejected',
      icon: 'cancel',
      style: 'material-icons rejected',
      status: ItemStatusFilters.Rejected,
    },
    {
      name: 'NotApplicable',
      icon: 'do_disturb_on',
      style: 'material-icons non-applicable',
      status: ItemStatusFilters.NotApplicable,
    },
    {
      name: 'Questions',
      icon: 'question_mark',
      style: 'material-icons text',
      status: ItemStatusFilters.Questions,
    },
    {
      name: 'AdditionalFiles',
      icon: 'insert_drive_file',
      style: 'material-icons text',
      status: ItemStatusFilters.AdditionalFiles,
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  get selectedItem(): MenuItem {
    return this.menu.find((item) => item.status === this.selectedStatus);
  }

  ngOnInit() {
    this.cdr.detectChanges();
  }

  selectItem(item: MenuItem) {
    this.selectedStatusChange.emit(item.status);
  }
}
