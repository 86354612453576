<div class="workspace-container" *ngIf="workspace | async">
  <workspace-menu [workspace]="workspace | async"></workspace-menu>

  <tabs>
    <tab
      [registrationUrl]="'Workspace details | files'"
      icon="description"
      iconClass="material-icons-outlined"
      id="workspace-tab"
      tabTitle="{{ 'Workspace' | translate }}">
      <div class="workspace-wrapper">
        <div *ngIf="workspaceNotFound">
          <app-notfound title="{{ 'WorkspaceNotFound' | translate }}"></app-notfound>
        </div>
        <div *ngIf="notAuthorized">
          <div class="empty-item-container">
            <h2>{{ 'NotAuthorizedWorkspaceAccess' | translate }}</h2>
            <p>
              {{ 'Back to' | translate }}
              <a [routerLinkActive]="['active-link']" [routerLink]="['/dashboard']">
                {{ 'dashboard' | translate }}
              </a>
            </p>
            <app-no-access-animation></app-no-access-animation>
          </div>
        </div>

        <app-workspace-view
          *ngIf="!workspaceNotFound && !notAuthorized"
          [workspace]="workspace | async"></app-workspace-view>
      </div>
    </tab>
    <tab
      [registrationUrl]="'Workspace details | settings'"
      [visible]="(workspace | async)?.isOwner"
      icon="tune"
      id="settings-tab"
      iconClass="material-icons"
      tabTitle="{{ 'Settings' | translate }}">
      <app-workspace-settings [workspace]="workspace | async"></app-workspace-settings>
    </tab>
  </tabs>
</div>
