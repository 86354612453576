import { Component } from '@angular/core';
import { TitleService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { PermissionType } from '@core/models';

@Component({
  selector: 'app-settings',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent {
  public permissionTypes = PermissionType;

  constructor(public titleService: TitleService, private translate: TranslateService) {
    this.translateTitle();
    translate.onLangChange.subscribe((d) => this.translateTitle());
  }

  translateTitle(): void {
    this.titleService.set(this.translate.instant('Organization'));
  }
}
