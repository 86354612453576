import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '@core/models';

@Pipe({
  name: 'contentGroup',
})
export class ContentGroupFilterPipe implements PipeTransform {
  transform(sections: Section[], searchText: string): unknown {
    if (!sections) {
      return [];
    }

    if (!searchText || searchText === '') {
      // Groups are inflated
      return sections.map((fileGroup) => {
        fileGroup.expanded = false;
        return fileGroup;
      });
    }

    searchText = searchText.toLowerCase();

    const filteredItems = sections.filter((section) => {
      const groupNameMeetsCriteria = this.groupNameMeetsSearchCriteria(searchText, section);
      const itemsMeetsCriteria = this.itemsMeetsSearchCriteria(searchText, section);
      if (groupNameMeetsCriteria || itemsMeetsCriteria) {
        return section;
      }
      else
        return null;
    });

    // Groups are expanded
    return filteredItems.map((fileGroup) => {
      fileGroup.expanded = true;
      return fileGroup;
    });
  }

  groupNameMeetsSearchCriteria(searchText: string, contentGroup: any): boolean {
    return contentGroup.key.toLowerCase().includes(searchText);
  }

  itemsMeetsSearchCriteria(searchText: string, contentGroup: any): boolean {
    const itemMeetsSearchCriteria = contentGroup.value.map((item) => {
      return item.requestContentItem.name.toLowerCase().includes(searchText);
    });
    return itemMeetsSearchCriteria.includes(true);
  }
}
