import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Membership, PermissionType, Role, StatisticsGroup, UserAccount } from '@core/models';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import {
  AccountService,
  MembershipService,
  NotificationService,
  PermissionService,
  QuotaService,
  RoleService,
  SubscriptionService,
  TitleService,
  UserAccountService,
  UserInfoService,
} from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: UserAccount[];
  roles: Role[];
  memberships: Membership[];
  permissions;
  ownAccount;
  isSubscriptionUpgradable = true;
  isUserQuotaReached = false;

  userSearchText: string;

  public permissionTypes = PermissionType;

  selectedRole: Role;

  @ViewChild('createUserPopup') createUserPopup: ModalPopupComponent;
  @ViewChild('checkUserBeforeAdd') checkUserEmail: ModalPopupComponent;
  @ViewChild('deleteUserPopup') deleteUserPopup: ModalPopupComponent;

  @ViewChild('createUserFirstnameInput') createUserFirstnameInput: ElementRef;

  selectedMembership: Membership;
  selectedUser: UserAccount;
  email = '';
  userNotChecked = true;

  colorScheme = {
    domain: ['#4FCCFF', '#b7b7b7', '#CE1313'],
  };

  view = [200, 35];
  userStatistics: StatisticsGroup[];

  constructor(
    private accountService: AccountService,
    private roleService: RoleService,
    private userInfoService: UserInfoService,
    private membershipService: MembershipService,
    private permissionService: PermissionService,
    private userAccountService: UserAccountService,
    private subscriptionService: SubscriptionService,
    public translate: TranslateService,
    public router: Router,
    titleService: TitleService,
    public quotaService: QuotaService,
    public notificationService: NotificationService
  ) {
    titleService.set(translate.instant('Settings'));
    this.permissionService.list().then((permissions) => {
      this.permissions = permissions.reduce((r, { section }) => {
        if (!r.some((o) => o.section === section)) {
          r.push({
            section,
            groupItem: permissions.filter((v) => v.section === section),
          });
        }
        return r;
      }, []);
    });
  }

  ngOnInit(): void {
    this.subscriptionService.currentPlan.subscribe(() => {
      this.loadUserStatistics();
    });
    this.loadUsers();
    this.loadRoles();
    this.loadMemberships();

    this.ownAccount = this.userAccountService.user?.email;

    this.roleService.rolesUpdated.subscribe(() => {
      this.loadRoles();
      this.loadUsers();
    });

    this.membershipService.membershipsUpdated.subscribe(() => {
      this.loadMemberships();
      this.loadUsers();
    });
    this.quotaService.quotaChanged.subscribe(() => {
      this.isSubscriptionUpgradable = this.getIsSubscriptionUpgradable();
      this.isUserQuotaReached = this.getIsOverMaxUserQuota();
    });
  }

  async loadRoles(): Promise<any> {
    this.roles = await this.roleService.list();
    if (this.selectedRole) {
      this.selectedRole = this.roles.find((r) => r.id === this.selectedRole.id);
    }
  }

  private getIsSubscriptionUpgradable(): boolean {
    return !!this.quotaService.nextSubscription;
  }

  private getIsOverMaxUserQuota(): boolean {
    return this.users && this.users.length >= this.quotaService.maxNumberOfUsers;
  }

  async loadUsers(): Promise<any> {
    this.users = await this.accountService.list();
    if (this.selectedUser) {
      this.selectedUser = this.users.find((r) => r.id === this.selectedUser.id);
    }
    this.loadUserStatistics();
  }

  loadUserStatistics(): void {
    if (!this.users || !this.subscriptionService.currentPlanValue) {
      return;
    }

    const activeUsers = this.users.filter((user: UserAccount) => user.isActive);

    this.userStatistics = [
      {
        name: '',
        series: [
          {
            name: this.translate.instant('Users'),
            value: Math.min(this.quotaService.maxNumberOfUsers, activeUsers.length),
          },
          {
            name: this.translate.instant('More available'),
            value: Math.max(0, this.quotaService.maxNumberOfUsers - activeUsers.length),
          },
          {
            name: this.translate.instant('Overspend'),
            value: Math.abs(Math.min(0, this.quotaService.maxNumberOfUsers - activeUsers.length)),
          },
        ],
      },
    ];
  }

  async loadMemberships(): Promise<any> {
    this.memberships = await this.membershipService.list();
    if (this.selectedMembership !== undefined) {
      this.selectedMembership = this.memberships.find((r) => r.id === this.selectedMembership.id);
    }
  }

  goToDetails(user: UserAccount): void {
    this.router.navigate([`/settings/users/${user.id}`]);
  }

  getMembershipNames(memberships: Membership[]): string {
    if (!memberships) return '';
    const result = memberships.map((m) => m.name);
    return result
      .filter((membershipName, i, arr) => {
        return arr.indexOf(arr.find((t) => t === membershipName)) === i;
      })
      .join(', ');
  }

  getRoleNames(roles: Role[]): string {
    if (!roles) return '';

    const result = roles.map((m) => (m.isSystemRole ? this.translate.instant(m.name) : m.name));
    return result
      .filter((membershipName, i, arr) => {
        return arr.indexOf(arr.find((t) => t === membershipName)) === i;
      })
      .join(', ');
  }

  confirmDeleteUser(userAccount: UserAccount): void {
    this.selectedUser = userAccount;
    this.deleteUserPopup.popupActive = true;
  }

  async deleteUserFromOrganization(): Promise<any> {
    const result = await this.accountService.delete(this.selectedUser.id);
    if (result) {
      this.notificationService.showSuccess(
        'delete-from-organization-successful',
        'Removal successful'
      );
    } else {
      this.notificationService.showError('delete-from-organization-failed', 'GenericErrorTitle');
    }
    await this.loadUsers();
    await this.loadMemberships();
    this.deleteUserPopup.popupActive = false;
  }

  createUser(): void {
    this.selectedUser = new UserAccount();
    this.selectedUser.languageCode = 'en';
    this.selectedUser.email = this.email;
    this.createUserPopup.popupActive = true;
    setTimeout(() => {
      this.createUserFirstnameInput.nativeElement.select();
    }, 300);
  }

  async addUser(): Promise<any> {
    const newUser = await this.accountService.post({
      id: this.selectedUser.id,
      firstname: this.selectedUser.firstname,
      languageCode: this.selectedUser.languageCode,
      lastname: this.selectedUser.lastname,
      email: this.selectedUser.email,
      organization: this.userAccountService.user.organization, // same organization as the user that is creating the account
    });

    this.selectedUser.id = newUser.body;

    await this.loadUsers();
    this.createUserPopup.popupActive = false;
    // move to edit screen for roles/memberships
    this.router.navigate([`/settings/users/${this.selectedUser.id}`]);
  }

  isValidLoginDate(date: Date): boolean {
    return new Date(date).getFullYear() > 1970;
  }

  changeIsActiveUser(): void {
    this.selectedUser.isActive = !this.selectedUser.isActive;
  }

  openCheckEmailPopup(): void {
    this.createUserPopup.popupActive = false;
    this.userNotChecked = true;
    this.checkUserEmail.popupActive = true;
  }

  async checkEmail(): Promise<void> {
    const user = await this.userInfoService.getByEmail(this.email);
    if (!user) {
      this.createUser();
      this.checkUserEmail.popupActive = false;
      return;
    } else if (user.organizationId) {
      this.userNotChecked = false;
      this.email = '';
      return;
    }
    await this.accountService.assignOrganizationToUser(user.id, {
      organizationId: this.users[0].organizationId,
    });
    await this.loadUsers();
    this.email = '';
    this.checkUserEmail.popupActive = false;
  }

  closeAddUserPopup(): void {
    this.checkUserEmail.popupActive = false;
    this.email = '';
  }
}
