<app-container>
  <div class="content-container workspace-list-container">
    <div
      *ngIf="
        workspaces &&
        workspaces.length > 0 &&
        (workspaces | workspaceStatusFilter : [statusTypes.Archived]).length !== workspaces.length
      "
      class="workspace-container-header">
      <div *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Workspaces' }]">
        <create-workspace-button
          (createWorkspace)="addNewWorkspace()"
          (createWorkspaceFromTemplate)="selectTemplateForWorkspacePopup.popupActive = true"
          [disabled]="workspaces.length >= quotaService.maxActiveWorkspaces"
          [showTemplates]="false"></create-workspace-button>
      </div>
    </div>

    <div *ngIf="workspaces" class="container">
      <span class="sub-heading">
        <div>
          <span class="material-icons-outlined">trending_up</span>
          {{ 'Latest workspace activity' | translate }}
        </div>
        <div>
          <span
            (click)="selectView(false)"
            [ngClass]="isListView ? '' : 'active-icon'"
            class="material-icons click">
            view_module
          </span>
          <span
            (click)="selectView(true)"
            [ngClass]="isListView ? 'active-icon' : ''"
            class="material-icons click">
            list
          </span>
        </div>
      </span>
      <div
        *ngIf="
          workspaces.length === 0 ||
          (workspaces | workspaceStatusFilter : [statusTypes.Archived]).length === workspaces.length
        "
        class="empty-item-container">
        <h2 class="no-items-title">{{ 'NoWorkspacesYet' | translate }}</h2>
        <div>
          <create-workspace-button
            (createWorkspace)="addNewWorkspace()"
            (createWorkspaceFromTemplate)="selectTemplateForWorkspacePopup.popupActive = true"
            [disabled]="workspaces.length >= quotaService.maxActiveWorkspaces"
            [showTemplates]="false"></create-workspace-button>

          <app-upgrade-button
            *ngIf="workspaces.length >= quotaService.maxActiveWorkspaces"></app-upgrade-button>
        </div>
        <app-empty-animation></app-empty-animation>
      </div>
      <app-archive-workspace-popup></app-archive-workspace-popup>
      <div *ngIf="!isListView" class="workspace-list">
        <app-workspace-card
          (archiveWorkspace)="openArchivePopup($event)"
          (deleteWorkspace)="openConfirmPopup($event)"
          (shareWorkspace)="shareWorkspace($event)"
          *ngFor="
            let workspace of workspaces
              | workspaceStatusFilter : [statusTypes.Unknown, statusTypes.Approved]
              | slice : 0 : 4
          "
          [workspace]="workspace"
          class="workspace-container-wrapper"></app-workspace-card>
      </div>
      <app-workspace-list
        (archiveWorkspace)="archiveWorkspace($event)"
        (removeWorkspace)="removeWorkspace($event)"
        (shareWorkspace)="shareWorkspace($event)"
        *ngIf="
          isListView &&
          workspaces.length !== 0 &&
          (workspaces | workspaceStatusFilter : [statusTypes.Archived]).length !== workspaces.length
        "
        [listViewWorkspaces]="
          workspaces
            | workspaceStatusFilter : [statusTypes.Unknown, statusTypes.Approved]
            | workspaceFilter : searchText
        "></app-workspace-list>
    </div>
  </div>

  <!--Statistics tab is hidden because this feature is W.I.P-->
  <!--Remove "*ngIf" to show tab.-->
  <tab
    *ngIf="false"
    icon="bar_chart"
    iconClass="material-icons"
    tabTitle="{{ 'Statistics' | translate }}">
    [registrationUrl]="'Dashboard | statistics'">
    <div class="content-container">
      <span class="sub-heading">
        <span class="material-icons-outlined">insights</span>
        {{ 'Statistics' | translate }}
      </span>
    </div>
    <div class="centered">
      <h2>{{ 'No statistics yet' | translate }}</h2>
      <h4>{{ 'No statistics yet expl' | translate }}</h4>
      <app-empty-animation></app-empty-animation>
    </div>
  </tab>
</app-container>

<app-modal-popup
  #deleteWorkspacePopup
  (eventPrimaryButtonClick)="removeWorkspace(focusedWorkspace)"
  [popupTitle]="'DeleteWorkspaceTitle' | translate"
  [primaryButtonText]="'DeleteWorkspaceActionButton' | translate : interpolationParams">
  <div>{{ 'ConfirmWorkspaceDelete' | translate }}</div>
</app-modal-popup>
<app-modal-popup
  #existingWorkspacePopup
  (eventPrimaryButtonClick)="openExistingWorkspace()"
  (eventSecondaryButtonClick)="createSecondaryEmptyWorkspace()"
  [popupTitle]="'ExistingWorkspaceTitle' | translate"
  [primaryButtonText]="'Yes' | translate"
  [secondaryButtonText]="'No' | translate">
  <div *ngIf="existingEmptyWorkspace">
    {{ 'AlreadyExistingWorkspacePrefix' | translate }} '{{ existingEmptyWorkspace.name }} '.
    {{ 'AlreadyExistingWorkspacePostfix' | translate }}
  </div>
</app-modal-popup>

<app-modal-popup
  #selectTemplateForWorkspacePopup
  [popupTitle]="'SelectTemplateForWorkspaceTitle' | translate"
  [showButtons]="false">
  <div class="popup-content rename-form">
    <app-template-search-panel
      (templateSelected)="popupEmptyWorkspaceByTemplate($event)"
      *ngIf="selectTemplateForWorkspacePopup.popupActive === true"></app-template-search-panel>
  </div>
</app-modal-popup>

<app-modal-popup
  #confirmTemplateForWorkspacePopup
  (eventPrimaryButtonClick)="createEmptyWorkspaceByTemplate()"
  [popupTitle]="'ConfirmTemplateForWorkspaceTitle' | translate">
  <div *ngIf="selectedTemplate">
    <span *ngIf="selectedTemplate.defaultShares.length > 0">
      {{ 'Automatic shares' | translate }}
    </span>
    <span *ngIf="selectedTemplate.defaultShares.length === 0">
      {{ 'No automatic shares' | translate }}
    </span>
    <ul>
      <li *ngFor="let share of selectedTemplate.defaultShares">
        {{ share.name }}
      </li>
    </ul>
  </div>
</app-modal-popup>

<app-sharing-popup #shareWorkspacePopup [workspace]="focusedWorkspace"></app-sharing-popup>

<app-archive-workspace-popup
  #archiveWorkspacePopup
  (changeEventEmitter)="listWorkspaces()"
  [workspace]="focusedWorkspace"></app-archive-workspace-popup>
