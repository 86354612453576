import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() dark: boolean;
  @Input() icon: string;
  @Input() style: string;
  @Input() disabled: boolean;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  clickHandler() {
    if (!this.disabled) {
      this.onClick.emit();
    }
  }
}
