<div
  *ngIf="contentItem.uploadProgress | async as progress"
  [class.edit]="isEdit | async"
  [filePreview]="contentItem"
  [ngClass]="progress.isActive ? '' : 'no-upload'"
  class="content-item"
  multiSelectHover>
  <app-multi-select-checkbox
    type="content-item"
    (checkboxChange)="checkboxChange.emit()"
    [item]="contentItem"></app-multi-select-checkbox>

  <span class="material-icons-outlined" [class.checked]="contentItem.selected">
    {{ getIcon(contentItem.name) }}
  </span>
  <div class="content">
    <span class="content-item__text">{{ contentItem.name }}</span>
    <div class="upload-bar">
      <span [style.max-width.%]="progress.percentage"></span>
    </div>

    <span class="percentage">{{ progress.percentage }}%</span>
  </div>
  <icon-button
    (onClick)="download(contentItem)"
    class="download"
    [disabled]="progress.isActive"
    click-stop-propagation
    icon="file_download"></icon-button>
  <icon-button
    (click)="removeRequest()"
    class="remove"
    click-stop-propagation
    icon="delete"></icon-button>
</div>
