import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './workspace-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { WorkspaceComponent } from '@feature/workspace/workspace.component';
import { DetailsComponent } from '@feature/workspace/details/details.component';
import { AutosizeModule } from 'ngx-autosize';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsComponent } from './details/settings/settings.component';
import { ViewComponent } from './details/view/view.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { WorkspaceCardComponent } from './details/workspace-layout/workspace-card/workspace-card.component';
import { WorkspaceListComponent } from './details/workspace-layout/workspace-list/workspace-list.component';
import { CopyWorkspacePopup } from '@feature/workspace/details/workspace-layout/copy-workspace-popup/copy-workspace-popup';
import { WorkspaceHeaderComponent } from '@feature/workspace/details/workspace-layout/workspace-header/workspace-header.component';
import { WorkspaceMenuComponent } from '@feature/workspace/details/workspace-layout/workspace-menu/workspace-menu.component';
import { WorkspaceAddSectionComponent } from '@feature/workspace/details/workspace-layout/workspace-add-section/workspace-add-section.component';
import { WorkspaceSectionComponent } from '@app/feature/workspace/details/workspace-layout/workspace-section/workspace-section.component';
import { WorkspaceSidebarComponent } from '@feature/workspace/details/workspace-layout/workspace-sidebar/workspace-sidebar.component';
import { WorkspaceRequestComponent } from '@feature/workspace/details/workspace-layout/workspace-request/workspace-request.component';
import { FileRequestComponent } from '@feature/workspace/details/workspace-layout/workspace-request/file-request/file-request.component';
import { ContentItemComponent } from '@feature/workspace/details/workspace-layout/workspace-request/content-item/content-item.component';
import { OpenQuestionComponent } from '@feature/workspace/details/workspace-layout/workspace-request/open-question/open-question.component';
import { CheckboxQuestionComponent } from '@feature/workspace/details/workspace-layout/workspace-request/checkbox-question/checkbox-question.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WorkspaceFilesComponent } from '@feature/workspace/details/workspace-layout/workspace-files/workspace-files.component';
import { SelectTemplatePopupComponent } from './details/workspace-layout/select-template-popup/select-template-popup';
import { WorkspaceFilterComponent } from './details/workspace-layout/workspace-filter/workspace-filter.component';
import { AddTextComponent } from './details/workspace-layout/workspace-request/add-text/add-text.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { SectionContainerComponent } from './details/workspace-layout/section-container/section-container.component';
import { WorkspaceStatisticsComponent } from './details/workspace-layout/workspace-statistics/workspace-statistics.component';
import { WorkspaceSearchComponent } from './details/workspace-layout/workspace-search/workspace-search.component';
import { WorkflowRelatePopupComponent } from './details/workspace-layout/workspace-workflow/workflow-relate-popup/workflow-relate-popup.component';
import { WorkspaceMultiStatus } from '@feature/workspace/details/workspace-layout/workspace-multi-status/workspace-multi-status.component';
import { WorkflowShowDirective } from '@feature/workspace/details/workspace-layout/workspace-workflow/workflow-show.directive';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  declarations: [
    WorkspaceCardComponent,
    WorkspaceListComponent,
    WorkspaceStatisticsComponent,
    WorkspaceSearchComponent,
    FileRequestComponent,
    ContentItemComponent,
    OpenQuestionComponent,
    CheckboxQuestionComponent,
    WorkspaceRequestComponent,
    SelectTemplatePopupComponent,
    CopyWorkspacePopup,
    WorkspaceHeaderComponent,
    WorkspaceMenuComponent,
    WorkspaceAddSectionComponent,
    WorkspaceSectionComponent,
    WorkspaceFilesComponent,
    WorkspaceFilterComponent,
    WorkspaceSidebarComponent,
    WorkspaceMultiStatus,
    SectionContainerComponent,
    DetailsComponent,
    WorkspaceComponent,
    SettingsComponent,
    ViewComponent,
    AddTextComponent,
    WorkflowRelatePopupComponent,
    WorkflowShowDirective,
  ],
  exports: [
    SelectTemplatePopupComponent,
    CopyWorkspacePopup,
    WorkspaceCardComponent,
    WorkspaceListComponent,
    WorkspaceHeaderComponent,
    WorkspaceMenuComponent,
    WorkspaceAddSectionComponent,
    WorkspaceSectionComponent,
    WorkspaceFilesComponent,
    WorkspaceSidebarComponent,
    WorkspaceFilterComponent,
    SectionContainerComponent,
    WorkspaceStatisticsComponent,
    WorkspaceSearchComponent,
    WorkspaceMultiStatus,
    WorkflowRelatePopupComponent,
    WorkflowShowDirective,
  ],
  imports: [
    PortalModule,
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AutosizeModule,
    TranslateModule,
    NgSelectModule,
    DragDropModule,
    TextFieldModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WorkspaceModule {}
