import { Component } from '@angular/core';
import { TitleService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { PermissionType } from '@core/models';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  public permissionTypes = PermissionType;

  constructor(public titleService: TitleService, private translate: TranslateService) {
    this.translateTitle();
    translate.onLangChange.subscribe(() => this.translateTitle());
  }

  translateTitle(): void {
    this.titleService.set(this.translate.instant('Profile'));
  }
}
