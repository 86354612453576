import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContentItem, FileRequest } from '../models';

export interface Preview {
  contentItems: ContentItem[];
  fileRequest?: FileRequest;
  fileIndex?: number;
}

@Injectable({ providedIn: 'root' })
export class PreviewService {
  contextChange: BehaviorSubject<Preview> = new BehaviorSubject<Preview>(undefined);

  close(): void {
    this.contextChange.next(undefined);
  }

  set(contentItems: ContentItem[], fileRequest?: FileRequest, fileIndex?: number): void {
    this.contextChange.next({ contentItems, fileRequest, fileIndex });
  }
}
