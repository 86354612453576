import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytesFilter',
})
export class FormatBytesFilterPipe implements PipeTransform {
  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  transform(bytes: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = 0; // nr of decimals.
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + this.sizes[i];
  }
}
