export class SearchMemberResult {
  constructor(
    email: string,
    id: string,
    alreadyShared = false,
    fullName: string,
    isContact = false,
    avatarInfo?: { avatar: string; avatarColor: string },
    additional?: { companyName?: string; teamName?: string },
    userId?: string
  ) {
    this.id = id;
    this.email = email;
    this.disabled = alreadyShared;
    this.isSelected = alreadyShared;
    this.fullName = fullName;
    this.isContact = isContact;
    this.avatarInfo = avatarInfo;
    this.additional = additional;
    this.userId = userId;
  }

  isContact: boolean;
  fullName: string;
  id: string;
  isInFilter = true;
  isSelected: boolean;
  email: string;
  disabled: boolean;
  avatarInfo: { avatar: string; avatarColor: string };
  additional: { companyName?: string; teamName?: string };
  userId: string;
}

export class SearchResultGroup {
  private readonly initCallBack: (group: SearchResultGroup) => void;
  id: string;
  disabled: boolean;

  constructor(
    name: string,
    searchFilterType: SearchFilterType = SearchFilterType.ByGroupName,
    members: SearchMemberResult[] = [],
    searchResultGroupType: SearchResultGroupType,
    initCallback: (group: SearchResultGroup) => void = null,
    id = ''
  ) {
    this.name = name;
    this.searchFilterType = searchFilterType;
    this.searchResultGroupType = searchResultGroupType;
    this.members = members;
    this.initCallBack = initCallback;
    this.id = id;
  }

  searchResultGroupType: SearchResultGroupType;

  isSelected: boolean;
  name: string;
  searchFilterType: SearchFilterType;
  members: SearchMemberResult[];
  isInFilter = false;

  async init(): Promise<any> {
    if (this.initCallBack !== null) {
      await this.initCallBack(this);
    }
  }
}

export enum SearchFilterType {
  ByGroupName,
  ByGroupMembers,
  Both,
}

export enum SearchResultGroupType {
  Organization = 'Organization',
  Relation = 'Relation',
  Team = 'Team',
  User = 'User',
}
