import { Injectable } from '@angular/core';
import { ContentItem, GetUploadUrlResponse, IContentItemDto, SectionItem } from '@core/models';
import { environment } from '@env/environment';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private baseUrl = `${environment.apiBaseUrl}/content`;

  constructor(private http: HttpClient) {}

  get(contentItemId: string): Promise<ContentItem> {
    return this.http.get<IContentItemDto>(`${this.baseUrl}/${contentItemId}`).then((ci) => {
      return new ContentItem(ci);
    });
  }

  confirmUploadSuccessful(workspaceId: string, contentItemId: string): Promise<any> {
    return this.http.put(`${this.baseUrl}/${workspaceId}/${contentItemId}`, null);
  }

  getUploadUrl(
    workspaceId: string,
    filename: string,
    publicAvailable = false,
    parent: SectionItem = undefined
  ): Promise<GetUploadUrlResponse> {
    const parentId = parent?.sectionId;
    const permissionType = parent?.permissionType;
    return this.http
      .get<GetUploadUrlResponse>(
        `${this.baseUrl}/getuploadurl/${workspaceId}/${filename}${
          publicAvailable ? '?publicAvailable=true' : ''
        }`
      )
      .then(({ uploadUrl, contentItem }) => ({
        uploadUrl,
        contentItem: new ContentItem({ ...contentItem, parentId, permissionType }, false),
      }));
  }

  getDownloadUrl(workspaceId: string, filename: string): Promise<any> {
    return this.http.getText(`${this.baseUrl}/download/url/${workspaceId}/${filename}`);
  }
}
