import { Component } from '@angular/core';
import { TitleService } from '@core/services';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent {
  constructor(titleService: TitleService) {
    titleService.set('Support');
  }
}
