import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.scss'],
})
export class ProgressChartComponent implements AfterViewInit, OnChanges {
  @Input() statistics;
  @Input() round = false;
  @Input() total: number;

  @ViewChild('barChart') barChart: ElementRef;
  @ViewChild('roundChart') roundChart: ElementRef;
  @ViewChild('chartWrapper') chartWrapper: ElementRef;

  externalTooltip = (context) => {
    let divs = context.chart.canvas.parentElement.querySelectorAll('.message-container'),
      i;
    for (i = 0; i < divs.length; ++i) {
      divs[i].classList.remove('active');
    }

    const labelClass = '.' + context.tooltip.title[0];
    const labelToShow = context.chart.canvas.parentElement.querySelector(labelClass);
    if (labelToShow) {
      labelToShow.classList.add('active');
    }
  };

  chart: any;
  config: any = {
    type: 'pie',
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ['#78d6ab', '#e75449', '#ffc674', '#4FCCFF', '#b7b7b7'],
          hoverOffset: 1,
          borderWidth: 0,
        },
      ],
    },
    options: {
      aspectRatio: 1,
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this.externalTooltip,
        },
      },
    },
  };

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.round) {
      this.createChart();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.detectChanges();
    if (this.round) {
      this.createChart();
    }
  }

  hideAll() {
    let divs = this.chartWrapper.nativeElement.querySelectorAll('.message-container'),
      i;
    for (i = 0; i < divs.length; ++i) {
      divs[i].classList.remove('active');
    }
  }

  activateBar(name) {
    const classTarget = '.message-container.' + name;
    this.chartWrapper.nativeElement.querySelector(classTarget).classList.add('active');
  }

  createChart() {
    if (this.statistics[0]?.series && this.total > 0) {
      this.config.data.labels = this.statistics[0]?.series.map((point) => point.name);
      this.config.data.datasets[0].data = this.statistics[0]?.series.map((point) => point.value);
    } else {
      return;
    }

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(this.roundChart.nativeElement, this.config);
  }
}
