<div [class.active]="active" [class.mobile-active]="mobileActive" class="chat-overlay">
  <div class="chat-overlay__header">
    <div (click)="close()" class="close-button">
      <span class="material-icons-outlined">close</span>
    </div>
    <h3>
      {{ fileRequest.name }}
    </h3>
    <p class="info" *ngIf="uploadedBy && uploadedDate && isOwner">
      {{ 'added-by' | translate }} {{ uploadedBy }} {{ 'on' | translate }}
      {{ uploadedDate | date : 'dd-MM-yyyy' }}
    </p>

    <p *ngIf="fileRequest?.description" class="description">{{ fileRequest.description }}</p>
  </div>
  <ng-content></ng-content>

  <div (click)="toggleShow()" [class.active]="showMessages" class="message-header">
    {{ 'comments-header' | translate }}
    <span class="material-icons-outlined">expand_more</span>
  </div>
  <div #messagePart [class.active]="showMessages" class="message-part">
    <div *ngIf="messages" class="messages">
      <p *ngIf="!messages.length" class="no_messages">{{ 'No messages' | translate }}</p>
      <div #messageRow class="message-row">
        <app-message
          (refreshList)="readMessages()"
          *ngFor="let message of messages"
          [canEdit]="canSendMessages"
          [message]="message"
          [ownEmail]="ownEmail"></app-message>
      </div>
    </div>

    <div *ngIf="canSendMessages" class="add-message">
      <span class="material-icons-outlined chat-icon">chat_bubble_outline</span>
      <textarea
        #messageArea
        (keydown)="inputTextHeight()"
        (keydown.enter)="enterMessage($event)"
        (keyup)="inputTextHeight()"
        (ngModelChange)="message = $event; isEmptyOrSpacesPostMessage(message)"
        [ngModel]="message"
        autofocus="true"
        placeholder="{{ 'message' | translate }}"
        rows="1"></textarea>
      <button
        (click)="postMessage()"
        [disabled]="postMessageDisabled"
        class="btn-secondary add-message-btn">
        <span class="material-icons">send</span>
      </button>
    </div>
  </div>
</div>
