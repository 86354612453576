import { ItemShare } from '@core/models/share.model';
import { FileRequest, IFileRequestDto } from './filerequest.model';
import {
  ISectionItem,
  ItemPermissionType,
  ItemStatusType,
  SectionItem,
  SectionItemType,
} from './sectionitem.model';
import { ISectionDto, Section } from './section.model';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ContentItem } from './content.item.model';
import { UserAccount, UserInfo } from './account.model';
import { MultipleChoiceItem, Question, QuestionType } from '@core/models/question.model';
import { Organization } from './organization.model';

export interface IWorkspaceBaseDto {
  id: string;
  expiryDate: Date;
  name: string;
  isActive: boolean;
  description: string;
  workspaceSettings: WorkspaceSettings;
  shares: ItemShare[];
  requestContentItemStatus: ItemStatusType;
  permissionType: ItemPermissionType;
  isTemplate: boolean;
  creatorId: string;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
}

export interface IWorkspaceDto extends IWorkspaceBaseDto {
  sections: ISectionDto[];
}

export interface IWorkspaceListDto extends IWorkspaceBaseDto {
  requestContentItems: IFileRequestDto[];
}

export class Workspace implements IWorkspaceBaseDto {
  id: string;
  name: string;
  isActive: boolean;
  description: string;
  workspaceSettings: WorkspaceSettings;
  shares: ItemShare[];
  siShares: ItemShare[] = [];
  requestContentItemStatus: ItemStatusType;
  creatorId: string;

  // client side properties
  creator: UserInfo;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
  permissionType: ItemPermissionType;
  isTemplate: boolean;
  private allSections: Section[];
  private _allRcis: FileRequest[];

  // Cached organization to determine whether notifications should be sent
  public organization?: Organization;

  constructor(workspace: IWorkspaceDto) {
    const { sections, shares, ...rest } = workspace;
    Object.assign(this, rest);
    const allShares = shares.map((share) => new ItemShare(share));
    this.shares = allShares.filter((share) => !share.sectionItemId);
    this.siShares = allShares.filter((share) => share.sectionItemId);
    this.allSections = sections.map((section) => new Section(section));
    const findSectionItem = (si: SectionItem): SectionItem =>
      this.allSections.find((section) => section.id === si.id) || si;
    this.allSections.forEach((section) => (section.items = section.items.map(findSectionItem)));
  }

  private _currentSectionId: string;

  get currentSectionId(): string {
    return this._currentSectionId ?? this.rootSection?.id;
  }

  set currentSectionId(value: string) {
    this._currentSectionId = value;
  }

  get expiryDate(): Date {
    return this.workspaceSettings?.workspaceExpiryDate && !this.isTemplate
      ? new Date(this.workspaceSettings.workspaceExpiryDate)
      : undefined;
  }

  get showExpire(): boolean {
    return this.workspaceSettings.workspaceRetentionInDays !== -1;
  }

  get aboutToExpire(): boolean {
    const now = new Date();
    return (
      this.workspaceSettings.workspaceRetentionInDays !== -1 &&
      this.expiryDate?.getTime() <= now.setDate(now.getDate() + 7)
    );
  }

  get containsUnapprovedRcis(): boolean {
    return this.allRcis.some((rci) => rci.status !== ItemStatusType.Approved);
  }

  get isEmpty(): boolean {
    return this.rootSection?.items.length === 0;
  }

  get rootSection(): Section {
    return this.allSections.find((section) => !section.parentId);
  }

  get allContentItems(): ContentItem[] {
    return filter<ContentItem>(this.allItems, SectionItemType.ContentItem);
  }

  get contentItems(): ContentItem[] {
    return filter<ContentItem>(this.items, SectionItemType.ContentItem);
  }

  get allWorkflowAnswers(): MultipleChoiceItem[] {
    return filter<Question>(this.allItems, SectionItemType.Question)
      .filter((q) => q.questionType === QuestionType.MultipleChoice)
      .flatMap((question) => question.value)
      .filter((item) => item.workflowId);
  }

  get hasUploads(): boolean {
    const rcis = this.allRcis;
    return rcis.some((rci) => rci.hasContentItems) || this.allContentItems.length > 0;
  }

  get allRcis(): FileRequest[] {
    return this._allRcis || filter<FileRequest>(this.allItems, SectionItemType.FileRequest);
  }

  set allRcis(value: FileRequest[]) {
    this._allRcis = value;
  }

  get allQuestions(): Question[] {
    return filter<Question>(this.allItems, SectionItemType.Question);
  }

  get currentSection(): Section {
    return this.getSection(this.currentSectionId);
  }

  get items(): SectionItem[] {
    return this.currentSection?.items || [];
  }

  get selectedItems(): SectionItem[] {
    return this.allSections
      .map((section) => section.items)
      .flat()
      .filter((u) => u.selected);
  }

  get allVisibleSelected(): boolean {
    return this.items.filter((u) => !u.selected).length === 0;
  }

  get allItems(): SectionItem[] {
    return this.allSections.map((section) => section.items).flat();
  }

  get itemsSorted(): SectionItem[] {
    const items = this.items.filter((i) => i.type !== SectionItemType.ContentItem);
    const files = this.items.filter((i) => i.type === SectionItemType.ContentItem);
    return items.concat(files);
  }

  get sections(): Section[] {
    return filter<Section>(this.items, SectionItemType.Section);
  }

  get defaultShares(): ItemShare[] {
    return this.shares.filter((share) => share.isDefaultShare);
  }

  get rcis(): FileRequest[] {
    return this.sections
      .map((section) => filter<FileRequest>(section.items))
      .flat() as FileRequest[];
  }

  get canEdit(): boolean {
    return (
      this.currentSection?.permissionType === ItemPermissionType.ReadWrite ||
      this.currentSection?.permissionType === ItemPermissionType.Owner
    );
  }

  get containsOwnedSections(): boolean {
    return this.currentSection.isOwner || this.sections.some((section) => section.isOwner);
  }

  get isOwner(): boolean {
    return this.permissionType === ItemPermissionType.Owner;
  }

  isOwnedByOrganization(organizationId: string): boolean {
    return this.isOwner && this.creator.organizationId === organizationId;
  }

  getSectionItem<TSectionItemType>(id: string): TSectionItemType {
    return this.getSectionItems().find((item) => item.id === id) as TSectionItemType;
  }

  getSectionItems(): ISectionItem[] {
    const items = this.allSections.map((section) => section.items).flat();
    const parent = this.getSection(this.currentSectionId);
    return [...items, ...parent.items];
  }

  getSection(id: string): Section {
    return this.allSections.find((section) => section.id === id);
  }

  isLocked = (item?: ISectionItem): boolean => {
    return (
      (!this.isOwner && this.requestContentItemStatus !== ItemStatusType.Unknown) ||
      (!this.isOwner && item.status === ItemStatusType.Approved) ||
      this.requestContentItemStatus === ItemStatusType.Approved ||
      this.requestContentItemStatus === ItemStatusType.Archived
    );
  };

  focusItem(itemToFocusId: string): void {
    const itemToFocus = this.getSectionItem<SectionItem>(itemToFocusId);
    if (itemToFocus) itemToFocus.focused = true;
  }

  removeSections(condition: (section: SectionItem) => boolean): void {
    this.allSections = this.allSections.filter(condition);
    this.allSections.forEach((section) => {
      section.items = section.items.filter(condition);
    });
  }

  getParent(itemId: string): Section {
    return this.allSections.find((s) => s.items.find((item) => item.id === itemId));
  }

  getPath(item: SectionItem, useFileRequestNames: boolean, path: string = undefined): string {
    const rci = this.allRcis.find((r) => r.getContentItem(item.id));
    path = path ?? rci?.getFilename(item, useFileRequestNames) ?? item.name;
    if (rci) item = rci;
    const parent = this.getParent(item.id);
    if (parent?.parentId) {
      const cappedName = parent.name.substring(0, 255);
      return this.getPath(parent, useFileRequestNames, `${cappedName}/${path}`);
    }
    return path;
  }

  removeSectionItem(itemId: string): void {
    const section = this.getParent(itemId);
    if (section) {
      section.removeSectionItem(itemId);
    } else {
      const fr = this.allRcis.find((r) => r.getContentItem(itemId));
      if (fr) fr.removeContentItem(itemId);
    }
  }

  insertSectionItem(item: SectionItem, parent: SectionItem, index = -1): void {
    if (item.type === SectionItemType.Section) {
      this.allSections.push(item as Section);
    }
    parent.insertSectionItem(item, index);
    this.focusItem(item.id);
  }

  moveSectionItem(item: SectionItem, fromIndex: number, toIndex: number): void {
    const section = this.getParent(item.id);
    moveItemInArray(section.items, fromIndex, toIndex);
  }

  transferSectionItem(
    fromSectionId: string,
    toSectionId: string,
    fromIndex: number,
    toIndex: number
  ): void {
    const fromSection = this.getSection(fromSectionId);
    const toSection = this.getSection(toSectionId);
    if (fromSection && toSection) {
      transferArrayItem(fromSection.items, toSection.items, fromIndex, toIndex);
    }
  }

  canShare(currentUser: UserAccount): boolean {
    return (
      this.isOwner ||
      (currentUser?.organizationId &&
        this.creator?.organizationId &&
        this.creator?.organizationId === currentUser?.organizationId)
    );
  }
}

export function filter<TType>(items: SectionItem[], ...types: SectionItemType[]): TType[] {
  return items.filter((section) => types.includes(section.type)) as TType[];
}

export interface WorkspaceSettings {
  workspaceExpiryDate: Date;
  deliveryDeadline: Date;
  allowNonTemplateFiles: boolean;
  emailOnPendingExpiration: boolean;
  emailOnFilesRequired: boolean;
  emailOnWorkspaceCompleted: boolean;
  workspaceId: string;
  workspaceRetentionInDays?: number;
  deliveryDeadlineInDays?: number;
}

export class WorkspaceCreationRequest {
  name: string;
  description: string;
  sourceTemplateId?: string;
  sourceWorkspaceId?: string;
  isTemplate?: boolean;
}

export interface WorkspaceUpdateRequest {
  newName?: string;
  newDescription?: string;
  workspaceSettings?: WorkspaceSettings;
  workspaceStatus?: ItemStatusType;
  templateIdToAdd?: string;
}

export interface IMoveToParentRequest {
  sectionItemId?: string;
  parentSection?: string;
  insertAt?: number;
  type: SectionItemType;
}

export interface TemplateRequest {
  workspaceId?: string;
  sectionId?: string;
  templateId?: string;
  insertAt?: number;
}

export interface WorkspaceWithFrs {
  id?: string;
  requestContentItems: IFileRequestDto[];
}
