import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Workspace } from '@app/core/models';
import { NotificationService, WorkspaceService } from '@app/core/services';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';

@Component({
  selector: 'select-template-popup',
  templateUrl: './select-template-popup.html',
})
export class SelectTemplatePopupComponent {
  isAddingTemplate: boolean;

  @Input() append: boolean;
  @Input() public position: number;
  @ViewChild('selectTemplateForWorkspacePopup')
  selectTemplateForWorkspacePopup: ModalPopupComponent;

  get workspace(): Workspace {
    return this.workspaceService.currentWorkspace;
  }

  constructor(
    private workspaceService: WorkspaceService,
    private notifyService: NotificationService,
    private route: ActivatedRoute
  ) {}

  get parentSectionId(): string {
    const sections = this.route.snapshot.queryParamMap.getAll('sections');
    return sections[sections.length - 1] ?? this.workspace.rootSection?.id;
  }

  async addTemplate(template: Workspace): Promise<void> {
    this.selectTemplateForWorkspacePopup.popupActive = false;
    const insertAt = this.position;
    await this.workspaceService.addTemplate(
      this.workspace.id,
      this.parentSectionId,
      template.id,
      insertAt
    );

    this.isAddingTemplate = true;

    await this.workspaceService.refreshWorkspace();
    this.isAddingTemplate = false;

    this.notifyService.showSuccess('Template added', undefined, {
      workspaceType: this.workspace.isTemplate ? 'template' : 'workspace',
    });
  }

  open(): void {
    this.selectTemplateForWorkspacePopup.popupActive = true;
  }
}
