import { Component } from '@angular/core';
import { WorkspaceUiStateService } from '@app/core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-workspace-files',
  templateUrl: './workspace-files.component.html',
  styleUrls: ['./workspace-files.component.scss'],
})
export class WorkspaceFilesComponent {
  constructor(private stateService: WorkspaceUiStateService) {}

  get isEdit(): Observable<boolean> {
    return this.stateService.isEdit;
  }
}
