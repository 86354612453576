import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent {
  dragAreaClass: string;

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  public files: any[] = [];

  @Input() hideDropZone = false;

  @Input() uploading: boolean;

  @Output() uploadFiles: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  /**
   * on file drop handler
   */
  onFileDropped($event): void {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files): void {
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>): void {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = '';
    this.uploadFiles.emit(this.files);
  }

  changeClass(event: string): void {
    this.dragAreaClass = event;
  }
}
