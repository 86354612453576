import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent {
  @Input() private active = false;

  isActive(): boolean {
    return this.active;
  }

  open(): void {
    this.active = true;
  }

  close(): void {
    this.active = false;
  }
}
