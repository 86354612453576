import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  ContentService,
  ContextMenuItem,
  NotificationService,
  WorkspaceService,
  WorkspaceUiStateService,
} from '@core/services';

import { MultipleChoiceItem, Question, UpdateQuestionRequest, Workspace } from '@core/models';
import { QuestionService } from '@app/core/services/api/question.service';
import { ISectionItemComponent } from '../workspace-request.component';
import {
  answerIndex,
  closeDialog,
  confirmDialog,
  question,
  WorkflowLink,
  WorkflowRelatePopupComponent,
} from '@feature/workspace/details/workspace-layout/workspace-workflow/workflow-relate-popup/workflow-relate-popup.component';
import { WorkflowUpdateService } from '@feature/workspace/details/workspace-layout/workspace-workflow/workflow-update.service';
import { TitleInputComponent } from '@app/shared/title-input/title-input.component';
import { Observable } from 'rxjs';
import { ComponentPortal, Portal } from '@angular/cdk/portal';

@Component({
  selector: 'app-checkbox-question',
  templateUrl: './checkbox-question.component.html',
  styleUrls: ['./checkbox-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxQuestionComponent implements ISectionItemComponent, OnInit {
  @Input() question: Question;

  @Output() removeSectionItem: EventEmitter<Question> = new EventEmitter<Question>();
  @Output() checkboxChange = new EventEmitter<MultipleChoiceItem>();
  @Output() sendFocusInput = new EventEmitter<MultipleChoiceItem>();

  @ViewChild('checkboxList') checkboxList: ElementRef;
  @ViewChildren('optionInput') options: QueryList<TitleInputComponent>;

  portal: Portal<any>;
  relatePopupComponent: ComponentPortal<WorkflowRelatePopupComponent>;

  justCreated = false;
  menu: ContextMenuItem[] = [];
  values: MultipleChoiceItem[] = [];
  selectedIndex = 0;

  get isEdit(): Observable<boolean> {
    return this.stateService.isEdit;
  }

  constructor(
    private requestQuestionItemService: QuestionService,
    protected workspaceService: WorkspaceService,
    private stateService: WorkspaceUiStateService,
    protected contentService: ContentService,
    private workflowUpdateService: WorkflowUpdateService,
    private notificationService: NotificationService,
    private injector: Injector
  ) {}

  get labelClass(): string {
    return this.question.properties.type === 'radio' ? 'radiocontainer' : 'checkboxcontainer--new';
  }

  get checkmarkClass(): string {
    return this.question.properties.type === 'radio' ? 'radiomark' : 'checkmark--new';
  }

  get anyChecked(): boolean {
    if (this.question.properties?.otherOption.checked) {
      return true;
    } else {
      return this.question.value.some((item) => item.checked);
    }
  }

  get workspace(): Workspace {
    return this.workspaceService.currentWorkspace;
  }

  ngOnInit(): void {
    this.question.value = this.question.value || [];
    if (!this.question.properties) {
      this.question.properties = {};
    }

    if (!this.question.properties.otherOption) {
      this.question.properties.otherOption = { enabled: false, isOtherOption: true };
    }
    this.values = this.getValues();
  }

  onFocus(): void {
    this.sendFocusInput.emit();
  }

  hasFocus(item: MultipleChoiceItem): boolean {
    if (this.justCreated) {
      return false;
    } else {
      return document.activeElement.getAttribute('id') === 'checkboxLabel' && this.isLast(item);
    }
  }

  isLast(item: MultipleChoiceItem): boolean {
    return this.values.indexOf(item) === this.values.length - 1;
  }

  async update(request: UpdateQuestionRequest): Promise<void> {
    this.values = this.getValues();
    await this.requestQuestionItemService.update(this.question, request);
    this.workflowUpdateService.updateWorkflow();
  }

  getValues(): MultipleChoiceItem[] {
    //reorder so that otherOption is always last
    const otherOption = this.question.value.find((item) => item.isOtherOption);
    const values = this.question.value.filter((item) => !item.isOtherOption);
    if (otherOption) {
      values.push(otherOption);
    } else if (this.question.properties.otherOption.enabled) {
      values.push(this.question.properties.otherOption);
    }

    return [...values, { name: '', checked: false }];
  }

  async toggleOtherOption(): Promise<void> {
    this.question.properties.otherOption.enabled = !this.question.properties.otherOption.enabled;
    this.values = this.getValues();
    if (this.question.properties.otherOption.enabled) {
      this.question.value.push(this.question.properties.otherOption);
    } else {
      this.question.value = this.question.value.filter((item) => !item.isOtherOption);
    }
    await this.update({ properties: this.question.properties });
  }

  titleEnter(): void {
    this.options.last?.textField.nativeElement.focus();
  }

  focusNext(item: MultipleChoiceItem): void {
    const nodes = this.options.toArray();
    const current = nodes.find((node) => node.item === item);
    const index = nodes.indexOf(current);
    nodes[index + 1]?.textField.nativeElement.focus();
  }

  async updateOther(item: MultipleChoiceItem): Promise<void> {
    this.values = this.getValues();
    this.question.properties.otherOption = {
      isOtherOption: true,
      enabled: true,
      name: item.name,
      workflowId: item.workflowId,
      checked: item.checked,
    };

    if (this.question.properties.type === 'radio' && item.checked)
      this.question.value = this.question.value.map((q, i) => ({
        ...q,
        checked: false,
      }));
    await this.update({ properties: this.question.properties, value: this.question.value });
  }

  async changeRadio(index: number): Promise<void> {
    this.question.value = this.question.value.map((q, i) => ({ ...q, checked: i === index }));
    this.question.properties.otherOption = {
      ...this.question.properties.otherOption,
      checked: false,
    };
    await this.update({ properties: this.question.properties, value: this.question.value });
  }

  async removeItem(index: number, otherOption: boolean): Promise<void> {
    if (otherOption) {
      await this.toggleOtherOption();
    } else {
      this.question.value.splice(index, 1);
    }
    await this.update({ value: this.question.value });
  }

  activateRelatePopup(index: number): void {
    this.portal = new ComponentPortal(
      WorkflowRelatePopupComponent,
      null,
      Injector.create({
        parent: this.injector,
        providers: [
          { provide: answerIndex, useValue: index },
          { provide: question, useValue: this.question },
          { provide: confirmDialog, useValue: this.onConfirmDialog.bind(this) },
          { provide: closeDialog, useValue: () => this.portal.detach() },
        ],
      })
    );
  }

  async onConfirmDialog(link: WorkflowLink): Promise<void> {
    this.portal.detach();
    if (this.question.parentId === link.workflowId || this.question.id === link.workflowId) {
      return;
    }

    const hasCycle = this.workflowUpdateService.detectCycle(this.question, link.workflowId);
    if (hasCycle) {
      this.notificationService.showError('Circular reference detected');
    } else {
      this.question.value[link.answer].workflowId = link.workflowId;
      await this.update({ value: this.question.value });
    }
  }

  async addItem(item: MultipleChoiceItem, createdCheck = false): Promise<void> {
    const { name, checked } = item;
    if (!name) return;
    if (!this.isLast(item)) return this.focusNext(item);

    if (createdCheck) this.justCreated = true;
    this.question.value.push({ name, checked });
    await this.update({ value: this.question.value });
    this.justCreated = false;
  }

  addOrUpdate(item: MultipleChoiceItem): Promise<void> {
    if (this.isLast(item)) {
      return this.addItem(item, true);
    } else {
      return this.update({ value: this.question.value });
    }
  }

  async removeRequest(): Promise<void> {
    this.removeSectionItem.emit(this.question);
    await this.requestQuestionItemService.delete(this.question);
  }

  async updateQuestion(updateRequest: UpdateQuestionRequest): Promise<void> {
    if (this.question.label.length) {
      await this.requestQuestionItemService.update(this.question, updateRequest);
    }
  }
}
