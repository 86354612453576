import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LeaveGuard } from '@core/guards';
import { RelationsDetailsComponent } from '@feature/settings/relations/details/relations-details.component';
import { UsersDetailsComponent } from '@feature/settings/users/details/users-details.component';
import { TeamsDetailComponent } from '@feature/settings/teams/detail/teams-detail.component';
import { RolesDetailComponent } from '@feature/settings/roles/detail/roles-detail.component';
import { RelationsComponent } from '@feature/settings/relations/relations.component';
import { UsersComponent } from '@feature/settings/users/users.component';
import { TeamsComponent } from '@feature/settings/teams/teams.component';
import { RolesComponent } from '@feature/settings/roles/roles.component';
import { AuditlogsComponent } from '@feature/settings/auditlogs/auditlogs.component';
import { SupersSettingsComponent } from '@feature/settings/supers-settings/supers-settings.component';
import { OrganizationDetailsComponent } from '@feature/settings/supers-settings/details/organization-details.component';
import { DiscountDetailsComponent } from '@feature/settings/supers-settings/details/organization-discounts/discount-details/discount-details.component';

const routes: Routes = [
  { path: '', component: RelationsComponent, canActivate: [AuthGuard] },
  {
    path: 'relations',
    component: RelationsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'relations/:id',
    component: RelationsDetailsComponent,
    data: {
      breadcrumb: '',
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    canDeactivate: [LeaveGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'users/:id',
    component: UsersDetailsComponent,
    data: {
      breadcrumb: '',
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'teams',
    component: TeamsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'teams/:id',
    component: TeamsDetailComponent,
    data: {
      breadcrumb: '',
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'roles',
    component: RolesComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'roles/:id',
    component: RolesDetailComponent,
    data: {
      breadcrumb: '',
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'supersettings',
    component: SupersSettingsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'supersettings/:id',
    component: OrganizationDetailsComponent,
    runGuardsAndResolvers: 'always',
    data: {
      breadcrumb: '',
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'supersettings/:organizationId/:discountId',
    component: DiscountDetailsComponent,
    runGuardsAndResolvers: 'always',
    data: {
      breadcrumb: '',
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'auditlogs',
    component: AuditlogsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
