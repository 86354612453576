import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { PermissionType, Role } from '@core/models';
import { RoleService, TitleService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LeaveGuardService } from '@core/services/leave-guard.service';

@Component({
  selector: 'app-roles-detail',
  templateUrl: './roles-detail.component.html',
  styleUrls: ['./roles-detail.component.scss'],
})
export class RolesDetailComponent implements OnInit {
  @ViewChild('deleteTeamPopup') deleteRolePopup: ModalPopupComponent;
  @ViewChild('warnAboutChangePagePopup')
  warnAboutChangePagePopup: ModalPopupComponent;

  @ViewChild('roleNameInput') roleNameInput: ElementRef;
  private readonly roleId: string;
  roles: Role[];
  rolesNames = [];
  duplicateName = '';
  public permissionTypes = PermissionType;

  constructor(
    private roleService: RoleService,
    private router: Router,
    private leaveGuardService: LeaveGuardService,
    private titleService: TitleService,
    private translate: TranslateService,
    route: ActivatedRoute
  ) {
    this.roleId = route.snapshot.paramMap.get('id');
  }

  get role(): Role {
    return this.roles?.find((r) => r.id === this.roleId);
  }

  async ngOnInit(): Promise<void> {
    this.leaveGuardService.subscribe(() => {
      this.warnAboutChangePagePopup.popupActive = true;
    });
    this.roles = await this.roleService.list();
    for (const role of this.roles) {
      if (role.id !== this.roleId) {
        this.rolesNames.push(role.name);
      }
    }
    if (this.role) {
      if (this.role.isSystemRole) {
        this.titleService.set(this.translate.instant(this.role.name));
      } else {
        this.titleService.set(this.role.name);
      }
    }

    this.leaveGuardService.setStatusMayLeave();

    const newRole = localStorage.getItem('newRole');
    if (newRole) {
      localStorage.removeItem('newRole');
      setTimeout(() => {
        this.roleNameInput?.nativeElement.select();
      }, 300);
    }
  }

  confirmDeleteRole(): void {
    this.deleteRolePopup.popupActive = true;
  }

  getNewName(): void {
    const newName = this.role.name.replace(/[(]\d{1,3}[)]/, '').trim();
    let max = Math.max(
      ...this.rolesNames
        .filter((d) => d.startsWith(newName))
        .map((d) => {
          const match = /\((?<number>\d*)\)/gm.exec(d);
          return match ? parseInt(match[1]) : 0;
        })
    );

    this.duplicateName = `${newName} (${++max})`;
  }

  async deleteRole(): Promise<any> {
    this.leaveGuardService.setStatusMayLeave();
    await this.roleService.delete(this.role.id);
    this.deleteRolePopup.popupActive = false;
    this.roleService.rolesUpdated.emit(this.role);
    this.router.navigate([`/settings/roles`]);
  }

  async navigateDespiteWarning(): Promise<void> {
    this.warnAboutChangePagePopup.popupActive = false;
    this.role.name = this.duplicateName;
    await this.updateRole();
    this.leaveGuardService.setStatusMayLeave();
    await this.router.navigate([`/admin/roles`]);
  }

  async updateRole(): Promise<any> {
    if (this.rolesNames.indexOf(this.role.name.trim()) >= 0) {
      this.getNewName();
      this.leaveGuardService.setStatusMustStay();
      return;
    }
    await this.roleService.update(this.role.id, {
      roleName: this.role.name,
      description: this.role.description,
    });
    this.roleService.rolesUpdated.emit(this.role);
    this.titleService.set(this.role.name);
    this.leaveGuardService.setStatusMayLeave();
  }
}
