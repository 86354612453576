import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Workspace } from '@core/models';
import { UploadGuardService } from '../upload-guard.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uploadGuardService: UploadGuardService
  ) {}

  navigateToSection(workspace: Workspace, sectionId: string) {
    if (sectionId === workspace.rootSection?.id) return;
    const sections = this.route.snapshot.queryParamMap.getAll('sections');
    this.navigateTo({ sections: [...sections, sectionId] });
  }

  navigateTo(commands: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: commands,
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  async navigateToBlockedRoute() {
    this.uploadGuardService.setStatusToDone();
    await this.router.navigateByUrl(this.uploadGuardService.getLastBlockedRoute());
  }

  get sections() {
    return this.route.snapshot.queryParamMap.getAll('sections');
  }
}
