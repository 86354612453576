<div *ngIf="user && roles">
  <div class="section-header">
    <input
      [(ngModel)]="roleSearchText"
      class="searchBox"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>
  <section *ngIf="roles.length > 0">
    <table>
      <tr>
        <th>{{ 'Assigned' | translate }}</th>
        <th [appSort]="roles" class="sortable" data-name="name" scope="col">
          {{ 'Name' }}
          <app-sort-icon></app-sort-icon>
        </th>
      </tr>
      <tr *ngFor="let role of roles | roleFilter: roleSearchText">
        <td>
          <label class="checkboxcontainer">
            <input
              (change)="toggleRoleActiveForUser(role)"
              [checked]="checkRoleActiveForUser(role)"
              type="checkbox" />
            <span class="checkmark"></span>
          </label>
        </td>
        <td>
          <span *ngIf="!role.isSystemRole">{{ role.name }}</span>
          <span *ngIf="role.isSystemRole">{{ role.name | translate }}</span>
        </td>
      </tr>
    </table>
  </section>
</div>
