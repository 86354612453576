export interface Role {
  description: string;
  name: string;
  id: string;
  permissions: Permission[];
  isSystemRole: boolean;
}

export interface Permission {
  id: string;
  section: string;
  permissionType: PermissionType;
}

export enum PermissionType {
  Create = 'Create',
  Edit = 'Edit',
  Delete = 'Delete',
  Any = 'Any',
}

export interface CreateRoleRequest {
  roleName: string;
  description: string;
}

export interface UpdateRoleRequest {
  roleName: string;
  description: string;
}

export interface RolePermissionRequest {
  permissionId: string;
  roleId: string;
}
