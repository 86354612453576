import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AccountService, NotificationService, OrganizationService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { PermissionType, UserAccount } from '@core/models';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  user: UserAccount;
  organizationNames: string[];
  @ViewChild('createUserEmailInput') createUserEmailInput: ElementRef;
  public permissionTypes = PermissionType;

  constructor(
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
    public translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = new UserAccount();
    this.organizationNames = (await this.organizationService.list()).map(({ name }) => name);

    setTimeout(() => {
      this.createUserEmailInput.nativeElement.select();
    }, 300);
  }

  async addUser(): Promise<any> {
    const result = await this.accountService.post({
      id: this.user.id,
      firstname: this.user.firstname,
      languageCode: this.translate.defaultLang,
      lastname: this.user.lastname,
      email: this.user.email,
      organization: this.user.organization,
    });

    if (result.ok) {
      this.notificationService.showSuccess('SuccessfullyCreatedUser', 'CreatedNewUser');
      this.user = new UserAccount();
    }
  }
}
