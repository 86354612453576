<div *ngIf="discounts" class="section-header">
  <button (click)="addDiscount()" class="btn-primary">
    <span class="material-icons-outlined">person_add</span>
    {{ 'Add discount' | translate }}
  </button>
</div>

<div *ngIf="discounts">
  <section *ngIf="discounts.length > 0">
    <table>
      <tr>
        <th [appSort]="discounts" class="sortable" data-name="discountStartDate" scope="col">
          {{ 'Title' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th [appSort]="discounts" class="sortable" data-name="discountStartDate" scope="col">
          {{ 'Start date' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th [appSort]="discounts" class="sortable" data-name="discountEndDate" scope="col">
          {{ 'End date' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th [appSort]="discounts" class="sortable" data-name="licenseDiscountRate" scope="col">
          {{ 'License discount' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th [appSort]="discounts" class="sortable" data-name="userDiscountRate" scope="col">
          {{ 'User discount' | translate }}
          <app-sort-icon></app-sort-icon>
        </th>
        <th>{{ 'Actions' | translate }}</th>
      </tr>
      <tr
        (click)="goToDetails(discount.id)"
        *ngFor="let discount of discounts"
        class="table-row"
        click-stop-propagation>
        <td>{{ discount.title }}</td>
        <td>
          {{ discount.discountStartDate | date: 'mediumDate':'':translate.currentLang }}
        </td>
        <td>
          {{ discount.discountEndDate | date: 'mediumDate':'':translate.currentLang }}
        </td>
        <td>
          {{ discount.licenseDiscountRate }}
        </td>
        <td>
          {{ discount.userDiscountRate }}
        </td>
        <td>
          <button (click)="removeDiscount(discount.id)" click-stop-propagation>
            <span class="material-icons">delete_outline</span>
          </button>
        </td>
      </tr>
    </table>
  </section>
</div>
