<tabs>
  <tab
    [registrationUrl]="'Workspaces | list'"
    icon="all_inbox"
    iconClass="material-icons-outlined"
    tabTitle="{{ (showTemplates ? 'Templates' : 'Workspaces') | translate }}">
    <div *ngIf="workspaces" class="content-container workspace-list-container">
      <div
        *ngIf="
          workspaces.length > 0 &&
          (workspaces | workspaceStatusFilter : [statusTypes.Archived]).length !== workspaces.length
        "
        class="workspace-container-header">
        <div *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Workspaces' }]">
          <create-workspace-button
            (createWorkspace)="addNewWorkspace()"
            (createWorkspaceFromTemplate)="selectTemplateForWorkspacePopup.popupActive = true"
            [showTemplates]="showTemplates"
            [disabled]="quotaWorkspaces"></create-workspace-button>
          <app-upgrade-button *ngIf="quotaWorkspaces"></app-upgrade-button>
        </div>
        <div class="right-header-section">
          <div class="view-switch">
            <span
              (click)="selectView(false)"
              [ngClass]="isListView ? '' : 'active-icon'"
              class="material-icons click">
              view_module
            </span>
            <span
              (click)="selectView(true)"
              [ngClass]="isListView ? 'active-icon' : ''"
              class="material-icons click">
              list
            </span>
          </div>
          <input
            [(ngModel)]="searchText"
            class="searchBox"
            placeholder="{{ 'search' | translate }}"
            type="text" />
        </div>
      </div>

      <div
        *ngIf="
          workspaces.length === 0 ||
          (workspaces | workspaceStatusFilter : [statusTypes.Archived]).length === workspaces.length
        "
        class="empty-item-container">
        <h2 class="no-items-title">
          {{ (showTemplates ? 'No templates' : 'NoWorkspacesYet') | translate }}
        </h2>
        <div>
          <create-workspace-button
            (createWorkspace)="addNewWorkspace()"
            (createWorkspaceFromTemplate)="selectTemplateForWorkspacePopup.popupActive = true"
            [showTemplates]="showTemplates"
            [disabled]="
              workspaces.length >= quotaService.maxActiveWorkspaces
            "></create-workspace-button>
          <app-upgrade-button
            *ngIf="workspaces.length >= quotaService.maxActiveWorkspaces"></app-upgrade-button>
        </div>
        <app-empty-animation></app-empty-animation>
      </div>
      <div *ngIf="!isListView" class="workspace-list">
        <app-workspace-card
          (approveWorkspace)="approveWorkspace($event)"
          (archiveWorkspace)="openArchivePopup($event)"
          (deleteWorkspace)="openConfirmPopup($event)"
          (shareWorkspace)="shareWorkspace($event)"
          *ngFor="
            let workspace of workspaces
              | workspaceStatusFilter : [statusTypes.Unknown, statusTypes.Approved]
              | workspaceFilter : searchText
          "
          [workspace]="workspace"
          class="workspace-container-wrapper"></app-workspace-card>
      </div>
      <app-workspace-list
        (approveWorkspace)="approveWorkspace($event)"
        (archiveWorkspace)="listWorkspaces()"
        (removeWorkspace)="removeWorkspace($event)"
        (shareWorkspace)="shareWorkspace($event)"
        *ngIf="
          isListView &&
          workspaces.length !== 0 &&
          (workspaces | workspaceStatusFilter : [statusTypes.Archived]).length !== workspaces.length
        "
        [listViewWorkspaces]="
          workspaces
            | workspaceStatusFilter : [statusTypes.Unknown, statusTypes.Approved]
            | workspaceFilter : searchText
        "></app-workspace-list>
    </div>
  </tab>
  <tab
    [registrationUrl]="'Workspaces | archived'"
    icon="archive"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Archived' | translate }}">
    <div *ngIf="workspaces" class="content-container workspace-list-container">
      <div
        *ngIf="(workspaces | workspaceStatusFilter : [statusTypes.Archived]).length > 0"
        class="workspace-container-header right">
        <div class="right-header-section">
          <div class="view-switch">
            <span
              (click)="selectView(false)"
              [ngClass]="isListView ? '' : 'active-icon'"
              class="material-icons click">
              view_module
            </span>
            <span
              (click)="selectView(true)"
              [ngClass]="isListView ? 'active-icon' : ''"
              class="material-icons click">
              list
            </span>
          </div>
          <input
            [(ngModel)]="searchText"
            class="searchBox"
            placeholder="{{ 'search' | translate }}"
            type="text" />
        </div>
      </div>
      <div
        *ngIf="(workspaces | workspaceStatusFilter : [statusTypes.Archived]).length === 0"
        class="empty-item-container">
        <h2 class="no-items-title">{{ 'No archived workspaces' | translate }}</h2>
        <label class="no-items-subtitle">
          {{ 'Clean workspace' | translate }}
        </label>
        <app-empty-animation></app-empty-animation>
      </div>
      <div *ngIf="!isListView" class="workspace-list">
        <app-workspace-card
          (deleteWorkspace)="openConfirmPopup($event)"
          *ngFor="
            let workspace of workspaces
              | workspaceStatusFilter : [statusTypes.Archived]
              | workspaceFilter : searchText
          "
          [workspace]="workspace"
          class="workspace-container-wrapper"></app-workspace-card>
      </div>
      <app-workspace-list
        (approveWorkspace)="approveWorkspaceConfirm($event)"
        (removeWorkspace)="removeWorkspace($event)"
        *ngIf="
          isListView && (workspaces | workspaceStatusFilter : [statusTypes.Archived]).length !== 0
        "
        [listViewWorkspaces]="
          workspaces | workspaceStatusFilter : [statusTypes.Archived] | workspaceFilter : searchText
        "></app-workspace-list>
    </div>
  </tab>
</tabs>

<app-sharing-popup #shareWorkspacePopup [workspace]="focusedWorkspace"></app-sharing-popup>

<app-modal-popup
  #deleteWorkspacePopup
  (eventPrimaryButtonClick)="removeWorkspace(focusedWorkspace)"
  [popupTitle]="'DeleteWorkspaceTitle' | translate"
  [primaryButtonText]="'DeleteWorkspaceActionButton' | translate : interpolationParams">
  <div>{{ 'ConfirmWorkspaceDelete' | translate }}</div>
</app-modal-popup>

<app-modal-popup
  #existingWorkspacePopup
  (eventPrimaryButtonClick)="openExistingWorkspace()"
  (eventSecondaryButtonClick)="createSecondaryEmptyWorkspace()"
  [popupTitle]="'ExistingWorkspaceTitle' | translate"
  [primaryButtonText]="'Yes' | translate"
  [secondaryButtonText]="'No' | translate">
  <div *ngIf="existingEmptyWorkspace">
    {{ 'AlreadyExistingWorkspacePrefix' | translate }} '{{ existingEmptyWorkspace.name }} '.
    {{ 'AlreadyExistingWorkspacePostfix' | translate }}
  </div>
</app-modal-popup>

<app-modal-popup
  #selectTemplateForWorkspacePopup
  [popupTitle]="'SelectTemplateForWorkspaceTitle' | translate"
  [showButtons]="false">
  <div class="popup-content rename-form">
    <app-template-search-panel
      (templateSelected)="popupEmptyWorkspaceByTemplate($event)"
      *ngIf="selectTemplateForWorkspacePopup.popupActive === true"></app-template-search-panel>
  </div>
</app-modal-popup>

<app-modal-popup
  #confirmTemplateForWorkspacePopup
  (eventPrimaryButtonClick)="createEmptyWorkspaceByTemplate()"
  [popupTitle]="'ConfirmTemplateForWorkspaceTitle' | translate">
  <div *ngIf="selectedTemplate">
    <span *ngIf="selectedTemplate.defaultShares.length > 0">
      {{ 'Automatic shares' | translate }}
    </span>
    <span *ngIf="selectedTemplate.defaultShares.length === 0">
      {{ 'No automatic shares' | translate }}
    </span>
    <ul>
      <li *ngFor="let share of selectedTemplate.defaultShares">
        {{ share.name }}
      </li>
    </ul>
  </div>
</app-modal-popup>

<app-archive-workspace-popup
  #archiveWorkspacePopup
  (changeEventEmitter)="this.listWorkspaces()"
  [workspace]="focusedWorkspace"></app-archive-workspace-popup>
