import { Component, Input } from '@angular/core';
import { Address, Currencies, Invoice, Organization } from '@core/models';
import { InvoiceService, OrganizationService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent {
  @Input() readOnly = false;
  invoices: Invoice[] = [];
  currencies = Currencies;
  invalidEmail = true;
  newEmail: string;
  bccEmails: string[];

  constructor(
    private invoiceService: InvoiceService,
    public translate: TranslateService,
    private organizationService: OrganizationService
  ) {}

  _organization: Organization;

  get organization(): Organization {
    return this._organization;
  }

  @Input() set organization(val: Organization) {
    this._organization = val;
    if (this._organization !== undefined) {
      this.invoiceService
        .forOrganization(this.organization.id)
        .toPromise()
        .then((invoices) => {
          this.invoices = invoices.body;
        });

      if (this._organization.invoiceAddress === null) {
        this._organization.invoiceAddress = new Address();
      }
      this.updateBccEmails();
    }
  }

  async updateBccEmails(): Promise<void> {
    if (this._organization.financialEmailBccAddress !== null) {
      this.bccEmails = this._organization.financialEmailBccAddress.split(';');
      if (this.bccEmails.length > 0) {
        this.bccEmails = this.bccEmails.filter((e) => e);
        return;
      }
      this._organization.financialEmailBccAddress =
        this._organization.financialEmailBccAddress.concat(';');
      await this.updateOrganization();
      this.bccEmails = this._organization.financialEmailBccAddress.split(';');
      this.bccEmails = this.bccEmails.filter((e) => e);
    }
  }

  async updateOrganization(): Promise<any> {
    await this.organizationService.update(this.organization.id, {
      OrganizationDto: this.organization,
    });
  }

  async addEmail(): Promise<void> {
    this._organization.financialEmailBccAddress =
      this._organization.financialEmailBccAddress.concat(this.newEmail, ';');
    await this.updateOrganization();
    this.newEmail = '';
    await this.updateBccEmails();
  }

  async removeEmail(email: string): Promise<void> {
    const toBeRemoved = email.concat(';');
    this._organization.financialEmailBccAddress =
      this._organization.financialEmailBccAddress.replace(toBeRemoved, '');
    await this.updateOrganization();
    await this.updateBccEmails();
  }
}
