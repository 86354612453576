import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { titlePattern } from '@app/core/common/constants';

@Component({
  selector: 'title-input',
  templateUrl: './title-input.component.html',
  styleUrls: ['./title-input.component.scss'],
})
export class TitleInputComponent implements AfterViewInit {
  @Output() outputBlur = new EventEmitter<string>();
  @Input() item;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @Input() updateOnKeyUp = true;
  @Input() titlePattern = titlePattern;
  @Input() id: string;
  @Input() disabled: boolean;
  debounceTimer: ReturnType<typeof setTimeout>;
  originalTitle;
  hasTitleError: boolean;
  @ViewChild('textField') textField: ElementRef;

  parentBlur(keyUp = false): void {
    if (!this.hasTitleError && (!keyUp || this.updateOnKeyUp)) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.outputBlur.emit('eventDesc');
      }, 400);
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.textField.nativeElement.focus();
    }
  }
}
