import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AvatarColors } from '@core/models';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() userName: string;
  @Input() avatar: string;
  @Input() isOwn: boolean;
  @Input() showTooltip = true;
  @Input() square = false;
  @Input() avatarColor: string;
  @Input() isUser = true;
  public initials: string;

  getInitials(): string {
    const initials = this.userName?.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  ngOnInit(): void {
    this.initials = this.getInitials();
    if (!this.avatarColor) {
      this.avatarColor = AvatarColors.COLORS[this.hash(this.userName)];
    }
  }

  hash(str: string): number {
    if (!str) return 0;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash % AvatarColors.COLORS.length);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }
}
