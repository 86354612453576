import { Component, Input, OnInit } from '@angular/core';
import { AccountService, RoleService } from '@core/services';
import { Role, UserAccount } from '@core/models';

@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss'],
})
export class UsersRolesComponent implements OnInit {
  @Input() userId: string;
  user: UserAccount;
  roles: Role[];
  roleSearchText: string;

  constructor(private accountService: AccountService, private roleService: RoleService) {}

  async ngOnInit(): Promise<void> {
    this.user = await this.accountService.get(this.userId);
    this.roles = await this.roleService.list();
  }

  checkRoleActiveForUser(role: Role): Role {
    return this.user.roles.find((p) => p.id === role.id);
  }

  async toggleRoleActiveForUser(role: Role): Promise<any> {
    const existingRole = this.checkRoleActiveForUser(role);
    if (existingRole) {
      this.user.roles.splice(this.user.roles.indexOf(existingRole), 1);
      await this.accountService.removeRoleFromUser({
        roleId: role.id,
        userId: this.user.id,
      });
    } else {
      this.user.roles.push(role);
      await this.accountService.assignRoleToUser({
        roleId: role.id,
        userId: this.user.id,
      });
    }
  }
}
