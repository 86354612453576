<app-simple-dialog
  (onCloseDialog)="cleanUp()"
  #sharingPopup
  [popupTitle]="popupTitle"
  *ngIf="workspace">
  <div class="invite-new-members">
    <div class="default-padding-left-right">
      <div class="title">
        <span>{{ 'invite-people-to-workspace' | translate }}</span>
      </div>
      <app-person-searchbar
        #searchbar
        (newMemberEvent)="addNewMember($event)"
        [relations]="relations"
        [memberships]="memberships"
        [users]="users"
        [contacts]="contacts"
        [organization]="organization"
        [existingShares]="existingShares"
        [newShares]="newShares"></app-person-searchbar>
    </div>
  </div>

  <div class="invited-members">
    <app-person-row
      *ngFor="let share of newShares"
      (click)="onMobile ? openBottomSheet(share) : null"
      (deleteMember)="deleteNewMember(share)"
      (changePermissionType)="changePermissionOfNewMember(share, $event)"
      [showDefaultShare]="workspace.isTemplate && !link"
      [isSelectable]="onMobile"
      [share]="share"
      [onMobile]="onMobile"></app-person-row>
  </div>

  <div class="actions default-padding-left-right">
    <!--  New message-field desktop-->
    <div class="append-message-textarea" *ngIf="isMessageFieldActive && !onMobile">
      <textarea
        [placeholder]="'your message' | translate"
        [(ngModel)]="shareMessage"
        [value]="shareMessage"></textarea>
    </div>

    <div class="action-buttons">
      <button
        class="btn-secondary"
        *ngIf="isAppendMessageMobile"
        (click)="isMessageFieldActive ? closeMobileMessageField() : openMobileMessageField()">
        {{
          isMessageFieldActive
            ? ('close-message-field' | translate)
            : ('Add message title' | translate)
        }}
      </button>

      <!--message-field mobile-->
      <div class="append-message-textarea" *ngIf="isMessageFieldActive && onMobile">
        <textarea
          [placeholder]="'your message' | translate"
          [(ngModel)]="shareMessage"
          [value]="shareMessage"></textarea>
      </div>

      <button class="btn-primary" [disabled]="newShares.length === 0" (click)="sendInvitation()">
        {{ 'Send invite' | translate }}
      </button>
    </div>
  </div>

  <hr class="line-divider" />

  <span class="title default-padding-left-right">
    {{ 'people with access' | translate }}
  </span>
  <div class="people-with-access" *ngIf="workspace">
    <div *ngFor="let share of existingShares" class="person-row">
      <app-person-row
        (click)="onMobile ? openBottomSheet(share, false) : null"
        (deleteMember)="deleteExistingMember(share)"
        (changePermissionType)="changePermissionOfExistingMember(share, $event)"
        (toggleIsDefaultShare)="updateShare(share)"
        [showDefaultShare]="workspace.isTemplate && isWorkspaceShare && !link"
        [share]="share"></app-person-row>
    </div>

    <div class="no-people-invited default-padding-left-right" *ngIf="existingShares.length === 0">
      <span>{{ 'No invitations' | translate : shareType }}</span>
    </div>
  </div>

  <app-bottom-sheet-share-content
    #bottomSheet
    *ngIf="onMobile"
    (changePermissionEvent)="changePermission($event)"
    (deleteMemberEvent)="deleteMember($event)"
    class="bottom-sheet"></app-bottom-sheet-share-content>
</app-simple-dialog>
