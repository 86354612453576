import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StatisticsUpdateService {
  // Observable string sources
  private updateStatisticsSubject = new Subject<string>();

  // Observable string streams
  onStatisticUpdate$ = this.updateStatisticsSubject.asObservable();

  // Service message commands
  updateStatistics(sectionId: string): void {
    this.updateStatisticsSubject.next(sectionId);
  }
}
