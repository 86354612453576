import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AuthService,
  BrandingService,
  OrganizationService,
  SubscriptionService,
  TitleService,
  UserAccountService,
  WorkspaceService,
} from '@core/services';
import { ItemPermissionType, ItemStatusType, PermissionType, Workspace } from '@core/models';
import { environment } from '@env/environment';
import { titlePattern } from '@app/core/common/constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  public permissionTypes = PermissionType;

  @ViewChild('titleInput') titleInputElement: ElementRef;

  get workspace(): BehaviorSubject<Workspace> {
    return this.workspaceService.currentWorkspace$;
  }

  public PermissionTypes = ItemPermissionType;
  isNewWorkspace: boolean;
  saved: boolean;
  navigationSubscription;

  workspaceNotFound: boolean;
  notAuthorized: boolean;
  titlePattern = titlePattern;

  public itemStatusTypes = ItemStatusType;

  constructor(
    private route: ActivatedRoute,
    authService: AuthService,
    private workspaceService: WorkspaceService,
    private organizationService: OrganizationService,
    private brandingService: BrandingService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private titleService: TitleService,
    private userAccountService: UserAccountService
  ) {
    authService.getIsAuthorized().subscribe((authorized) => {
      titleService.set('');
      if (authorized === false) {
        route.queryParams.subscribe((params) => {
          if (params['resetToken'] === undefined) {
            // Normal login
            authService.login();
          } else {
            const tokenUrl = encodeURIComponent(params['resetToken']);
            window.location.href = `${environment.authBaseUrl}/passwordReset?resetPasswordToken=${tokenUrl}`;
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.navigationSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });

    this.init();

    setTimeout(() => {
      if (this.isNewWorkspace) {
        this.titleInputElement?.nativeElement.select();
      }
    }, 500);
  }

  showSaved(): void {
    this.saved = true;
    setTimeout(() => {
      this.saved = false;
    }, 2000);
  }

  async init(): Promise<void> {
    this.titleService.set('');
    const workspaceId = this.route.snapshot.paramMap.get('id');

    try {
      const workspace = await this.workspaceService.navigateTo(workspaceId);
      if (workspace) {
        if (!workspace.workspaceSettings) {
          const date = new Date();
          date.setDate(date.getDate() + 5);
          workspace.workspaceSettings = {
            deliveryDeadline: date,
            workspaceExpiryDate: date,
            allowNonTemplateFiles: false,
            emailOnFilesRequired: false,
            emailOnPendingExpiration: false,
            emailOnWorkspaceCompleted: false,
            workspaceId,
          };
        }

        const newWsString = localStorage.getItem('isNewWorkspace');
        this.isNewWorkspace = newWsString === 'true';
        localStorage.setItem('isNewWorkspace', 'false');

        if (!this.workspace.value.isOwner) {
          this.titleService.set(workspace.name);
        }
        const creator = workspace.creator;
        if (!creator.organizationId) return;

        const organization = await this.organizationService.get(creator.organizationId);
        const subscription = await this.subscriptionService.get(organization.subscriptionId);
        await this.brandingService.checkAndApplyBranding(organization, subscription);
      }
    } catch (error) {
      if (error.status === 404) {
        this.workspaceNotFound = true;
      } else if (error.status === 401) {
        this.notAuthorized = true;
      } else {
        console.log(error);
      }
    }
  }

  ngOnDestroy(): void {
    this.workspaceService.clearCurrentWorkspace();
    this.userAccountService.resetOrganization();

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
