import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  ViewChild,
} from '@angular/core';
import { ContextService, WorkspaceService } from '@core/services';
import { Question, QuestionType, SectionItem, SectionItemType, Workspace } from '@core/models';
import { BehaviorSubject } from 'rxjs';

export interface WorkflowLink {
  answer: number;
  workflowId: string;
}

export const question = new InjectionToken<Question>('question');
export const answerIndex = new InjectionToken<Question>('answerIndex');
export const confirmDialog = new InjectionToken<(l: WorkflowLink) => void>('coupleAnswer');
export const closeDialog = new InjectionToken<() => void>('coupleAnswer');

@Component({
  selector: 'app-workspace-relate-popup',
  templateUrl: './workflow-relate-popup.component.html',
  styleUrls: ['./workflow-relate-popup.component.scss'],
})
export class WorkflowRelatePopupComponent implements AfterViewInit {
  QuestionType: typeof QuestionType = QuestionType;
  selectedId?: string;
  expandedSections: string[] = [];

  @ViewChild('relateMenu') relateMenu: ElementRef;

  get workspace(): BehaviorSubject<Workspace> {
    return this.workspaceService.currentWorkspace$;
  }

  get labelClass(): string {
    return this.question.properties.type === 'radio' ? 'radiocontainer' : 'checkboxcontainer--new';
  }

  get checkmarkClass(): string {
    return this.question.properties.type === 'radio' ? 'radiomark' : 'checkmark--new';
  }

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public contextService: ContextService,
    private workspaceService: WorkspaceService,
    @Inject(question) public question: Question,
    @Inject(answerIndex) public answerSelect: number,
    @Inject(confirmDialog) public confirmDialog: (l: WorkflowLink) => void,
    @Inject(closeDialog) public closeDialog: () => void
  ) {
    this.setSelected();
  }

  public close(): void {
    this.closeDialog();
  }

  ngAfterViewInit(): void {
    this.setSelected();
  }

  collapseSection(si: SectionItem): void {
    const index = this.expandedSections.indexOf(si.id);
    if (index > -1) this.expandedSections.splice(index, 1);
    else this.expandedSections.push(si.id);
  }

  setSelected(): void {
    if (this.question.value[this.answerSelect]?.otherOption) {
      this.selectedId = this.question.value[this.answerSelect].otherOption.workflowId;
    } else if (this.question.value[this.answerSelect]?.workflowId) {
      this.selectedId = this.question.value[this.answerSelect].workflowId;
    }

    const workspace = this.workspaceService.currentWorkspace;
    const si = workspace.getSectionItem<SectionItem>(this.selectedId);
    const section =
      !si || si.type === SectionItemType.Section
        ? si
        : workspace.getSectionItem<SectionItem>(si.parentId);
    if (section) {
      this.expandedSections.push(section.id);
    }
  }

  changeSelected(event, idToSelect): void {
    if (event.srcElement.checked) {
      this.selectedId = idToSelect;
    } else {
      this.selectedId = undefined;
    }
  }

  confirm(): void {
    this.confirmDialog({ answer: this.answerSelect, workflowId: this.selectedId });
  }
}
