import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  DataSeries,
  FileRequest,
  ItemStatusType,
  Section,
  StatisticsGroup,
  Workspace,
} from '@core/models';
import { ItemStatusFilters } from '@core/models/item-status-filters';
import { StatisticsUpdateService } from './statistics-update.service';

export class Statistic {
  key: string;
  statusType?: ItemStatusFilters;
  value: number;
  icon: string;
}

@Component({
  selector: 'app-workspace-statistics',
  templateUrl: './workspace-statistics.component.html',
  styleUrls: ['./workspace-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceStatisticsComponent implements OnInit {
  @Input() workspace?: Workspace;
  @Input() allRcis?: FileRequest[];
  @Input() section?: Section;
  @Input() showInvisible = true;
  @Input() round = false;

  allStats: Statistic[];
  series: StatisticsGroup[];

  constructor(
    private cdr: ChangeDetectorRef,
    private statisticsUpdateService: StatisticsUpdateService
  ) {}

  ngOnInit(): void {
    this.updateStatistics();
    this.statisticsUpdateService.onStatisticUpdate$.subscribe((sectionId) => {
      this.updateStatistics(sectionId);
      this.cdr.markForCheck();
    });
  }

  get rcis(): FileRequest[] {
    return this.section ? this.section.rcis : this.allRcis || this.workspace.allRcis;
  }

  get visibleRcis(): FileRequest[] {
    return this.showInvisible ? this.rcis : this.rcis.filter((rci) => rci.isVisible);
  }

  updateStatistics(sectionId?: string): void {
    if (this.section?.id && sectionId && sectionId !== this.section?.id) return;
    this.allStats = this.calculateStatistics(
      this.visibleRcis,
      ItemStatusFilters.Approved,
      ItemStatusFilters.Rejected,
      ItemStatusFilters.Received,
      ItemStatusFilters.NotApplicable,
      ItemStatusFilters.Pending
    );
    this.series = this.getStatisticsGroup(this.allStats);
    this.cdr.detectChanges();
  }

  private calculateStatistics(
    rcis: FileRequest[],
    ...statusIndicators: ItemStatusFilters[]
  ): Statistic[] {
    const hasStatus = (...statuses: ItemStatusType[]) =>
      rcis.filter((rci) => statuses.includes(rci.status));
    const totalApproved = hasStatus(ItemStatusType.Approved).length;
    const totalPending = hasStatus(ItemStatusType.Pending, ItemStatusType.Unknown).length;
    const totalRejected = hasStatus(ItemStatusType.Rejected).length;
    const totalReceived = hasStatus(ItemStatusType.Received).length;
    const totalNonApplicable = hasStatus(ItemStatusType.NotApplicable).length;
    const totalFreeUpload = rcis.filter((r) => !r.hasContentItems).length;

    const stats = [
      {
        key: 'Pending',
        statusType: ItemStatusFilters.Pending,
        value: totalPending,
        icon: 'pending',
      },
      {
        key: 'Received',
        statusType: ItemStatusFilters.Received,
        value: totalReceived,
        icon: 'swap_horizontal_circle',
      },
      {
        key: 'Approved',
        statusType: ItemStatusFilters.Approved,
        value: totalApproved,
        icon: 'check_circle',
      },
      {
        key: 'Rejected',
        statusType: ItemStatusFilters.Rejected,
        value: totalRejected,
        icon: 'cancel',
      },
      {
        key: 'NotApplicable',
        statusType: ItemStatusFilters.NotApplicable,
        value: totalNonApplicable,
        icon: 'do_disturb_on',
      },
      {
        key: 'AdditionalFiles',
        statusType: ItemStatusFilters.AdditionalFiles,
        value: totalFreeUpload,
        icon: 'add_circle_outline',
      },
    ] as Statistic[];
    if (statusIndicators.length > 0) {
      return statusIndicators.map((si) => stats.find((stat) => stat.statusType === si));
    }
    return stats;
  }

  private getStatisticsGroup(statistics: Statistic[]): StatisticsGroup[] {
    const series = statistics.map(
      (stat) =>
        ({
          name: stat.key,
          value: stat.value,
        } as DataSeries)
    );
    return [new StatisticsGroup(series)];
  }
}
