import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { DragService, WorkspaceService } from '@core/services';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Input() dragTitle: string = '';
  @Input() dragIcon: string = '';
  @Input() appDndEnabled: boolean = true;
  @Output() fileDropped = new EventEmitter<any>();
  @Output() changeClass = new EventEmitter<any>();

  dropPosition: number;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public dragService: DragService,
    protected workspaceService: WorkspaceService
  ) {}

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    if (this.appDndEnabled) {
      evt.preventDefault();
      evt.stopPropagation();

      this.drawLine(evt);
      this.dragService.set(true, evt.x, evt.y, this.dragTitle, this.dragIcon);
    }
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    this.changeClass.emit('');
    this.clearStyling();
    evt.preventDefault();
    evt.stopPropagation();
    this.dragService.hide();
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    if (this.appDndEnabled) {
      this.dragService.hide();
      this.clearStyling();
      this.changeClass.emit('');
      evt.preventDefault();
      evt.stopPropagation();

      const path = evt.path || (evt.composedPath && evt.composedPath());
      for (let i = 0; i < path.length; i++) {
        if (path[i].classList?.contains('workspace-files')) {
          this.dropPosition = this.dropPosition + 1;
        }
      }
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.fileDropped.emit({ files: files, position: this.dropPosition });
      }
    }
  }

  private clearStyling() {
    this.fileOver = false;
    if (document.querySelector('.dnd-line-wrap.prepend')) {
      document.querySelector('.dnd-line-wrap.prepend').classList.remove('prepend');
    }

    if (document.querySelector('.dnd-line-wrap.append')) {
      document.querySelector('.dnd-line-wrap.append').classList.remove('append');
    }
  }

  private getDropNumber(dropTarget) {
    const allLines = dropTarget.closest('.line-group').getElementsByClassName('dnd-drop-count');
    let num = 0;
    for (let i = 0; i < allLines.length; i++) {
      if (allLines[i] === dropTarget) {
        return num;
      }
      num++;
    }
    return -1;
  }

  private drawLine(event) {
    let linePosition = 0;
    const path = event.path || (event.composedPath && event.composedPath());
    if (!path) return;
    path.forEach((element, index) => {
      if (element.classList) {
        if (element.classList.contains('dnd-line-wrap')) {
          linePosition = index;
        }
      }
    });

    this.clearStyling();

    if (linePosition) {
      const halfHeight = path[linePosition].offsetHeight / 2;
      const midPoint =
        event.clientY - (path[linePosition].getBoundingClientRect().top + halfHeight);
      this.dropPosition = this.getDropNumber(path[linePosition]);
      if (midPoint > 0) {
        this.dropPosition = this.dropPosition + 1;
        path[linePosition].classList.add('append');
      } else {
        path[linePosition].classList.add('prepend');
      }
    } else {
      this.dropPosition = -1;
      this.fileOver = true;
    }
  }
}
