import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ItemStatusType, Workspace } from '@core/models';
import { WorkspaceService } from '@core/services';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';

@Component({
  selector: 'app-approve-workspace-popup',
  templateUrl: './approve-workspace-popup.component.html',
})
export class ApproveWorkspacePopupComponent {
  @ViewChild('confirmApproveWorkspacePopup')
  confirmApproveWorkspacePopup: ModalPopupComponent;

  @Output() changeEventEmitter = new EventEmitter();

  get workspace(): Workspace {
    return this.workspaceService.currentWorkspace;
  }

  constructor(private workspaceService: WorkspaceService) {}

  activatePopup(): void {
    this.confirmApproveWorkspacePopup.popupActive = true;
  }

  async approveWorkspace(workspace: Workspace): Promise<void> {
    await this.workspaceService.update(workspace.id, {
      newDescription: workspace.description,
      newName: workspace.name,
      workspaceSettings: workspace.workspaceSettings,
      workspaceStatus: ItemStatusType.Approved,
    });
    await this.workspaceService.refresh();
    this.changeEventEmitter.emit(workspace);
    this.confirmApproveWorkspacePopup.popupActive = false;
  }
}
