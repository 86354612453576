import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadGuardService {
  private fileBeingUploadedSubject: Subject<boolean> = new Subject<boolean>();
  private fileIsBeingUploaded = false;
  private lastBlockedRoute: string;

  setStatusToInProgress(): void {
    this.fileIsBeingUploaded = true;
  }

  setStatusToDone(): void {
    this.fileIsBeingUploaded = false;
  }

  isFileBeingUploaded(): boolean {
    return this.fileIsBeingUploaded;
  }

  getLastBlockedRoute(): string {
    return this.lastBlockedRoute;
  }

  notifySubscribers(): void {
    this.fileBeingUploadedSubject.next(true);
  }

  subscribe(callBackFunction: () => void): void {
    this.fileBeingUploadedSubject.subscribe({
      next: callBackFunction,
    });
  }
}
