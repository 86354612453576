<app-container *hasPermission="[{ permissionType: permissionTypes.Any, section: 'Teams' }]">
  <div *ngIf="memberships">
    <div *ngIf="memberships.length > 0" class="section-header">
      <div *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Teams' }]">
        <button (click)="addTeam()" class="btn-primary">
          <span class="material-icons-outlined">group_add</span>
          {{ 'Add team' | translate }}
        </button>
      </div>
      <input
        [(ngModel)]="membershipSearchText"
        class="searchBox"
        placeholder="{{ 'search' | translate }}"
        type="text" />
    </div>
  </div>
  <div *ngIf="memberships">
    <section *ngIf="memberships.length > 0">
      <table>
        <tr>
          <th [appSort]="memberships" class="sortable" data-name="name" scope="col">
            {{ 'Name' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="memberships" class="sortable" data-name="roles" scope="col">
            {{ 'Roles' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="memberships" class="strech sortable" data-name="users" scope="col">
            {{ 'Members' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th>{{ 'Actions' | translate }}</th>
        </tr>
        <tr
          (click)="goToDetails(membership.id)"
          *ngFor="let membership of memberships | membershipFilter : membershipSearchText"
          class="table-row">
          <td>{{ membership.name }}</td>
          <td>
            <span *ngFor="let role of membership.roles | slice : 0 : 4; let i = index">
              {{ role.isSystemRole ? (role.name | translate) : role.name
              }}{{ i < membership.roles.length - 1 ? ', ' : '' }}
            </span>
            <span *ngIf="membership.roles.length > 4" class="others">
              {{ membership.roles.length - 1 }} {{ 'more' | translate }}
            </span>
          </td>
          <td class="strech">
            <span *ngFor="let user of membership.users | slice : 0 : 4; let i = index">
              {{ user.firstname }} {{ user.lastname
              }}{{ i < membership.users.length - 1 ? ', ' : '' }}
            </span>
            <span *ngIf="membership.users.length > 4" class="others">
              {{ membership.users.length - 1 }} {{ 'more' | translate }}
            </span>
          </td>
          <td
            (click)="confirmDeleteMembership(membership)"
            *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Teams' }]"
            class="td-delete-membership"
            click-stop-propagation>
            <div class="delete-membership-icon">
              <span class="material-icons">delete_outline</span>
            </div>
            <!--              <button (click)="confirmDeleteMembership(membership)"-->
            <!--                      click-stop-propagation-->
            <!--                      *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Teams'}]">-->

            <!--              </button>-->
          </td>
        </tr>
      </table>
    </section>
  </div>
  <div *ngIf="memberships">
    <div *ngIf="memberships.length === 0" class="empty-item-container">
      <h2 class="no-items-title">{{ 'No teams' | translate }}</h2>
      <button
        (click)="addTeam()"
        *hasPermission="[{ permissionType: permissionTypes.Create, section: 'Relations' }]"
        class="btn-primary no-items-subtitle">
        <span>
          <img
            alt="new workspace icon"
            decoding="async"
            loading="lazy"
            src="assets/contacts-light.svg" />
          {{ 'CreateItem' | translate }}
        </span>
      </button>
      <app-empty-animation></app-empty-animation>
    </div>
  </div>

  <app-modal-popup
    #deleteTeamPopup
    (eventPrimaryButtonClick)="deleteTeam()"
    [popupTitle]="'DeleteTeamTitle' | translate"
    [primaryButtonText]="'DeleteCaption' | translate">
    <div>{{ 'ConfirmTeamDelete' | translate }}</div>
  </app-modal-popup>
</app-container>
<router-outlet></router-outlet>
