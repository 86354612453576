import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ContentItem, EventType, FileRequest } from '@app/core/models';
import { AuditService } from '@app/core/services/api/audit.service';
import { PreviewService } from '@core/services';

@Directive({
  selector: '[filePreview]',
})
export class FilePreviewDirective implements OnInit, OnDestroy {
  @Input() filePreview: FileRequest | ContentItem;
  @Input() fileIndex?: number;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public previewService: PreviewService,
    private auditService: AuditService
  ) {}

  public ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener('click', this.showPreview);
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('click', this.showPreview);
  }

  get contentItems(): ContentItem[] {
    return this.filePreview instanceof FileRequest
      ? this.filePreview.contentItems
      : [this.filePreview];
  }

  get fileRequest(): FileRequest | undefined {
    return this.filePreview instanceof FileRequest ? this.filePreview : undefined;
  }

  private showPreview = (event$): void => {
    event$.stopPropagation();
    if (this.filePreview) {
      this.auditService.post({ eventType: EventType.OpenFileRequestPreview });
      this.previewService.set(this.contentItems, this.fileRequest, this.fileIndex);
    }
  };
}
