import { Injectable } from '@angular/core';

import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private options: Partial<IndividualConfig> = {
    timeOut: 3000,
    positionClass: 'toast-bottom-right',
    progressAnimation: 'increasing',
    easing: 'ease-in',
    progressBar: true,
  };

  constructor(private toastr: ToastrService, private translateService: TranslateService) {}

  private showToaster(fn: string, messageKey: string, titleKey?: string, options: any = {}): void {
    const { translate } = options;
    if (translate !== false) {
      const body = this.translateService.instant(messageKey, options);
      const title = titleKey ? this.translateService.instant(titleKey, options) : undefined;
      this.toastr[fn](body, title, this.options);
    } else {
      this.toastr[fn](messageKey, titleKey, this.options);
    }
  }

  showSuccess(messageKey: string, titleKey?: string, options: any = {}): void {
    this.showToaster('success', messageKey, titleKey, options);
  }

  showError(messageKey: string, titleKey?: string, options: any = {}): void {
    this.showToaster('error', messageKey, titleKey, options);
  }

  showInfo(messageKey: string, titleKey?: string, options: any = {}): void {
    this.showToaster('info', messageKey, titleKey, options);
  }

  showWarning(messageKey: string, titleKey?: string, options: any = {}): void {
    this.showToaster('warning', messageKey, titleKey, options);
  }
}
