import * as zip from '@zip.js/zip.js';
import { BlobWriter } from '@zip.js/zip.js';
import { map, scan, takeWhile } from 'rxjs/operators';
import { ContentService, download, UserAccountService } from '@core/services';
import {
  Workspace,
  ContentItem,
  FileRequest,
  Section,
  SectionItemType,
  EventType,
  TargetType,
} from '@core/models';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { DownloadStatus, ProgressKind } from '@core/models/download.model';
import { Injectable } from '@angular/core';
import { LoaderService, ProgressEvent } from '../loader.service';
import { parallel } from '@app/core/common/parallel.functions';
import { sum } from 'ramda';
import { AuditService } from './audit.service';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  public downloadPercentageSubject = new BehaviorSubject<DownloadStatus>(new DownloadStatus());

  abortController: AbortController;

  abortAll(): void {
    this.abortController.abort('Download aborted');
  }

  private isDownloadActive(): boolean {
    return !this.abortController.signal.aborted;
  }

  constructor(
    private contentService: ContentService,
    private http: HttpClient,
    private userAccountService: UserAccountService,
    public loaderService: LoaderService,
    private auditService: AuditService
  ) {}

  get useFileRequestNames(): boolean {
    return this.userAccountService.user.downloadFilesAsRequestedFileName;
  }

  private getDownloadableItems(workspace: Workspace): ContentItem[] {
    const rcis = workspace.allRcis.flatMap((fileRequest) => fileRequest.contentItems);
    const contentItems = workspace.allContentItems;
    const items = [...rcis, ...contentItems];
    return items.filter((contentItem) => contentItem.selected !== false);
  }

  public async downloadWorkspace(workspace: Workspace): Promise<Blob[]> {
    this.auditService.post({
      eventType: EventType.Download,
      targetId: workspace.id,
      targetType: TargetType.Workspace,
    });
    const items = this.getDownloadableItems(workspace);
    const getFileName = (ci): string => workspace.getPath(ci, this.useFileRequestNames);
    return this.download(workspace.id, items, getFileName, workspace.name);
  }

  public async downloadFileRequest(fileRequest: FileRequest): Promise<Blob[]> {
    this.auditService.post({
      eventType: EventType.Download,
      targetId: fileRequest.id,
      targetType: TargetType.RequestContentItem,
    });
    const items = fileRequest.contentItems;
    const getFileName = (ci): string => ci.name;
    return this.download(fileRequest.workspaceId, items, getFileName, fileRequest.name);
  }

  public downloadSection(workspace: Workspace, section: Section): Promise<Blob[]> {
    this.auditService.post({
      eventType: EventType.Download,
      targetId: section.id,
      targetType: TargetType.Section,
    });
    const items = section.getChildren<ContentItem>(SectionItemType.ContentItem);
    const getFileName = (ci): string => workspace.getPath(ci, this.useFileRequestNames);
    return this.download(section.workspaceId, items, getFileName, section.name);
  }

  public async downloadContentItem(
    workspaceId: string,
    contentItem: ContentItem,
    fileName: string
  ): Promise<void> {
    const blobs = await this.download(workspaceId, [contentItem], () => fileName);
    const blob = blobs[0];
    if (blob) saveAs(blob, fileName);
  }

  private async download(
    workspaceId: string,
    contentItems: ContentItem[],
    getFileName: (ci: ContentItem) => string,
    zipFileName?: string
  ): Promise<Blob[]> {
    const fileNames = fixDuplicateFileNames(contentItems.map(getFileName));
    this.abortController = new AbortController();

    this.updateProgress(0, ProgressKind.Download);

    const totals = contentItems.map((c) => c.size);
    const progress = Array(contentItems.length).fill(0);

    const blobs = await parallel(5, contentItems, async (contentItem, fileIndex) => {
      const response = await this.contentService.getDownloadUrl(workspaceId, contentItem.id);

      const fileName = fileNames[fileIndex];
      // loading a file and add it in a zip file
      return this.http
        .get(response.body, {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        })
        .pipe(
          takeWhile(() => this.isDownloadActive()),
          scan((_, value) => {
            const index = contentItems.indexOf(contentItem);
            this.setDownloadProgress(value, index, progress, totals);
            return value;
          }),
          download((blob) => blob),
          map((download) => download.content)
        )
        .toPromise()
        .catch((e) => {
          // In case a corrupt file is present in the workspace, don't cancel the download
          console.warn(`Error downloading ${fileName}`, e);
        });
    });

    if (this.isDownloadActive() && zipFileName) {
      const blobWriter = new zip.BlobWriter('application/zip');
      const zipWriter = new zip.ZipWriter(blobWriter, { level: 0 });
      await this.addFilesToArchive(blobs, fileNames, zipWriter);
      await zipWriter.close();
      await this.startZipDownload(blobWriter, zipFileName);
    }
    this.updateProgress();
    return blobs;
  }

  async addFilesToArchive(
    blobs: Blob[],
    fileNames: string[],
    zipWriter: zip.ZipWriter<Blob>
  ): Promise<void> {
    const nrFiles = blobs.length;

    for (let i = 0; i < nrFiles; i++) {
      if (!blobs[i]) return;
      await zipWriter
        .add(fileNames[i], new zip.BlobReader(blobs[i]), {
          signal: this.abortController.signal,
        })
        .then(() => {
          const percentage = Math.round((i / nrFiles) * 100);
          this.updateProgress(percentage, ProgressKind.Archive);
        })
        .catch((e) => {
          console.warn('Archiving aborted', e.name);
        });
    }
  }

  private setDownloadProgress(
    value: HttpEvent<Blob>,
    index: number,
    progress: number[],
    totals: number[]
  ): void {
    if (value.type === HttpEventType.DownloadProgress) {
      // Total doesn't always have to be computed....fallback to loaded
      const total = value.total ? value.total : value.loaded;
      totals[index] = total;
      progress[index] = value.loaded;

      const totalProgress = sum(progress);
      const totalSize = sum(totals);
      const downloadProgress = (totalProgress / (totalSize || totalProgress)) * 100;

      // Since progress bar stalls at 100% for a second, we'll set it to 0% archiving instead
      if (downloadProgress < 100) this.updateProgress(downloadProgress, ProgressKind.Download);
      else this.updateProgress(0, ProgressKind.Archive);
    }
  }

  private async startZipDownload(blobWriter: BlobWriter, zipFileName: string): Promise<void> {
    // get the zip file as a Blob
    if (this.isDownloadActive()) {
      const blob = await blobWriter.getData();
      saveAs(blob, `${zipFileName}.zip`);
    }
  }

  public cancelDownload(): void {
    this.abortAll();
    this.updateProgress();
  }

  private updateProgress(
    percentage: number = undefined,
    progressKind: ProgressKind = ProgressKind.None
  ): void {
    percentage = percentage !== undefined ? Math.round(percentage * 10) / 10 : undefined;
    this.loaderService.progress.next(new ProgressEvent(percentage));
    this.downloadPercentageSubject.next({ percentage, progressKind });
  }

  public get isDownloading(): boolean {
    return this.loaderService.progress.value.isActive;
  }
}

export const fixDuplicateFileNames = (fileNames: string[]): string[] =>
  fileNames.reduce((acc, fileName, i) => {
    let duplicates = fileNames.slice(0, i).filter((file) => file === fileName);
    let newName = appendIndex(fileName, duplicates.length);
    duplicates = acc.filter((file) => file === newName);
    newName = appendIndex(newName, duplicates.length);
    return [...acc, newName];
  }, []);

const appendIndex = (fileName: string, nrDuplicates: number): string => {
  const [, root, extension] = /(.*)\.([^./]*)$/.exec(fileName) || [];
  if (nrDuplicates >= 1)
    return root ? `${root} (${nrDuplicates}).${extension}` : `${fileName} (${nrDuplicates})`;
  else return fileName;
};
