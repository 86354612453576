<div
  *ngIf="(isEdit | async) || (question.label.length && question.value.length)"
  [class.edit]="isEdit | async"
  class="checkbox-question"
  multiSelectHover>
  <app-multi-select-checkbox
    [item]="question"
    [type]="(isEdit | async) ? 'checkbox-edit' : 'checkbox'"
    (checkboxChange)="checkboxChange.emit()"></app-multi-select-checkbox>

  <div *ngIf="isEdit | async" class="checkbox-question__inputs">
    <div
      *ngIf="question"
      [ngClass]="!question.label.length && values.length > 1 ? 'invalid-regex' : ''"
      class="form-input">
      <mt-input
        [autofocus]="question.focused"
        placeholder="{{
          question.properties.type === 'radio'
            ? ('radio-question' | translate)
            : ('checkbox-question' | translate)
        }}">
        <input
          #mt="ngModel"
          #title="ngModel"
          (blur)="updateQuestion({ label: question.label })"
          (focus)="onFocus()"
          (keyup.enter)="titleEnter()"
          [(ngModel)]="question.label"
          [autofocus]="question.focused"
          autocomplete="off"
          type="text" />
      </mt-input>

      <small *ngIf="!question.label.length && values.length > 1" class="text-danger">
        {{ 'empty-field-question' | translate }}
      </small>
    </div>

    <div *ngIf="isEdit | async" class="checkbox-question__control">
      <app-three-dot-menu [menu]="menu" click-stop-propagation></app-three-dot-menu>

      <icon-button (click)="removeRequest()" icon="delete" class="remove-control"></icon-button>
      <label class="checkbox-switch" [tooltip]="'Is required' | translate">
        <input
          (change)="updateQuestion({ isRequired: question.isRequired })"
          (focus)="onFocus()"
          [(ngModel)]="question.isRequired"
          [ngModelOptions]="{ standalone: true }"
          type="checkbox" />
        <span class="switch"></span>
      </label>
    </div>
  </div>

  <div *ngIf="(isEdit | async) === false" class="checkbox-question__inputs">
    <h3>{{ question.label }}</h3>
    <div [class.active]="anyChecked" class="check-mark">
      <span *ngIf="anyChecked" class="material-icons-outlined">check</span>
      <span *ngIf="!anyChecked" class="material-icons-outlined">close</span>
    </div>
    <div class="checkbox-list">
      <div *ngFor="let checkItem of values; let i = index">
        <div class="checkbox-item" *ngIf="checkItem.name && !checkItem.isOtherOption">
          <label [ngClass]="labelClass">
            {{ checkItem.isOtherOption ? undefined : checkItem.name }}
            <input
              (change)="
                question.properties.type === 'radio'
                  ? changeRadio(i)
                  : update({ value: question.value })
              "
              [(ngModel)]="checkItem.checked"
              [ngModelOptions]="{ standalone: true }"
              type="checkbox" />
            <span [ngClass]="checkmarkClass"></span>
          </label>
        </div>
        <div *ngIf="checkItem.isOtherOption" class="checkbox-item other-label">
          <label [ngClass]="labelClass">
            {{ 'other-namely' | translate }}
            <input
              (change)="updateOther(checkItem)"
              [(ngModel)]="checkItem.checked"
              [ngModelOptions]="{ standalone: true }"
              type="checkbox" />
            <span [ngClass]="checkmarkClass"></span>
          </label>
          <title-input
            (outputBlur)="updateOther(checkItem)"
            [autofocus]="hasFocus(checkItem)"
            [item]="checkItem"
            [titlePattern]="''"
            [updateOnKeyUp]="false"
            class="title"
            placeholder="{{ 'your-answer' | translate }}"
            type="title"></title-input>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isEdit | async" class="checkbox-list" #checkboxList>
    <div
      *ngFor="let checkItem of values; let i = index"
      [class.add]="i === values.length - 1"
      class="checkbox-item">
      <label [ngClass]="labelClass">
        <input
          (change)="
            question.properties.type === 'radio'
              ? changeRadio(i)
              : update({ value: question.value })
          "
          [(ngModel)]="checkItem.checked"
          [ngModelOptions]="{ standalone: true }"
          type="checkbox" />
        <span [ngClass]="checkmarkClass"></span>
      </label>

      <div class="other-label" *ngIf="checkItem.isOtherOption">
        {{ 'other-namely' | translate }}
      </div>

      <title-input
        #optionInput
        (keydown.enter)="addItem(checkItem)"
        (outputBlur)="addOrUpdate(checkItem)"
        [autofocus]="hasFocus(checkItem)"
        [id]="i === values.length - 1 ? 'checkboxLabel' : undefined"
        [item]="checkItem"
        [titlePattern]="''"
        [updateOnKeyUp]="false"
        [class.other-input]="checkItem.isOtherOption"
        [disabled]="checkItem.isOtherOption"
        class="title"
        placeholder="{{ (checkItem.isOtherOption ? 'your-answer' : 'add-option') | translate }}"
        type="title"></title-input>

      <div
        *ngIf="!question.properties.otherOption.enabled && i === values.length - 1"
        [class.deactive]="checkItem.name"
        (click)="toggleOtherOption()"
        class="other-option-button">
        {{ 'or' | translate }}
        <span>{{ 'add-other-option' | translate }}</span>
      </div>

      <div
        (click)="activateRelatePopup(i)"
        cdkOverlayOrigin
        *ngIf="i < values.length - 1"
        class="bolt"
        [class.active]="
          checkItem.workflowId ||
          (i === values.length - 1 && question.properties.otherOption.workflowId)
        ">
        <span class="material-icons-outlined">bolt</span>
      </div>

      <div
        (click)="removeItem(i, checkItem.isOtherOption)"
        *ngIf="i < values.length - 1"
        class="delete">
        <span class="material-icons-outlined">close</span>
      </div>
    </div>
  </div>
</div>

<ng-template [cdkPortalOutlet]="portal"></ng-template>
