<div class="options-container">
  <div class="left-side" [ngClass]="[deleteColour ? 'text-colour-red' : 'text-colour-black']">
    <div class="container">
      <div class="icon-all">
        <span class="material-icons-outlined icon" *ngIf="materialIcon !== ''">
          {{ materialIcon }}
        </span>
      </div>
      <div class="text">
        <span>{{ label }}</span>
      </div>
    </div>
  </div>

  <div class="right-side">
    <div class="container" *ngIf="hasNavigation">
      <div class="label">
        {{ status }}
      </div>
      <div class="navigate-next-arrow">
        <span class="material-icons-outlined">navigate_next</span>
      </div>
    </div>
  </div>
</div>
