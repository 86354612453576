import { Component, Input, OnInit } from '@angular/core';
import { AccountService, MembershipService } from '@core/services';
import { Membership, UserAccount } from '@core/models';

@Component({
  selector: 'app-users-teams',
  templateUrl: './users-teams.component.html',
  styleUrls: ['./users-teams.component.scss'],
})
export class UsersTeamsComponent implements OnInit {
  public memberships: Membership[];
  @Input() userId: string;
  user: UserAccount;
  membershipSearchText: string;

  constructor(
    private accountService: AccountService,
    private membershipService: MembershipService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadMemberships();
    this.user = await this.accountService.get(this.userId);
  }

  async loadMemberships(): Promise<any> {
    this.memberships = await this.membershipService.list();
  }

  checkMembershipActiveForUser(membership: Membership): Membership {
    return this.user.memberships.find((p) => p.id === membership.id);
  }

  async toggleMembershipActiveForUser(membership: Membership): Promise<any> {
    const existingMembership = this.checkMembershipActiveForUser(membership);
    if (existingMembership) {
      this.user.memberships.splice(this.user.memberships.indexOf(existingMembership), 1);
      await this.accountService.removeMembershipFromUser({
        userId: this.user.id,
        membershipId: membership.id,
      });
    } else {
      this.user.memberships.push(membership);
      await this.accountService.assignMembershipToUser({
        userId: this.user.id,
        membershipId: membership.id,
      });
    }
    this.accountService.accountsUpdated.emit(this.user);
  }
}
