/* eslint-disable no-bitwise */

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {environment} from '@env/environment';
import {EditAccountRole, HonorificType, PermissionType, PreferredCommunicationType, UserAccount,} from '@core/models';
import {ModalPopupComponent} from '@app/shared/directives/modal-popup/modal-popup.component';
import {
  AccountService,
  BlobService,
  ContentService,
  NotificationService,
  TitleService,
  UserAccountService,
} from '@core/services';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
})
export class UserAccountComponent implements OnInit {
  public HonorificTypes = HonorificType;
  public EditAccountRole = EditAccountRole;

  newPassword = '';
  confirmNewPassword = '';

  PreferredCommunicationType = PreferredCommunicationType;

  @ViewChild('changePasswordPopup') changePasswordPopup: ModalPopupComponent;
  @ViewChild('deleteUserPopup') deleteWorkspacePopup: ModalPopupComponent;

  @Input() userId: string;
  @Input() titleShouldChange: boolean;
  user: UserAccount;
  phonePattern = '[- +()0-9]+';
  public permissionTypes = PermissionType;

  constructor(
    private router: Router,
    private titleService: TitleService,
    private httpClient: HttpClient,
    private userAccountService: UserAccountService,
    private accountService: AccountService,
    private contentService: ContentService,
    private blobService: BlobService,
    private notifyService: NotificationService,
    public translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = await this.accountService.get(this.userId);
    this.userAccountService.accountUpdated.subscribe((user) => {
      if (this.titleShouldChange) {
        this.titleService.set(this.user.fullName);
      }
    });
  }

  get editRole(): EditAccountRole {
    if (this.userAccountService.user?.id === this.userId) {
      return EditAccountRole.FullAccess;
    } else if (this.userAccountService.user.organizationId === this.user.organizationId) {
      return EditAccountRole.PartialAccess;
    } else {
      return EditAccountRole.NoAccess;
    }
  }

  updatePhoneNumber(error?: boolean): void {
    if (error !== null) {
      return;
    }
    this.updateUserAccount();
  }

  async updateUserAccount(fromPopup?: boolean): Promise<void> {
    const d = await this.accountService.update(this.userId, {
      ...this.user,
      roles: [],
      updatedPassword: this.newPassword,
      confirmUpdatedPassword: this.confirmNewPassword,
    });

    if (this.titleShouldChange) {
      this.titleService.set(this.user.fullName);
    }

    if (!fromPopup && this.editRole === EditAccountRole.FullAccess) {
      if (this.userAccountService.user.avatar !== this.user.avatar) {
        this.userAccountService.user.avatar = this.user.avatar;
      }
      this.userAccountService.user = this.user;
      if (!this.user.address?.id) {
        // get the new address Id
        const userResult = await this.accountService.get(this.userId);
        this.user.address = userResult.address;
      }
      return;
    }

    this.changePasswordPopup.closePopup();
    if (d.ok) {
      this.notifyService.showSuccess('AccountUpdateSuccessfulBody', 'Update successful');
    } else {
      const messageTitle = this.translate.instant('UnableToStoreChangesTitle');
      // eslint-disable-next-line guard-for-in
      this.notifyService.showError(d.body, messageTitle, { translate: false });
    }
  }

  async uploadLoginPicture(file: File): Promise<void> {
    if (!file) {
      this.user.avatar = '';
      return;
    }
    const result = await this.contentService.getUploadUrl(this.userId, file.name, true);
    const { contentItem, uploadUrl } = result;
    await this.blobService.uploadFile(uploadUrl, file).toPromise();
    this.user.avatar = (
      await this.contentService.getDownloadUrl(this.userId, contentItem.id)
    ).body.split('?')[0];
    await this.updateUserAccount(false);
  }

  async switchLang(lang: string): Promise<void> {
    this.user.languageCode = lang;
    // set the language for the user account we're updating.

    await this.updateUserAccount();

    // only change the language cookie and ui if the user is the current user
    if (this.userAccountService.user?.id === this.userId) {
      this.translate.use(lang);
      // call auth service set cookie url
      await this.httpClient
        .get(`${environment.authBaseUrl}/${lang}/account/SetCultureCookie`)
        .toPromise();
      await this.userAccountService.refreshSession();
    }
  }

  clearAvatar(): void {
    this.user.avatar = '';
  }

  openChangePasswordPopup(): void {
    this.changePasswordPopup.popupActive = true;
  }

  togglePreferredCommunicationType(preferredCommunicationType: PreferredCommunicationType): void {
    if (this.checkCommunicationTypeActive(preferredCommunicationType)) {
      // Unset the enum flag
      this.user.communicationSettings.preferredCommunicationType &= ~preferredCommunicationType;
    } else {
      // Set the enum flag
      this.user.communicationSettings.preferredCommunicationType |= preferredCommunicationType;
    }
    this.updateUserAccount();
  }

  checkCommunicationTypeActive(communicationType: PreferredCommunicationType): boolean {
    return (
      communicationType ===
      (this.user.communicationSettings.preferredCommunicationType & communicationType)
    );
  }

  async removeUser(): Promise<void> {
    await this.accountService.delete(this.userId);
    this.router.navigate([`/settings/users`]);
  }

  openConfirmPopup(): void {
    this.deleteWorkspacePopup.popupActive = true;
  }

  toggleMessageReceivedNotification(): void {
    if (
      this.user.communicationSettings.receiveMessageReceivedNotification ===
      PreferredCommunicationType.Email
    ) {
      this.user.communicationSettings.receiveMessageReceivedNotification =
        PreferredCommunicationType.None;
    } else {
      this.user.communicationSettings.receiveMessageReceivedNotification =
        PreferredCommunicationType.Email;
    }
    this.updateUserAccount();
  }
}
