export interface CreateAuditEntry {
  eventTimestamp?: string;
  targetType?: TargetType;
  eventType: EventType;
  comment?: string;
  targetId?: string;
  targetName?: string;
}

export interface AuditEntry {
  path: string;
  host: string;
  userName?: string;
  actorId?: string;
  organization?: string;
  organizationId?: string;
  payload?: string;
  creationTimestampUtc: string;
  eventTimestampUtc: string;
  eventType: EventType;
  actorType: ActorType;
  comment?: string;

  traceId: string;
  targetName?: string;
  targetIds: string[];
  targetTypes: TargetType[];
}

export enum ActorType {
  Undefined = 'Undefined',
  User = 'User',
  System = 'System',
  Admin = 'Admin',
}

export enum TargetType {
  Undefined = 'Undefined',
  Workspace = 'Workspace',
  User = 'User',
  Organization = 'Organization',
  Template = 'Template',
  ContentItem = 'ContentItem',
  Subscription = 'Subscription',
  Role = 'Role',
  Membership = 'Membership',
  Permission = 'Permission',
  Contact = 'Contact',
  Relation = 'Relation',
  RequestContentItem = 'RequestContentItem',
  ItemShare = 'ItemShare',
  SharePermission = 'SharePermission',
  Filename = 'Filename',
  Section = 'Section',
  Question = 'Question',
  RequestContentItemMessage = 'RequestContentItemMessage',
  Payment = 'Payment',
}

export enum EventType {
  Undefined = 'Undefined',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Retrieve = 'Retrieve',
  SendEmail = 'SendEmail',
  Login = 'Login',
  Logout = 'Logout',
  SendInvoices = 'SendInvoices',
  GenerateInvoiceForOrganization = 'GenerateInvoiceForOrganization',
  Download = 'Download',
  UploadContentItem = 'UploadContentItem',
  UploadUrl = 'UploadUrl',
  DownloadUrl = 'DownloadUrl',
  OpenFileRequestPreview = 'OpenFileRequestPreview',
  OpenAuditLog = 'OpenAuditLog',
}
