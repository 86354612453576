import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Discount } from '@core/models/discount.model';
import { DiscountService } from '@core/services/api/discount.service';
import { NotificationService, TitleService } from '@core/services';
import { PermissionType } from '@core/models';

@Component({
  selector: 'app-discount-details',
  templateUrl: './discount-details.component.html',
  styleUrls: ['./discount-details.component.scss'],
})
export class DiscountDetailsComponent implements OnInit {
  discountId;
  organizationId;
  discount: Discount;

  licensePercentageIsValid = true;
  userPercentageIsValid = true;

  permissionTypes = PermissionType;

  @ViewChild('titleInput') titleInput: ElementRef;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private discountService: DiscountService,
    private titleService: TitleService,
    private notifyService: NotificationService
  ) {
    this.discountId = route.snapshot.paramMap.get('discountId');
    this.organizationId = route.snapshot.paramMap.get('organizationId');
  }

  async ngOnInit(): Promise<void> {
    this.discount = (await this.discountService.get(this.discountId).toPromise()).body;
    if (this.discount !== null) {
      this.titleService.set(this.discount.title);
    }

    const newDiscount = localStorage.getItem('newDiscount');
    if (newDiscount !== null) {
      localStorage.removeItem('newDiscount');
      setTimeout(() => {
        this.titleInput.nativeElement.select();
      }, 300);
    }
  }

  checkLicenseDiscount(value: number): void {
    this.licensePercentageIsValid = value > 0 && value <= 100;
  }

  checkUserDiscount(value: number): void {
    this.userPercentageIsValid = value > 0 && value <= 100;
  }

  async removeDiscount(): Promise<void> {
    await this.discountService.delete(this.discountId).toPromise();
    this.router.navigate([`/settings/supersettings/${this.organizationId}`], {
      fragment: 'discounts',
    });
  }

  async updateDiscount(): Promise<void> {
    if (this.userPercentageIsValid && this.licensePercentageIsValid) {
      const result = await this.discountService
        .update(this.discountId, {
          title: this.discount.title,
          description: this.discount.description,
          discountEndDate: this.discount.discountEndDate,
          discountStartDate: this.discount.discountStartDate,
          licenseDiscountRate: this.discount.licenseDiscountRate,
          userDiscountRate: this.discount.userDiscountRate,
        })
        .toPromise();

      if (result.ok) {
        this.notifyService.showSuccess('DiscountUpdateSuccessfulBody', 'Update successful');
        this.titleService.set(this.discount.title);
      }
    } else {
      this.notifyService.showError('GenericErrorMessage', 'GenericErrorTitle');
    }
  }
}
