import { Component, ElementRef, HostListener, Input, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextMenuItem, ContextService } from '@core/services';

@Component({
  selector: 'app-three-dot-menu',
  templateUrl: './three-dot-menu.component.html',
})
export class ThreeDotMenuComponent {
  @Input() menu: ContextMenuItem[];
  @Input() dark: boolean;
  @ViewChildren('contextMenu') contextMenu: ElementRef;
  public sub: Subscription;

  x: number;
  y: number;

  constructor(public elementRef: ElementRef<HTMLElement>, public contextService: ContextService) {}

  get visibleItems(): ContextMenuItem[] {
    return this.menu.filter((item) =>
      typeof item.visible !== 'function' ? item.visible !== false : item.visible()
    );
  }

  public open(): void {
    const divider = (item) => item.divider !== true;
    const width = document.getElementsByClassName('context-menu')[0].clientWidth - 40;
    //filter out every non visible item
    this.menu = this.menu.filter((item) =>
      typeof item.visible !== 'function' ? item.visible !== false : item.visible()
    );
    const itemHeight = 48 * this.menu.filter((item) => !item.divider).length;
    const dividerHeight = 17 * this.menu.filter((item) => item.divider).length;
    const height = itemHeight + 10 + dividerHeight;
    const screenTop = this.elementRef.nativeElement.getBoundingClientRect().top;

    if (screenTop + height > window.innerHeight) {
      this.y = window.innerHeight - height;
    } else {
      this.y = this.elementRef.nativeElement.getBoundingClientRect().top + 50;
    }

    this.x = this.elementRef.nativeElement.getBoundingClientRect().left;
    if (this.x + width > document.body.offsetWidth) {
      this.x = this.elementRef.nativeElement.getBoundingClientRect().left - width;
    }

    this.contextService.set(this.x, this.y, this.menu);

    setTimeout(() => {
      this.contextService.animate(true);
    }, 300);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const contextMenuEnabled = targetElement.getAttribute('context-menu-enabled');
    if (contextMenuEnabled === null) {
      this.contextService.hide();
    }
  }

  showContextMenu = (event$): void => {
    this.open();
    event$.preventDefault();
  };
}
