import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BrandingService,
  NotificationService,
  OrganizationService,
  SubscriptionService,
  UserAccountService,
} from '@core/services';
import { Organization, Subscription } from '@core/models';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';

@Component({
  selector: 'app-settings-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  freeSubscription: Subscription;
  agencySubscription: Subscription;
  professionalSubscription: Subscription;
  enterPriceSubscription: Subscription;
  @ViewChild('confirmSubscription') confirmSubscription: ModalPopupComponent;
  private selectedSubscription: Subscription;
  organization: Organization;

  constructor(
    private userAccountService: UserAccountService,
    private organizationService: OrganizationService,
    public subscriptionService: SubscriptionService,
    private notifyService: NotificationService,
    private brandingService: BrandingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userAccountService.accountUpdated.subscribe(async (user) => {
      if (user.hasOrganization) {
        const organizationId = user?.organizationId;
        this.organization = await this.organizationService.get(organizationId);
        const subscriptions = await this.subscriptionService.list();
        subscriptions.sort(
          // HMM...sorting by retention in days works...but it isn't really an actual index indicator...
          // Perhaps the index indicator should be added to the subscriptions model (server-side)
          (a, b) => {
            const retentionA =
              a.workspaceRetentionInDays !== null && a.workspaceRetentionInDays !== undefined
                ? a.workspaceRetentionInDays
                : 9999999;
            const retentionB =
              b.workspaceRetentionInDays !== null && b.workspaceRetentionInDays !== undefined
                ? b.workspaceRetentionInDays
                : 9999999;
            return retentionA - retentionB;
          }
        );

        this.freeSubscription = subscriptions.find((d) => d.isStarterTier);
        this.agencySubscription = subscriptions.find((d) => d.name === 'Starter');
        this.professionalSubscription = subscriptions.find((d) => d.name === 'Professional');
        this.enterPriceSubscription = subscriptions.find((d) => d.name === 'Enterprise');
        this.brandingService.checkAndApplyBranding(this.organization, this.currentPlan);
      }
    });
  }

  get currentPlan(): Subscription {
    return this.subscriptionService.currentPlanValue;
  }

  async selectSubscription(subscription: Subscription): Promise<any> {
    this.selectedSubscription = subscription;
    this.confirmSubscription.popupActive = true;
  }

  openMailForm(): void {
    window.location.href =
      'mailto:hello@binsend.com?SUBJECT=Binsend Enterprise subscription request';
  }

  async updateSubscription(): Promise<any> {
    if (!this.organization) return;
    const subscriptionId = this.selectedSubscription.id;
    const result = await this.subscriptionService.post({
      subscriptionId,
      organizationId: this.organization.id,
    });
    if (result.ok) {
      this.ngOnInit();
      this.notifyService.showSuccess('AssignSubscriptionSuccessBody', 'Update successful');
      this.subscriptionService.currentPlan.next({ subscription: this.selectedSubscription });
      if (
        this.organization.defaultWorkspaceRetentionInDays >
        this.selectedSubscription.workspaceRetentionInDays
      ) {
        this.organization.defaultWorkspaceRetentionInDays =
          this.selectedSubscription.workspaceRetentionInDays;
        await this.organizationService.update(this.organization.id, {
          OrganizationDto: this.organization,
        });
      }
    } else {
      this.notifyService.showError('AssignSubscriptionErrorBody', 'UnableToStoreChangesTitle');
    }
    this.confirmSubscription.closePopup();
  }
}
