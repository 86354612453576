import { Component, Input, OnInit } from '@angular/core';
import { Organization, Role } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AccountService } from '@core/services';

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss'],
})
export class OrganizationUsersComponent implements OnInit {
  @Input() organization: Organization;
  userSearchText: string;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private accountService: AccountService
  ) {}

  async ngOnInit(): Promise<void> {
    for (const u of this.organization.users) {
      const user = await this.accountService.get(u.id);
      u.roles = user.roles;
    }
  }

  isValidDate(date: Date): boolean {
    return new Date(date).getFullYear() > 1970;
  }

  getRoleNames(roles: Role[]): string {
    if (roles === undefined) {
      return '';
    }
    const result = roles.map((m) => (m.isSystemRole ? this.translate.instant(m.name) : m.name));
    return result
      .filter((membershipName, i, arr) => {
        return arr.indexOf(arr.find((t) => t === membershipName)) === i;
      })
      .join(', ');
  }

  goToDetail(id: string): void {
    this.router.navigate([`/settings/users/${id}`]);
  }
}
