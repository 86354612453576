<div>
  <app-bottom-sheet #mobileMenu>
    <ng-template [ngIf]="getCurrentDirectory() === 'home'">
      <div class="options margins">
        <div class="option">
          <app-option-button
            (click)="navigateTo('user-rights')"
            [label]="'Rechten'"
            [hasNavigation]="true"
            [status]="share?.permissionType | translate"
            [materialIcon]="'folder_shared'"></app-option-button>
        </div>

        <div class="option color-red">
          <app-option-button
            (click)="deleteMember()"
            [label]="'Gebruiker verwijderen'"
            [materialIcon]="'delete'"
            [deleteColour]="true"></app-option-button>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="getCurrentDirectory() === 'user-rights'">
      <div class="header margins">
        <span class="material-icons-outlined back-arrow" (click)="navigateBack()">arrow_back</span>
        <app-option-button [label]="'Rechten'" [materialIcon]="'folder_shared'"></app-option-button>
      </div>

      <hr class="line-divider" />

      <div class="content margins">
        <div class="option" *ngFor="let permissionType of permissionTypes">
          <app-mobile-option-checkbox
            (click)="setPermission(permissionType)"
            [checked]="share?.permissionType === permissionType"
            [label]="'permissionType_' + permissionType | translate"></app-mobile-option-checkbox>
        </div>
      </div>
    </ng-template>
  </app-bottom-sheet>
</div>
