export enum ItemStatusFilters {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Received = 'Received',
  Approved = 'Approved',
  Rejected = 'Rejected',
  NotApplicable = 'NotApplicable',
  Questions = 'Questions',
  AdditionalFiles = 'AdditionalFiles',
  None = 'None',
}
