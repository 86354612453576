<div
  *ngIf="(isEdit | async) || item.value.length"
  [class.edit]="isEdit | async"
  class="add-text"
  multiSelectHover>
  <app-multi-select-checkbox
    [type]="(isEdit | async) ? 'request' : 'simple-text'"
    [item]="item"
    (checkboxChange)="checkboxChange.emit()"></app-multi-select-checkbox>

  <div *ngIf="isEdit | async" class="add-text__inputs">
    <div *ngIf="item" class="form-input">
      <mt-input
        [autofocus]="item.focused"
        placeholder="{{ isParagraph ? ('paragraph' | translate) : ('Title' | translate) }}">
        <input
          #mt="ngModel"
          #title="ngModel"
          (blur)="updateContentItem({ value: item.value })"
          [class.active]="!isParagraph"
          [(ngModel)]="item.value"
          autocomplete="off"
          type="text" />
        <textarea
          #mt="ngModel"
          #title="ngModel"
          (blur)="updateContentItem({ value: item.value })"
          [class.active]="isParagraph"
          [(ngModel)]="item.value"
          autocomplete="off"></textarea>
      </mt-input>
    </div>
    <div>
      <ng-select
        (change)="updateLines($event)"
        [(ngModel)]="isParagraph"
        [bindValue]="item.properties?.isParagraph"
        [clearable]="false"
        [placeholder]="'select-type' | translate"
        [searchable]="false">
        <ng-option *ngFor="let option of options" [value]="option.isParagraph">
          {{ option.name }}
        </ng-option>
      </ng-select>
    </div>
    <div *ngIf="isEdit | async" class="add-text__control">
      <app-three-dot-menu [menu]="menu" click-stop-propagation></app-three-dot-menu>

      <icon-button (click)="removeRequest()" icon="delete" class="remove-control"></icon-button>
    </div>
  </div>

  <div *ngIf="(isEdit | async) === false" class="add-text__inputs">
    <div class="form-input view-mode">
      <h3 *ngIf="!isParagraph" class="title">{{ item.value }}</h3>
      <p *ngIf="isParagraph" [innerText]="item.value" class="paragraph"></p>
    </div>
  </div>
</div>
