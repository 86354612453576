import { ItemStatusType, SectionItem, SectionItemBase } from './sectionitem.model';

export interface UpdateQuestionRequest {
  label?: string;
  properties?: any;
  value?: any;
  isRequired?: boolean;
  parentId?: string;
}

export enum QuestionType {
  Boolean = 'Boolean',
  MultipleChoice = 'MultipleChoice',
  OpenQuestion = 'OpenQuestion',
  Datetime = 'Datetime',
  Number = 'Number',
  Currency = 'Currency',
  Text = 'Text',
}

export interface IQuestionDto {
  label: string;
  questionType: QuestionType;
  properties: any;
  value: any;
  isRequired?: boolean;
  type?: string | number;
}

export interface MultipleChoiceItem {
  name: string;
  checked: boolean;
  isOtherOption: boolean;
  workflowId?: string;
}

export class Question extends SectionItemBase implements IQuestionDto {
  label: string;
  questionType: QuestionType;
  properties: any;
  value: any | MultipleChoiceItem[];
  isRequired?: boolean;
  status: ItemStatusType;

  constructor(question: IQuestionDto) {
    super();
    Object.assign(this, question);
  }

  get isVisible(): boolean {
    if (this.questionType === QuestionType.Text) return this.value.length;
    else
      return (
        !!this.label.length &&
        (this.questionType !== QuestionType.MultipleChoice || this.value.length)
      );
  }

  get isCompleted(): boolean {
    if (!this.isVisible) return true;
    if (
      this.questionType === QuestionType.MultipleChoice &&
      !this.value?.some((item) => item.checked)
    ) {
      return false;
    } else if (this.questionType === QuestionType.OpenQuestion && !this.value.length) {
      return false;
    }

    return true;
  }

  override get name(): string {
    return this.label;
  }

  get sectionId(): string {
    return this.parentId;
  }

  get isSection(): boolean {
    return false;
  }

  insertSectionItem(sectionItem: SectionItem, index: number): void {
    throw new Error('Cannot add a section item to a question.');
  }
}
