<tabs>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'SuperSettings | information'"
    icon="badge"
    iconClass="material-icons-outlined"
    id="information"
    tabTitle="{{ 'Information' | translate }}">
    <app-organization-information
      *ngIf="organization"
      [organization]="organization"
      [readOnly]="true"></app-organization-information>
  </tab>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'SuperSettings | users'"
    icon="people"
    iconClass="material-icons-outlined"
    id="users"
    tabTitle="{{ 'Users' | translate }}">
    <app-organization-users
      *ngIf="organization"
      [organization]="organization"></app-organization-users>
  </tab>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'SuperSettings | payments'"
    icon="account_balance"
    iconClass="material-icons-outlined"
    id="payments"
    tabTitle="{{ 'Payments' | translate }}">
    <app-invoices [organization]="organization" [readOnly]="true"></app-invoices>
  </tab>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'SuperSettings | discounts'"
    icon="local_offer"
    iconClass="material-icons-outlined"
    id="discounts"
    tabTitle="{{ 'Discounts' | translate }}">
    <app-organization-discounts
      *ngIf="organization"
      [organization]="organization"></app-organization-discounts>
  </tab>
</tabs>
