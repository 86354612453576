<app-container *hasPermission="[{ permissionType: permissionTypes.Any, section: 'AuditLog' }]">
  <div class="upgrade-button">
    <app-upgrade-button
      *ngIf="auditLogsEnabled === false"
      targetSubscriptionName="Professional"></app-upgrade-button>
  </div>
  <div
    *ngIf="auditLogsEnabled"
    infinite-scroll
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollUpDistance]="scrollUpDistance"
    (scrolled)="onScrollDown()">
    <h2>{{ 'Audit log' | translate }}</h2>
    <table>
      <thead>
        <tr>
          <th [appSort]="audits" class="sortable" data-name="creationTimestampUtc" scope="col">
            {{ 'Timestamp' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="audits" class="sortable" data-name="userName" scope="col">
            {{ 'User' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="audits" class="sortable" data-name="organization" scope="col">
            {{ 'Organization' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th>
            {{ 'Name' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th scope="col">
            {{ 'EventType' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th scope="col">
            {{ 'Identifiers' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th scope="col">
            {{ 'Payload' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let audit of audits">
          <td data-title="Timestamp">
            {{ audit?.creationTimestampUtc | date : 'medium' }}
          </td>
          <td data-title="userName">{{ audit?.userName }}</td>
          <td data-title="Organization">{{ audit?.organization }}</td>
          <td data-title="TargetId">{{ audit?.targetName }}</td>
          <td data-title="EventType">
            {{ key(audit) | translate }}
          </td>
          <td data-title="Identifiers">
            <span *ngFor="let id of params(audit)">
              {{ id }}
              <br />
            </span>
          </td>
          <td data-title="Payload">
            <span *ngFor="let line of payload(audit)">
              {{ line }}
              <br />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-container>
