import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Question, UpdateQuestionRequest } from '@core/models';
import { QuestionService } from '@app/core/services/api/question.service';
import {
  ContentService,
  ContextMenuItem,
  WorkspaceUiStateService,
  WorkspaceService,
} from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { ISectionItemComponent } from '../workspace-request.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-text',
  templateUrl: './add-text.component.html',
  styleUrls: ['./add-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTextComponent implements OnInit, ISectionItemComponent {
  @Input() item: Question;
  @Output() removeSectionItem: EventEmitter<Question> = new EventEmitter<Question>();
  @Output() checkboxChange = new EventEmitter<any>();

  menu: ContextMenuItem[] = [];

  isParagraph: boolean;
  options = [
    { isParagraph: false, name: this.translateService.instant('Title') },
    { isParagraph: true, name: this.translateService.instant('paragraph') },
  ];

  get isEdit(): Observable<boolean> {
    return this.stateService.isEdit;
  }

  constructor(
    private requestQuestionItemService: QuestionService,
    protected workspaceService: WorkspaceService,
    private stateService: WorkspaceUiStateService,
    protected contentService: ContentService,
    private translateService: TranslateService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.item.properties) {
      this.isParagraph = this.item.properties.isParagraph;
    } else {
      this.isParagraph = false;
    }
  }

  async updateLines(event): Promise<void> {
    this.item.properties = { isParagraph: event };
    await this.updateContentItem({ properties: { isParagraph: event } });
  }

  async removeRequest(): Promise<void> {
    this.removeSectionItem.emit(this.item);
    await this.requestQuestionItemService.delete(this.item);
  }

  async updateContentItem(request: UpdateQuestionRequest): Promise<any> {
    if (this.item.value.length) {
      await this.requestQuestionItemService.update(this.item, request);
    }
  }
}
