<div [class.active]="this.active" class="small-popup">
  <div *ngIf="title" class="small-popup__header">
    {{ title }}
  </div>
  <div class="small-popup__content">
    <ng-content></ng-content>
  </div>

  <div (click)="closePopup()" class="small-popup__save">
    {{ 'Save' | translate }}
  </div>
</div>
