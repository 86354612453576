import { Injectable } from '@angular/core';
import { WorkspaceService } from './api/workspace.service';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Workspace } from '../models';

class WorkspaceState {
  isEditMode = false;
  sections: string[] = [];
}

const sectionsKey = 'sections';
const isEditModeKey = 'isEditMode';
const workspacesKey = 'workspaces';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WorkspaceUiStateService {
  state: { [key: string]: WorkspaceState } = JSON.parse(localStorage.getItem('workspaces') || '{}');
  isEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  constructor(private workspaceService: WorkspaceService) {
    this.isEdit
      .pipe(untilDestroyed(this))
      .subscribe((isEdit) => this.set(this.workspaceId, isEditModeKey, isEdit));
    this.workspaceService.currentWorkspace$
      .pipe(untilDestroyed(this))
      .subscribe(this.newWorkspace.bind(this));
  }

  newWorkspace(workspace: Workspace): void {
    if (!workspace) return;
    const isEdit = this.get<boolean>(this.workspaceId, isEditModeKey);
    // Checking for isOwner is typically only necessary if one switches user accounts
    this.setIsEdit(workspace.isTemplate || (workspace.isOwner && isEdit));
  }

  set<TValue>(workspaceId, key: string, value: TValue): void {
    if (value === undefined) return;
    this.state[workspaceId] = this.state[workspaceId] || new WorkspaceState();
    this.state[workspaceId][key] = value;
    localStorage.setItem(workspacesKey, JSON.stringify(this.state));
  }

  get<TValue>(workspaceId, key: string): TValue {
    if (!workspaceId) return undefined;
    this.state[workspaceId] = this.state[workspaceId] || new WorkspaceState();
    return this.state[workspaceId][key];
  }

  toggleIsEdit(): void {
    this.setIsEdit(!this.isEdit.value);
  }

  setIsEdit(isEdit: boolean): void {
    this.isEdit.next(isEdit);
  }

  isSectionCollapsed(sectionId: string): boolean {
    return this.sections?.has(sectionId) === true;
  }

  collapseAll(): void {
    const workspace = this.workspaceService.currentWorkspace;
    const sections = workspace.sections.map((s) => s.id);
    this.setSections(sections);
  }

  expandAll(): void {
    this.setSections([]);
  }

  setSectionCollapsed(sectionId: string, collapsed: boolean): void {
    const sections = this.sections;
    if (collapsed) {
      sections.add(sectionId);
    } else {
      sections.delete(sectionId);
    }
    this.setSections(Array.from(sections.values()));
  }

  setSections(sections: string[]): void {
    this.set(this.workspaceId, sectionsKey, sections);
  }

  get sections(): Set<string> {
    return new Set<string>(this.get<string[]>(this.workspaceId, sectionsKey) || []);
  }

  get workspaceId(): string {
    return this.workspaceService.currentWorkspace?.id;
  }
}
