import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { NotFoundComponent } from './shared/notfound/notfound.component';
import { UploadGuard } from '@core/guards/upload.guard';
import { WorkspaceModule } from '@feature/workspace/workspace.module';
import { DashboardModule } from './feature/dashboard/dashboard.module';
import { SupportModule } from './feature/support/support.module';
import { ProfileModule } from './feature/profile/profile.module';
import { OrganizationModule } from './feature/organization/organization.module';
import { SettingsModule } from './feature/settings/settings.module';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule,
    runGuardsAndResolvers: 'always',
    title: 'page-title.dashboard',
    canActivate: [AuthGuard],
  },
  {
    path: 'support',
    loadChildren: () => SupportModule,
    title: 'page-title.support',
  },
  {
    path: 'profile',
    loadChildren: () => ProfileModule,
    title: 'page-title.profile',
  },
  {
    path: 'settings/organization',
    loadChildren: () => OrganizationModule,
    title: 'page-title.settings',
  },
  {
    path: 'workspace',
    data: {
      breadcrumb: 'Workspace',
    },
    loadChildren: () => WorkspaceModule,
    runGuardsAndResolvers: 'always',
    title: 'page-title.workspaces',
    canDeactivate: [UploadGuard],
  },
  {
    path: 'templates',
    data: {
      breadcrumb: 'Templates',
    },
    title: 'page-title.templates',
    loadChildren: () => WorkspaceModule,
  },
  {
    path: 'settings',
    data: {
      breadcrumb: 'Settings',
    },
    title: 'page-title.settings',
    loadChildren: () => SettingsModule,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
