<div class="request-item">
  <ng-content></ng-content>
  <div
    [class.dnd-line-wrap]="item.type !== SectionItemType.FileRequest"
    class="dnd-drop-count"
    [contextMenuParent]="childRequest">
    <app-file-request
      #childRequest
      (removeSectionItem)="removeSectionItem.emit(item)"
      (sendFocusInput)="focusInput()"
      (shareFileRequest)="shareFileRequest.emit(item)"
      (checkboxChange)="checkboxChange.emit()"
      *ngIf="item.type === 'FileRequest'"
      [isTemplate]="isTemplate"
      [isOwner]="isOwner"
      [item]="item"></app-file-request>

    <app-open-question
      #childRequest
      (removeSectionItem)="removeSectionItem.emit(item)"
      (sendFocusInput)="focusInput()"
      (checkboxChange)="checkboxChange.emit()"
      (shareFileRequest)="shareFileRequest.emit(item)"
      *ngIf="question.questionType === QuestionType.OpenQuestion"
      [item]="item"></app-open-question>

    <app-add-text
      #childRequest
      (removeSectionItem)="removeSectionItem.emit(item)"
      (checkboxChange)="checkboxChange.emit()"
      (shareFileRequest)="shareFileRequest.emit(item)"
      *ngIf="question.questionType === QuestionType.Text"
      [item]="item"></app-add-text>

    <app-checkbox-question
      #childRequest
      (removeSectionItem)="removeSectionItem.emit(item)"
      (sendFocusInput)="focusInput()"
      (checkboxChange)="checkboxChange.emit()"
      (shareFileRequest)="shareFileRequest.emit(item)"
      *ngIf="question.questionType === QuestionType.MultipleChoice"
      [question]="item"></app-checkbox-question>

    <app-content-item
      #childRequest
      (removeSectionItem)="removeSectionItem.emit(item)"
      (checkboxChange)="checkboxChange.emit()"
      *ngIf="item.type === 'ContentItem'"
      [contentItem]="item"></app-content-item>
  </div>
</div>
