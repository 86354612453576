import { Component, OnInit } from '@angular/core';
import { SubscriptionService, TitleService } from '@core/services';
import { AuditEntry, EventType, PermissionType } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { AuditService } from '@core/services/api/audit.service';

@Component({
  selector: 'app-auditlogs',
  templateUrl: './auditlogs.component.html',
  styleUrls: ['./auditlogs.component.scss'],
})
export class AuditlogsComponent implements OnInit {
  auditLogsEnabled: boolean;
  public permissionTypes = PermissionType;
  private isDescending = true;
  private orderByValue = 'CreationTimestampUtc';

  audits: AuditEntry[] = [];
  pageSize = 10;
  page = 1;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(
    private subscriptionService: SubscriptionService,
    titleService: TitleService,
    translate: TranslateService,
    private auditService: AuditService,
    private translateService: TranslateService
  ) {
    titleService.set(translate.instant('Administration'));
    this.auditLogsEnabled = subscriptionService.currentPlanValue?.auditLogsEnabled ?? false;
  }

  async ngOnInit(): Promise<void> {
    this.subscriptionService.currentPlan.subscribe(({ subscription }) => {
      this.auditLogsEnabled = subscription?.auditLogsEnabled ?? false;
    });

    this.auditService.post({
      eventType: EventType.OpenAuditLog,
    });
    for (let i = 0; i < 5; i++) await this.appendItems();
  }

  removeAscDescIcons(element: any): void {
    element.parentElement.childNodes.forEach((c) => {
      c.setAttribute('data-order', null);
    });
  }

  params(auditEnty: AuditEntry): string[] {
    const { targetTypes, targetIds } = auditEnty;
    return targetTypes.map(
      (targetType, i) => `${this.translateService.instant(targetType)}: ${targetIds[i]}`
    );
  }

  translate(key: string, interpolationParams?: any): string {
    return key ? this.translateService.instant(key, interpolationParams) : undefined;
  }

  key(audit: AuditEntry): string {
    const { eventType, targetTypes } = audit;
    let result = eventType.toString();
    const fullKey = targetTypes?.length
      ? `${eventType}_${this.translate(targetTypes.join('_'))}`
      : undefined;
    const translation = this.translate(fullKey);
    if (translation && translation !== fullKey) {
      return translation;
    } else {
      const length = targetTypes?.length;
      const targetType = this.translate(length ? targetTypes[length - 1] : undefined);
      const interpolationParams = targetTypes.length ? { targetType } : {};
      return this.translate(result, interpolationParams);
    }
  }

  payload(audit: AuditEntry): string[] {
    const { payload } = audit;
    const parsed = payload ? JSON.parse(payload) : {};
    return Object.entries(parsed)
      .filter(([_, val]) => val)
      .map(([key, val]) => `${key}: ${val}`);
  }

  async appendItems() {
    const auditEntries = await this.auditService.get(
      `orderBy=${this.orderByValue}&descending=${this.isDescending.toString()}&pageSize=${
        this.pageSize
      }&page=${this.page - 1}`
    );

    this.audits.push(...auditEntries);
  }

  async onScrollDown(): Promise<void> {
    this.page++;
    await this.appendItems();
  }
}
