import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAccountService } from '@core/services';
import { PermissionType } from '@core/models';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
  private permissions: PermissionDefinition[];

  private wasInitialized = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userAccountService: UserAccountService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userAccountService.accountUpdated.subscribe(() => this.updateView());
  }

  get currentUser() {
    return this.userAccountService.userData;
  }

  @Input()
  set hasPermission(permission: PermissionDefinition[]) {
    this.permissions = permission;
    this.updateView();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (!this.wasInitialized) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      this.wasInitialized = true;
    } else {
      this.wasInitialized = false;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    if (!this.currentUser) return false;
    for (const permission of this.permissions) {
      if (this.currentUser && this.currentUser[permission.section]) {
        const isArray = Array.isArray(this.currentUser[permission.section]);
        if (
          permission.permissionType === PermissionType.Any &&
          this.currentUser[permission.section]
        ) {
          return true;
        } else if (isArray) {
          const hasPermissionType = this.currentUser[permission.section].find(
            (d) => PermissionType[d] === permission.permissionType
          );
          if (hasPermissionType) {
            return true;
          }
        } else if (
          PermissionType[this.currentUser[permission.section]] === permission.permissionType
        ) {
          return true;
        }
      }
    }
    return false;
  }
}

export class PermissionDefinition {
  section: string;
  permissionType: PermissionType;
}
