import { Component, Input } from '@angular/core';
import { Section, Workspace } from '@app/core/models';
import { RouterService } from '@app/core/services/api/router.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() title: string;
  @Input() workspace: Workspace;

  constructor(private routerService: RouterService) {}

  get tree(): Section[] {
    const workspace = this.workspace;
    return this.routerService.sections.map((sectionId) => workspace.getSection(sectionId));
  }

  navigateToSection(sectionId: string): void {
    const sections = this.routerService.sections;
    sections.splice(sections.indexOf(sectionId), sections.length);
    this.routerService.navigateTo({ sections: [...sections, sectionId] });
  }

  navigateToHome(): void {
    this.routerService.navigateTo({ sections: [] });
  }
}
