import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent {
  popupActive = false;
  ifScrollShadow = false;
  @Input() popupTitle: string;
  @Output() onCloseDialog = new EventEmitter();

  get isPopupActive(): boolean {
    return this.popupActive;
  }

  setIsScrollShadow(event): void {
    this.ifScrollShadow = event.showScrollShadow;
  }

  getIfScrollShadow(): boolean {
    return this.ifScrollShadow;
  }

  closePopup(): void {
    this.popupActive = false;
    this.onCloseDialog.emit();
  }

  openPopup(): void {
    this.popupActive = true;
  }
}
