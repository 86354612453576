import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ItemStatusType, PermissionType, Subscription, Workspace } from '@core/models';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ArchiveWorkspacePopupComponent } from '@app/shared/pop-up/archive-workspace-popup/archive-workspace-popup.component';
import { SubscriptionService, UserAccountService } from '@core/services';

@Component({
  selector: 'app-workspace-list',
  templateUrl: './workspace-list.component.html',
  styleUrls: ['./workspace-list.component.scss'],
})
export class WorkspaceListComponent implements OnInit {
  @Input() listViewWorkspaces: Workspace[] = [];

  @Output() archiveWorkspace: EventEmitter<Workspace> = new EventEmitter<Workspace>();
  @Output() removeWorkspace: EventEmitter<Workspace> = new EventEmitter<Workspace>();
  @Output() shareWorkspace: EventEmitter<Workspace> = new EventEmitter<Workspace>();

  @ViewChild('deleteWorkspacePopup') deleteWorkspacePopup: ModalPopupComponent;
  @ViewChild('confirmApproveWorkspacesPopup')
  confirmApproveWorkspacesPopup: ModalPopupComponent;

  @ViewChild('deleteWorkspacesPopup') deleteWorkspacesPopup: ModalPopupComponent;
  @ViewChild('archiveWorkspacesPopup') archiveWorkspacesPopup: ModalPopupComponent;
  @ViewChild('archiveWorkspacePopup')
  archiveWorkspacePopup: ArchiveWorkspacePopupComponent;

  focusedWorkspace: Workspace;
  permissionTypes = PermissionType;
  searchText: string;
  statusTypes = ItemStatusType;
  currentPlan;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private userAccountService: UserAccountService
  ) {}

  public get archivedWorkspaces(): boolean {
    return (
      this.listViewWorkspaces.filter((d) => d.requestContentItemStatus === ItemStatusType.Archived)
        .length > 0
    );
  }

  get interpolationParams(): any {
    return { workspaceType: this.focusedWorkspace?.isTemplate ? 'template' : 'workspace' };
  }

  showShares(workspace: Workspace): boolean {
    return workspace.shares.length > 0 && workspace.canShare(this.userAccountService.user);
  }

  ngOnInit(): void {
    this.setCurrentPlan(this.subscriptionService.currentPlanValue);
    this.subscriptionService.currentPlan.subscribe(({ subscription }) =>
      this.setCurrentPlan(subscription)
    );

    this.listViewWorkspaces.forEach((b) => (b.isActive = false));
  }

  setCurrentPlan(subscription: Subscription): void {
    if (!subscription) return;
    this.currentPlan = subscription;
  }

  selectAll(): void {
    let allActive = true;

    for (const listViewWorkspace of this.listViewWorkspaces) {
      if (!listViewWorkspace.isActive) {
        allActive = false;
      }
    }
    this.listViewWorkspaces.forEach((b) => {
      b.isActive = !allActive;
    });
  }

  allActiveWorkspaces(): boolean {
    return (
      this.listViewWorkspaces.filter((d) => d.isActive).length === this.listViewWorkspaces.length
    );
  }

  minOneActiveWorkspace(): boolean {
    return this.listViewWorkspaces.filter((d) => d.isActive).length > 0;
  }

  processOnArchiveEvent(archivedWorkspace): void {
    this.archiveWorkspace.emit(archivedWorkspace);
  }

  archiveSelection(): void {
    this.archiveWorkspacesPopup.popupActive = false;
    for (const listViewWorkspace of this.activeWorkspaces()) {
      this.archiveWorkspace.emit(listViewWorkspace);
    }
  }

  confirmArchiveSelection(): void {
    this.archiveWorkspacesPopup.popupActive = true;
  }

  confirmRemoveSelection(): void {
    this.deleteWorkspacesPopup.popupActive = true;
  }

  removeSelection(): void {
    this.deleteWorkspacesPopup.popupActive = false;
    for (const listViewWorkspace of this.activeWorkspaces()) {
      this.removeWorkspace.emit(listViewWorkspace);
    }
  }

  openArchiveWorkspacePopup(workspace: Workspace): void {
    this.focusedWorkspace = workspace;
    this.archiveWorkspacePopup.activatePopup();
  }

  requestWorkspaceShare(workspace: Workspace): void {
    this.shareWorkspace.emit(workspace);
  }

  activeWorkspaces(): Workspace[] {
    return this.listViewWorkspaces.filter(
      (b) => b.isActive === true && b.requestContentItemStatus !== ItemStatusType.Archived
    );
  }

  confirmRemoveWorkspace(workspace: Workspace): void {
    this.focusedWorkspace = workspace;
    this.deleteWorkspacePopup.popupActive = true;
  }

  goToDetails(workspace: Workspace): void {
    this.router.navigate([`/workspace/${workspace.id}`]);
  }

  remove(): void {
    this.deleteWorkspacePopup.popupActive = false;
    this.removeWorkspace.emit(this.focusedWorkspace);
  }
}
