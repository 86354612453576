import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  constructor(private httpClient: HttpClient, private translateService: TranslateService) {}

  diffSeconds = 0;
  timeIssueWarning = '';

  ngOnInit(): void {
    this.httpClient
      .get('https://worldtimeapi.org/api/timezone/Europe/Amsterdam', {
        observe: 'response',
        responseType: 'json',
      })
      .subscribe((_response: any) => {
        const diffTime = Math.abs(
          new Date(_response.body.utc_datetime).getTime() - new Date().getTime()
        );
        this.diffSeconds = Math.ceil(diffTime / 1000);
        this.timeIssueWarning = this.translateService.instant('WrongTimeSettings');
        this.timeIssueWarning = this.timeIssueWarning.replace(
          '{seconds}',
          this.diffSeconds.toString()
        );
      });
  }
}
