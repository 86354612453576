import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-workspace-sidebar',
  templateUrl: './workspace-sidebar.component.html',
  styleUrls: ['./workspace-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSidebarComponent {
  @Input() menu: TemplateRef<object>;
  @Input() sectionId: string;

  @ViewChild('topPosition') topPosition: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  @HostListener('window:scroll', ['$event']) onScroll(): void {
    this.cdr.detectChanges();
  }

  get translateY(): string {
    if (!this.sectionId) return undefined;
    const sectionElement = document.querySelector<HTMLElement>(`[id='${this.sectionId}']`);
    if (!sectionElement || !this.topPosition) return undefined;
    const top = sectionElement.offsetTop;
    const menuOffset = this.topPosition.nativeElement.getBoundingClientRect().y > 150 ? 0 : 150;
    const offsetTop = -(this.topPosition.nativeElement.getBoundingClientRect().top - menuOffset);
    return `translateY(${top > offsetTop ? top : offsetTop}px)`;
  }
}
