<app-modal-popup
  #selectTemplateForWorkspacePopup
  [popupTitle]="append ?('SelectTemplateTitle'|translate) : ('SelectTemplateForWorkspaceTitle' | translate)"
  [showButtons]="false">
  <div class="popup-content rename-form">
    <ng-container *ngIf="selectTemplateForWorkspacePopup.popupActive === true">
      <app-template-search-panel
        (templateSelected)="addTemplate($event)"></app-template-search-panel>
    </ng-container>
  </div>
</app-modal-popup>
