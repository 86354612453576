import { Component, Input, OnInit } from '@angular/core';
import { Invoice, InvoiceStatusType, Organization } from '@core/models';
import { BlobService, InvoiceService, OrganizationService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-payments',
  templateUrl: './invoice-payments.component.html',
  styleUrls: ['./invoice-payments.component.scss'],
})
export class InvoicePaymentsComponent implements OnInit {
  @Input() invoices: Invoice[] = [];
  @Input() showOrganization = false;

  public statusTypes = InvoiceStatusType;
  organizations: Organization[];

  constructor(
    private invoiceService: InvoiceService,
    public translate: TranslateService,
    private blobService: BlobService,
    private organizationService: OrganizationService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.showOrganization) {
      this.organizations = await this.organizationService.list();
    }
  }

  async downloadInvoice(invoice: Invoice): Promise<any> {
    const r = await this.invoiceService.getDownloadUrl(invoice.id).toPromise();
    invoice.name = invoice.invoiceStorageLocation;
    invoice.download = this.blobService.download(r.body, invoice).toPromise();
  }

  getOrganizationName(organizationId): string {
    if (this.organizations) {
      for (const organization of this.organizations) {
        if (organization.id === organizationId) {
          return organization.name;
        }
      }
    }
    return this.translate.instant('Unknown');
  }
}
