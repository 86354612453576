import { PreviewService, UserAccountService, UserInfoService } from '@core/services';

import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { MessageService } from '@core/services/api/message.service';
import { Message } from '@core/models/message.model';
import { TranslateService } from '@ngx-translate/core';
import { ContentItem, FileRequest, ItemPermissionType, ItemStatusType } from '@app/core/models';

@Component({
  selector: 'app-chat-overlay',
  templateUrl: './chat-overlay.component.html',
  styleUrls: ['./chat-overlay.component.scss'],
})
export class ChatOverlayComponent implements OnChanges {
  @Input() fileIndex = 0;
  @Input() active = false;
  @Input() mobileActive = false;
  @Input() fileRequest: FileRequest;

  @ViewChild('messageArea') textarea: ElementRef;
  @ViewChild('messageRow') messageRow: ElementRef;
  @ViewChild('messagePart') messagePart: ElementRef;

  showMessages = true;
  ownEmail: string;
  message = '';
  postMessageDisabled = true;
  messages: Message[];
  tempMessages: Message[];
  canSendMessages = true;

  uploadedDate = '';
  uploadedBy = '';

  constructor(
    public previewService: PreviewService,
    public translate: TranslateService,
    private userAccountService: UserAccountService,
    private userInfoService: UserInfoService,
    private messageService: MessageService
  ) {}

  @Input() set itemMessages(message: Message[]) {
    if (message) {
      this.messages = message.sort((a, b) =>
        a.creationTimestampUtc > b.creationTimestampUtc ? -1 : 1
      );
    }
  }

  close(): void {
    this.previewService.close();
  }

  async ngOnChanges(): Promise<void> {
    this.ownEmail = this.userAccountService.user?.email;
    this.canSendMessages = this.fileRequest.status !== ItemStatusType.Archived;

    await this.readMessages();
    this.inputTextHeight();
    this.scrollDown('auto');
    this.getUploadedBy();
  }

  inputTextHeight(): void {
    const limit = 362; //height limit
    if (this.textarea) {
      //back to zero for limit calculation
      this.textarea.nativeElement.style.height = 0;
      const heightToSet = Math.min(this.textarea.nativeElement.scrollHeight, limit);
      this.textarea.nativeElement.style.height = heightToSet + 'px';

      if (this.messageRow) {
        this.messageRow.nativeElement.style.paddingBottom = heightToSet + 30 + 'px';
      }
    }
  }

  get activeContentItem(): ContentItem {
    return this.fileRequest.contentItems[this.fileIndex];
  }

  get isOwner(): boolean {
    return this.fileRequest.permissionType === ItemPermissionType.Owner;
  }

  async getUploadedBy(): Promise<void> {
    if (this.fileRequest.contentItems.length && this.activeContentItem) {
      const user = await this.userInfoService.get(this.activeContentItem.userId);
      this.uploadedDate = this.activeContentItem.lastModifiedTimestampUtc.toString();
      this.uploadedBy = user.firstname + ' ' + user.lastname;
    }
  }

  async setUsers(): Promise<void> {
    if (this.tempMessages) {
      for (const m of this.tempMessages) {
        const user = await this.userInfoService.get(m.userId);
        m.userAvatar = user.avatar;
        m.userName = user.firstname + ' ' + user.lastname;
        m.userEmail = user.email;
        m.userAvatarColor = user.avatarColor;
      }
    }
  }

  async readMessages(): Promise<void> {
    this.ownEmail = this.userAccountService.user?.email;
    const result = await this.messageService.get(this.fileRequest.id).toPromise();
    this.tempMessages = result.body;
    await this.setUsers();
    this.messages = this.tempMessages;
  }

  isEmptyOrSpacesPostMessage(message: string): void {
    this.postMessageDisabled =
      message === undefined || message === '' || message.match(/^ *$/) !== null;
  }

  async postMessage(): Promise<void> {
    this.postMessageDisabled = true;
    await this.addMessage(this.message);
    this.message = '';
    await this.readMessages();
    this.inputTextHeight();
    this.scrollDown('smooth');
  }

  async addMessage(message: string): Promise<void> {
    await this.messageService
      .post({
        message,
        requestContentItemId: this.fileRequest.id,
      })
      .toPromise();
  }

  enterMessage(event): boolean {
    if (event.shiftKey) {
      return false;
    }

    event.preventDefault();
    this.postMessage();
    return true;
  }

  scrollDown(behavior): void {
    setTimeout(() => {
      const messagePart = this.messagePart.nativeElement;
      messagePart.scroll({
        top: messagePart.scrollHeight,
        left: 0,
        behavior: behavior,
      });
    });
  }

  toggleShow(): void {
    this.showMessages = !this.showMessages;
  }
}
