import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PermissionType, Relation } from '@core/models';
import { RelationsService, TitleService } from '@core/services';
import { Router } from '@angular/router';

import { LeaveGuardService } from '@core/services/leave-guard.service';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';

@Component({
  selector: 'app-relations-detail-information',
  templateUrl: './relations-detail-information.component.html',
  styleUrls: ['./relations-detail-information.component.scss'],
})
export class RelationsDetailInformationComponent implements OnInit, AfterViewInit {
  public permissionTypes = PermissionType;
  public relations: Relation[];
  relationNames = [];
  duplicateName = '';

  @Input() public relation: Relation;
  @ViewChild('nameInput') nameInputElement: ElementRef;

  constructor(
    private leaveGuardService: LeaveGuardService,
    private titleService: TitleService,
    private relationService: RelationsService,
    private router: Router
  ) {}

  @ViewChild('warnAboutChangePagePopup')
  warnAboutChangePagePopup: ModalPopupComponent;

  async ngOnInit(): Promise<any> {
    this.leaveGuardService.subscribe(() => {
      this.warnAboutChangePagePopup.popupActive = true;
    });
    this.relations = await this.relationService.list();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.relation.creationTimestampUtc === this.relation.lastModifiedTimestampUtc) {
        this.nameInputElement.nativeElement.select();
      }
      for (const single of this.relations) {
        if (single.id !== this.relation.id) {
          this.relationNames.push(single.name.trim());
        }
      }

      if (this.relationNames.indexOf(this.relation.name.trim()) >= 0) {
        this.getNewName();
        this.relation.name = this.duplicateName;
        this.updateRelation();
      }

      this.leaveGuardService.setStatusMayLeave();
    }, 300);
  }

  getNewName(): void {
    const newName = this.relation.name.replace(/[(]\d{1,3}[)]/, '').trim();
    let max = Math.max(
      ...this.relationNames
        .filter((d) => d.startsWith(newName))
        .map((d) => {
          const match = /\((?<number>\d*)\)/gm.exec(d);
          return match ? parseInt(match[1]) : 0;
        })
    );

    this.duplicateName = `${newName} (${++max})`;
  }

  async updateRelation(): Promise<any> {
    if (this.relationNames.indexOf(this.relation.name.trim()) >= 0) {
      this.getNewName();
      this.leaveGuardService.setStatusMustStay();
    } else {
      this.relation = await this.relationService.update(this.relation.id, this.relation);
      this.titleService.set(this.relation.name);

      this.leaveGuardService.setStatusMayLeave();
    }
  }

  async navigateDespiteWarning(): Promise<void> {
    this.warnAboutChangePagePopup.popupActive = false;
    this.relation.name = this.duplicateName;
    this.titleService.set(this.duplicateName);

    this.relation = await this.relationService.update(this.relation.id, this.relation);
    this.leaveGuardService.setStatusMayLeave();

    await this.router.navigate([`/settings/relations`]);
  }

  async deleteRelation(): Promise<any> {
    this.leaveGuardService.setStatusMayLeave();
    await this.relationService.delete(this.relation.id);
    this.router.navigate([`/settings`]);
  }
}
