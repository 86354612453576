<div oncontextmenu="return false;">
  <ng-content></ng-content>

  <ul
    *ngIf="context"
    [ngClass]="[context.active ? 'active' : '', context.animate ? 'animate-position' : '']"
    [style.left.px]="context.x"
    [style.top.px]="context.y"
    class="context-menu">
    <li *ngFor="let item of list" for="contextList">
      <div
        (click)="(item.action || context.action)(item); close()"
        *ngIf="(item.action || context.action) && item.checked === undefined"
        click-stop-propagation>
        <span *ngIf="item.icon" class="{{ item.style ? item.style : 'material-icons-outlined' }}">
          {{ item.icon }}
        </span>
        {{ item.name | translate : params(item) }}
      </div>

      <div
        class="checkboxItem"
        *ngIf="(item.action || context.action) && item.checked !== undefined"
        click-stop-propagation>
        <label class="checkboxcontainer--new">
          {{ item.name | translate : params(item) }}

          <input [checked]="item.checked()" (change)="item.action()" type="checkbox" />
          <span class="checkmark--new"></span>
        </label>
      </div>

      <div
        (click)="close()"
        *ngIf="item.link && !(item.action || context.action)"
        routerLink="{{ item.link }}">
        <span *ngIf="item.icon" class="{{ item.style ? item.style : 'material-icons-outlined' }}">
          {{ item.icon }}
        </span>
        {{ item.name | translate : params(item) }}
      </div>

      <div *ngIf="!item.action && item.divider" class="divider" click-stop-propagation></div>
    </li>
  </ul>
</div>
