<div
  class="container"
  appDnd
  (fileDropped)="onFileDropped($event)"
  [hidden]="hideDropZone"
  (changeClass)="changeClass($event)"
  [ngClass]="dragAreaClass">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="fileBrowseHandler($event.target.files)" />
  <h3 class="middleText">{{ 'Drop new files' | translate }}</h3>
</div>
