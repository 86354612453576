import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MultiSelectUpdateService {
  // Observable string sources
  private updateMultiSubject = new Subject<string>();

  // Observable string streams
  onMultiUpdate$ = this.updateMultiSubject.asObservable();

  // Service message commands
  updateMultiSelect(selectId?: string) {
    this.updateMultiSubject.next(selectId);
  }
}
