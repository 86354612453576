import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select-bar',
  templateUrl: './multi-select-bar.component.html',
  styleUrls: ['./multi-select-bar.component.scss'],
})
export class MultiSelectBarComponent {
  @Input() totalSelected: number;
  @Input() active: boolean;
  @Input() isOwner: boolean = false;

  @Output() deleteFunction = new EventEmitter<any>();
}
