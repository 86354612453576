import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ContextMenuItem } from '@app/core/services';
import { IContextMenuParent } from '@app/shared/context-menu/context-menu.directive';
import { Question, QuestionType, SectionItem, SectionItemType } from '@core/models';

export interface ISectionItemComponent extends IContextMenuParent {
  removeRequest(): void;
}

@Component({
  selector: 'app-workspace-request',
  templateUrl: './workspace-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceRequestComponent implements AfterViewInit {
  @Input() isTemplate: boolean;
  @Input() item: SectionItem;

  @Output() checkboxChange = new EventEmitter<any>();

  QuestionType: typeof QuestionType = QuestionType;
  SectionItemType: typeof SectionItemType = SectionItemType;

  get menu(): ContextMenuItem[] {
    return this.childRequest?.menu || [];
  }

  @ViewChild('childRequest') childRequest: ISectionItemComponent;

  get question(): Question {
    return this.item as Question;
  }

  get isOwner(): boolean {
    return this.item.isOwner;
  }

  @Output() removeSectionItem = new EventEmitter<SectionItem>();
  @Output() sendFocusInput = new EventEmitter<any>();
  @Output() shareFileRequest = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.childRequest) {
      this.childRequest.menu.splice(
        0,
        0,
        {
          name: 'Remove',
          icon: 'delete',
          visible: () => this.item.canEdit && this.isOwner,
          action: () => this.childRequest.removeRequest(),
        },
        {
          name: 'Share',
          visible: () => this.isOwner,
          icon: 'share',
          action: () => this.shareSectionItem(),
        },
        {
          name: 'Link to relation',
          visible: () => this.isOwner && this.isTemplate,
          icon: 'account_tree',
          action: () => this.shareSectionItem(true),
        }
      );
      this.cdr.detectChanges();
    }
  }

  public shareSectionItem(link = false): void {
    this.shareFileRequest.emit(link);
  }

  focusInput(): void {
    this.sendFocusInput.emit();
  }
}
