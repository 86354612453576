<tabs>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'Administration | organizations'"
    icon="business"
    iconClass="material-icons-outlined"
    id="organizations"
    tabTitle="{{ 'Organizations' | translate }}">
    <div *ngIf="organizations" class="section-header">
      <input
        [(ngModel)]="organizationSearchText"
        class="searchBox"
        placeholder="{{ 'search' | translate }}"
        type="text" />
    </div>

    <section>
      <table *ngIf="organizations && subscriptions">
        <tr>
          <th [appSort]="organizations" class="sortable" data-name="name" scope="col">
            {{ 'Name' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="organizations" class="sortable" data-name="subscriptionName" scope="col">
            {{ 'Subscription' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th
            [appSort]="organizations"
            class="sortable"
            data-name="creationTimestampUtc"
            scope="col">
            {{ 'Created on' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th
            [appSort]="organizations"
            class="sortable"
            data-name="numberOfActiveUsers"
            scope="col">
            {{ 'Active users' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
        </tr>
        <tr
          (click)="goToDetailsPage(organization.id)"
          *ngFor="let organization of organizations | organizationFilter: organizationSearchText"
          class="table-row">
          <td>{{ organization.name }}</td>
          <td>
            {{ organization.subscriptionName }}
            <span *ngIf="!organization.subscriptionName">
              {{ 'Unknown' | translate }}
            </span>
          </td>
          <td>
            <span *ngIf="isValidDate(organization.creationTimestampUtc)">
              {{ organization.creationTimestampUtc | date: 'medium':'':translate.currentLang }}
            </span>
          </td>
          <td>{{ organization.numberOfActiveUsers }}</td>
        </tr>
      </table>
    </section>
  </tab>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'Administration | adduser'"
    icon="person_add"
    iconClass="material-icons-outlined"
    id="adduser"
    tabTitle="{{ 'Add user' | translate }}">
    <app-create-account></app-create-account>
  </tab>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'Administration | invoices'"
    icon="account_balance"
    iconClass="material-icons-outlined"
    id="invoices"
    tabTitle="{{ 'Invoices' | translate }}">
    <app-invoice-payments
      *ngIf="invoices"
      [invoices]="invoices"
      [showOrganization]="true"></app-invoice-payments>
  </tab>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'Administration | monitoring'"
    icon="donut_large"
    iconClass="material-icons-outlined"
    id="monitoring"
    tabTitle="{{ 'Monitoring' | translate }}">
    <div class="settings-row">
      <a [href]="dashboardUrl" class="btn-secondary" target="_blank">
        LinkerD Dashboard
        <img alt="linkerd" decoding="async" loading="lazy" src="assets/linkerd-icon.svg" />
      </a>
      <a [href]="metricsUrl" class="btn-secondary" target="_blank">
        Grafana realtime metrics
        <img alt="grafana-logo" decoding="async" loading="lazy" src="assets/grafana-icon.svg" />
      </a>
    </div>
  </tab>
  <tab
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]"
    [registrationUrl]="'Administration | styleguide'"
    icon="style"
    iconClass="material-icons-outlined"
    id="style"
    tabTitle="{{ 'Styleguide' | translate }}">
    <app-styleguide></app-styleguide>
  </tab>
</tabs>
