<div *ngIf="workspace" class="section-container">
  <div
    #sectionContainer
    (cdkDropListDropped)="dropItemGroup($event)"
    [cdkDropListData]="workspace.sections"
    [id]="workspace.currentSectionId"
    cdkDropList
    cdkDropListGroup
    class="group-drag-container">
    <app-workspace-add-section
      (placeTemplate)="placeTemplate(0)"
      (makeSection)="makeSection($event, 0)"
      [isActive]="workspace.canEdit"
      [empty]="workspace.isEmpty"></app-workspace-add-section>

    <section
      *ngFor="
        let section of workspace.sections
          | visibilityFilter : (isEdit | async)
          | workspaceGroupContentFilter : searchText;
        let i = index
      "
      [appWorkflowShow]="section"
      [isEdit]="isEdit | async">
      <div
        (click)="groupActivate(section.id)"
        [cdkDragData]="section"
        [cdkDragDisabled]="!workspace.canEdit"
        [class.isFolder]="section.isFolder"
        [id]="section.id"
        [class.completed]="section.isCompleted && (isEdit | async) === false"
        cdkDrag
        class="section-drag-wrap section-item"
        [class.edit]="isEdit | async"
        [appDndEnabled]="workspace.workspaceSettings.allowNonTemplateFiles && workspace.canEdit"
        (fileDropped)="upload(section, $event)"
        appDnd
        dragIcon="{{ section.isFolder ? 'folder' : 'inbox' }}"
        dragTitle="{{ 'place-file-in-name' | translate }} {{ section.name }}">
        <app-workspace-section
          (cdkDropListDropped)="dropItem($event, section)"
          (goToSection)="routerService.navigateToSection(workspace, section.id)"
          (mouseup)="setFocusedSectionItem($event)"
          (removeGroup)="groupRemove(section)"
          (makeSection)="makeSection($event, i + 1)"
          (shareSection)="shareSectionItem(section, $event)"
          (checkboxChange)="checkboxChange(section)"
          [cdkDropListData]="section.items"
          [workspace]="workspace"
          [id]="section.id"
          [section]="section"
          cdkDropList
          [cdkDropListConnectedTo]="[section.sectionType]"
          [cdkDropListEnterPredicate]="canDrop">
          <div cdkDragHandle class="drag-handle" [class.active]="isEdit | async"></div>

          <div
            *ngFor="
              let item of section.items
                | visibilityFilter : (isEdit | async)
                | itemStatusFilter : selectedStatus
                | contentFilter : searchText;
              let i = index
            "
            (mouseup)="setFocusedSectionItem($event, item)"
            [cdkDragData]="item"
            [appWorkflowShow]="item"
            [isEdit]="isEdit | async"
            cdkDrag
            class="item-drag-wrap">
            <app-workspace-request
              (removeSectionItem)="removeSectionItem(item)"
              (sendFocusInput)="setFocusedSectionItem($event, item)"
              (shareFileRequest)="shareSectionItem(item, $event)"
              (checkboxChange)="checkboxChange(item)"
              [isTemplate]="workspace.isTemplate"
              [item]="item">
              <div cdkDragHandle class="item-drag-handle" [class.active]="isEdit | async"></div>
            </app-workspace-request>
          </div>

          <p *ngIf="!(section.items | visibilityFilter : (isEdit | async)).length" class="no-items">
            {{ 'no-requests-placed' | translate }}
          </p>
          <p
            *ngIf="
              !(
                section.items
                | visibilityFilter : (isEdit | async)
                | itemStatusFilter : selectedStatus
                | contentFilter : searchText
              )?.length && selectedStatus !== 'None'
            "
            class="no-items">
            {{ 'no-requests-found' | translate }}
          </p>
        </app-workspace-section>
      </div>

      <section *ngIf="workspace.sections?.length">
        <app-workspace-add-section
          (placeTemplate)="placeTemplate(i + 1)"
          [isActive]="workspace.canEdit"
          (makeSection)="makeSection($event, i + 1)"></app-workspace-add-section>
      </section>
    </section>

    <app-workspace-files
      (cdkDropListDropped)="dropContentItem($event)"
      *ngIf="workspace.contentItems?.length"
      [cdkDropListData]="workspace.contentItems"
      [cdkDropListEnterPredicate]="isContentItem"
      [id]="workspace.currentSectionId"
      cdkDropList>
      <div
        *ngFor="
          let contentItem of workspace.contentItems
            | itemStatusFilter : selectedStatus
            | contentFilter : searchText
        "
        [cdkDragData]="contentItem"
        [appWorkflowShow]="contentItem"
        [isEdit]="isEdit | async"
        cdkDrag
        class="item-drag-wrap">
        <app-workspace-request
          (removeSectionItem)="removeSectionItem(contentItem)"
          (shareFileRequest)="shareSectionItem(contentItem, $event)"
          (checkboxChange)="checkboxChange(contentItem)"
          [item]="contentItem">
          <div cdkDragHandle class="item-drag-handle" [class.active]="isEdit | async"></div>
        </app-workspace-request>
      </div>
    </app-workspace-files>
  </div>

  <div #sidebar *ngIf="isEdit | async" class="sidebar" class="sidebar">
    <app-workspace-sidebar
      *ngIf="groupCount > 0"
      [menu]="sidebarMenu"
      [sectionId]="currentSection?.id"></app-workspace-sidebar>
  </div>
</div>

<app-multi-select-bar
  (deleteFunction)="multipleDeletePopup()"
  [totalSelected]="workspace.selectedItems.length"
  [isOwner]="workspace.isOwner"
  [active]="true">
  <app-workspace-multi-status
    [showStatusChange]="selectedItemsStatus"
    (statusChange)="multipleStatusChange($event)"></app-workspace-multi-status>
</app-multi-select-bar>

<app-modal-popup
  #deleteSectionPopup
  (eventPrimaryButtonClick)="removeSection()"
  [popupTitle]="'DeleteGroupTitle' | translate : removeGroupParams"
  [primaryButtonText]="'DeleteCaption' | translate">
  <div>{{ 'ConfirmGroupDelete' | translate : removeGroupParams }}</div>
</app-modal-popup>

<app-modal-popup
  #deleteMultiplePopup
  (eventPrimaryButtonClick)="multipleDelete()"
  [popupTitle]="'MultipleDeleteTitle' | translate : selectedItemsLength"
  [primaryButtonText]="'DeleteCaption' | translate">
  <div>{{ 'MultipleDeleteText' | translate : selectedItemsLength }}</div>
</app-modal-popup>

<app-modal-popup
  #deleteSectionItemPopup
  (eventPrimaryButtonClick)="removeSectionItemConfirm(deletableItemRequest)"
  [popupTitle]="deletingFile ? ('DeleteFileTitle' | translate) : ('DeleteRequestTitle' | translate)"
  [primaryButtonText]="'DeleteCaption' | translate">
  <div>
    {{ deletingFile ? ('ConfirmFileDelete' | translate) : ('ConfirmRequestDelete' | translate) }}
  </div>
</app-modal-popup>

<app-sharing-popup #sharePopup [workspace]="workspace"></app-sharing-popup>

<select-template-popup
  #selectTemplateForWorkspacePopup
  [append]="true"
  [position]="templateInsertIndex"></select-template-popup>
