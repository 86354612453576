<div
  [class.dark]="this.dark"
  context-menu-enabled
  [class.disabled]="disabled"
  (click)="clickHandler()">
  <!-- leftoffset is filthy little hack to make share icon appear centered -->
  <span [class.leftoffset]="icon === 'share'" class="{{ style || 'material-icons-outlined' }}">
    {{ icon }}
  </span>
</div>
<ng-content></ng-content>
