<article>
  <h3>PRIVACY NOTICE</h3>
  <p>Last updated October 28, 2020</p>
  <p>
    Thank you for choosing to be part of our community at Binsend B.V., doing business as Binsend
    ("Binsend", "we", "us", "our"). We are committed to protecting your personal information and
    your right to privacy. If you have any questions or concerns about our policy, or our practices
    with regards to your personal information, please contact us at hello@binsend.com.
  </p>
  <p>
    When you visit our website
    <a
      href="https://www.google.com/url?q=https://binsend.com/&amp;sa=D&amp;ust=1604062390690000&amp;usg=AOvVaw2uq8UHCaFARZVrgXVrRQer">
      https://binsend.com
    </a>
    , mobile application, and use our services, you trust us with your personal information. We take
    your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to
    explain to you in the clearest way possible what information we collect, how we use it and what
    rights you have in relation to it. We hope you take some time to read through it carefully, as
    it is important. If there are any terms in this privacy policy that you do not agree with,
    please discontinue use of our Sites or Apps and our services.
  </p>
  <p>
    This privacy policy applies to all information collected through our website (such as
    <a
      href="https://www.google.com/url?q=https://binsend.com/&amp;sa=D&amp;ust=1604062390691000&amp;usg=AOvVaw2YGkLl6saHRc0EnyaCddib">
      https://binsend.com
    </a>
    ), mobile application, ("Apps"), and/or any related services, sales, marketing or events (we
    refer to them collectively in this privacy policy as the "Sites").
  </p>
  <p>
    Please read this privacy policy carefully as it will help you make informed decisions about
    sharing your personal information with us.
  </p>
  <h3>TABLE OF CONTENTS</h3>
  <p>
    <button (click)="scrollToElement(informationWhat)">1. WHAT INFORMATION DO WE COLLECT?</button>
  </p>
  <p>
    <button (click)="scrollToElement(informationHow)">2. HOW DO WE USE YOUR INFORMATION?</button>
  </p>
  <p>
    <button (click)="scrollToElement(informationSharing)">
      3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
    </button>
  </p>
  <p>
    <button (click)="scrollToElement(cookies)">
      4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
    </button>
  </p>
  <p>
    <button (click)="scrollToElement(informationSocial)">
      5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
    </button>
  </p>
  <p>
    <button (click)="scrollToElement(informationRetention)">
      6. HOW LONG DO WE KEEP YOUR INFORMATION?
    </button>
  </p>
  <p>
    <button (click)="scrollToElement(informationSafe)">
      7. HOW DO WE KEEP YOUR INFORMATION SAFE?
    </button>
  </p>
  <p>
    <button (click)="scrollToElement(informationMinors)">
      8. DO WE COLLECT INFORMATION FROM MINORS?
    </button>
  </p>
  <p>
    <button (click)="scrollToElement(privacyRights)">9. WHAT ARE YOUR PRIVACY RIGHTS?</button>
  </p>
  <p>
    <button (click)="scrollToElement(doNotTrack)">10. CONTROLS FOR DO-NOT-TRACK FEATURES</button>
  </p>
  <p>
    <button (click)="scrollToElement(california)">
      11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    </button>
  </p>
  <p>
    <button (click)="scrollToElement(policyUpdates)">12. DO WE MAKE UPDATES TO THIS NOTICE?</button>
  </p>
  <p>
    <button (click)="scrollToElement(contact)">
      13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    </button>
  </p>
  <hr />
  <h3 #informationWhat>1. WHAT INFORMATION DO WE COLLECT?</h3>
  <p>Personal information you disclose to us</p>
  <p>
    In Short: We collect personal information that you provide to us such as name, address, contact
    information, passwords and security data, payment information, and social media login data.
  </p>
  <p>
    We collect personal information that you voluntarily provide to us when registering at the Sites
    or Apps, expressing an interest in obtaining information about us or our products and services,
    when participating in activities on the Sites or Apps (such as posting messages in our online
    forums or entering competitions, contests or giveaways) or otherwise contacting us.
  </p>
  <p>
    The personal information that we collect depends on the context of your interactions with us and
    the Sites or Apps, the choices you make and the products and features you use. The personal
    information we collect may include the following:
  </p>
  <p>
    Name and Contact Data. We collect your first and last name, email address, postal address, phone
    number, and other similar contact data.
  </p>
  <p>
    Credentials. We collect passwords, password hints, and similar security information used for
    authentication and account access.
  </p>
  <p>
    Payment Data. We collect data necessary to process your payment if you make purchases, such as
    your payment instrument number (such as a credit card number), and the security code associated
    with your payment instrument. All payment data is stored by our payment processor and you should
    review its privacy policies and contact the payment processor directly to respond to your
    questions.
  </p>
  <p>
    Social Media Login Data. We provide you with the option to register using social media account
    details, like your Facebook, Twitter or other social media account. If you choose to register in
    this way, we will collect the Information described in the section called "
    <button (click)="scrollToElement(informationSocial)">
      HOW DO WE HANDLE YOUR SOCIAL LOGINS
    </button>
    " below.
  </p>
  <p>
    All personal information that you provide to us must be true, complete and accurate, and you
    must notify us of any changes to such personal information.
  </p>
  <p>Information automatically collected</p>
  <p>
    In Short: Some information — such as IP address and/or browser and device characteristics — is
    collected automatically when you visit our Sites or Apps.
  </p>
  <p>
    We automatically collect certain information when you visit, use or navigate the Sites or Apps.
    This information does not reveal your specific identity (like your name or contact information)
    but may include device and usage information, such as your IP address, browser and device
    characteristics, operating system, language preferences, referring URLs, device name, country,
    location, information about how and when you use our Sites or Apps and other technical
    information. This information is primarily needed to maintain the security and operation of our
    Sites or Apps, and for our internal analytics and reporting purposes.
  </p>
  <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
  <p>Information collected through our Apps</p>
  <p>
    In Short: We may collect information regarding your geo-location, mobile device, push
    notifications, when you use our apps.
  </p>
  <p>If you use our Apps, we also collect the following information:</p>
  <ul>
    <li>
      Geo-Location Information. We may request access or permission to and track location-based
      information from your mobile device, either continuously or while you are using our mobile
      application, to provide location-based services. If you wish to change our access or
      permissions, you may do so in your device's settings.
    </li>
  </ul>
  <ul>
    <li>
      Mobile Device Access. We may request access or permission to certain features from your mobile
      device, including your mobile device's camera, storage, and other features. If you wish to
      change our access or permissions, you may do so in your device's settings.
    </li>
  </ul>
  <ul>
    <li>
      Mobile Device Data. We automatically collect device information (such as your mobile device
      ID, model and manufacturer), operating system, version information and IP address.
    </li>
  </ul>
  <ul>
    <li>
      Push Notifications. We may request to send you push notifications regarding your account or
      the mobile application. If you wish to opt-out from receiving these types of communications,
      you may turn them off in your device's settings.
    </li>
  </ul>
  <p>Information collected from other sources</p>
  <p>
    In Short: We may collect limited data from public databases, marketing partners, social media
    platforms, and other outside sources.
  </p>
  <p>
    We may obtain information about you from other sources, such as public databases, joint
    marketing partners, social media platforms (such as Facebook), as well as from other third
    parties. Examples of the information we receive from other sources include: social media profile
    information (your name, gender, birthday, email, current city, state and country, user
    identification numbers for your contacts, profile picture URL and any other information that you
    choose to make public); marketing leads and search results and links, including paid listings
    (such as sponsored links).
  </p>
  <h3 #informationHow>2. HOW DO WE USE YOUR INFORMATION?</h3>
  <p>
    In Short: We process your information for purposes based on legitimate business interests, the
    fulfillment of our contract with you, compliance with our legal obligations, and/or your
    consent.
  </p>
  <p>
    We use personal information collected via our Sites or Apps for a variety of business purposes
    described below. We process your personal information for these purposes in reliance on our
    legitimate business interests ("Business Purposes"), in order to enter into or perform a
    contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our
    legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next
    to each purpose listed below.
  </p>
  <p>We use the information we collect or receive:</p>
  <ul>
    <li>
      To facilitate account creation and logon process. If you choose to link your account with us
      to a third party account (such as your Google or Facebook account), we use the information you
      allowed us to collect from those third parties to facilitate account creation and logon
      process. See the section below headed "
      <a
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/628812/privacy-policy%23sociallogins&amp;sa=D&amp;ust=1604062390706000&amp;usg=AOvVaw3VyBaDGL26nVfVh2s8c3MU">
        HOW DO WE HANDLE YOUR SOCIAL LOGINS
      </a>
      " for further information.
    </li>
  </ul>
  <ul>
    <li>
      To send you marketing and promotional communications. We and/or our third party marketing
      partners may use the personal information you send to us for our marketing purposes, if this
      is in accordance with your marketing preferences. You can opt-out of our marketing emails at
      any time (see the "
      <a
        href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/628812/privacy-policy%23privacyrights&amp;sa=D&amp;ust=1604062390707000&amp;usg=AOvVaw2r9GPeETL2RLooaKMS2pg_">
        WHAT ARE YOUR PRIVACY RIGHTS
      </a>
      " below).
    </li>
  </ul>
  <ul>
    <li>
      To send administrative information to you. We may use your personal information to send you
      product, service and new feature information and/or information about changes to our terms,
      conditions, and policies.
    </li>
  </ul>
  <ul>
    <li>
      Fulfill and manage your orders. We may use your information to fulfill and manage your orders,
      payments, returns, and exchanges made through the Sites or Apps.
    </li>
  </ul>
  <ul>
    <li>
      To protect our Sites. We may use your information as part of our efforts to keep our Sites or
      Apps safe and secure (for example, for fraud monitoring and prevention).
    </li>
  </ul>
  <ul>
    <li>
      To enable user-to-user communications. We may use your information in order to enable
      user-to-user communications with each user's consent.
    </li>
  </ul>
  <ul>
    <li>To enforce our terms, conditions and policies.</li>
  </ul>
  <ul>
    <li>
      To respond to legal requests and prevent harm. If we receive a subpoena or other legal
      request, we may need to inspect the data we hold to determine how to respond.
    </li>
  </ul>
  <ul>
    <li>
      For other Business Purposes. We may use your information for other Business Purposes, such as
      data analysis, identifying usage trends, determining the effectiveness of our promotional
      campaigns and to evaluate and improve our Sites or Apps, products, services, marketing and
      your experience.
    </li>
  </ul>
  <h3 #informationSharing>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
  <p>
    In Short: We only share information with your consent, to comply with laws, to provide you with
    services, to protect your rights, or to fulfill business obligations.
  </p>
  <p>We may process or share your data that we hold based on the following legal basis:</p>
  <ul>
    <li>
      Consent: We may process your data if you have given us specific consent to use your personal
      information for a specific purpose.
    </li>
  </ul>
  <ul>
    <li>
      Legitimate Interests: We may process your data when it is reasonably necessary to achieve our
      legitimate business interests.
    </li>
  </ul>
  <ul>
    <li>
      Performance of a Contract: Where we have entered into a contract with you, we may process your
      personal information to fulfill the terms of our contract.
    </li>
  </ul>
  <ul>
    <li>
      Legal Obligations: We may disclose your information where we are legally required to do so in
      order to comply with applicable law, governmental requests, a judicial proceeding, court
      order, or legal process, such as in response to a court order or a subpoena (including in
      response to public authorities to meet national security or law enforcement requirements).
    </li>
  </ul>
  <ul>
    <li>
      Vital Interests: We may disclose your information where we believe it is necessary to
      investigate, prevent, or take action regarding potential violations of our policies, suspected
      fraud, situations involving potential threats to the safety of any person and illegal
      activities, or as evidence in litigation in which we are involved.
    </li>
  </ul>
  <p>
    More specifically, we may need to process your data or share your personal information in the
    following situations:
  </p>
  <ul>
    <li>
      Business Transfers. We may share or transfer your information in connection with, or during
      negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
      portion of our business to another company.
    </li>
  </ul>
  <ul>
    <li>
      Business Partners. We may share your information with our business partners to offer you
      certain products, services or promotions.
    </li>
  </ul>
  <ul>
    <li>
      Other Users. When you share personal information (for example, by posting comments,
      contributions or other content to the Sites or Apps) or otherwise interact with public areas
      of the Sites or Apps, such personal information may be viewed by all users and may be publicly
      distributed outside the Sites or Apps in perpetuity. If you interact with other users of our
      Sites or Apps and register through a social network (such as Facebook), your contacts on the
      social network will see your name, profile photo, and descriptions of your activity.
      Similarly, other users will be able to view descriptions of your activity, communicate with
      you within our Sites or Apps, and view your profile.
    </li>
  </ul>
  <h3 #cookies>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
  <p>
    In Short: We may use cookies and other tracking technologies to collect and store your
    information.
  </p>
  <p>
    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
    store information. Specific information about how we use such technologies and how you can
    refuse certain cookies is set out in our Cookie Policy.
  </p>
  <h3 #informationSocial>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
  <p>
    In Short: If you choose to register or log in to our website using a social media account, we
    may have access to certain information about you.
  </p>
  <p>
    Our Sites or Apps offers you the ability to register and login using your third party social
    media account details (like your Facebook or Twitter logins). Where you choose to do this, we
    will receive certain profile information about you from your social media provider. The profile
    Information we receive may vary depending on the social media provider concerned, but will often
    include your name, e-mail address, friends list, profile picture as well as other information
    you choose to make public.
  </p>
  <p>
    We will use the information we receive only for the purposes that are described in this privacy
    policy or that are otherwise made clear to you on the Sites or Apps. Please note that we do not
    control, and are not responsible for, other uses of your personal information by your third
    party social media provider. We recommend that you review their privacy policy to understand how
    they collect, use and share your personal information, and how you can set your privacy
    preferences on their sites and apps.
  </p>
  <h3 #informationRetention>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
  <p>
    In Short: We keep your information for as long as necessary to fulfill the purposes outlined in
    this privacy policy unless otherwise required by law.
  </p>
  <p>
    We will only keep your personal information for as long as it is necessary for the purposes set
    out in this privacy policy, unless a longer retention period is required or permitted by law
    (such as tax, accounting or other legal requirements). No purpose in this policy will require us
    keeping your personal information for longer than 1 year past the termination of the user's
    account.
  </p>
  <p>
    When we have no ongoing legitimate business need to process your personal information, we will
    either delete or anonymize it, or, if this is not possible (for example, because your personal
    information has been stored in backup archives), then we will securely store your personal
    information and isolate it from any further processing until deletion is possible.
  </p>
  <h3 #informationSafe>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
  <p>
    In Short: We aim to protect your personal information through a system of organizational and
    technical security measures.
  </p>
  <p>
    We have implemented appropriate technical and organizational security measures designed to
    protect the security of any personal information we process. However, please also remember that
    we cannot guarantee that the internet itself is 100% secure. Although we will do our best to
    protect your personal information, transmission of personal information to and from our Sites or
    Apps is at your own risk. You should only access the services within a secure environment.
  </p>
  <h3 #informationMinors>8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
  <p>
    In Short: We do not knowingly collect data from or market to children under 18 years of age.
  </p>
  <p>
    We do not knowingly solicit data from or market to children under 18 years of age. By using the
    Sites or Apps, you represent that you are at least 18 or that you are the parent or guardian of
    such a minor and consent to such minor dependent’s use of the Sites or Apps. If we learn that
    personal information from users less than 18 years of age has been collected, we will deactivate
    the account and take reasonable measures to promptly delete such data from our records. If you
    become aware of any data we may have collected from children under age 18, please contact us at
    support@baseflow.com.
  </p>
  <h3 #privacyRights>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
  <p>
    In Short: In some regions, such as the European Economic Area, you have rights that allow you
    greater access to and control over your personal information. You may review, change, or
    terminate your account at any time.
  </p>
  <p>
    In some regions (like the European Economic Area), you have certain rights under applicable data
    protection laws. These may include the right (i) to request access and obtain a copy of your
    personal information, (ii) to request rectification or erasure; (iii) to restrict the processing
    of your personal information; and (iv) if applicable, to data portability. In certain
    circumstances, you may also have the right to object to the processing of your personal
    information. To make such a request, please use the
    <a
      href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/628812/privacy-policy%23contact&amp;sa=D&amp;ust=1604062390716000&amp;usg=AOvVaw1caBpklpengaNtkWP2kZdP">
      contact details
    </a>
    provided below. We will consider and act upon any request in accordance with applicable data
    protection laws.
  </p>
  <p>
    If we are relying on your consent to process your personal information, you have the right to
    withdraw your consent at any time. Please note however that this will not affect the lawfulness
    of the processing before its withdrawal.
  </p>
  <p>
    If you are a resident in the European Economic Area and you believe we are unlawfully processing
    your personal information, you also have the right to complain to your local data protection
    supervisory authority. You can find their contact details here:
    <a
      href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;ust=1604062390717000&amp;usg=AOvVaw3Gv2Canwqkcgc06EK5acqs">
      https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
    </a>
    .
  </p>
  <p>Account Information</p>
  <p>
    If you would at any time like to review or change the information in your account or terminate
    your account, you can:
  </p>
  <ul>
    <li>Log into your account settings and update your user account.</li>
  </ul>
  <ul>
    <li>Contact us using the contact information provided.</li>
  </ul>
  <p>
    Upon your request to terminate your account, we will deactivate or delete your account and
    information from our active databases. However, some information my be retained in our files to
    prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use
    and/or comply with legal requirements.
  </p>
  <p>
    Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you
    prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If
    you choose to remove cookies or reject cookies, this could affect certain features or services
    of our Sites or Apps. To opt-out of interest-based advertising by advertisers on our Sites or
    Apps visit
    <a
      href="https://www.google.com/url?q=https://www.aboutads.info/choices/&amp;sa=D&amp;ust=1604062390719000&amp;usg=AOvVaw0e0geLFO840xFbEmjggRVl">
      https://www.aboutads.info/choices/
    </a>
    .
  </p>
  <p>
    Opting out of email marketing: You can unsubscribe from our marketing email list at any time by
    clicking on the unsubscribe link in the emails that we send or by contacting us using the
    details provided below. You will then be removed from the marketing email list — however, we
    will still need to send you service-related emails that are necessary for the administration and
    use of your account. To otherwise opt-out, you may:
  </p>
  <ul>
    <li>Note your preferences when you register an account with the site.</li>
  </ul>
  <ul>
    <li>Access your account settings and update preferences.</li>
  </ul>
  <h3 #doNotTrack>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
  <p>
    Most web browsers and some mobile operating systems and mobile applications include a
    Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not
    to have data about your online browsing activities monitored and collected. No uniform
    technology standard for recognizing and implementing DNT signals has been finalized. As such, we
    do not currently respond to DNT browser signals or any other mechanism that automatically
    communicates your choice not to be tracked online. If a standard for online tracking is adopted
    that we must follow in the future, we will inform you about that practice in a revised version
    of this Privacy Policy.
  </p>
  <h3 #california>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
  <p>
    In Short: Yes, if you are a resident of California, you are granted specific rights regarding
    access to your personal information.
  </p>
  <p>
    California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
    users who are California residents to request and obtain from us, once a year and free of
    charge, information about categories of personal information (if any) we disclosed to third
    parties for direct marketing purposes and the names and addresses of all third parties with
    which we shared personal information in the immediately preceding calendar year. If you are a
    California resident and would like to make such a request, please submit your request in writing
    to us using the contact information provided below.
  </p>
  <p>
    If you are under 18 years of age, reside in California, and have a registered account with the
    Sites or Apps, you have the right to request removal of unwanted data that you publicly post on
    the Sites or Apps. To request removal of such data, please contact us using the contact
    information provided below, and include the email address associated with your account and a
    statement that you reside in California. We will make sure the data is not publicly displayed on
    the Sites or Apps, but please be aware that the data may not be completely or comprehensively
    removed from all our systems.
  </p>
  <h3 #policyUpdates>12. DO WE MAKE UPDATES TO THIS POLICY?</h3>
  <p>
    In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
  </p>
  <p>
    >We may update this privacy policy from time to time. The updated version will be indicated by
    an updated "Revised" date and the updated version will be effective as soon as it is accessible.
    If we make material changes to this privacy policy, we may notify you either by prominently
    posting a notice of such changes or by directly sending you a notification. We encourage you to
    review this privacy policy frequently to be informed of how we are protecting your information.
  </p>
  <h3 #contact>13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
  <p>
    If you have questions or comments about this policy, you may email us at
    <a href="mailto: support@baseflow.com">support@baseflow.com</a>
    or by post to:
  </p>
  <p>
    Binsend B.V.
    <br />
    Gronausestraat 710
    <br />
    Enschede, Overijsel 7534 AM
    <br />
    Netherlands
  </p>

  <p>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
  <p>
    Based on the applicable laws of your country, you may have the right to request access to the
    personal information we collect from you, change that information, or delete it in some
    circumstances. To request to review, update, or delete your personal information, please visit:
    binsend.com/settings. We will respond to your request within 30 days.
  </p>
</article>
