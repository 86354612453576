import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@env/environment';
import { CreateRoleRequest, Role, UpdateRoleRequest } from '@core/models';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private baseUrl = `${environment.apiBaseUrl}/users/role/v1`;

  @Output() rolesUpdated = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  list(): Promise<Role[]> {
    return this.http.get<Role[]>(`${this.baseUrl}`);
  }

  update(roleId: string, request: UpdateRoleRequest): Promise<any> {
    return this.http.put(`${this.baseUrl}/${roleId}`, request);
  }

  post(request: CreateRoleRequest): Promise<string> {
    return this.http.post<string>(`${this.baseUrl}`, request).then((r) => r.body);
  }

  delete(roleId: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${roleId}`);
  }
}
