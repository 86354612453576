import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SectionType } from '@app/core/models';
import { ContextMenuItem, WorkspaceUiStateService } from '@app/core/services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-workspace-add-section',
  templateUrl: './workspace-add-section.component.html',
  styleUrls: ['./workspace-add-section.component.scss'],
})
export class WorkspaceAddSectionComponent implements OnInit {
  @Output() makeSection = new EventEmitter<SectionType>();
  @Output() placeTemplate = new EventEmitter<SectionType>();
  @Input() empty: boolean;
  @Input() isActive: boolean;

  mobileToggle = false;

  menu: ContextMenuItem[] = [];

  get isEdit(): BehaviorSubject<boolean> {
    return this.stateService.isEdit;
  }

  constructor(private stateService: WorkspaceUiStateService) {}

  ngOnInit(): void {
    this.menu = [
      {
        name: 'Add folder',
        icon: 'folder',
        visible: () => this.isEdit.value,
        action: () => this.makeSection.emit(SectionType.ContainsOnlySections),
      },
      {
        name: 'Add group',
        icon: 'inbox',
        visible: () => this.isEdit.value,
        action: () => this.makeSection.emit(SectionType.Default),
      },
      {
        name: 'Add template between',
        icon: 'description',
        visible: () => this.isEdit.value,
        action: () => this.placeTemplate.emit(),
      },
    ];
  }

  buttonGroup(): void {
    if (this.isEdit.value) {
      this.makeSection.emit(SectionType.Default);
    }
  }

  mobileClick(): void {
    this.mobileToggle = !this.mobileToggle;
  }

  buttonFolder(): void {
    if (this.isEdit.value) {
      this.makeSection.emit(SectionType.ContainsOnlySections);
    }
  }
}
