import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';
import { ContextService, QuotaService, UserAccountService, WorkspaceService } from '@core/services';
import { animate, style, transition, trigger } from '@angular/animations';
import { ItemStatusType, IWorkspaceBaseDto, PermissionType, Workspace } from '@core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('usermenu', [
      transition(':enter', [
        style({ transform: 'scale3d(.0, .0, .0)', opacity: 0 }),
        animate('200ms ease-out', style({ transform: 'scale3d(1, 1, 1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'scale3d(1, 1, 1)', opacity: 1 }),
        animate('200ms ease-out', style({ transform: 'scale3d(0, 0, 0)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class HeaderComponent {
  user;
  inviteUrl = `/${environment.authBaseUrl}/invite`;
  mobileOpen = false;
  closeHover = false;
  public permissionTypes = PermissionType;
  private numberOfWorkspacesCap = 4;

  private get workspaces(): Workspace[] {
    return this.workspaceService.workspaces;
  }

  constructor(
    private workspaceService: WorkspaceService,
    private authService: AuthService,
    public userAccountService: UserAccountService,
    public contextService: ContextService,
    private router: Router,
    private quotaService: QuotaService
  ) {
    userAccountService.accountUpdated.subscribe((user) => (this.user = user));
  }

  logout(): void {
    this.authService.logout();
  }

  login(): void {
    this.authService.login();
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  closeMenu(): void {
    this.mobileOpen = false;
    this.closeHover = true;

    setTimeout(() => {
      this.closeHover = false;
    }, 350);
  }

  menuMobile(): void {
    this.mobileOpen = !this.mobileOpen;
  }

  isActive(instruction: string): boolean {
    return this.router.isActive(instruction, false);
  }

  closeContext(): void {
    this.contextService.hide();
  }

  navigateTo(routerLink: (string | object)[]): void {
    this.router.navigate(routerLink);
  }

  top4Workspaces(): IWorkspaceBaseDto[] {
    return this.workspaces
      .filter((d) => d.requestContentItemStatus !== ItemStatusType.Archived)
      .sort((a, b) => (a.lastModifiedTimestampUtc > b.lastModifiedTimestampUtc ? -1 : 1))
      .slice(0, this.numberOfWorkspacesCap);
  }

  get exceedsQuota(): boolean {
    return this.workspaces.length >= this.quotaService.maxActiveWorkspaces;
  }
}
