import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandingSettings } from '@core/models';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandingApiService {
  private baseUrl = `${environment.apiBaseUrl}/users/branding/v1`;

  constructor(private http: HttpClient) {}

  get(url: string): Observable<BrandingSettings> {
    return this.http.post<BrandingSettings>(`${this.baseUrl}`, {
      url,
    });
  }
}
