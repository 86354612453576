import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DropzoneComponent } from '@app/shared/dropzone/dropzone.component';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { ItemStatusType, PermissionType, SectionItem, Workspace } from '@core/models';
import {
  ContentService,
  WorkspaceUiStateService,
  QuotaService,
  WorkspaceService,
} from '@core/services';
import { DownloadStatus, ProgressKind } from '@core/models/download.model';
import { SectionContainerComponent } from '../workspace-layout/section-container/section-container.component';
import { ItemStatusFilters } from '@core/models/item-status-filters';
import { UploadGuardService } from '@core/services/upload-guard.service';
import { TranslateService } from '@ngx-translate/core';
import { FileDownloaderService } from '@core/services/api/file-downloader.service';
import { FileDroppedEvent, FileUploaderService } from '@core/services/api/file-uploader.service';
import { Observable, Subject } from 'rxjs';
import { RouterService } from '@app/core/services/api/router.service';
import { MultiSelectUpdateService } from '@app/shared/multi-select/multi-select-update.service';
import { titlePattern } from '@app/core/common/constants';
import { WorkflowUpdateService } from '../workspace-layout/workspace-workflow/workflow-update.service';

@Component({
  selector: 'app-workspace-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnChanges, OnInit {
  @ViewChild('sidebar') sidebar: ElementRef;
  @ViewChild('hoverImage') hoverImage: ElementRef;
  @ViewChild('fileDropRef') fileDropRef: ElementRef<DropzoneComponent>;
  @ViewChild('sectionContainer') sectionContainer: SectionContainerComponent;
  @ViewChild('selectTemplateForWorkspacePopup')
  selectTemplateForWorkspacePopup: ModalPopupComponent;

  @ViewChild('warnAboutChangePagePopup')
  warnAboutChangePagePopup: ModalPopupComponent;

  @Input() workspace: Workspace;

  public onMobile = window.innerWidth < 601;
  public statusTypes = ItemStatusType;
  titlePattern = titlePattern;
  public itemStatusTypes = ItemStatusType;
  public permissionTypes = PermissionType;
  public ProgressKind = ProgressKind;

  searchText: string;
  saved: boolean;
  selectedStatus: ItemStatusFilters = ItemStatusFilters.None;
  selectionActive: boolean;

  constructor(
    public quotaService: QuotaService,
    protected httpClient: HttpClient,
    private workspaceService: WorkspaceService,
    private stateService: WorkspaceUiStateService,
    private routerService: RouterService,
    private uploadGuardService: UploadGuardService,
    protected contentService: ContentService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private downloadHelper: FileDownloaderService,
    private fileUploadService: FileUploaderService,
    private multiSelectUpdateService: MultiSelectUpdateService,
    private workflowUpdateService: WorkflowUpdateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }

  get isEdit(): Observable<boolean> {
    return this.stateService.isEdit;
  }

  progressCaption(progressKind: string): string {
    switch (progressKind) {
      case ProgressKind.Download:
        return this.translate.instant('cancel-download');
      case ProgressKind.Archive:
        return this.translate.instant('cancel-archive');
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.uploadGuardService.subscribe(() => (this.warnAboutChangePagePopup.popupActive = true));
    this.workflowUpdateService.updateWorkflow();
  }

  async navigateDespiteWarning(): Promise<void> {
    this.warnAboutChangePagePopup.popupActive = false;
    await this.routerService.navigateToBlockedRoute();
  }

  get downloadStatus(): Subject<DownloadStatus> {
    return this.downloadHelper.downloadPercentageSubject;
  }

  selectStatus(status: ItemStatusFilters): void {
    this.selectedStatus = status;
    this.workspace.sections.forEach((section) => (section.expanded = true));
    this.cdr.markForCheck();
  }

  async refreshWorkspace(): Promise<void> {
    await this.workspaceService.refreshWorkspace();
  }

  checkAll(): void {
    const changeTo = !this.workspace.allVisibleSelected;
    this.workspace.currentSection.getChildren<SectionItem>().forEach((item) => {
      item.selected = changeTo;
    });

    this.cdr.detectChanges();

    this.multiSelectUpdateService.updateMultiSelect();
  }

  hideSidebar(): void {
    this.sectionContainer.hideSidebar();
  }

  async upload(event: FileDroppedEvent): Promise<any> {
    const section = this.workspace.currentSection;
    await this.fileUploadService.upload(event, this.workspace, section);
  }

  uploadFromBrowser(files: FileList): void {
    this.upload({ files, position: 0 });
  }

  removeSectionItem(item): void {
    this.workspace.removeSectionItem(item.id);
  }

  async downloadWorkspace(): Promise<void> {
    await this.downloadAsZip();
  }

  onClickCancelAllDownloads(): void {
    this.downloadHelper?.cancelDownload();
  }

  async downloadAsZip(): Promise<void> {
    await this.downloadHelper.downloadWorkspace(this.workspace);
  }

  setDragOver(): void {
    this.renderer.addClass(this.hoverImage.nativeElement, 'hover');
  }

  unsetDragOver(): void {
    this.renderer.removeClass(this.hoverImage.nativeElement, 'hover');
  }

  @HostListener('window:beforeunload', ['$event'])
  warningOnRefreshOnUpload(): boolean {
    return !this.uploadGuardService.isFileBeingUploaded();
  }

  showSaved(): void {
    this.saved = true;
    setTimeout(() => {
      this.saved = false;
    }, 2000);
  }
}
