import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-option-button',
  templateUrl: './option-button.component.html',
  styleUrls: ['./option-button.component.scss'],
})
export class OptionButtonComponent {
  @Input() label: string;
  @Input() materialIcon = '';
  @Input() status = '';
  @Input() hasNavigation = false;
  @Input() deleteColour = false;
}
