<div class="empty-item-container">
  <h2 *ngIf="!title">{{ 'Page not found' | translate }}</h2>
  <h2 *ngIf="title">{{ title }}</h2>
  <p>
    {{ 'Back to' | translate }}
    <a [routerLinkActive]="['active-link']" [routerLink]="['/dashboard']">
      {{ 'dashboard' | translate }}
    </a>
    ?
  </p>
  <ng-lottie
    (animationCreated)="animationCreated($event)"
    (complete)="animationEnd()"
    [options]="options"></ng-lottie>
</div>
