<div class="workspace-settings-object">
  <h2>{{ 'Add user' | translate }}</h2>

  <div
    class="input-group"
    *hasPermission="[{ permissionType: permissionTypes.Any, section: 'SuperSettings' }]">
    <mt-input placeholder="{{ 'Id' | translate }}">
      <input
        #createIdInput
        id="id"
        type="text"
        [(ngModel)]="user.id"
        #mt="ngModel"
        name="id"
        ngDefaultControl />
    </mt-input>
  </div>

  <div class="input-group">
    <mt-input placeholder="{{ 'Email' | translate }}">
      <input
        #createUserEmailInput
        id="email"
        type="text"
        [(ngModel)]="user.email"
        #mt="ngModel"
        name="email"
        ngDefaultControl />
    </mt-input>
  </div>

  <div class="input-group">
    <mt-input placeholder="{{ 'First name' | translate }}">
      <input
        id="firstname"
        type="text"
        [(ngModel)]="user.firstname"
        #mt="ngModel"
        name="firstname"
        ngDefaultControl />
    </mt-input>
  </div>

  <div class="input-group">
    <mt-input placeholder="{{ 'Last name' | translate }}">
      <input
        id="lastname"
        type="text"
        [(ngModel)]="user.lastname"
        #mt="ngModel"
        name="lastname"
        ngDefaultControl />
    </mt-input>
  </div>

  <div class="input-group">
    <ng-select
      id="organization"
      placeholder="{{ 'Organization' | translate }}"
      [items]="organizationNames"
      [(ngModel)]="user.organization"
      name="organization"
      ngDefaultControl
      [addTag]="true"
      [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      (ngModelChange)="user.organization = $event"></ng-select>
  </div>

  <div class="actions">
    <button class="btn-primary" (click)="addUser()">
      {{ 'Add' | translate }}
    </button>
  </div>
</div>
