<tabs>
  <tab
    [registrationUrl]="'Team | General info'"
    icon="badge"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Information' | translate }}">
    <div *ngIf="membership" class="settings-row">
      <div class="workspace-settings-group">
        <h3>{{ 'General information' | translate }}</h3>

        <div class="workspace-settings-object">
          <div class="input-group">
            <label class="settings-title" for="name">
              {{ 'Name' | translate }}
            </label>
            <input
              #teamNameInput
              (ngModelChange)="membership.name = $event; updateTeam()"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
              [ngModel]="membership.name"
              autocomplete="__away"
              id="name"
              type="text" />
          </div>
        </div>
        <h3>{{ 'Other actions' | translate }}</h3>
        <button
          (click)="confirmDeleteMembership()"
          *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Teams' }]"
          class="btn-secondary-large"
          click-stop-propagation>
          <span class="material-icons material-icon-rejected">delete_outline</span>
          {{ 'DeleteTeamActionButton' | translate }}
        </button>
      </div>
    </div>
  </tab>
  <tab
    [registrationUrl]="'Team | Role'"
    icon="shield"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Roles' | translate }}">
    <app-teams-roles [membership]="membership"></app-teams-roles>
  </tab>
  <tab
    [registrationUrl]="'Team | Users'"
    icon="people"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Users' | translate }}">
    <app-teams-users [membership]="membership"></app-teams-users>
  </tab>
</tabs>

<app-modal-popup
  #warnAboutChangePagePopup
  (eventPrimaryButtonClick)="navigateDespiteWarning()"
  [popupTitle]="'WarningDoubleRelation' | translate">
  <div>
    {{ 'WarningDoubleRelationTextOne' | translate }}
    <span *ngIf="membership">{{ membership.name }}.</span>
    <br />
    {{ 'WarningDoubleRelationTextTwo' | translate }}{{ duplicateName }}
  </div>
</app-modal-popup>

<app-modal-popup
  #deleteTeamPopup
  (eventPrimaryButtonClick)="deleteTeam()"
  [popupTitle]="'DeleteTeamTitle' | translate"
  [primaryButtonText]="'DeleteCaption' | translate">
  <div>{{ 'ConfirmTeamDelete' | translate }}</div>
</app-modal-popup>
