import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateStringInMiddleFilter',
})
export class TruncateStringInMiddleFilterPipe implements PipeTransform {
  ellipsis = '...';

  transform(inputString: string, maxLength: number): string {
    if (inputString.length <= maxLength) {
      return inputString;
    }

    const spacePerPart = Math.ceil((maxLength - this.ellipsis.length) / 2.0);
    const beforeEllipsis = inputString.substr(0, spacePerPart);
    const afterEllipsis = inputString.substr(inputString.length - spacePerPart, spacePerPart);

    return `${beforeEllipsis}${this.ellipsis}${afterEllipsis}`;
  }
}

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
