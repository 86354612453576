import { Pipe, PipeTransform } from '@angular/core';
import { ItemShare } from '@core/models';

@Pipe({
  name: 'workspaceSharePermissionsFilter',
})
export class WorkspaceSharePermissionsFilterPipe implements PipeTransform {
  transform(items: ItemShare[], searchText: string): ItemShare[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return (
        it.email.toLowerCase().includes(searchText) ||
        it.name.toLowerCase().includes(searchText) ||
        it.user?.firstname.toLowerCase().includes(searchText) ||
        it.user?.lastname.toLowerCase().includes(searchText)
      );
    });
  }
}

@Pipe({
  name: 'itemShareFilterPipe',
})
export class ItemShareFilterPipe implements PipeTransform {
  transform(items: ItemShare[], searchText: string): ItemShare[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return (
        it.email.toLowerCase().includes(searchText) ||
        it.name.toLowerCase().includes(searchText) ||
        it.email.toLowerCase().includes(searchText)
      );
    });
  }
}
