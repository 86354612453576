import { ContentItem, IContentItemDto } from './content.item.model';
import {
  ISectionItemDto,
  ItemStatusType,
  SectionItem,
  SectionItemBase,
  SectionItemType,
} from './sectionitem.model';
import { Message } from './message.model';

export interface UpdateSectionItemRequest {
  messages?: Message[];
  isRequired?: boolean;
  name?: string;
  description?: string;
  contentItemId?: string;
  title?: string;
  status?: ItemStatusType;
  allowedFileTypes?: string[];
  workspaceId?: string;
  parentId?: string;
  metadata?: any;
}

export interface IFileRequestDto extends ISectionItemDto {
  contentItems: IContentItemDto[];
  messages?: Message[];
  allowedFileTypes?: string[];
  lastModifiedTimestampUtc: Date;
}

export class FileRequest extends SectionItemBase implements IFileRequestDto {
  contentItems: ContentItem[];
  allowedFileTypes?: string[];
  lastModifiedTimestampUtc: Date;
  isRequired?: boolean;
  name: string;
  metadata?: any;
  _status: ItemStatusType;
  private noContentItems = false;

  get isVisible(): boolean {
    return !!this.name.length;
  }

  get isCompleted(): boolean {
    return (
      this.status === ItemStatusType.NotApplicable ||
      this.status === ItemStatusType.Approved ||
      !this.isVisible
    );
  }

  get status(): ItemStatusType {
    if (
      (this._status === ItemStatusType.Pending || this._status === ItemStatusType.Unknown) &&
      this.contentItems.length
    )
      return ItemStatusType.Received;
    else if (
      this._status === ItemStatusType.Received &&
      !this.contentItems.length &&
      !this.noContentItems
    )
      return ItemStatusType.Pending;
    else return this._status;
  }

  set status(value: ItemStatusType) {
    this._status = value;
  }

  constructor(fileRequest: IFileRequestDto, noContentItems = false) {
    super();
    this.noContentItems = noContentItems;
    const { contentItems, messages, status, ...rest } = fileRequest;
    Object.assign(this, rest);
    this.contentItems = (contentItems || [])
      .map((ci) => new ContentItem(ci))
      .filter((ci) => ci.isUploadComplete);
    this._status = status;
    this.messages = messages || [];
  }

  getContentItem(id: string): ContentItem {
    return this.contentItems.find((ci) => ci.id === id);
  }

  insertSectionItem(sectionItem: SectionItem, index = -1): void {
    if (sectionItem.type !== SectionItemType.ContentItem)
      throw new Error('Only content items can be inserted into a file request');
    const contentItem = sectionItem as ContentItem;
    index = index === -1 ? this.contentItems.length : index;
    this.contentItems.splice(index, 0, contentItem);
    this.contentItems = [...this.contentItems];
    this.status = ItemStatusType.Received;
  }

  removeContentItem(contentItemId: string): void {
    const index = this.contentItems.findIndex((ci) => ci.id === contentItemId);
    this.contentItems.splice(index, 1);
    this.contentItems = [...this.contentItems];
    this.status = this.contentItems.length === 0 ? ItemStatusType.Pending : this.status;
  }

  getFilename(item: SectionItem, useFileRequestNames: boolean): string {
    if (useFileRequestNames) {
      if (this.contentItems.length === 1) {
        const fileName = this.contentItems[0].name;
        const [_, __, extension] = /(.*)\.([^.]*)/.exec(fileName);
        return `${this.name}.${extension}`;
      } else {
        return `${this.name}/${item.name}`;
      }
    } else {
      return item.name;
    }
  }

  isFileAllowed(file: File): boolean {
    const extension = '.' + file.name.split('.').pop();
    const allowedTypes = this.allowedFileTypes.filter((d) => d.startsWith('.'));
    return !allowedTypes.length || allowedTypes.includes(extension);
  }

  get hasContentItems(): boolean {
    return this.contentItems.length > 0;
  }

  get sectionId(): string {
    return this.parentId;
  }

  get isSection(): boolean {
    return false;
  }

  get isApprovable(): boolean {
    return this.metadata?.isApprovable ?? false;
  }
}
