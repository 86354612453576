<div class="breadcrumb">
  <div (click)="navigateToHome()" class="home">
    <span>{{ title | translate }}</span>
  </div>

  <div (click)="navigateToSection(treeItem.id)" *ngFor="let treeItem of tree" class="link">
    <span class="material-icons-outlined">chevron_right</span>
    {{ treeItem.name }}
  </div>
</div>
