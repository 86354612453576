import { Injectable } from '@angular/core';
import { IQuestionDto, Question, UpdateQuestionRequest } from '@core/models';
import { environment } from '@env/environment';
import { HttpClient } from './http.client';

const baseUrl = `${environment.apiBaseUrl}/share`;

const getBaseUrl = (workspaceId: string, sectionId: string, fileRequestId?: string): string => {
  return `${baseUrl}/v1/workspaces/${workspaceId}/sections/${sectionId}/questions${
    fileRequestId ? `/${fileRequestId}` : ''
  }`;
};

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  constructor(private http: HttpClient) {}

  update(question: Question, updateRequest: UpdateQuestionRequest): Promise<boolean> {
    const { id, workspaceId, parentId: parentSectionId } = question;
    return this.http.put<boolean>(getBaseUrl(workspaceId, parentSectionId, id), updateRequest);
  }

  post(workspaceId: string, sectionId: string, question: IQuestionDto): Promise<Question> {
    return this.http
      .post(getBaseUrl(workspaceId, sectionId), question)
      .then((r) => new Question(r.body));
  }

  async delete(question: Question): Promise<any> {
    const { id, workspaceId, parentId: parentSectionId } = question;
    return this.http.delete(getBaseUrl(workspaceId, parentSectionId, id));
  }
}
