import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Drag {
  active: boolean;
  x: number;
  y: number;
  title: string;
  icon: string;
}

@Injectable({
  providedIn: 'root',
})
export class DragService {
  drag: Drag = { active: false, x: 0, y: 0, title: '', icon: '' };
  dragChange: Subject<object> = new Subject<object>();

  get() {
    return this.drag;
  }

  getState() {
    return this.drag.active;
  }

  hide() {
    this.drag.active = false;
  }

  setPosition(x, y): void {
    this.drag.x = x;
    this.drag.y = y;
  }

  set(active, x, y, title, icon): void {
    if (title.length) {
      this.drag.active = true;
      this.drag.x = x;
      this.drag.y = y;
      this.drag.title = title;
      this.drag.icon = icon;
      this.dragChange.next(this.drag);
    } else {
      this.hide();
    }
  }
}
