import { Component } from '@angular/core';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss'],
})
export class StyleguideComponent {
  menu = [
    {
      name: 'Test context',
      icon: 'delete',
      action: () => console.log('Test'),
    },
    {
      name: 'Workspaces',
      icon: 'all_inbox',
      link: '/workspace#list',
    },
    {
      name: 'Remove',
      style: 'material-icons',
      icon: 'delete',
      action: () => console.log('REMOVE'),
    },
  ];

  bread = [
    {
      name: 'Breadcrumb',
      icon: 'delete',
      action: () => console.log('Test'),
    },
  ];

  folder = {
    title: 'Test folder',
    description: 'Test description voor de folder',
    link: '/dashboard',
  };

  group = {
    title: 'Test group',
    description: 'Test description voor de group',
  };

  item = {
    title: 'File',
    description: 'Label',
  };

  selectClick(): void {
    console.log('selecting');
  }

  alert(str) {
    alert(str);
  }
}
