import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  animations: [
    trigger('dialog', [
      transition(':enter', [
        style({ transform: 'scale3d(.0, .0, .0)', opacity: 0 }),
        animate('200ms ease-out', style({ transform: 'scale3d(1, 1, 1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'scale3d(1, 1, 1)', opacity: 1 }),
        animate('200ms ease-out', style({ transform: 'scale3d(0, 0, 0)', opacity: 0 })),
      ]),
    ]),
    trigger('background', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ModalPopupComponent {
  @Input() popupActive: boolean;
  @Input() popupTitle: string;
  @Input() showButtons = true;
  @Input() fixed = true;
  @Input() customContainerStyles = {};
  @Input() customContentStyles = {};

  @Input() secondaryButtonText;
  @Input() primaryButtonText = 'Ok';
  @Input() isItemDetails = false;

  @Input() requiresConsent = false;
  @Input() scrollableContent = false;

  @Output() eventSecondaryButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() eventPrimaryButtonClick: EventEmitter<any> = new EventEmitter<any>();

  consentGiven = false;

  constructor(translate: TranslateService) {
    this.secondaryButtonText = translate.instant('Cancel');
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.closePopup();
  }

  secondaryButtonClick(): void {
    if (this.eventSecondaryButtonClick.observers.length > 0) {
      this.eventSecondaryButtonClick.emit();
    } else {
      this.closePopup();
    }
  }

  primaryButtonClick(): void {
    if (this.eventPrimaryButtonClick.observers.length > 0) {
      // To prevent double clicks
      this.popupActive = false;
      this.eventPrimaryButtonClick.emit();
    } else {
      this.closePopup();
    }
  }

  closePopup(): void {
    this.popupActive = false;
    this.consentGiven = false;
  }

  toggleConsent($event: Event): void {
    this.consentGiven = ($event.currentTarget as any).checked;
  }
}
