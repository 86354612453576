import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LeaveGuardService } from '@core/services/leave-guard.service';

@Injectable({
  providedIn: 'root',
})
export class LeaveGuard implements CanDeactivate<unknown> {
  constructor(private leaveGuardService: LeaveGuardService) {}

  canDeactivate(): boolean {
    if (this.leaveGuardService.getStatusMayLeave()) {
      return true;
    } else {
      this.leaveGuardService.notifySubscribers();
      return false;
    }
  }
}
