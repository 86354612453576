<div *ngIf="invoices && invoices.length === 0" class="empty-item-container">
  <h2 class="no-items-title">{{ 'No invoices' | translate }}</h2>
  <img
    alt="no archived workspaces"
    class="empty-item-image"
    decoding="async"
    loading="lazy"
    src="../../../../../assets/empty-item.svg" />
</div>
<section *ngIf="invoices.length > 0">
  <table>
    <tr>
      <th [appSort]="invoices" class="sortable" data-name="invoiceNumber" scope="col">
        {{ 'InvoiceNumber' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th
        *ngIf="showOrganization"
        [appSort]="organizations"
        class="sortable"
        data-name="name"
        scope="col">
        {{ 'Name' | translate }}
      </th>
      <th [appSort]="invoices" class="sortable" data-name="invoiceDate" scope="col">
        {{ 'InvoiceDate' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th [appSort]="invoices" class="sortable" data-name="invoiceTotal" scope="col">
        {{ 'InvoiceTotal' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th [appSort]="invoices" class="sortable" data-name="invoiceStatus" scope="col">
        {{ 'Status' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th>{{ 'Actions' | translate }}</th>
    </tr>
    <tr
      (click)="downloadInvoice(invoice)"
      *ngFor="let invoice of invoices"
      class="table-row"
      click-stop-propagation>
      <td>{{ invoice.invoiceNumber }}</td>
      <td *ngIf="showOrganization">
        {{ getOrganizationName(invoice.organizationId) }}
      </td>
      <td class="strech">
        {{ invoice.invoiceDate | date : 'mediumDate' : '' : translate.currentLang }}
      </td>
      <td>
        {{ invoice.invoiceTotal | currency : 'EUR' }}
      </td>
      <td>
        {{ statusTypes[invoice.invoiceStatus] | translate }}
      </td>
      <td>
        <label (click)="downloadInvoice(invoice)" click-stop-propagation>
          <span class="material-icons-outlined">file_download</span>
        </label>
      </td>
    </tr>
  </table>
</section>
