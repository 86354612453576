import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BottomSheetComponent } from '@app/shared/bottom-sheet/bottom-sheet.component';
import { IItemShare, ItemPermissionType } from '@core/models';

export interface IChangePermissionRequest {
  member: IItemShare;
  permissionType: ItemPermissionType;
  isNewUser: boolean;
}

export interface IDeleteShareRequest {
  member: IItemShare;
  isNewUser: boolean;
}

@Component({
  selector: 'app-bottom-sheet-share-content',
  templateUrl: './bottom-sheet-share-content.component.html',
  styleUrls: ['./bottom-sheet-share-content.component.scss'],
})
export class BottomSheetShareContentComponent {
  public permissionTypes: string[] = Object.keys(ItemPermissionType);

  @ViewChild('mobileMenu') mobileMenu: BottomSheetComponent;
  @Output() changePermissionEvent = new EventEmitter<IChangePermissionRequest>();
  @Output() deleteMemberEvent = new EventEmitter<IDeleteShareRequest>();
  @Input() label = '';
  public share: IItemShare;

  private isNewUser: boolean;
  private directives: string[] = ['home'];

  openMobileMenu(share: IItemShare, isNewUser: boolean = true): void {
    this.share = share;
    this.mobileMenu.open();
    this.isNewUser = isNewUser;
  }

  deleteMember(): void {
    this.deleteMemberEvent.emit({
      member: this.share,
      isNewUser: this.isNewUser,
    });
    this.mobileMenu.close();
    this.share = null;
  }

  setPermission(permissionType: ItemPermissionType): void {
    this.share.permissionType = permissionType;
    this.changePermissionEvent.emit({
      member: this.share,
      permissionType,
      isNewUser: this.isNewUser,
    });
  }

  navigateTo(directive: string): void {
    this.directives.push(directive);
  }

  navigateBack(): void {
    if (this.directives.length > 1) {
      this.directives.pop();
    }
  }

  getCurrentDirectory(): string {
    return this.directives[this.directives.length - 1];
  }
}
