<section>
  <table>
    <tr>
      <th>
        <div class="select-field">
          <label class="checkboxcontainer">
            <input (click)="selectAll()" [checked]="allActiveWorkspaces()" type="checkbox" />
            <span class="checkmark"></span>
          </label>
          <div *ngIf="minOneActiveWorkspace()" class="show-info">
            <span class="material-icons">arrow_drop_down</span>
            <div class="share-info">
              <ul class="menu">
                <li *ngIf="!archivedWorkspaces">
                  <button (click)="confirmArchiveSelection()">
                    <span class="material-icons-outlined">archive</span>
                    {{ 'Archive selection' | translate }}
                  </button>
                </li>
                <li>
                  <button (click)="confirmApproveSelection()">
                    <span class="material-icons material-icon-approved">check_circle</span>
                    {{ 'Approve selection' | translate }}
                  </button>
                </li>
                <li>
                  <button
                    (click)="confirmRemoveSelection()"
                    *hasPermission="[
                      {
                        permissionType: permissionTypes.Delete,
                        section: 'Workspaces'
                      }
                    ]"
                    class="remove-button">
                    <span class="material-icons material-icon-rejected">delete_outline</span>
                    {{ 'Remove selection' | translate }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </th>
      <th [appSort]="listViewWorkspaces" class="sortable" data-name="name" scope="col">
        {{ 'Name' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th>{{ 'Shared with' | translate }}</th>
      <th [appSort]="listViewWorkspaces" class="sortable" data-name="expiryDate" scope="col">
        {{ 'Workspace expiry date' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th
        [appSort]="listViewWorkspaces"
        class="sortable"
        data-name="lastModifiedTimestampUtc"
        scope="col">
        {{ 'Last modified' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th class="sortable" data-name="allRcis" scope="col">
        {{ 'Progress' | translate }}
      </th>
      <th>{{ 'Actions' | translate }}</th>
    </tr>
    <tr
      (click)="goToDetails(workspace)"
      *ngFor="let workspace of listViewWorkspaces"
      [class.warning-workspace]="workspace.aboutToExpire"
      class="table-row">
      <td>
        <label class="checkboxcontainer" click-stop-propagation>
          <input [(ngModel)]="workspace.isActive" type="checkbox" />
          <span class="checkmark"></span>
        </label>
      </td>
      <td>{{ workspace.name }}</td>
      <td>
        <div *ngIf="showShares(workspace)" class="share-info">
          <div *ngFor="let share of workspace.shares | slice : 0 : 5; let i = index">
            <app-user-avatar
              showTooltip="true"
              [avatarColor]="share.avatarColor"
              [class]="'avatar' + i"
              [userName]="share.name"></app-user-avatar>
          </div>
          <span *ngIf="workspace.shares.length > 5" class="others">
            {{ workspace.shares.length - 5 }} {{ 'others' | translate }}...
          </span>
        </div>
      </td>
      <td>
        <div>
          <span class="table-date" *ngIf="!workspace.showExpire">
            {{ 'Does not expire' | translate }}
          </span>
          <span class="table-date" *ngIf="workspace.showExpire">
            {{ workspace.expiryDate | date : 'mediumDate' : '' : translate.currentLang }}
          </span>
          <span
            *ngIf="workspace.aboutToExpire"
            class="material-icons-outlined material-icon-rejected">
            report_problem
          </span>
        </div>
      </td>
      <td>
        <div>
          <span class="table-date">
            {{
              workspace.lastModifiedTimestampUtc | date : 'mediumDate' : '' : translate.currentLang
            }}
          </span>
        </div>
      </td>
      <td>
        <div class="statistics-bar" *ngIf="workspace.allRcis.length > 0">
          <app-workspace-statistics
            [workspace]="workspace"
            [showInvisible]="false"></app-workspace-statistics>
        </div>
      </td>
      <td click-stop-propagation>
        <div *ngIf="workspace.isOwner">
          <span
            (click)="openArchiveWorkspacePopup(workspace)"
            *ngIf="!archivedWorkspaces"
            class="material-icons-outlined">
            archive
          </span>
          <span
            (click)="confirmRemoveWorkspace(workspace)"
            class="material-icons material-icon-rejected">
            delete_outline
          </span>
          <span (click)="requestWorkspaceShare(workspace)" class="material-icons">share</span>
        </div>
      </td>
    </tr>
  </table>
</section>

<app-modal-popup
  #deleteWorkspacesPopup
  (eventPrimaryButtonClick)="removeSelection()"
  [popupTitle]="'DeleteWorkspacesTitle' | translate"
  [primaryButtonText]="'DeleteWorkspacesActionButton' | translate">
  <div>
    {{ 'ConfirmWorkspacesDelete' | translate }}

    <ul *ngFor="let workspace of activeWorkspaces()">
      <li>{{ workspace.name }}</li>
    </ul>
  </div>
</app-modal-popup>

<app-modal-popup
  #archiveWorkspacesPopup
  (eventPrimaryButtonClick)="archiveSelection()"
  [popupTitle]="'ArchiveWorkspacesTitle' | translate"
  [primaryButtonText]="'Archive workspaces' | translate">
  <div>
    {{ 'ConfirmWorkspacesArchive' | translate }}

    <ul *ngFor="let workspace of activeWorkspaces()">
      <li>{{ workspace.name }}</li>
    </ul>
  </div>
</app-modal-popup>

<app-modal-popup
  #deleteWorkspacePopup
  (eventPrimaryButtonClick)="remove()"
  [popupTitle]="'DeleteWorkspaceTitle' | translate"
  [primaryButtonText]="'DeleteWorkspaceActionButton' | translate : interpolationParams">
  <div>{{ 'ConfirmWorkspaceDelete' | translate }}</div>
</app-modal-popup>

<app-archive-workspace-popup
  #archiveWorkspacePopup
  (changeEventEmitter)="processOnArchiveEvent($event)"
  [workspace]="focusedWorkspace"></app-archive-workspace-popup>
