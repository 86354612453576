<div #topPosition></div>
<ul *ngIf="menu" class="workspace-sidebar" [style.transform]="translateY">
  <li *ngFor="let item of menu">
    <div (click)="item.action()" *ngIf="item.action">
      <span class="{{ item.style ? item.style : 'material-icons-outlined' }}">
        {{ item.icon ? item.icon : 'grade' }}
      </span>

      <span class="text">
        {{ item.name | translate }}
      </span>
    </div>
  </li>
</ul>
