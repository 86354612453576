<div
  [ngClass]="[isUser ? 'round' : 'square']"
  [style.background-color]="avatarColor"
  class="avatar"
  [tooltip]="userName" isActivated="{{showTooltip.toString()}}"
>
  <img
    *ngIf="avatar && avatar !== ''"
    [src]="avatar"
    alt="avatar"
    decoding="async"
    loading="lazy" />
  <span *ngIf="!avatar || avatar === ''" class="initials">
    {{ initials }}
  </span>
</div>
