import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Permission, RolePermissionRequest } from '@core/models';
import { HttpClient } from './http.client';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private baseUrl = `${environment.apiBaseUrl}/users/permissions/v1`;

  constructor(private http: HttpClient) {}

  get(id: string): Promise<Permission> {
    return this.http.get<Permission>(`${this.baseUrl}/${id}`);
  }

  list(): Promise<Permission[]> {
    return this.http.get<Permission[]>(`${this.baseUrl}`);
  }

  assignToRole(request: RolePermissionRequest): Promise<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiBaseUrl}/users/role/permission/v1`, request);
  }

  removeFromRole(payload: RolePermissionRequest): Promise<HttpResponse<any>> {
    return this.http.delete(`${environment.apiBaseUrl}/users/role/permission/v1`, {
      body: payload,
    });
  }
}
