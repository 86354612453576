import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService, UserAccountService } from '@core/services';
import { EditAccountRole, UserAccount } from '@core/models';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-details.component.html',
  styleUrls: ['./users-details.component.scss'],
})
export class UsersDetailsComponent implements OnInit {
  public userId: string;
  public user: UserAccount;
  public editRole: EditAccountRole;
  EditRole = EditAccountRole;

  constructor(
    route: ActivatedRoute,
    private userAccountService: UserAccountService,
    private accountService: AccountService
  ) {
    this.userId = route.snapshot.paramMap.get('id');
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.accountService.get(this.userId);

    if (this.userAccountService.user?.id === this.userId) {
      this.editRole = EditAccountRole.FullAccess;
    } else if (this.userAccountService.user.organizationId === this.user?.organizationId) {
      this.editRole = EditAccountRole.PartialAccess;
    } else {
      this.editRole = EditAccountRole.NoAccess;
    }
  }
}
