import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Section, SectionType, Workspace } from '@app/core/models';
import { ContextMenuItem, WorkspaceUiStateService } from '@app/core/services';
import { SectionService } from '@app/core/services/api/section.service';
import { FileDownloaderService } from '@app/core/services/api/file-downloader.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-workspace-section',
  templateUrl: './workspace-section.component.html',
  styleUrls: ['./workspace-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSectionComponent implements OnInit {
  @Input() section: Section;

  @Output() removeGroup = new EventEmitter<string>();
  @Output() goToSection = new EventEmitter<string>();
  @Output() makeSection = new EventEmitter<SectionType>();
  @Output() shareSection = new EventEmitter<boolean>();
  @Output() checkboxChange = new EventEmitter<void>();

  menu: ContextMenuItem[] = [];

  @Input() workspace: Workspace;

  get isEdit(): BehaviorSubject<boolean> {
    return this.stateService.isEdit;
  }

  constructor(
    private sectionService: SectionService,
    private stateService: WorkspaceUiStateService,
    private downloadHelper: FileDownloaderService
  ) {}

  get interpolationParams(): any {
    return { sectionType: this.section.isFolder ? 'folder' : 'group', toLowerCase: true };
  }

  ngOnInit(): void {
    this.section.expanded = !this.stateService.isSectionCollapsed(this.section.sectionId);
    this.menu = [
      {
        name: 'remove-section',
        icon: 'delete',
        visible: () => this.section.isOwner,
        interpolationParams: this.interpolationParams,
        action: () => this.removeGroup.emit('eventDesc'),
      },
      {
        name: 'download-section',
        icon: 'file_download',
        interpolationParams: this.interpolationParams,
        visible: () =>
          (this.section.contentItems.length > 0 ||
            this.section.rcis.some((rci) => rci.hasContentItems)) &&
          !this.downloadHelper.isDownloading,
        action: () => this.downloadSection(),
      },
      {
        name: 'add-folder-below',
        icon: 'folder',
        visible: () => this.isEdit.value && this.workspace.currentSection?.canEdit,
        interpolationParams: this.interpolationParams,
        action: () => this.makeSection.emit(SectionType.ContainsOnlySections),
      },
      {
        name: 'add-group-below',
        icon: 'inbox',
        visible: () => this.isEdit.value && this.workspace.currentSection?.canEdit,
        interpolationParams: this.interpolationParams,
        action: () => this.makeSection.emit(SectionType.Default),
      },
      {
        name: 'share-section',
        icon: 'share',
        visible: () => this.section.isOwner,
        interpolationParams: this.interpolationParams,
        action: () => this.shareSection.emit(),
      },
      {
        name: 'link-section',
        icon: 'account_tree',
        visible: () => this.section.isOwner && this.workspace.isTemplate,
        interpolationParams: this.interpolationParams,
        action: () => this.shareSection.emit(true),
      },
    ];
  }

  get isFolder(): boolean {
    return this.section.isFolder;
  }

  get animationState(): string {
    return this.section.expanded ? 'out' : 'in';
  }

  sectionClick($event: MouseEvent): void {
    if (!this.isFolder && !this.isEdit.value) this.section.expanded = !this.section.expanded;
    else this.gotoSection();
    if (!this.isEdit.value || this.isFolder) $event.stopPropagation();
  }

  navigationButtonClick(): void {
    if (!this.isFolder) {
      this.section.expanded = !this.section.expanded;
      this.stateService.setSectionCollapsed(this.section.sectionId, !this.section.expanded);
    } else {
      this.gotoSection();
    }
  }

  gotoSection(): void {
    if (this.isFolder) this.goToSection.emit('eventDesc');
  }

  async downloadSection(): Promise<void> {
    await this.downloadHelper.downloadSection(this.workspace, this.section);
  }

  updateSection(): Promise<any> {
    return this.sectionService.update(this.workspace.id, this.section.id, this.section);
  }
}
