import { Organization, Subscription } from '@core/models';
import { Injectable } from '@angular/core';
import { BrandingApiService } from '@core/services/api/branding.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  eventNotifier: Subject<boolean> = new Subject<boolean>();

  constructor(private brandingApiService: BrandingApiService) {}

  public async checkAndApplyBranding(
    organization: Organization,
    subscription: Subscription
  ): Promise<any> {
    // eslint-disable-next-line no-useless-escape
    const domain = `https://${/:\/\/([^\/]+)/.exec(window.location.href)[1]}`;
    const custom_branding = await this.brandingApiService
      .get(encodeURIComponent(domain))
      .toPromise();
    if (custom_branding.accentColor) {
      organization.accentColor = custom_branding.accentColor;
    }
    if (custom_branding.primaryColor) {
      organization.primaryColor = custom_branding.primaryColor;
    }
    if (custom_branding.logoUrl) {
      organization.logoUrl = custom_branding.logoUrl;
    }
    if (custom_branding.loginImageUrl) {
      organization.loginImageUrl = custom_branding.loginImageUrl;
    }

    if (subscription?.customBranding) {
      if (organization.accentColor) {
        document.documentElement.style.setProperty('--accent-color', organization.accentColor);

        document.documentElement.style.setProperty(
          '--accent-color-semi',
          `${organization.accentColor}12`
        );
        document.documentElement.style.setProperty(
          '--accent-color-hover',
          `${organization.accentColor}BF`
        );
      } else {
        document.documentElement.style.setProperty('--accent-color', '#626262');
        document.documentElement.style.setProperty('--accent-color-semi', '#62626212');
        document.documentElement.style.setProperty('--accent-color-hover', '#626262BF');
      }

      if (organization.primaryColor) {
        this.changeThemeColor('#2991ff');
        document.documentElement.style.setProperty('--primary-color', organization.primaryColor);

        document.documentElement.style.setProperty(
          '--primary-color-semi',
          `${organization.primaryColor}12`
        );
      } else {
        this.changeThemeColor(organization.primaryColor);
        document.documentElement.style.setProperty('--primary-color', '#2991ff');
        document.documentElement.style.setProperty('--primary-color-semi', '#2991ff12');
      }

      if (organization.logoUrl) {
        document.documentElement.style.setProperty(
          '--branding-logo',
          `url('${organization.logoUrl}')`
        );
      } else {
        document.documentElement.style.setProperty(
          '--branding-logo',
          `url('/assets/binsend-logo-horizontal-white@3x.png')`
        );
      }
    } else {
      document.documentElement.style.setProperty('--accent-color', '#626262');
      document.documentElement.style.setProperty('--accent-color-semi', '#62626212');
      document.documentElement.style.setProperty('--accent-color-hover', '#626262BF');
      document.documentElement.style.setProperty('--primary-color', '#2991ff');
      document.documentElement.style.setProperty('--primary-color-semi', '#2991ff12');
      document.documentElement.style.setProperty(
        '--branding-logo',
        `url('/assets/binsend-logo-horizontal-white@3x.png')`
      );
    }
    this.eventNotifier.next(true);
  }

  changeThemeColor(color: string): void {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    metaThemeColor.setAttribute('content', color);
  }
}
