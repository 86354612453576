import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import {
  WorkflowUpdate,
  WorkflowUpdateService,
} from '@feature/workspace/details/workspace-layout/workspace-workflow/workflow-update.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SectionItem } from '@app/core/models';

@UntilDestroy()
@Directive({
  selector: '[appWorkflowShow]',
})
export class WorkflowShowDirective implements OnInit {
  @Input() appWorkflowShow: SectionItem;
  @Input() isEdit: boolean;

  constructor(
    private workflowUpdateService: WorkflowUpdateService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  show(show: boolean): void {
    show = show || this.isEdit;
    if (this.isEdit) {
      this.renderer.addClass(this.el.nativeElement, 'coupled-item');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'coupled-item');
    }

    const display = show || this.isEdit ? 'block' : 'none';
    if (this.el.nativeElement.style.display === display) return;
    this.el.nativeElement.style.display = display;
  }

  ngOnInit(): void {
    this.workflowUpdateService.onWorkflowUpdate$
      .pipe(untilDestroyed(this))
      .subscribe((update) => this.onWorkflowChange(update));
  }

  public onWorkflowChange(update: WorkflowUpdate): void {
    if (update?.workflowId !== this.appWorkflowShow.id) return;
    this.show(update.show);
  }
}
