import {
  AfterContentInit,
  Directive,
  EventEmitter,
  OnDestroy,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appScrollState]',
})

/*
Emits true if the user can scroll down on the element, false otherwise.
* */
export class ScrollStateDirective implements OnDestroy, AfterContentInit {
  @Output() appScrollState: EventEmitter<any> = new EventEmitter();
  private readonly destroy$: Subject<void> = new Subject();
  private readonly hostElement: HTMLElement;
  private resizeEvent: ResizeObserver;
  private debounceTimer: ReturnType<typeof setTimeout>;

  constructor(readonly vcRef: ViewContainerRef) {
    this.hostElement = vcRef.element.nativeElement;
    this.setResizeEventListener(this.hostElement);
    fromEvent(this.hostElement, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getScrollStatus();
      });
  }

  public ngAfterContentInit(): void {
    this.getScrollStatus();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resizeEvent.disconnect();
  }

  setResizeEventListener(element: HTMLElement): void {
    this.resizeEvent = new ResizeObserver(() => {
      this.getScrollStatus();
    });
    this.resizeEvent.observe(element);
  }

  /*Scrolled al the way down or no scroll on element*/
  private isScrolledDown(): boolean {
    if (this.hostElement.scrollHeight === 0) {
      return false;
    }
    return (
      Math.round(this.hostElement.scrollHeight - this.hostElement.scrollTop) ===
      this.hostElement.clientHeight
    );
  }

  private canScrollDown(): boolean {
    return (
      this.hostElement.scrollTop + this.hostElement.clientHeight < this.hostElement.scrollHeight
    );
  }

  private getScrollStatus(): void {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      if (this.isScrolledDown()) {
        this.appScrollState.emit({ showScrollShadow: false });
      } else if (this.canScrollDown()) {
        this.appScrollState.emit({ showScrollShadow: true });
      }
    }, 50);
  }
}
