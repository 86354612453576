import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Membership, PermissionType } from '@core/models';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MembershipService, TitleService } from '@core/services';
import { LeaveGuardService } from '@core/services/leave-guard.service';

@Component({
  selector: 'app-teams-detail',
  templateUrl: './teams-detail.component.html',
  styleUrls: ['./teams-detail.component.scss'],
})
export class TeamsDetailComponent implements OnInit {
  @ViewChild('deleteTeamPopup') deleteTeamPopup: ModalPopupComponent;
  @ViewChild('warnAboutChangePagePopup')
  warnAboutChangePagePopup: ModalPopupComponent;

  @ViewChild('teamNameInput') teamNameInput: ElementRef;
  private readonly membershipId: string;
  memberships: Membership[];
  membershipNames = [];
  duplicateName: string;
  membership: Membership;
  public permissionTypes = PermissionType;

  constructor(
    private membershipService: MembershipService,
    private router: Router,
    private titleService: TitleService,
    private leaveGuardService: LeaveGuardService,
    route: ActivatedRoute
  ) {
    this.membershipId = route.snapshot.paramMap.get('id');
  }

  async ngOnInit(): Promise<void> {
    this.leaveGuardService.subscribe(() => {
      this.warnAboutChangePagePopup.popupActive = true;
    });
    this.memberships = await this.membershipService.list();
    for (const membership of this.memberships) {
      if (membership.id !== this.membershipId) {
        this.membershipNames.push(membership.name);
      }
    }
    if (!this.membership) {
      this.membership = this.memberships.find((r) => r.id === this.membershipId);
      this.titleService.set(this.membership.name);
    }
    this.leaveGuardService.setStatusMayLeave();
    const newTeam = localStorage.getItem('newTeam');
    if (newTeam) {
      localStorage.removeItem('newTeam');
      setTimeout(() => {
        this.teamNameInput?.nativeElement.select();
      }, 300);
    }
  }

  getNewName(): void {
    const newName = this.membership.name.replace(/[(]\d{1,3}[)]/, '').trim();
    let max = Math.max(
      ...this.membershipNames
        .filter((d) => d.startsWith(newName))
        .map((d) => {
          const match = /\((?<number>\d*)\)/gm.exec(d);
          return match ? parseInt(match[1]) : 0;
        })
    );

    this.duplicateName = `${newName} (${++max})`;
  }

  confirmDeleteMembership(): void {
    this.deleteTeamPopup.popupActive = true;
  }

  async deleteTeam(): Promise<any> {
    this.leaveGuardService.setStatusMayLeave();
    await this.membershipService.delete(this.membership.id).toPromise();
    this.deleteTeamPopup.popupActive = false;
    this.membershipService.membershipsUpdated.emit(this.membership);
    this.router.navigate([`/settings/teams`]);
  }

  async navigateDespiteWarning(): Promise<void> {
    this.warnAboutChangePagePopup.popupActive = false;
    this.membership.name = this.duplicateName;
    await this.updateTeam();

    this.leaveGuardService.setStatusMayLeave();

    await this.router.navigate([`/admin/teams`]);
  }

  async updateTeam(): Promise<any> {
    if (this.membershipNames.indexOf(this.membership.name.trim()) >= 0) {
      this.getNewName();
      this.leaveGuardService.setStatusMustStay();
      return;
    }
    await this.membershipService
      .update(this.membership.id, {
        name: this.membership.name,
      })
      .toPromise();
    this.membershipService.membershipsUpdated.emit(this.membership);
    this.titleService.set(this.membership.name);
    this.leaveGuardService.setStatusMayLeave();
  }
}
