import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemPermissionType, ItemShare } from '@app/core/models';
import { UserAccountService } from '@core/services';

@Component({
  selector: 'app-person-row',
  templateUrl: './person-row.component.html',
  styleUrls: ['./person-row.component.scss'],
})
export class PersonRowComponent {
  @Input() smallAvatar = false;
  @Input() smallPadding = false;
  @Input() onMobile: boolean = false;
  @Input() isSelectable: boolean = false;
  @Input() showMenu: boolean = true;
  @Input() showDefaultShare: boolean = true;
  @Input() share: ItemShare;

  @Output() toggleIsDefaultShare = new EventEmitter();
  @Output() changePermissionType = new EventEmitter<ItemPermissionType>();
  @Output() deleteMember = new EventEmitter();

  _share: ItemShare;

  public permissionTypes: string[] = Object.keys(ItemPermissionType);

  constructor(public userAccountService: UserAccountService) {}

  get isUser(): boolean {
    return !!this.share.userId;
  }

  get kind(): string {
    if (this.share.relationId) return 'relation';
    else if (this.share.organizationId) return 'organization';
    else if (this.share.membershipId) return 'team';
    return undefined;
  }
}
