import { IDownloadable } from '@core/models/IDownloadable';

export interface Invoice extends IDownloadable {
  download: any;
  id: string;
  organizationId: string;
  totalExclVat: number;
  vatTotal: number;
  invoiceTotal: number;
  invoiceStatus: InvoiceStatusType;
  invoiceStorageLocation: string;
  invoiceNumber: string;
  invoiceDate: Date;
  invoiceDueDate: Date;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
}

export enum InvoiceStatusType {
  Pending = 0,
  Paid = 1,
  Overdue = 2,
  Cancelled = 3,
}
