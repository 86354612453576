<tabs>
  <tab
    [registrationUrl]="'Relation | General info'"
    icon="badge"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Information' | translate }}">
    <app-relations-detail-information
      *ngIf="relation"
      [relation]="relation"></app-relations-detail-information>
  </tab>
  <tab
    [registrationUrl]="'Relation | Contact'"
    icon="contact_page"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Contacts' | translate }}">
    <div class="relations-container-header">
      <div *hasPermission="[{ permissionType: permissionTypes.Edit, section: 'Relations' }]">
        <button
          (click)="addContact()"
          *hasPermission="[{ permissionType: permissionTypes.Edit, section: 'Relations' }]"
          class="btn-primary">
          <span class="material-icons-outlined">person_add</span>
          {{ 'Add contact' | translate }}
        </button>
      </div>
      <input
        [(ngModel)]="searchString"
        class="searchBox"
        placeholder="{{ 'search' | translate }}"
        type="text" />
    </div>
    <section *ngIf="relation && relation.contacts.length > 0">
      <table>
        <tr>
          <th [appSort]="relation.contacts" class="sortable" data-name="firstname" scope="col">
            {{ 'Firstname' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="relation.contacts" class="sortable" data-name="lastname" scope="col">
            {{ 'Lastname' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="relation.contacts" class="sortable" data-name="emailAddress" scope="col">
            {{ 'Email' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="relation.contacts" class="sortable" data-name="isPrimary" scope="col">
            {{ 'Primary' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="relation.contacts" class="sortable" data-name="isPrimary" scope="col">
            {{ 'Active Binsend account' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th [appSort]="relation.companyName" class="sortable" data-name="companyName" scope="col">
            {{ 'Company name' | translate }}
            <app-sort-icon></app-sort-icon>
          </th>
          <th>{{ 'Actions' | translate }}</th>
        </tr>
        <tr
          (click)="editContactDetails(contact)"
          *ngFor="let contact of relation.contacts | contactFilter : searchString"
          class="table-row">
          <td>{{ contact.firstname }}</td>
          <td>{{ contact.lastname }}</td>
          <td>{{ contact.emailAddress }}</td>
          <td class="icon-column">
            <span *ngIf="contact.isPrimary" class="material-icons">check_circle_outline</span>
          </td>
          <td class="icon-column">
            <span *ngIf="contact.activeBinsendAccount" class="material-icons">verified_user</span>
          </td>
          <td>{{ contact.companyName }}</td>
          <td>
            <button
              (click)="confirmDeleteContact(contact)"
              *hasPermission="[{ permissionType: permissionTypes.Delete, section: 'Relations' }]"
              click-stop-propagation>
              <span class="material-icons">delete_outline</span>
            </button>
          </td>
        </tr>
      </table>
    </section>
    <div *ngIf="relation && relation.contacts.length === 0" class="empty-item-container">
      <h2 class="no-items-title">{{ 'No contacts' | translate }}</h2>
      <h4>{{ 'CreateItem' | translate }}</h4>
      <app-empty-animation></app-empty-animation>
    </div>
  </tab>
  <tab
    [registrationUrl]="'Relation | Notes'"
    icon="sticky_note_2"
    iconClass="material-icons-outlined"
    tabTitle="{{ 'Notes' | translate }}">
    <div class="settings-row full-size">
      <div class="workspace-settings-group full-size">
        <h3>{{ 'Notes' | translate }}</h3>

        <div *ngIf="relation" class="workspace-settings-object full-size">
          <div class="input-group">
            <textarea
              (ngModelChange)="relation.notes = $event; updateRelation()"
              [(ngModel)]="relation.notes"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
              autocomplete="__away"
              class="full-size"
              id="notes"
              placeholder="{{ 'Notes' | translate }}"></textarea>
          </div>
        </div>
      </div>
    </div>
  </tab>
</tabs>

<app-modal-popup
  #deleteContactPopup
  (eventPrimaryButtonClick)="deleteContact()"
  [popupTitle]="'DeleteContactTitle' | translate"
  [primaryButtonText]="'DeleteContactActionButton' | translate">
  <div>{{ 'ConfirmContactDelete' | translate }}</div>
</app-modal-popup>

<app-modal-popup
  #editContactPopup
  (eventPrimaryButtonClick)="updateContact(); editContactPopup.closePopup()"
  [primaryButtonText]="'Save' | translate"
  [showButtons]="true">
  <div class="settings-row">
    <div *ngIf="selectedContact" class="workspace-settings-group">
      <h3>{{ 'General information' | translate }}</h3>

      <div *ngIf="relation" class="workspace-settings-object">
        <div class="input-group">
          <label for="firstname">{{ 'First name' | translate }}</label>
          <input
            (keyup.enter)="updateContact()"
            [(ngModel)]="selectedContact.firstname"
            autocomplete="__away"
            id="firstname"
            type="text" />
        </div>
        <div class="input-group">
          <label for="lastname">{{ 'Last name' | translate }}</label>
          <input
            (keyup.enter)="updateContact()"
            [(ngModel)]="selectedContact.lastname"
            autocomplete="__away"
            id="lastname"
            type="text" />
        </div>
        <div class="input-group">
          <label for="companyName">{{ 'Company name' | translate }}</label>
          <input
            (keyup.enter)="updateContact()"
            [(ngModel)]="selectedContact.companyName"
            autocomplete="__away"
            id="companyName"
            type="text" />
        </div>
        <div class="input-group">
          <label for="email">{{ 'Email' | translate }}</label>
          <input
            #contactEmail
            (blur)="checkKnownUser()"
            (keyup.enter)="checkKnownUser(); updateContact()"
            [(ngModel)]="selectedContact.emailAddress"
            autocomplete="__away"
            id="email"
            type="text" />
        </div>
        <div class="input-group">
          <label for="phoneNumber">
            {{ 'Phone number' | translate }} ({{ 'optional' | translate }})
          </label>
          <div [ngClass]="invalidPhoneNumber ? 'invalid-input-column' : ''">
            <input
              #phoneNumber="ngModel"
              (keyup)="invalidPhoneNumber = phoneNumber.invalid"
              (keyup.enter)="updateContact()"
              [(ngModel)]="selectedContact.phoneNumber"
              autocomplete="__away"
              id="phoneNumber"
              pattern="[+]?[0-9 ]{1,14}$"
              type="tel" />
            <small *ngIf="phoneNumber.invalid" class="text-danger">
              {{ 'Invalid number' | translate }}
            </small>
          </div>
        </div>

        <div class="input-group">
          <label class="checkboxLabel" for="isPrimary">
            {{ 'Primary' | translate }}
          </label>
          <label class="checkboxcontainer">
            <input
              [(ngModel)]="selectedContact.isPrimary"
              [checked]="selectedContact.isPrimary"
              id="isPrimary"
              type="checkbox" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="selectedContact" class="workspace-settings-group">
      <app-address-form
        (addressUpdated)="selectedContact.address = $event; updateContact()"
        [address]="selectedContact.address"
        [title]="'Address' | translate"></app-address-form>
    </div>
  </div>
</app-modal-popup>
