import { Constants } from '@core/common/constants';
import { UserAccount } from '@core/models/account.model';
import { Subscription } from '@core/models/subscription.model';

export interface UpdateOrganizationDto {
  name?: string;
  phoneNumber?: string;
  emailAddress?: string;
  subscriptionId?: string;
  address?: Address;
  invoiceAddress?: Address;
  binsendUrl?: string;
  creationTimestampUtc?: Date;
  users?: UserAccount[];
  addressId?: string;
  loginImageUrl?: string;
  logoUrl?: string;
  primaryColor?: string;
  accentColor?: string;
  customEmailSender?: string;
  vatId?: string;
  preferredCurrency?: string;
  financialEmailAddress?: string;
  chamberOfCommerce?: string;
  financialEmailBccAddress?: string;
  websiteUrl?: string;
  defaultWorkspaceRetentionInDays?: number;
  requestContentItemUpdateNotification?: boolean;
  sectionUpdateNotification?: boolean;
}

export class Organization implements UpdateOrganizationDto {
  id: string = Constants.EmptyGuid;
  name?: string = Constants.EmptyString;
  phoneNumber?: string = Constants.EmptyString;
  emailAddress?: string = Constants.EmptyString;
  subscriptionId?: string = Constants.EmptyString;
  address?: Address = new Address();
  invoiceAddress?: Address = new Address();
  binsendUrl?: string = Constants.EmptyString;
  creationTimestampUtc?: Date;
  users?: UserAccount[];
  addressId?: string = Constants.EmptyString;
  loginImageUrl?: string = Constants.EmptyString;
  logoUrl?: string = Constants.EmptyString;
  primaryColor?: string = Constants.EmptyString;
  accentColor?: string = Constants.EmptyString;
  customEmailSender?: string = Constants.EmptyString;
  vatId?: string = Constants.EmptyString;
  preferredCurrency?: string = Constants.EmptyString;
  financialEmailAddress?: string = Constants.EmptyString;
  chamberOfCommerce?: string = Constants.EmptyString;
  financialEmailBccAddress?: string = Constants.EmptyString;
  websiteUrl?: string = Constants.EmptyString;
  defaultWorkspaceRetentionInDays?: number = 5;
  auditEntries: [];
  count: number = Constants.EmptyNumber;
  requestContentItemUpdateNotification?: boolean;
  sectionUpdateNotification?: boolean;
}

export class Address {
  id: string;
  streetName: string = Constants.EmptyString;
  postalCode: string = Constants.EmptyString;
  city: string = Constants.EmptyString;
  country: string = Constants.EmptyString;
  state: string = Constants.EmptyString;
}

export interface UpdateOrganizationRequest {
  OrganizationDto: UpdateOrganizationDto;
}

export interface CreateOrganizationRequest {
  organizationName: string;
}

export enum Currencies {
  Euro = '€ EUR',
  UsDollar = '$ USD',
}

export interface BrandingSettings {
  accentColor: string;
  primaryColor: string;
  logoUrl: string;
  loginImageUrl: string;
}

export class OrganizationListItem {
  name: string;
  subscriptionName: string;
  id: string;
  creationTimestampUtc: Date;
  numberOfActiveUsers;

  constructor(source: Organization, subscriptions: Subscription[]) {
    this.name = source.name;
    this.id = source.id;
    this.creationTimestampUtc = source.creationTimestampUtc;
    this.numberOfActiveUsers = source.users.filter((u) => u.isActive).length;
    this.subscriptionName = subscriptions.find((s) => s.id === source.subscriptionId)?.name;
  }
}
