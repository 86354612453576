<div
  (click)="tagClick()"
  [ngClass]="[tag ? '' : 'empty', new ? 'new' : '', remove ? 'remove' : '']"
  class="tag">
  <span (blur)="tagUpdate()" class="input" contenteditable role="textbox">
    {{ tag }}
  </span>

  <span *ngIf="!tag" class="material-icons add-symbol">add</span>

  <span (click)="tagRemove()" *ngIf="tag" class="material-icons remove-symbol">close</span>
</div>
