import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { SubscriptionService } from '@core/services';
import { Subscription } from '@core/models';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
})
export class SubscriptionCardComponent implements OnInit {
  constructor(private subscriptionService: SubscriptionService) {
    this.subscriptionService.currentPlan.subscribe(({ subscription }) => {
      this.isActive = subscription.id === this.subscription.id;
    });
  }

  @Input() name: string;
  @Input() pricingSubheader: string;

  @Input() userPrice: string;

  @Input() mostPopular: boolean;

  @Input() subscription: Subscription;
  @HostBinding('class.active') isActive: boolean;
  @Input() buttonTitle: string;

  @Output() selectSubscription: EventEmitter<Subscription> = new EventEmitter<Subscription>();

  ngOnInit(): void {
    if (this.subscriptionService.currentPlanValue !== undefined) {
      this.isActive = this.subscriptionService.currentPlanValue.id === this.subscription.id;
    }
  }

  raiseSelectSubscription(): void {
    if (this.selectSubscription.observers.length > 0 && !this.isActive) {
      this.selectSubscription.emit(this.subscription);
    }
  }
}
