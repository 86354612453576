import { Component, OnInit, ViewChild } from '@angular/core';
import { QuotaService, TitleService, UserInfoService, WorkspaceService } from '@core/services';
import { ItemStatusType, IWorkspaceBaseDto, PermissionType, Workspace } from '@core/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ArchiveWorkspacePopupComponent } from '@app/shared/pop-up/archive-workspace-popup/archive-workspace-popup.component';
import { SharingPopupComponent } from '@app/shared/sharing-popup/sharing-popup.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('deleteWorkspacePopup') deleteWorkspacePopup: ModalPopupComponent;
  @ViewChild('archiveWorkspacePopup')
  archiveWorkspacePopup: ArchiveWorkspacePopupComponent;

  @ViewChild('selectTemplateForWorkspacePopup')
  selectTemplateForWorkspacePopup: ModalPopupComponent;

  @ViewChild('confirmTemplateForWorkspacePopup')
  confirmTemplateForWorkspacePopup: ModalPopupComponent;

  @ViewChild('existingWorkspacePopup') existingWorkspacePopup: ModalPopupComponent;
  @ViewChild('renameWorkspacePopup') renameWorkspacePopup: ModalPopupComponent;
  @ViewChild('shareWorkspacePopup') shareWorkspacePopup: SharingPopupComponent;

  public permissionTypes = PermissionType;
  existingEmptyWorkspace: IWorkspaceBaseDto;
  selectedTemplate: Workspace;
  isListView = false;

  public statusTypes = ItemStatusType;

  workspaces: IWorkspaceBaseDto[];
  searchText: string;

  focusedWorkspace: IWorkspaceBaseDto;

  constructor(
    private workspaceService: WorkspaceService,
    private titleService: TitleService,
    private router: Router,
    private userService: UserInfoService,
    private translate: TranslateService,
    public quotaService: QuotaService,
    private route: ActivatedRoute
  ) {
    this.titleService.set(this.translate.instant('Dashboard'));
  }

  get interpolationParams(): any {
    return { workspaceType: this.focusedWorkspace?.isTemplate ? 'template' : 'workspace' };
  }

  // Returns true if the workspace quota is not exceeded.
  exceedsWorkspaceQuota(): boolean {
    return this.workspaces.length >= this.quotaService.maxActiveWorkspaces;
  }

  openArchivePopup(workspace: Workspace): void {
    this.focusedWorkspace = workspace;
    this.archiveWorkspacePopup.activatePopup();
  }

  openConfirmPopup(workspace: Workspace): void {
    this.deleteWorkspacePopup.popupActive = true;
    this.focusedWorkspace = workspace;
  }

  async addNewWorkspace(): Promise<any> {
    const workspaces = await this.workspaceService.list();
    // Create a new workspace.
    await this.createNewWorkspace(workspaces);
  }

  async createSecondaryEmptyWorkspace(): Promise<any> {
    const workspaces = await this.workspaceService.list();
    await this.createNewWorkspace(workspaces);
  }

  private navigateToWorkspace(workspaceId, isNew = false): void {
    if (isNew) {
      const storage = JSON.parse(localStorage.getItem('edits') || '[]');
      storage.push(workspaceId);

      localStorage.setItem('isNewWorkspace', 'true');
      localStorage.setItem('edits', JSON.stringify(storage));
    }
    this.router.navigate(['workspace', workspaceId]);
  }

  async createNewWorkspace(workspaces: IWorkspaceBaseDto[]): Promise<any> {
    const newWorkspaces = workspaces.filter((d) => d.name.indexOf('New workspace') > -1).length;
    const newWorkspace = await this.workspaceService.post({
      name:
        newWorkspaces > 0
          ? `${this.translate.instant('New workspace')} (${newWorkspaces + 1})`
          : this.translate.instant('New workspace'),
      description: '',
      isTemplate: false,
    });
    await this.workspaceService.refresh();
    this.navigateToWorkspace(newWorkspace.id, true);
  }

  async removeWorkspace(workspace: IWorkspaceBaseDto): Promise<any> {
    await this.workspaceService.delete(workspace.id);
    this.deleteWorkspacePopup.popupActive = false;
    this.listWorkspaces();
  }

  // eslint-disable-next-line
  async ngOnInit() {
    const targetRedirectUrl = localStorage.getItem('targetRedirectUrl');
    if (
      targetRedirectUrl !== null &&
      targetRedirectUrl !== window.location.href &&
      targetRedirectUrl !== `${window.location.origin}/`
    ) {
      localStorage.removeItem('targetRedirectUrl');
      // Extract the relative path and nagivate to that.
      await this.router.navigate([`${targetRedirectUrl.replace(/^(?:\/\/|[^/]+)*\//, '')}`]);
    }

    await this.listWorkspaces();
    this.route.queryParams.subscribe(async (params: Params) => {
      await this.handleParams(params);
    });
  }

  async handleParams(params: Params): Promise<void> {
    if (params['newWorkspace']) {
      await this.addNewWorkspace();
    } else if (params['workspaceFromTemplate']) {
      this.selectTemplateForWorkspacePopup.popupActive = true;
    }
  }

  async listWorkspaces(): Promise<void> {
    const response = await this.workspaceService.list();
    this.workspaces = response.sort((a, b) =>
      a.lastModifiedTimestampUtc > b.lastModifiedTimestampUtc ? -1 : 1
    );
    const isListViewString = localStorage.getItem('listView');
    if (isListViewString !== null) {
      this.isListView = isListViewString === 'true';
    }
  }

  openExistingWorkspace(): void {
    this.router.navigate([`/workspace/${this.existingEmptyWorkspace.id}`]);
  }

  async archiveWorkspace(workspace: Workspace): Promise<any> {
    await this.workspaceService.update(workspace.id, {
      newDescription: workspace.description,
      newName: workspace.name,
      workspaceSettings: workspace.workspaceSettings,
      workspaceStatus: ItemStatusType.Archived,
    });
    this.listWorkspaces();
    this.archiveWorkspacePopup.deActivatePopup();
  }

  async popupEmptyWorkspaceByTemplate(template: Workspace): Promise<void> {
    this.selectedTemplate = template;
    this.selectTemplateForWorkspacePopup.popupActive = false;
    this.confirmTemplateForWorkspacePopup.popupActive = true;
    for (const share of template.shares.filter((s) => s.isDefaultShare)) {
      if (share.email === null) {
        share.email = (await this.userService.get(share.userId)).email;
      }
    }
  }

  async createEmptyWorkspaceByTemplate(): Promise<any> {
    const workspaceCreationResult = await this.workspaceService.post({
      name: this.selectedTemplate.name,
      description: this.selectedTemplate.description,
      sourceTemplateId: this.selectedTemplate.id,
      isTemplate: false,
    });
    await this.workspaceService.refresh();
    this.navigateToWorkspace(workspaceCreationResult.id, true);
  }

  selectView(isListView: boolean): void {
    this.isListView = isListView;
    const isListViewString = this.isListView ? 'true' : 'false';
    localStorage.setItem('listView', isListViewString);
  }

  async shareWorkspace(workspace: Workspace): Promise<any> {
    this.focusedWorkspace = workspace;
    await this.shareWorkspacePopup.open();
  }
}
