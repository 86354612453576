import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AccountService,
  MembershipService,
  PermissionService,
  QuotaService,
  RoleService,
  TitleService,
} from '@core/services';
import { Membership, PermissionType, Role, UserAccount } from '@core/models';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})
export class TeamsComponent implements OnInit {
  users: UserAccount[];
  roles: Role[];
  memberships: Membership[];
  permissions;

  membershipSearchText: string;

  public permissionTypes = PermissionType;

  @ViewChild('createTeamPopup') createTeamPopup: ModalPopupComponent;
  @ViewChild('editTeamPopup') editTeamPopup: ModalPopupComponent;
  @ViewChild('deleteTeamPopup') deleteTeamPopup: ModalPopupComponent;

  @ViewChild('teamNameCreateInput') teamNameCreateInput: ElementRef;

  selectedMembership: Membership;
  selectedUser: UserAccount;

  constructor(
    private accountService: AccountService,
    private roleService: RoleService,
    public router: Router,
    private membershipService: MembershipService,
    private permissionService: PermissionService,
    titleService: TitleService,
    public translate: TranslateService,
    public quotaService: QuotaService
  ) {
    titleService.set(translate.instant('Settings'));
    this.permissionService.list().then((permissions) => {
      this.permissions = permissions.reduce((r, { section }) => {
        if (!r.some((o) => o.section === section)) {
          r.push({
            section,
            groupItem: permissions.filter((v) => v.section === section),
          });
        }
        return r;
      }, []);
    });
  }

  ngOnInit(): void {
    this.loadUsers();
    this.loadRoles();
    this.loadMemberships();

    this.roleService.rolesUpdated.subscribe(() => {
      this.loadRoles();
      this.loadMemberships();
    });

    this.accountService.accountsUpdated.subscribe(() => this.loadMemberships());
  }

  async loadRoles(): Promise<any> {
    this.roles = await this.roleService.list();
  }

  async loadUsers(): Promise<any> {
    this.users = await this.accountService.list();
  }

  async loadMemberships(): Promise<any> {
    this.memberships = await this.membershipService.list();
    if (this.selectedMembership !== undefined) {
      this.selectedMembership = this.memberships.find((r) => r.id === this.selectedMembership.id);
    }
  }

  async addTeam(): Promise<any> {
    const amountOfNewTeams = this.memberships.filter((m) =>
      m.name.startsWith(this.translate.instant('New team'))
    ).length;
    const newMemberShipId = await this.membershipService
      .post({
        name:
          amountOfNewTeams > 0
            ? `${this.translate.instant('New team')} (${amountOfNewTeams})`
            : this.translate.instant('New team'),
      })
      .toPromise();
    await this.loadMemberships();
    localStorage.setItem('newTeam', 'true');
    this.router.navigate([`/settings/teams/${newMemberShipId.body}`]);
  }

  async deleteTeam(): Promise<any> {
    await this.membershipService.delete(this.selectedMembership.id).toPromise();
    await this.loadMemberships();
    this.deleteTeamPopup.popupActive = false;
    this.membershipService.membershipsUpdated.emit(this.selectedMembership);
  }

  confirmDeleteMembership(membership: Membership): void {
    this.selectedMembership = membership;
    this.deleteTeamPopup.popupActive = true;
  }

  goToDetails(membershipId: string): void {
    this.router.navigate([`/settings/teams/${membershipId}`]);
  }
}
