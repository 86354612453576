import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserAccountService } from '@app/core/services';
import {
  ItemPermissionType,
  ItemShare,
  ItemStatusType,
  IWorkspaceBaseDto,
  PermissionType,
  Workspace,
} from '@core/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workspace-card',
  templateUrl: './workspace-card.component.html',
  styleUrls: ['./workspace-card.component.scss'],
})
export class WorkspaceCardComponent {
  constructor(public translate: TranslateService, private userAccountService: UserAccountService) {}

  public permissionTypes = PermissionType;
  public statusTypes = ItemStatusType;
  public workspacePermissionType = ItemPermissionType;

  view = [150, 35];

  @Input() workspace: Workspace;

  @Output() deleteWorkspace: EventEmitter<IWorkspaceBaseDto> =
    new EventEmitter<IWorkspaceBaseDto>();

  @Output() archiveWorkspace: EventEmitter<IWorkspaceBaseDto> =
    new EventEmitter<IWorkspaceBaseDto>();

  @Output() shareWorkspace: EventEmitter<IWorkspaceBaseDto> = new EventEmitter<IWorkspaceBaseDto>();

  get shares(): ItemShare[] {
    return this.workspace.shares.filter((share) => share.loaded).slice(0, 5);
  }

  showShares(workspace: Workspace): boolean {
    return workspace.shares.length > 0 && workspace.canShare(this.userAccountService.user);
  }

  requestWorkspaceDelete(ws: IWorkspaceBaseDto): void {
    this.deleteWorkspace.emit(ws);
  }

  requestWorkspaceArchive(ws: IWorkspaceBaseDto): void {
    this.archiveWorkspace.emit(ws);
  }

  requestWorkspaceShare(ws: IWorkspaceBaseDto): void {
    this.shareWorkspace.emit(ws);
  }
}
