import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateMessageModel, Message, UpdateMessageModel } from '@core/models/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private baseUrl = `${environment.apiBaseUrl}/share/requestcontentitemmessage/v1`;

  constructor(private http: HttpClient) {}

  get(requestContentItemId: string): Observable<HttpResponse<Message[]>> {
    return this.http.get<Message[]>(`${this.baseUrl}/${requestContentItemId}`, {
      headers: new HttpHeaders()
        .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        .set('Pragma', 'no-cache')
        .set('Expires', '0'),
      observe: 'response',
    });
  }

  post(request: CreateMessageModel): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}`, request, {
      observe: 'response',
    });
  }

  update(messageId: string, request: UpdateMessageModel): Observable<HttpResponse<any>> {
    return this.http.put(`${this.baseUrl}/${messageId}`, request, {
      observe: 'response',
    });
  }

  delete(messageId: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.baseUrl}/${messageId}`, {
      observe: 'response',
      responseType: 'text',
    });
  }
}
