import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env/environment';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { AuthorizationRoutingModule } from './authorization-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthorizationRoutingModule,
    AuthModule.forRoot({
      config: {
        authority: environment.authBaseUrl,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'binsend-portal',
        scope: 'contentApi.read openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Warn,
        secureRoutes: [environment.apiBaseUrl],
      },
    }),
  ],
})
export class AuthorizationModule {}
