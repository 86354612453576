<svg viewBox="0, 0, 100, 100">
  <circle
    class="progress-circle"
    stroke-width="6"
    [style.strokeDasharray]="circumference"
    [style.strokeDashoffset]="strokeDashoffset"
    fill="transparent"
    stroke="#2991ff"
    r="47"
    cx="50"
    cy="50" />
  <text x="55" y="55" class="text" text-anchor="middle">{{ value + '%' }}</text>
</svg>
