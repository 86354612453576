<div class="input-group">
  <label for="supportCategory">{{ 'Support category' | translate }}</label>
  <select id="supportCategory" [(ngModel)]="selectedSupportQuestionSection">
    <option
      *ngFor="let section of SupportQuestionSections | keyvalue"
      [ngValue]="section.key"
      [selected]="SupportQuestionSections[section.key] === selectedSupportQuestionSection">
      {{ section.value | translate }}
    </option>
  </select>
</div>
<div class="input-group">
  <mt-input placeholder="{{ 'Support question' | translate }}">
    <textarea [(ngModel)]="message" rows="5" cols="20" autosize #mt="ngModel"></textarea>
  </mt-input>
</div>

<div class="support-actions">
  <button
    class="btn-primary"
    [disabled]="subscriptionService?.currentPlanValue?.isStarterTier"
    (click)="sendSupportRequest()">
    {{ 'Send support message' | translate }}
  </button>
  <app-upgrade-button
    *ngIf="subscriptionService?.currentPlanValue?.isStarterTier"></app-upgrade-button>
</div>
