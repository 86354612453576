<div>
  <ng-content></ng-content>

  <div (click)="close()" [class.active]="active" class="file-preview__background"></div>

  <div [class.active]="active" class="popup-wrapper">
    <div class="preview-wrapper" [class.chat-active]="messages">
      <div *ngIf="hasNoPreview" class="file-preview__no-preview">
        <div class="file-preview__header">
          <h3>
            {{ icon + '-preview-title' | translate }}
          </h3>
        </div>
        <h3 *ngIf="activeContentItem" class="file-name">
          {{ activeContentItem.name }}
        </h3>
        <img
          (click)="imageButton()"
          [class.image-button]="isProcessing"
          loading="lazy"
          src="assets/{{ icon }}.svg" />

        <button
          (click)="download()"
          *ngIf="activeContentItem && (tooBig || !isSupportedFile)"
          [disabled]="downloadService.isDownloading"
          class="preview-button">
          {{ 'download-file-preview' | translate }}
        </button>

        <button (click)="upload()" *ngIf="!activeContentItem" class="preview-button">
          {{ 'upload-file-preview' | translate }}
        </button>
      </div>
      <div *ngIf="!hasNoPreview" [ngClass]="[isImage ? 'full-width' : '']" class="file-preview">
        <div class="file-preview-wrapper">
          <app-loading-spinner></app-loading-spinner>
          <h3 *ngIf="activeContentItem" class="file-preview__title">
            {{ activeContentItem.name }}
          </h3>
          <div *ngIf="isImage">
            <img src="{{ fileUrl }}" />
          </div>

          <ngx-doc-viewer
            *ngIf="isDoc"
            [url]="fileUrl"
            class="doc-viewer"
            viewer="google"></ngx-doc-viewer>
          <div *ngIf="isDoc" class="overlay-protect"></div>

          <video *ngIf="isVideo" autoplay controls>
            <source src="{{ fileUrl }}" type="video/{{ fileFormat }}" />
          </video>
        </div>
      </div>

      <div (click)="close()" class="close-back"></div>
    </div>

    <app-chat-overlay
      *ngIf="messages"
      [mobileActive]="chatActive"
      [active]="active"
      [itemMessages]="messages"
      [fileRequest]="fileRequest"
      [fileIndex]="fileIndex"></app-chat-overlay>
  </div>

  <div
    (click)="previous()"
    [class.active]="activeContentItem && contentItems?.length > 1"
    class="nav-arrow">
    <span class="material-icons-outlined">arrow_back</span>
  </div>

  <div
    (click)="next()"
    [class.active]="activeContentItem && contentItems?.length > 1"
    class="nav-arrow nav-arrow__next">
    <span class="material-icons-outlined">arrow_forward</span>
  </div>
</div>
