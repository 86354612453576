import { ContentItem, IContentItemDto } from './content.item.model';
import { FileRequest, IFileRequestDto } from './filerequest.model';
import { IQuestionDto, Question } from './question.model';
import {
  ISectionItemDto,
  ItemStatusType,
  SectionItem,
  SectionItemBase,
  SectionItemType,
} from './sectionitem.model';

export enum SectionType {
  Default = 'Default',
  ContainsOnlySections = 'ContainsOnlySections',
}

export interface ISectionDto extends ISectionItemDto {
  sectionType: SectionType;
  sectionItemType: SectionItemType;
  items: SectionItem[];
}

export class Section extends SectionItemBase implements ISectionDto {
  items: SectionItem[];
  sectionType: SectionType;
  sectionItemType: SectionItemType;
  name: string;

  isVisible = true;
  expanded = true;
  status: ItemStatusType;

  constructor(section: ISectionDto) {
    super();
    Object.assign(this, section);
    this.items = section.items
      .map((item) => {
        switch (item.type) {
          case SectionItemType.Section:
            return item;
          case SectionItemType.FileRequest:
            return new FileRequest(item as IFileRequestDto);
          case SectionItemType.Question:
            return new Question(item as IQuestionDto);
          case SectionItemType.ContentItem:
            return new ContentItem(item as IContentItemDto);
          default:
            throw new Error(`Unknown section item type ${item.type}}`);
        }
      })
      .filter((item) => (item as ContentItem)?.isUploadComplete != false);
  }

  removeSectionItem(itemId: string): void {
    this.items = this.items.filter((item) => item.id !== itemId);
  }

  private get sections(): Section[] {
    return filter<Section>(this.items, SectionItemType.Section);
  }

  get allItems(): SectionItem[] {
    return [this, ...this.sections].map((section) => section.items).flat();
  }

  get rcis(): FileRequest[] {
    return filter<FileRequest>(this.allItems, SectionItemType.FileRequest);
  }

  get visibleItems(): SectionItem[] {
    return this.items.filter((item) => item.isVisible);
  }

  get contentItems(): ContentItem[] {
    return filter<ContentItem>(this.allItems, SectionItemType.ContentItem);
  }

  get isFolder(): boolean {
    return this.sectionType === SectionType.ContainsOnlySections;
  }

  get isCompleted(): boolean {
    return this.allItems.every((si) => si.isCompleted);
  }

  get isSection(): boolean {
    return true;
  }

  getChildren<TType>(type: SectionItemType = undefined): TType[] {
    return this.addChildren<TType>([], type);
  }

  private addChildren<TType>(items: TType[], type: SectionItemType = undefined): TType[] {
    if (type) items.push(...filter<TType>(this.items, type));
    else items.push(...(this.items as TType[]));
    this.items.forEach((item) => {
      if (item.type === SectionItemType.Section) {
        const section = item as Section;
        section.addChildren<TType>(items, type);
      } else if (
        item.type === SectionItemType.FileRequest &&
        (type === SectionItemType.ContentItem || !type)
      ) {
        const fileRequest = item as FileRequest;
        items.push(...(fileRequest.contentItems as TType[]));
      }
    });
    return items;
  }

  get sectionId(): string {
    return this.id;
  }

  insertSectionItem(item: SectionItem, index: number): void {
    if (index !== -1) {
      this.items.splice(index, 0, item);
    } else {
      this.items.push(item);
    }
  }
}

function filter<TType>(items: SectionItem[], ...types: SectionItemType[]): TType[] {
  return items.filter((section) => types.includes(section.type)) as TType[];
}

export interface SectionRequestItem {
  name: string;
  description: string;
  SectionType: SectionType;
  items: SectionItem[];
  insertAt?: number;
}
