import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ItemStatusType, Workspace } from '@core/models';
import { WorkspaceService } from '@core/services';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';

@Component({
  selector: 'app-archive-workspace-popup',
  templateUrl: './archive-workspace-popup.component.html',
})
export class ArchiveWorkspacePopupComponent {
  @Input() workspace: Workspace;
  @Output() changeEventEmitter = new EventEmitter();
  @ViewChild('archiveWorkspacePopup') archiveWorkspacePopup: ModalPopupComponent;

  constructor(private workspaceService: WorkspaceService) {}

  activatePopup(): void {
    this.archiveWorkspacePopup.popupActive = true;
  }

  deActivatePopup(): void {
    this.archiveWorkspacePopup.popupActive = false;
  }

  async archiveWorkspace(workspace: Workspace): Promise<any> {
    await this.workspaceService.update(workspace.id, {
      newDescription: workspace.description,
      newName: workspace.name,
      workspaceSettings: workspace.workspaceSettings,
      workspaceStatus: ItemStatusType.Archived,
    });
    await this.workspaceService.refresh();
    this.changeEventEmitter.emit(workspace);
    this.deActivatePopup();
  }
}
