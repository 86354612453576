import { ItemPermissionType, IUserAccount, Organization, UserInfo } from '@core/models';

export interface CreateShareRequest {
  personalMessage: string;
  workspaceId: string;
  email?: string;
  name: string;
  permissionType: ItemPermissionType;
  relationId?: string;
  organizationId?: string;
  userId?: string;
  membershipId?: string;
  isDefaultShare?: boolean;
  sectionItemId?: string;
}

export class ShareUpdateRequest {
  permissionType?: ItemPermissionType;
  isDefaultShare?: boolean;
}

export interface IItemShareDto {
  id?: string;
  name?: string;
  permissionType?: ItemPermissionType;
  email?: string;
  relationId?: string;
  organizationId?: string;
  userId?: string;
  membershipId?: string;
  isDefaultShare?: boolean;
  sectionItemId?: string;
}

export interface IItemShare extends IItemShareDto {
  user?: IUserAccount;
  organization?: Organization;
  personalMessage?: string;
}

export class ItemShare implements IItemShare {
  id: string;
  workspaceId: string;
  sectionItemId: string;
  creatorId: string;
  relationId?: string;
  userId?: string;
  membershipId?: string;
  contactId: string;
  email?: string;
  organizationId?: string;
  creationTimestamp: Date;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
  permissionType: ItemPermissionType = ItemPermissionType.ReadWrite;
  lastVisitUtc: Date;
  isDefaultShare = false;
  isLink = false;

  personalMessage: string;

  constructor(share: IItemShare) {
    const { name, ...rest } = share;
    Object.assign(this, rest);
    this._name = name;
  }

  get avatar(): string {
    return this.user?.avatar ?? this.organization?.logoUrl;
  }

  get avatarColor(): string {
    return this.user?.avatarColor ?? this.organization?.primaryColor;
  }

  get loaded(): boolean {
    return (!this.userId && !this.organizationId) || !!this.user || !!this.organization;
  }

  private _name: string;
  get name(): string {
    return this.user?.fullName ?? this.organization?.name ?? this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  user?: UserInfo;
  organization?: Organization;
}

export class ItemShareUpdateRequest {
  permissionType: ItemPermissionType;
}

export class ItemShareCreateRequest {
  workspaceId: string;
  userId: string;
  email: string;
  membershipId: string;
  organizationId: string;
  personalMessage: string;
  permissionType: number;
  relationId: string;
  contactId: string;
}
