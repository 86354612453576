import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-small-popup',
  templateUrl: './small-popup.component.html',
  styleUrls: ['./small-popup.component.scss'],
})
export class SmallPopupComponent {
  @Input() active: boolean;
  @Input() title: string;

  @Output() submitButton = new EventEmitter<void>();

  public closePopup(): void {
    this.submitButton.emit();
  }
}
