import { IDownloadable } from './IDownloadable';
import {
  ISectionItemDto,
  ItemStatusType,
  SectionItem,
  SectionItemBase,
  SectionItemType,
} from './sectionitem.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Download } from '@core/services';
import { ProgressEvent } from '../services/loader.service';

export interface GetUploadUrlResponse {
  uploadUrl: string;
  contentItem: ContentItem;
}

export interface INamed {
  name: string;
}

export interface IContentItemDto extends IDownloadable, INamed, ISectionItemDto {
  id: string;
  fileHash: string;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
  size: number;
  userId: string;
  type: SectionItemType;
}

export interface IContentItem extends IContentItemDto {
  selected?: boolean;
}

export class ContentItem extends SectionItemBase implements IContentItem {
  fileHash: string;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
  size: number;
  userId: string;
  download: Observable<Download>;
  name: string;
  isCompleted = true;
  isVisible = true;
  status: ItemStatusType;

  constructor(contentItem: IContentItemDto, isUploadComplete = true) {
    super();
    Object.assign(this, contentItem);
    this.type = SectionItemType.ContentItem;
    this.uploadProgress = new BehaviorSubject<ProgressEvent>(
      new ProgressEvent(isUploadComplete ? undefined : 0)
    );
  }

  public uploadProgress;

  public get isUploadComplete(): boolean {
    return this.uploadProgress.value.isActive || this.size > 0;
  }

  get sectionId(): string {
    return this.parentId;
  }

  get isSection(): boolean {
    return false;
  }

  insertSectionItem(sectionItem: SectionItem, index: number): void {
    throw new Error('Cannot add a section item to a question.');
  }
}

export interface ContentItemMeta {
  name: string;
  value: string;
}

export interface GetContentItemSetRequest {
  contentItemIds: string[];
}
