import { Component, OnInit } from '@angular/core';
import { UserAccountService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-settings-account',
  templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit {
  user: any;

  constructor(private userAccountService: UserAccountService, public translate: TranslateService) {}

  async ngOnInit(): Promise<any> {
    this.user = this.userAccountService.userData;
  }
}
