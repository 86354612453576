import { StatusIndicators } from '@core/models/statusticsIndicator';

export interface DataSeries {
  name: string;
  statusType?: StatusIndicators;
  value: number;
}

export class StatisticsGroup {
  constructor(public series: DataSeries[], public name: string = '') {}
}
