import { Injectable } from '@angular/core';
import {
  FileRequest,
  Workspace,
  WorkspaceCreationRequest,
  WorkspaceUpdateRequest,
  WorkspaceWithFrs,
} from '@core/models';
import { environment } from '@env/environment';
import { HttpClient } from './http.client';
import { WorkspaceShareInfoService } from '../workspace.shareinfo.service';
import { UserInfoService } from './userinfo.service';
import { RouterService } from './router.service';
import { WorkspaceCopier } from './workspace-copier/workspace-copier';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private baseUrl = `${environment.apiBaseUrl}/share/v1/workspaces`;

  public currentWorkspace$ = new BehaviorSubject<Workspace>(null);
  private _workspaces: Workspace[] = [];
  private _templates: Workspace[] = [];

  constructor(
    private http: HttpClient,
    private shareInfoService: WorkspaceShareInfoService,
    private userInfoService: UserInfoService,
    private routerService: RouterService,
    private workspaceCopier: WorkspaceCopier,
    private authService: AuthService
  ) {
    this.authService
      .getIsAuthorized()
      .pipe(untilDestroyed(this))
      .subscribe((isAuthorized) => {
        if (isAuthorized) this.refresh();
      });
  }

  get(workspaceId: string): Promise<Workspace> {
    return this.http
      .get<Workspace>(`${this.baseUrl}/${workspaceId}`)
      .then((ws) => new Workspace(ws))
      .then(async (ws) => {
        this.shareInfoService.GetShareInformation(ws);
        ws.creator = await this.userInfoService.get(ws.creatorId);
        return ws;
      });
  }

  async list(templates = false, deep = true): Promise<Workspace[]> {
    const result = await this.http
      .get<Workspace[]>(`${this.baseUrl}${templates ? '?isTemplate=true' : ''}`)
      .then((workspaces) => workspaces.map((ws) => new Workspace(ws)))
      .then((workspaces) => {
        if (deep) {
          this.shareInfoService.GetShareInformation(...workspaces);
          this.getFileRequests(workspaces);
        }
        return workspaces;
      });
    if (templates) this._templates = result;
    else this._workspaces = result;
    return result;
  }

  get workspaces(): Workspace[] {
    return this._workspaces;
  }

  get templates(): Workspace[] {
    return this._templates;
  }

  public get currentWorkspace(): Workspace {
    return this.currentWorkspace$.value;
  }

  update(workspaceId: string, request: WorkspaceUpdateRequest): Promise<any> {
    return this.http.put(`${this.baseUrl}/${workspaceId}`, request);
  }

  addTemplate(
    workspaceId: string,
    sectionId: string,
    templateId: string,
    insertAt?: number
  ): Promise<Workspace> {
    return this.http
      .post(`${this.baseUrl}/${workspaceId}/sections/${sectionId}/template/${templateId}`, {
        insertAt,
      })
      .then((response) => new Workspace(response.body))
      .then((ws) => this.updateReferences(templateId, ws));
  }

  post(request: WorkspaceCreationRequest): Promise<Workspace> {
    const sourceId = request.sourceTemplateId ?? request.sourceWorkspaceId;
    return this.http
      .post<Workspace>(`${this.baseUrl}`, request)
      .then((r) => new Workspace(r.body))
      .then((ws) => this.updateReferences(sourceId, ws));
  }

  async updateReferences(sourceId: string, destination: Workspace): Promise<Workspace> {
    if (!sourceId) return destination;
    const source = await this.get(sourceId);
    return this.workspaceCopier.updateReferences(source, destination);
  }

  delete(workspaceId: string): Promise<string> {
    return this.http.delete(`${this.baseUrl}/${workspaceId}`, {
      responseType: 'text',
    });
  }

  async getFileRequests(workspaces: Workspace[]): Promise<void> {
    const response = await this.http.post<WorkspaceWithFrs[]>(
      `${this.baseUrl}/fileRequests`,
      workspaces.map((ws) => ws.id)
    );
    const workspacesWithFrs = response.body;
    workspacesWithFrs.forEach((wsWithFrs) => {
      const workspace = workspaces.find((ws) => ws.id === wsWithFrs.id);
      workspace.allRcis = wsWithFrs.requestContentItems.map((rci) => new FileRequest(rci, true));
    });
  }

  public async refreshWorkspace(workspaceId?: string): Promise<Workspace> {
    const workspace = await this.get(workspaceId || this.currentWorkspace?.id);
    this.setCurrentSection(workspace);
    this.currentWorkspace$.next(workspace);
    return workspace;
  }

  navigateTo(workspaceId: string): Promise<Workspace> {
    if (workspaceId !== this.currentWorkspace?.id) {
      return this.refreshWorkspace(workspaceId);
    } else {
      this.setCurrentSection(this.currentWorkspace);
      return null;
    }
  }

  private setCurrentSection(workspace: Workspace): void {
    const sections = this.routerService.sections;
    const parentSection = sections[sections.length - 1];
    workspace.currentSectionId = parentSection;
  }

  public async clearCurrentWorkspace(): Promise<void> {
    this.currentWorkspace$.next(null);
  }

  public async refresh(): Promise<void> {
    await this.list(false, false);
    await this.list(true, false);
  }
}
