import { Injectable } from '@angular/core';
import { MultipleChoiceItem, Question, Section, SectionItem, Workspace } from '@app/core/models';
import { WorkspaceService, WorkspaceUiStateService } from '@app/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

export interface WorkflowUpdate {
  workflowId: string;
  show: boolean;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class WorkflowUpdateService {
  // Observable string sources
  private updateWorkflowSubject = new Subject<WorkflowUpdate>();

  private isEdit = false;

  // Observable string streams
  onWorkflowUpdate$ = this.updateWorkflowSubject.asObservable();

  workflowMap: Map<SectionItem, MultipleChoiceItem[]> = new Map<
    SectionItem,
    MultipleChoiceItem[]
  >();

  constructor(
    private workspaceUiStateService: WorkspaceUiStateService,
    private workspaceService: WorkspaceService
  ) {
    this.workspaceUiStateService.isEdit.pipe(untilDestroyed(this)).subscribe((isEdit) => {
      this.isEdit = isEdit;
      this.workflowMap = this.getWorkflowMap();
      this.updateWorkflow();
    });
  }

  get workspace(): Workspace {
    return this.workspaceService.currentWorkspace;
  }

  findPredecessors(
    si: SectionItem,
    sections: Section[],
    questions: Question[],
    mcAnswers: MultipleChoiceItem[],
    predecessors: MultipleChoiceItem[],
    prevItems: SectionItem[] = []
  ): void {
    const matchingAnswer = mcAnswers.find((item) => item.workflowId === si.id);
    prevItems.push(si);
    if (matchingAnswer && !predecessors.includes(matchingAnswer)) {
      const question = questions.find((q) => (q?.value || []).includes(matchingAnswer));
      predecessors.push(matchingAnswer);
      if (question) {
        this.findPredecessors(question, sections, questions, mcAnswers, predecessors, prevItems);
      }
    } else if (!matchingAnswer && si.parentId) {
      const parentSection = sections.find((section) => section.id === si.parentId);
      if (parentSection) {
        this.findPredecessors(
          parentSection,
          sections,
          questions,
          mcAnswers,
          predecessors,
          prevItems
        );
      }
    }
  }

  public getWorkflowMap(): Map<SectionItem, MultipleChoiceItem[]> {
    const workflowMap = new Map<SectionItem, MultipleChoiceItem[]>();
    const mcAnswers = this.workspace.allWorkflowAnswers;
    const workflowIds = mcAnswers.map((item) => item.workflowId);
    const allItems = this.workspace.allItems;
    const referencedSis = allItems.filter((item) => workflowIds.includes(item.id));
    const questions = this.workspace.allQuestions;
    const sections = this.workspace.sections;

    referencedSis.forEach((si) => {
      const predecessors = [];
      this.findPredecessors(si, sections, questions, mcAnswers, predecessors);
      workflowMap.set(si, predecessors);
    });
    return workflowMap;
  }

  public isVisible(si: SectionItem): boolean {
    const predecessors = this.workflowMap.get(si);
    return !predecessors || predecessors.every((item) => item.checked);
  }

  detectCycle(si: SectionItem, workflowId: string): boolean {
    const mcAnswers = this.workspace.allWorkflowAnswers;
    const questions = this.workspace.allQuestions;
    const sections = this.workspace.sections;

    const prevItems = [];
    this.findPredecessors(si, sections, questions, mcAnswers, [], prevItems);
    return prevItems.map((si) => si.id).includes(workflowId);
  }

  // Service message commands
  updateWorkflowById(workflowId: string, show: boolean): void {
    this.updateWorkflowSubject.next({ workflowId, show });
  }

  updateWorkflow(): void {
    this.workflowMap = this.getWorkflowMap();
    this.workflowMap.forEach((value, key) => {
      this.updateWorkflowById(key.id, this.isEdit || value.every((item) => item.checked));
    });
  }
}
