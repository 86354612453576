import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ContentItem, SectionItem } from '@core/models';
import { ContentService, ContextMenuItem, WorkspaceUiStateService } from '@core/services';
import { FileDownloaderService } from '@app/core/services/api/file-downloader.service';
import { TranslateService } from '@ngx-translate/core';
import { ISectionItemComponent } from '../workspace-request.component';
import { SectionService } from '@app/core/services/api/section.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-content-item',
  templateUrl: './content-item.component.html',
  styleUrls: ['./content-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentItemComponent implements OnChanges, ISectionItemComponent {
  @Input() contentItem: ContentItem;

  menu: ContextMenuItem[] = [];
  @Output() removeSectionItem = new EventEmitter<SectionItem>();
  @Output() checkboxChange = new EventEmitter<any>();

  constructor(
    public downloadService: FileDownloaderService,
    protected sectionService: SectionService,
    protected contentService: ContentService,
    public translate: TranslateService,
    private stateService: WorkspaceUiStateService,
    private cdr: ChangeDetectorRef
  ) {}

  get isEdit(): Observable<boolean> {
    return this.stateService.isEdit;
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }

  getIcon(fileName): 'panorama' | 'article' | 'theaters' | 'insert_drive_file' {
    const videoIcon = ['mpg', 'mpeg', 'mp4', 'webm', 'ogg'];
    const imageIcon = ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'webp'];
    const textIcon = [
      'txt',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'pdf',
      'ai',
      'psd',
      'svg',
      'eps',
      'ps',
      'tif',
      'xps',
    ];

    const fileArray = fileName.split('.');
    const fileType = fileArray[fileArray.length - 1];

    if (imageIcon.indexOf(fileType) > -1) {
      return 'panorama';
    } else if (textIcon.indexOf(fileType) > -1) {
      return 'article';
    } else if (videoIcon.indexOf(fileType) > -1) {
      return 'theaters';
    } else {
      return 'insert_drive_file';
    }
  }

  async download(item): Promise<void> {
    await this.downloadService.downloadContentItem(item.workspaceId, item, item.name);
  }

  async removeRequest(): Promise<void> {
    this.removeSectionItem.emit(this.contentItem);
    const { workspaceId, parentId: parentSectionId, id } = this.contentItem;
    await this.sectionService.deleteContentItemById(workspaceId, parentSectionId, id);
  }
}
