<div class="btn-with-submenu">
  <button (click)="createWorkspace.emit()" [disabled]="disabled" class="btn-primary">
    <span class="material-icons-outlined">add</span>
    {{ 'CreateCaption' | translate }}
  </button>
  <div
    [ngClass]="disabled ? 'disabled-menu-button' : 'click menu-button'"
    class="btn-primary"
    tabindex="-1">
    <span class="material-icons material-icon-light">keyboard_arrow_down</span>
  </div>
  <div class="popup popout-menu">
    <ul class="menu">
      <li>
        <button (click)="createWorkspace.emit()">
          <span class="material-icons-outlined">inbox</span>
          {{ 'New empty' | translate : { workspaceType: workspaceType } }}
        </button>
      </li>
      <li *ngIf="!showTemplates">
        <button (click)="createWorkspaceFromTemplate.emit()">
          <span class="material-icons-outlined">inbox</span>
          {{ 'Workspace from template' | translate }}
        </button>
      </li>
    </ul>
  </div>
</div>
