import { Component, HostListener } from '@angular/core';
import { ContextMenuItem, ContextService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent {
  context;
  subscription;

  constructor(public contextService: ContextService, private TranslateService: TranslateService) {
    this.context = contextService.context;
    this.subscription = contextService.contextChange.subscribe((value) => {
      this.context = value;
    });
  }

  public close(): void {
    this.contextService.hide();
  }

  params(item: any): any {
    const toLower = item.interpolationParams?.toLowerCase;
    const translate = (str: string): any => {
      const result = str ? this.TranslateService.instant(str) : '';
      return toLower ? result.toLowerCase() : result;
    };

    return Object.entries(item.interpolationParams || {})
      .filter(([key]) => key !== 'toLowerCase')
      .reduce((acc, [key, value]) => ({ ...acc, [key]: translate(value as string) }), {});
  }

  get list(): ContextMenuItem[] {
    return this.context.list.filter((item) =>
      typeof item.visible !== 'function' ? item.visible !== false : item.visible()
    );
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent(): void {
    this.contextService.hide();
  }
}
