import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Workspace } from '@app/core/models';
import { NotificationService, WorkspaceService } from '@app/core/services';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';

@Component({
  selector: 'copy-workspace-popup',
  templateUrl: 'copy-workspace-popup.html',
  styleUrls: ['copy-workspace-popup.scss'],
})
export class CopyWorkspacePopup implements OnInit {
  newName: string;
  newDescription: string;
  saveAsTemplate: boolean;

  @ViewChild('copyWorkspacePopup') copyWorkspacePopup: ModalPopupComponent;
  @Input() public workspace: Workspace;

  constructor(
    private workspaceService: WorkspaceService,
    private notifyService: NotificationService
  ) {}

  ngOnInit() {
    this.newName = this.workspace.name;
    this.newDescription = this.workspace.description;
  }

  get params() {
    return {
      workspaceType: this.destinationIsTemplate ? 'template' : 'workspace',
    };
  }

  get destinationIsTemplate() {
    return this.saveAsTemplate ? true : this.workspace.isTemplate;
  }

  async storeAsTemplate(): Promise<any> {
    // Hide dialog to prevent double clicks
    this.copyWorkspacePopup.popupActive = false;

    await this.workspaceService.post({
      name: this.newName,
      description: this.newDescription,
      sourceWorkspaceId: this.workspace.id,
      isTemplate: this.destinationIsTemplate,
    });
    this.closeSaveTemplateForm();
    const title = this.saveAsTemplate ? 'Workspace saved' : 'Workspace copied';
    const message = this.saveAsTemplate
      ? 'Workspace saved description'
      : 'Workspace copied description';
    this.notifyService.showSuccess(message, title, this.params);
  }

  closeSaveTemplateForm(): void {
    this.copyWorkspacePopup.popupActive = false;
    this.newName = '';
    this.newDescription = '';
  }

  open(saveAsTemplate: boolean) {
    this.copyWorkspacePopup.popupActive = true;
    this.saveAsTemplate = saveAsTemplate;
  }
}
