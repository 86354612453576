<div
  #checker
  class="multi-select-checker {{ type }}"
  [class.checked]="item.selected"
  click-stop-propagation>
  <label class="checkboxcontainer--new">
    <input type="checkbox" (change)="checkChange(item.selected)" [checked]="checkActiveCheckbox" />
    <span class="checkmark--new"></span>
  </label>
</div>
