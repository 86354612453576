export enum ProgressKind {
  None = 'None',
  Download = 'Download',
  Archive = 'Archive',
}

export class DownloadStatus {
  percentage: number = 0;
  progressKind: ProgressKind = ProgressKind.None;
}
