<div
  class="multi-status"
  [class.show-select]="showStatusChange"
  #statusMenu
  (click)="toggleSelect($event)">
  {{ 'Change status' | translate }}
  <span class="material-icons-outlined arrow-down">arrow_drop_down</span>

  <ul class="select-options" *ngIf="showStatusChange" [class.active]="selectOpen">
    <li *ngFor="let item of list">
      <div (click)="selectOpen = false; statusChange.emit(item.status)" click-stop-propagation>
        <span class="{{ item.style }}">
          {{ item.icon }}
        </span>
        {{ item.name | translate }}
      </div>
    </li>
  </ul>
</div>
