<div *ngIf="role && users">
  <div class="section-header">
    <input
      [(ngModel)]="userSearchText"
      class="searchBox"
      placeholder="{{ 'search' | translate }}"
      type="text" />
  </div>
</div>
<section *ngIf="users">
  <table *ngIf="users.length > 0">
    <tr>
      <th>{{ 'Assigned' | translate }}</th>
      <th [appSort]="users" class="sortable" data-name="firstname" scope="col">
        {{ 'Name' }}
        <app-sort-icon></app-sort-icon>
      </th>
      <th [appSort]="users" class="sortable" data-name="email" scope="col">
        {{ 'Email address' | translate }}
        <app-sort-icon></app-sort-icon>
      </th>
    </tr>
    <tr *ngFor="let user of users | userFilter: userSearchText">
      <td>
        <label class="checkboxcontainer">
          <input
            (change)="toggleUserActiveForRole(user)"
            [checked]="checkRoleActiveForUser(user)"
            type="checkbox" />
          <span class="checkmark"></span>
        </label>
      </td>
      <td>{{ user.firstname }} {{ user.lastname }}</td>
      <td>
        {{ user.email }}
      </td>
    </tr>
  </table>
</section>
