import { Component } from '@angular/core';
import { TabComponent } from '@app/shared/tabs/tab.component';

const hashMatch = /([^#]*)#?(.*)$/i;
const registrationMatch = /\s+\|\s+(.*)$/i;

@Component({
  selector: 'tabs',
  template: `
    <ul>
      <ng-container *ngFor="let tab of tabs">
        <li
          *ngIf="tab.visible"
          (click)="selectTab(tab)"
          [id]="tab.id"
          [ngClass]="tab.active ? 'active' : ''">
          <label>{{ tab.tabTitle }}</label>
          <span [ngClass]="tab.iconClass">{{ tab.icon }}</span>
        </li>
      </ng-container>
    </ul>
    <ng-content></ng-content>
  `,
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  tabs: TabComponent[] = [];

  selectTab(tab: TabComponent): void {
    this.tabs.forEach((activeTab) => {
      activeTab.active = false;
    });
    tab.active = true;
    this.registerTabNavigation(tab);
  }

  addTab(tab: TabComponent): void {
    if (this.tabs.length === 0) {
      tab.active = true;
    }
    this.tabs.push(tab);
  }

  jumpToFirst(): void {
    this.selectTab(this.tabs[0]);
  }

  registerTabNavigation(tab: TabComponent): void {
    if (typeof history.pushState !== 'undefined') {
      const href = document.location.href;
      const [_, stem, appendix] = hashMatch.exec(href) || [];
      const [__, tabKey] = registrationMatch.exec(tab.registrationUrl) || [];

      if (appendix !== tabKey) {
        const url = `${stem}#${encodeURIComponent(tabKey)}`;
        const obj = { Title: document.title, Url: url };
        history.pushState(obj, document.title, url);
      }
    }
    (window as any).gtag('config', 'G-N5J1MZD6WF', {
      // page_path: tab.registrationUrl,
      page_title: tab.registrationUrl,
    });
  }
}
