import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MultiSelectUpdateService } from '@app/shared/multi-select/multi-select-update.service';

@Component({
  selector: 'app-multi-select-checkbox',
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: ['./multi-select-checkbox.component.scss'],
})
export class MultiSelectCheckboxComponent implements OnInit {
  @ViewChild('checker') checker: ElementRef;

  @Input() type = 'request';
  @Input() item: any;

  @Output() checkboxChange = new EventEmitter<any>();

  constructor(
    private cdr: ChangeDetectorRef,
    private multiSelectUpdateService: MultiSelectUpdateService
  ) {}

  ngOnInit(): void {
    this.multiSelectUpdateService.onMultiUpdate$.subscribe(() => this.cdr.markForCheck());
  }

  get checkActiveCheckbox(): boolean {
    return this.item.selected;
  }

  checkChange(checked): void {
    this.item.selected = !checked;
    this.checkboxChange.emit();
  }
}
