import { Component } from '@angular/core';
import { Recipient } from '@app/core/models';
import {
  CommunicationService,
  NotificationService,
  SubscriptionService,
  UserAccountService,
} from '@core/services';
import { environment } from '@env/environment';

@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss'],
})
export class SupportFormComponent {
  message: string;
  selectedSupportQuestionSection: SupportQuestionSection = SupportQuestionSection.Sharing;

  public SupportQuestionSections = SupportQuestionSection;

  constructor(
    private userAccount: UserAccountService,
    private communicationService: CommunicationService,
    private notificationService: NotificationService,
    public subscriptionService: SubscriptionService
  ) {}

  async sendSupportRequest(): Promise<any> {
    // Extra catch in case somebody enables the button in the developer settings.
    // Needs server-side validation too.
    if (this.subscriptionService.currentPlanValue.isStarterTier) {
      this.notificationService.showError('UpgradePlanMessage', 'UpgradePlanTitle');
      return;
    }

    if (this.message === undefined || this.message === '') {
      this.notificationService.showError(
        'Please fill in the question you would like to ask to us.',
        'Error'
      );
      return;
    }

    const recipient: Recipient = {
      recipientEmailAddress: environment.supportEmailsRecipientAddress,
      recipientName: 'Binsend support',
    };
    await this.communicationService.sendEmail({
      senderAddress: this.userAccount.user.email,
      senderName: this.userAccount.user.email,
      templateId: environment.emptyMailTemplateId,
      recipients: [recipient],
      templateData: {
        Subject: `Binsend support question`,
        Sender: this.userAccount.user.email,
        Preheader: 'A binsend user has asked for your assistance.',
        EmailHeader: `Support section: ${this.selectedSupportQuestionSection}`,
        EmailBody: this.message,
      },
    });

    this.message = '';
    this.selectedSupportQuestionSection = SupportQuestionSection.Sharing;

    this.notificationService.showSuccess(
      'Your support question has been send. We will reach out to you as soon as possible',
      'Message successfully send'
    );
  }
}

export enum SupportQuestionSection {
  Sharing = 'Sharing',
  Workspaces = 'Workspaces',
  Templates = 'Templates',
  Account = 'Account',
  Subscription = 'Subscription',
  Payment = 'Payment',
  Other = 'Other',
}
