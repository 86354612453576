import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeaveGuardService {
  private mayLeaveSubject: Subject<boolean> = new Subject<boolean>();
  private mayLeave = false;
  private lastBlockedRoute: string;

  setStatusMayLeave(): void {
    this.mayLeave = true;
  }

  setStatusMustStay(): void {
    this.mayLeave = false;
  }

  getStatusMayLeave(): boolean {
    return this.mayLeave;
  }

  getLastBlockedRoute(): string {
    return this.lastBlockedRoute;
  }

  notifySubscribers(): void {
    this.mayLeaveSubject.next(true);
  }

  subscribe(callBackFunction: () => void): void {
    this.mayLeaveSubject.subscribe({
      next: callBackFunction,
    });
  }
}
