<h3>{{ title }}</h3>
<form *ngIf="address" autocomplete="__away" class="workspace-settings-object">
  <div class="input-group">
    <mt-input placeholder="{{ 'Street name' | translate }}">
      <input
        #mt="ngModel"
        (ngModelChange)="updateAddress({ streetName: $event })"
        [(ngModel)]="address.streetName"
        [disabled]="readOnly"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        autocomplete="__away"
        type="text" />
    </mt-input>
  </div>

  <div class="input-group">
    <mt-input placeholder="{{ 'Postal code' | translate }}">
      <input
        #mt="ngModel"
        (ngModelChange)="updateAddress({ postalCode: $event })"
        [(ngModel)]="address.postalCode"
        [disabled]="readOnly"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        autocomplete="__away"
        type="text" />
    </mt-input>
  </div>
  <div class="input-group">
    <mt-input placeholder="{{ 'City' | translate }}">
      <input
        #mt="ngModel"
        (ngModelChange)="updateAddress({ city: $event })"
        [(ngModel)]="address.city"
        [disabled]="readOnly"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        autocomplete="__away"
        id="city"
        type="text" />
    </mt-input>
  </div>
  <div class="input-group">
    <mt-input placeholder="{{ 'State' | translate }}">
      <input
        #mt="ngModel"
        (ngModelChange)="updateAddress({ state: $event })"
        [(ngModel)]="address.state"
        [disabled]="readOnly"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        autocomplete="__away"
        id="state"
        type="text" />
    </mt-input>
  </div>
  <div class="input-group">
    <ng-select
      (ngModelChange)="updateAddress({ country: $event })"
      [(ngModel)]="address.country"
      [disabled]="readOnly"
      [items]="countries"
      [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      id="country"
      placeholder="{{ 'Country' | translate }}"></ng-select>
  </div>
</form>
