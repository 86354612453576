import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { UploadGuardService } from '@core/services/upload-guard.service';

@Injectable({
  providedIn: 'root',
})
export class UploadGuard implements CanDeactivate<unknown> {
  constructor(private uploadGuardService: UploadGuardService) {}

  canDeactivate(): boolean {
    if (this.uploadGuardService.isFileBeingUploaded()) {
      this.uploadGuardService.notifySubscribers();
      return false;
    }
    return true;
  }
}
