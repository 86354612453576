import { ContentItem, INamed } from './content.item.model';
import { Message } from './message.model';
import { FileRequest } from './filerequest.model';
import { Question } from './question.model';
import { Section } from './section.model';

export enum SectionItemType {
  Section = 'Section',
  FileRequest = 'FileRequest',
  Question = 'Question',
  ContentItem = 'ContentItem',
}

export enum ItemPermissionType {
  Denied = 'Denied',
  Read = 'Read',
  ReadWrite = 'ReadWrite',
  Owner = 'Owner',
  PublicShare = 'PublicShare',
}

export enum ItemStatusType {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Received = 'Received',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Archived = 'Archived',
  NotApplicable = 'NotApplicable',
}

export interface ISectionItem extends ISectionItemDto {
  messages?: Message[];
  selected?: boolean;
  hoverActive?: boolean;
  focused: boolean;
  isOwner: boolean;
  isVisible: boolean;
  isCompleted: boolean;
  sectionId: string;

  insertSectionItem(sectionItem: SectionItem, index: number): void;
}

export interface ISectionItemDto extends INamed {
  id: string;
  type: SectionItemType;
  workspaceId: string;
  parentId: string;
  name: string;
  description?: string;
  permissionType: ItemPermissionType;
  status: ItemStatusType;
  copiedFrom?: string;
}

export type SectionItem = Section | FileRequest | Question | ContentItem;

export abstract class SectionItemBase implements ISectionItem {
  id: string;
  type: SectionItemType;
  workspaceId: string;
  parentId: string;
  abstract name: string;
  description?: string;
  permissionType: ItemPermissionType;
  abstract status: ItemStatusType;
  copiedFrom?: string;

  messages?: Message[];
  selected?: boolean;
  hoverActive?: boolean = false;
  focused = false;
  abstract isVisible: boolean;
  abstract isCompleted: boolean;
  abstract sectionId: string;
  abstract isSection: boolean;

  abstract insertSectionItem(sectionItem: SectionItem, index: number): void;

  get isOwner(): boolean {
    return this.permissionType === ItemPermissionType.Owner;
  }

  get canEdit(): boolean {
    return (
      this.permissionType === ItemPermissionType.ReadWrite ||
      this.permissionType === ItemPermissionType.Owner
    );
  }
}
