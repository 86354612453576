<div *ngIf="organization" class="settings-row">
  <div class="workspace-settings-group">
    <h3>{{ 'General' | translate }}</h3>
    <div class="workspace-settings-object">
      <div class="input-group">
        <label class="settings-title" for="currency">
          {{ 'Currency' | translate }}
        </label>
        <select
          (ngModelChange)="organization.preferredCurrency = $event; updateOrganization()"
          [(ngModel)]="organization.preferredCurrency"
          [disabled]="readOnly"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          autocomplete="__away"
          id="currency">
          <option
            *ngFor="let shareType of currencies | keyvalue"
            [selected]="currencies === shareType.value"
            [value]="shareType.value">
            {{ shareType.value | translate }}
          </option>
        </select>
      </div>

      <div class="input-group">
        <mt-input placeholder="{{ 'Financial email address' | translate }}">
          <input
            #mt="ngModel"
            (ngModelChange)="organization.financialEmailAddress = $event; updateOrganization()"
            [(ngModel)]="organization.financialEmailAddress"
            [disabled]="readOnly"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            autocomplete="__away"
            id="organization-email"
            type="text" />
        </mt-input>
      </div>
      <div class="input-group">
        <label class="settings-title" for="organization_bcc_email">
          Bcc {{ 'Financial email addresses' | translate | lowercase }}
        </label>
        <div class="bcc-emails">
          <div class="add-bcc">
            <input
              #organization_bcc_email="ngModel"
              (keyup)="invalidEmail = organization_bcc_email.invalid"
              (keyup.enter)="addEmail()"
              [(ngModel)]="newEmail"
              [disabled]="readOnly"
              autocomplete="__away"
              id="organization_bcc_email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              placeholder="{{ 'New email' | translate }}"
              type="text" />
            <button
              (click)="addEmail()"
              [disabled]="invalidEmail || newEmail === ''"
              class="btn-secondary add-file-request-btn">
              {{ 'Add' | translate }}
            </button>
          </div>
          <div *ngIf="bccEmails" class="bcc-chips">
            <div *ngFor="let email of bccEmails" class="email-chip">
              <p>{{ email }}</p>
              <span (click)="removeEmail(email)" class="material-icons">delete_outline</span>
            </div>
          </div>
        </div>
      </div>
      <div class="input-group">
        <mt-input placeholder="{{ 'VAT Id' | translate }}">
          <input
            #mt="ngModel"
            (ngModelChange)="organization.vatId = $event; updateOrganization()"
            [(ngModel)]="organization.vatId"
            [disabled]="readOnly"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            autocomplete="__away"
            id="phone"
            type="text" />
        </mt-input>
      </div>
      <div class="input-group">
        <mt-input placeholder="{{ 'Chamber of commerce' | translate }}">
          <input
            #mt="ngModel"
            (ngModelChange)="organization.chamberOfCommerce = $event; updateOrganization()"
            [(ngModel)]="organization.chamberOfCommerce"
            [disabled]="readOnly"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            autocomplete="__away"
            id="chamberOfCommerce"
            type="text" />
        </mt-input>
      </div>
    </div>
  </div>
  <app-address-form
    (addressUpdated)="organization.invoiceAddress = $event; updateOrganization()"
    *ngIf="organization"
    [address]="_organization.invoiceAddress"
    [readOnly]="readOnly"
    [title]="'Invoice address' | translate"
    class="workspace-settings-group"></app-address-form>
</div>

<h3>{{ 'Invoices' | translate }}</h3>
<app-invoice-payments *ngIf="invoices" [invoices]="invoices"></app-invoice-payments>
