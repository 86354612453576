import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Address, Organization, Subscription, UpdateOrganizationDto } from '@core/models';
import {
  BlobService,
  ContentService,
  NotificationService,
  OrganizationService,
  SubscriptionService,
} from '@core/services';
import { Constants } from '@core/common/constants';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';

@Component({
  selector: 'app-organization-information',
  templateUrl: './organization-information.component.html',
  styleUrls: ['./organization-information.component.scss'],
})
export class OrganizationInformationComponent implements OnInit, OnChanges {
  @Input() organization: Organization;
  @Input() readOnly = false;
  @Output() onUpdateOrganization: EventEmitter<UpdateOrganizationDto> =
    new EventEmitter<UpdateOrganizationDto>();

  @ViewChild('changeSubscriptionPopup')
  changeSubscriptionPopup: ModalPopupComponent;

  private selectedSubscription: Subscription;
  currentPlan: Subscription;
  subscriptions: Subscription[];
  originalSubscriptionId: string;

  logoPicture = '';
  loginPicture = '';

  customDomain = '';
  // eslint-disable-next-line no-useless-escape
  currentDomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.').slice(1).join('.');

  address: Address;

  newAmountOfDays;
  maxAllowedDays: number;

  constructor(
    public subscriptionService: SubscriptionService,
    private organizationService: OrganizationService,
    private contentService: ContentService,
    private blobService: BlobService,
    private notifyService: NotificationService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    if (this.organization?.binsendUrl) {
      this.customDomain = this.organization.binsendUrl
        .replace('https://', '')
        .replace(this.currentDomain, '')
        .replace('.', '')
        .replace('/', '');
    }
    this.cdr.markForCheck();
  }

  darkColor(color): boolean {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    const r = color >> 16;
    const g = (color >> 8) & 255;
    const b = color & 255; // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html

    // Variables for red, green, blue values
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)); // Using the HSP value, determine whether the color is light or dark
    return hsp <= 127.5;
  }

  async ngOnInit(): Promise<void> {
    this.originalSubscriptionId = this.organization.subscriptionId;
    this.currentPlan = this.subscriptionService.currentPlanValue;
    this.newAmountOfDays = this.organization.defaultWorkspaceRetentionInDays;
    this.subscriptionService.currentPlan.subscribe(({ subscription }) => {
      this.currentPlan = subscription;
      this.maxAllowedDays = this.currentPlan?.workspaceRetentionInDays;
    });

    this.subscriptions = await this.subscriptionService.list();
    this.address = this.organization?.address || new Address();
  }

  async uploadLogo(file: File): Promise<void> {
    let logoUrl = Constants.EmptyString;
    if (file) {
      const result = await this.contentService.getUploadUrl(this.organization.id, file.name, true);
      const { contentItem, uploadUrl } = result;
      await this.blobService.uploadFile(uploadUrl, file).toPromise();
      logoUrl = (
        await this.contentService.getDownloadUrl(this.organization.id, contentItem.id)
      ).body.split('?')[0];
      this.logoPicture = logoUrl;
    }

    this.updateOrganization({ logoUrl });
  }

  changeDate(): void {
    if (this.newAmountOfDays < 0) {
      this.newAmountOfDays = 30;
    } else {
      this.newAmountOfDays = -1;
    }

    this.updateDays();
  }

  async uploadLoginPicture(file: File): Promise<void> {
    let loginImageUrl = Constants.EmptyString;
    if (file) {
      const organizationId = this.organization.id;
      const result = await this.contentService.getUploadUrl(organizationId, file.name, true);
      const { contentItem, uploadUrl } = result;
      await this.blobService.uploadFile(uploadUrl, file).toPromise();
      loginImageUrl = (
        await this.contentService.getDownloadUrl(this.organization.id, contentItem.id)
      ).body.split('?')[0];
      this.loginPicture = loginImageUrl;
    }
    this.updateOrganization({ loginImageUrl });
  }

  clearLogo(): void {
    this.logoPicture = null;
    this.uploadLogo(null);
  }

  clearLoginImage(): void {
    this.loginPicture = null;
    this.uploadLoginPicture(null);
  }

  updateOrganization(organization: UpdateOrganizationDto) {
    const { websiteUrl, ...rest } = organization;
    organization = websiteUrl ? { ...rest, websiteUrl: absoluteUrl(websiteUrl) } : rest;
    this.onUpdateOrganization.emit(organization);
  }

  selectSubscriptionTab(): void {
    document.getElementById('payments').click();
  }

  async updateSubscription(): Promise<void> {
    const result = await this.subscriptionService.post({
      subscriptionId: this.organization.subscriptionId,
      organizationId: this.organization.id,
    });

    if (result.ok) {
      this.notifyService.showSuccess('AssignSubscriptionSuccessBody', 'Update successful');
      this.subscriptionService.currentPlan.next({ subscription: this.selectedSubscription });
      if (
        this.currentPlan.workspaceRetentionInDays >
        this.selectedSubscription.workspaceRetentionInDays
      ) {
        this.currentPlan.workspaceRetentionInDays =
          this.selectedSubscription.workspaceRetentionInDays;
        await this.organizationService.update(this.organization.id, {
          OrganizationDto: this.currentPlan,
        });
      }
    } else {
      this.notifyService.showError('AssignSubscriptionErrorBody', 'UnableToStoreChangesTitle');
    }
    this.changeSubscriptionPopup.popupActive = false;
  }

  resetPrimaryColor(): void {
    this.updateOrganization({ primaryColor: Constants.BinsendColorHex });
  }

  resetAccentColor(): void {
    this.updateOrganization({ accentColor: Constants.BinsendColorHex });
  }

  async setBinsendUrl(newValue: string): Promise<void> {
    this.customDomain = newValue;
    await this.updateOrganization({
      binsendUrl: `https://${this.customDomain}.${this.currentDomain}`,
    });
  }

  keypress(event: any) {
    const pattern = /[0-9a-zA-Z]/;
    let inputChar = event.key;

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  confirmUpdateOrganization(subscriptionId): void {
    const currentSub = this.subscriptions.filter((sub) => sub.id === subscriptionId);
    if (currentSub) {
      this.selectedSubscription = currentSub[0];
    }
    this.changeSubscriptionPopup.popupActive = true;
  }

  resetSubscription(): void {
    this.organization.subscriptionId = this.originalSubscriptionId;
    this.changeSubscriptionPopup.popupActive = false;
  }

  isInvalidAmountOfDays(): boolean {
    if (this.newAmountOfDays === -1) {
      return false;
    } else if (!this.maxAllowedDays && this.newAmountOfDays > 0) {
      return false;
    }
    return this.newAmountOfDays < 1 || this.newAmountOfDays > this.maxAllowedDays;
  }

  async updateDays(): Promise<void> {
    if (this.isInvalidAmountOfDays()) {
      return;
    }
    await this.updateOrganization({ defaultWorkspaceRetentionInDays: this.newAmountOfDays });
  }
}

const absoluteUrl = (url?: string) =>
  url && (url.startsWith('http') || url.startsWith('https') ? url : `https://${url}`);
