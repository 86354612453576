import { Address } from '@core/models/organization.model';
import { BehaviorSubject } from 'rxjs';
import { IUserAccount } from './account.model';

export class Relation {
  id: string;
  name: string;
  address: Address = new Address();
  phoneNumber: string;
  website: string;
  reference: string;
  emailAddress: string;
  notes: string;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
  chamberOfCommerce: string;
  contacts: Contact[];
  contactsList: BehaviorSubject<Contact[]>;

  constructor(relation: any) {
    const { contacts, ...rest } = relation;
    Object.assign(this, rest);
    this.contacts = contacts.map((contact) => new Contact(contact));
  }
}

export class Contact implements IUserAccount {
  id: string;
  firstname: string;
  lastname: string;
  activeBinsendAccount?: boolean;
  emailAddress: string;
  phoneNumber: string;
  isPrimary: boolean;
  creationTimestampUtc: Date;
  lastModifiedTimestampUtc: Date;
  organizations: string[];
  companyName: string;
  address?: Address;

  constructor(contact: any) {
    const { address, ...rest } = contact;
    Object.assign(this, rest);
    this.address = address ?? new Address();
  }

  get email(): string {
    return this.emailAddress;
  }

  get fullName(): string {
    return this.firstname ? `${this.firstname} ${this.lastname}` : this.email;
  }
}

export interface CreateRelationRequest {
  name: string;
}

export interface UpdateRelationRequest {
  name: string;
  address: Address;
  phoneNumber: string;
  website: string;
  reference: string;
  emailAddress: string;
  chamberOfCommerce: string;
  notes: string;
}

export interface UpdateContactRequest {
  email: string;
  firstName: string;
  lastName: string;
  isPrimary: boolean;
  phoneNumber: string;
  address?: Address;
  companyName?: string;
}

export interface CreateContactRequest {
  email: string;
  firstName: string;
  lastName: string;
  isPrimary: boolean;
  phoneNumber: string;
  relationId: string;
  address?: Address;
  companyName?: string;
}
