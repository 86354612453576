import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService, RelationsService, TitleService, UserInfoService } from '@core/services';
import { Contact, PermissionType, Relation } from '@core/models';
import { ModalPopupComponent } from '@app/shared/directives/modal-popup/modal-popup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-details',
  templateUrl: './relations-details.component.html',
  styleUrls: ['./relations-details.component.scss'],
})
export class RelationsDetailsComponent implements OnInit {
  public relation: Relation;
  public selectedContact: Contact;
  public permissionTypes = PermissionType;
  public invalidPhoneNumber;

  @ViewChild('deleteContactPopup') deleteContactPopup: ModalPopupComponent;
  @ViewChild('editContactPopup') editContactPopup: ModalPopupComponent;
  @ViewChild('contactEmail') contactEmailInputElement: ElementRef;
  searchString: string;
  private relationId: string;

  constructor(
    route: ActivatedRoute,
    private contactService: ContactService,
    private titleService: TitleService,
    private userInfoService: UserInfoService,
    private translateService: TranslateService,
    private relationService: RelationsService
  ) {
    this.relationId = route.snapshot.paramMap.get('id');
  }

  async ngOnInit(): Promise<void> {
    this.relation = await this.relationService.get(this.relationId);
    for (const contact of this.relation.contacts) {
      contact.activeBinsendAccount = !!(await this.userInfoService.getByEmail(
        contact.emailAddress
      ));
    }

    this.titleService.set(this.relation.name);
  }

  async updateRelation(): Promise<void> {
    this.relation = await this.relationService.update(this.relationId, this.relation);
    this.titleService.set(this.relation.name);
  }

  editContactDetails(contact: Contact): void {
    this.selectedContact = contact;
    this.editContactPopup.popupActive = true;
    this.editContactPopup.popupTitle = this.translateService.instant('Edit contact');
  }

  confirmDeleteContact(contact: Contact): void {
    this.selectedContact = contact;
    this.deleteContactPopup.popupActive = true;
  }

  async deleteContact(): Promise<void> {
    await this.contactService.delete(this.selectedContact.id);
    this.relation.contacts.splice(this.relation.contacts.indexOf(this.selectedContact), 1);
    this.deleteContactPopup.popupActive = false;
  }

  addContact(): void {
    this.selectedContact = new Contact({});
    this.editContactPopup.popupActive = true;
    this.editContactPopup.popupTitle = this.translateService.instant('New contact');

    setTimeout(() => {
      this.contactEmailInputElement.nativeElement.select();
    }, 300);
  }

  // If the selected contact is a new contact, this function will post a new contact to the API
  // If the selected contact is an existing contact, this function will update the existing contact
  async updateContact(): Promise<void> {
    if (this.invalidPhoneNumber) {
      return;
    }
    if (!this.selectedContact.id) {
      const result = await this.contactService.post({
        email: this.selectedContact.emailAddress,
        firstName: this.selectedContact.firstname,
        lastName: this.selectedContact.lastname,
        isPrimary: this.selectedContact.isPrimary,
        phoneNumber: this.selectedContact.phoneNumber,
        relationId: this.relationId,
        address: this.selectedContact.address,
        companyName: this.selectedContact.companyName,
      });
      this.selectedContact.id = result.body;
      this.relation.contacts.push(this.selectedContact);
    } else {
      await this.contactService.update(this.selectedContact.id, {
        email: this.selectedContact.emailAddress,
        firstName: this.selectedContact.firstname,
        lastName: this.selectedContact.lastname,
        isPrimary: this.selectedContact.isPrimary,
        phoneNumber: this.selectedContact.phoneNumber,
        address: this.selectedContact.address,
        companyName: this.selectedContact.companyName,
      });
    }
  }

  async checkKnownUser(): Promise<void> {
    const knownBinsendUser = await this.userInfoService.getByEmail(
      this.selectedContact.emailAddress
    );
    this.selectedContact.activeBinsendAccount = knownBinsendUser !== null;
    if (
      knownBinsendUser !== null &&
      (this.selectedContact.id === '' || this.selectedContact.id === null)
    ) {
      this.selectedContact.firstname = knownBinsendUser.firstname;
      this.selectedContact.lastname = knownBinsendUser.lastname;
    }
  }
}
