import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'create-workspace-button',
  templateUrl: './create-workspace-button.component.html',
})
export class CreateWorkspaceButtonComponent implements OnChanges {
  @Output() createWorkspace = new EventEmitter();
  @Output() createWorkspaceFromTemplate = new EventEmitter();
  @Input() disabled: boolean;
  @Input() showTemplates: boolean;
  showButtons: boolean;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  async ngOnChanges() {
    this.cdr.markForCheck();
  }

  get workspaceType(): any {
    return this.translateService.instant(this.showTemplates ? 'template' : 'workspace');
  }
}
