import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Organization, PermissionType, Subscription } from '@core/models';
import {
  NotificationService,
  OrganizationService,
  SubscriptionService,
  TitleService,
} from '@core/services';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss'],
})
export class OrganizationDetailsComponent implements OnInit {
  organizationId: string;
  organization: Organization;
  public permissionTypes = PermissionType;
  subscriptions: Subscription[];
  originalSubscriptionId: string;

  constructor(
    private organizationService: OrganizationService,
    private titleService: TitleService,
    private notifyService: NotificationService,
    private subscriptionService: SubscriptionService,
    route: ActivatedRoute
  ) {
    this.organizationId = route.snapshot.paramMap.get('id');
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions = await this.subscriptionService.list();
    this.organization = await this.organizationService.get(this.organizationId);
    this.originalSubscriptionId = this.organization.subscriptionId;
    this.titleService.set(this.organization.name);
  }

  async updateOrganization(): Promise<void> {
    const result = await this.subscriptionService.post({
      subscriptionId: this.organization.subscriptionId,
      organizationId: this.organizationId,
    });

    if (result.ok) {
      this.ngOnInit();
      this.notifyService.showSuccess('AssignOtherSubscriptionSuccessBody', 'Update successful');
    } else {
      this.notifyService.showError('AssignSubscriptionErrorBody', 'UnableToStoreChangesTitle');
    }
  }
}
