import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationListItem } from '@core/models';

@Pipe({
  name: 'organizationFilter',
})
export class OrganizationFilterPipe implements PipeTransform {
  transform(items: OrganizationListItem[], searchText: string): OrganizationListItem[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.name.toLowerCase().includes(searchText);
    });
  }
}
