// This service will keep track of the quota's for the user.
// E.g. how many workspaces can be created...
// What is the limit size of each workspace
// How many templates can be created.
// This service should be injected where needed to check quota's.

import { Injectable } from '@angular/core';
import { Subscription } from '@core/models';
import { SubscriptionService } from './api/subscription.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuotaService {
  public maxNumberOfTemplates?: number;
  public maxActiveWorkspaces?: number;
  public maxShareWithUsers?: number;
  public maxNumberOfUsers?: number;
  public maxNumberOfStyles?: number;
  public maxGbPerWorkspace?: number;
  public maxWorkspaceRetentionDays?: number;
  public nextSubscription: string;
  public maxNumberOfRelations?: number;
  public quotaChanged = new Subject<void>();

  constructor(private subscriptionService: SubscriptionService) {
    this.subscriptionService.currentPlan.subscribe(({ subscription, nextSubscription }) => {
      this.nextSubscription = nextSubscription?.name || '';
      this.calculateQuotas(subscription);
    });
  }

  calculateQuotas(subscription: Subscription): void {
    if (subscription === null || subscription === undefined) {
      // defaults for basic...
      this.maxNumberOfTemplates = 1;
      this.maxGbPerWorkspace = 2;
      this.maxActiveWorkspaces = 5;
      this.maxShareWithUsers = 5;
      this.maxWorkspaceRetentionDays = 5;
      this.maxNumberOfUsers = 1;
      this.maxNumberOfStyles = 0;
      this.maxNumberOfRelations = 5;
    } else {
      this.maxNumberOfUsers = subscription.maxUsers;
      this.maxNumberOfStyles = 2; // TODO add to domain model for subscriptions
      this.maxShareWithUsers = subscription.isStarterTier ? 5 : undefined; // TODO add to domain model for subscriptions
      this.maxActiveWorkspaces = subscription.isStarterTier ? 5 : undefined; // TODO add to domain model for subscriptions.
      this.maxWorkspaceRetentionDays = subscription.workspaceRetentionInDays;
      this.maxGbPerWorkspace = subscription.maxWorkspaceSizeInGb;
      this.maxNumberOfTemplates = subscription.maxFileSets;
      this.maxNumberOfRelations = subscription.maxNumberOfRelations;
    }
    this.quotaChanged.next();
  }
}
